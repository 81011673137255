/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutMedicalInstitutionLabelIdRequestSelections
 */
export interface PutMedicalInstitutionLabelIdRequestSelections {
    /**
     * 医療機関ラベル選択肢ID（更新の場合は設定する）
     * @type {number}
     * @memberof PutMedicalInstitutionLabelIdRequestSelections
     */
    id?: number | null;
    /**
     * 選択肢ラベル
     * @type {string}
     * @memberof PutMedicalInstitutionLabelIdRequestSelections
     */
    label: string;
}

export function PutMedicalInstitutionLabelIdRequestSelectionsFromJSON(json: any): PutMedicalInstitutionLabelIdRequestSelections {
    return PutMedicalInstitutionLabelIdRequestSelectionsFromJSONTyped(json, false);
}

export function PutMedicalInstitutionLabelIdRequestSelectionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutMedicalInstitutionLabelIdRequestSelections {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': json['label'],
    };
}

export function PutMedicalInstitutionLabelIdRequestSelectionsToJSON(value?: PutMedicalInstitutionLabelIdRequestSelections | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
    };
}

