/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Account,
    AccountFromJSON,
    AccountToJSON,
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    GetAccountsResponse,
    GetAccountsResponseFromJSON,
    GetAccountsResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostAccountRequest,
    PostAccountRequestFromJSON,
    PostAccountRequestToJSON,
    PostAccountResponse,
    PostAccountResponseFromJSON,
    PostAccountResponseToJSON,
    PutAccountRequest,
    PutAccountRequestFromJSON,
    PutAccountRequestToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteAccountRequest {
    id: number;
}

export interface GetAccountsRequest {
    page?: number;
}

export interface PostAccountOperationRequest {
    postAccountRequest: PostAccountRequest;
}

export interface PutAccountOperationRequest {
    id: number;
    putAccountRequest: PutAccountRequest;
}

/**
 * AccountsApi - interface
 * 
 * @export
 * @interface AccountsApiInterface
 */
export interface AccountsApiInterface {
    /**
     * 招待中のアカウントは削除、招待後のアカウントは非活性にする。 
     * @summary アカウントの削除
     * @param {number} id アカウントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    deleteAccountRaw(requestParameters: DeleteAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * 招待中のアカウントは削除、招待後のアカウントは非活性にする。 
     * アカウントの削除
     */
    deleteAccount(requestParameters: DeleteAccountRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * メダップユーザー以外のアカウントがログイン順(降順)で取得できる。 
     * @summary アカウント一覧の取得
     * @param {number} [page] ページ番号（１始まり）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    getAccountsRaw(requestParameters: GetAccountsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAccountsResponse>>;

    /**
     * メダップユーザー以外のアカウントがログイン順(降順)で取得できる。 
     * アカウント一覧の取得
     */
    getAccounts(requestParameters: GetAccountsRequest, initOverrides?: RequestInit): Promise<GetAccountsResponse>;

    /**
     * アカウントを発行し、ログイン方法がメールアドレスの場合は招待メールを送信する。 
     * @summary アカウントの発行
     * @param {PostAccountRequest} postAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    postAccountRaw(requestParameters: PostAccountOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostAccountResponse>>;

    /**
     * アカウントを発行し、ログイン方法がメールアドレスの場合は招待メールを送信する。 
     * アカウントの発行
     */
    postAccount(requestParameters: PostAccountOperationRequest, initOverrides?: RequestInit): Promise<PostAccountResponse>;

    /**
     * 
     * @summary アカウントの更新
     * @param {number} id アカウントID
     * @param {PutAccountRequest} putAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApiInterface
     */
    putAccountRaw(requestParameters: PutAccountOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Account>>;

    /**
     * アカウントの更新
     */
    putAccount(requestParameters: PutAccountOperationRequest, initOverrides?: RequestInit): Promise<Account>;

}

/**
 * 
 */
export class AccountsApi extends runtime.BaseAPI implements AccountsApiInterface {

    /**
     * 招待中のアカウントは削除、招待後のアカウントは非活性にする。 
     * アカウントの削除
     */
    async deleteAccountRaw(requestParameters: DeleteAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 招待中のアカウントは削除、招待後のアカウントは非活性にする。 
     * アカウントの削除
     */
    async deleteAccount(requestParameters: DeleteAccountRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteAccountRaw(requestParameters, initOverrides);
    }

    /**
     * メダップユーザー以外のアカウントがログイン順(降順)で取得できる。 
     * アカウント一覧の取得
     */
    async getAccountsRaw(requestParameters: GetAccountsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAccountsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accounts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAccountsResponseFromJSON(jsonValue));
    }

    /**
     * メダップユーザー以外のアカウントがログイン順(降順)で取得できる。 
     * アカウント一覧の取得
     */
    async getAccounts(requestParameters: GetAccountsRequest = {}, initOverrides?: RequestInit): Promise<GetAccountsResponse> {
        const response = await this.getAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アカウントを発行し、ログイン方法がメールアドレスの場合は招待メールを送信する。 
     * アカウントの発行
     */
    async postAccountRaw(requestParameters: PostAccountOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostAccountResponse>> {
        if (requestParameters.postAccountRequest === null || requestParameters.postAccountRequest === undefined) {
            throw new runtime.RequiredError('postAccountRequest','Required parameter requestParameters.postAccountRequest was null or undefined when calling postAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAccountRequestToJSON(requestParameters.postAccountRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostAccountResponseFromJSON(jsonValue));
    }

    /**
     * アカウントを発行し、ログイン方法がメールアドレスの場合は招待メールを送信する。 
     * アカウントの発行
     */
    async postAccount(requestParameters: PostAccountOperationRequest, initOverrides?: RequestInit): Promise<PostAccountResponse> {
        const response = await this.postAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アカウントの更新
     */
    async putAccountRaw(requestParameters: PutAccountOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putAccount.');
        }

        if (requestParameters.putAccountRequest === null || requestParameters.putAccountRequest === undefined) {
            throw new runtime.RequiredError('putAccountRequest','Required parameter requestParameters.putAccountRequest was null or undefined when calling putAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/accounts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutAccountRequestToJSON(requestParameters.putAccountRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     * アカウントの更新
     */
    async putAccount(requestParameters: PutAccountOperationRequest, initOverrides?: RequestInit): Promise<Account> {
        const response = await this.putAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
