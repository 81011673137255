/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutActionListItemsStatusRequest
 */
export interface PutActionListItemsStatusRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PutActionListItemsStatusRequest
     */
    medicalInstitutionIds: Array<number>;
    /**
     * ステータス
     * @type {string}
     * @memberof PutActionListItemsStatusRequest
     */
    status: PutActionListItemsStatusRequestStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum PutActionListItemsStatusRequestStatusEnum {
    Actioned = 'actioned',
    NotActioned = 'not_actioned'
}

export function PutActionListItemsStatusRequestFromJSON(json: any): PutActionListItemsStatusRequest {
    return PutActionListItemsStatusRequestFromJSONTyped(json, false);
}

export function PutActionListItemsStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutActionListItemsStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutionIds': json['medical_institution_ids'],
        'status': json['status'],
    };
}

export function PutActionListItemsStatusRequestToJSON(value?: PutActionListItemsStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution_ids': value.medicalInstitutionIds,
        'status': value.status,
    };
}

