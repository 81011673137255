/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 集計期間
 * @export
 * @enum {string}
 */
export enum AlertConditionUnitType {
    Month = 'month',
    Quarter = 'quarter',
    HalfYear = 'half_year',
    Year = 'year'
}

export function AlertConditionUnitTypeFromJSON(json: any): AlertConditionUnitType {
    return AlertConditionUnitTypeFromJSONTyped(json, false);
}

export function AlertConditionUnitTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionUnitType {
    return json as AlertConditionUnitType;
}

export function AlertConditionUnitTypeToJSON(value?: AlertConditionUnitType | null): any {
    return value as any;
}

