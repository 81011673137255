/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OwnDepartmentReportItemDefinitionEnabledFiltersEnum {
    Exists = 'exists',
    MoreEqual = 'more_equal',
    LessEqual = 'less_equal',
    NotIn = 'not_in',
    In = 'in'
}

export function OwnDepartmentReportItemDefinitionEnabledFiltersEnumFromJSON(json: any): OwnDepartmentReportItemDefinitionEnabledFiltersEnum {
    return OwnDepartmentReportItemDefinitionEnabledFiltersEnumFromJSONTyped(json, false);
}

export function OwnDepartmentReportItemDefinitionEnabledFiltersEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportItemDefinitionEnabledFiltersEnum {
    return json as OwnDepartmentReportItemDefinitionEnabledFiltersEnum;
}

export function OwnDepartmentReportItemDefinitionEnabledFiltersEnumToJSON(value?: OwnDepartmentReportItemDefinitionEnabledFiltersEnum | null): any {
    return value as any;
}

