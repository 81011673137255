/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationLabelConditionDateConditions,
    CommunicationLabelConditionDateConditionsFromJSON,
    CommunicationLabelConditionDateConditionsFromJSONTyped,
    CommunicationLabelConditionDateConditionsToJSON,
} from './CommunicationLabelConditionDateConditions';
import {
    CommunicationLabelConditionIntegerConditions,
    CommunicationLabelConditionIntegerConditionsFromJSON,
    CommunicationLabelConditionIntegerConditionsFromJSONTyped,
    CommunicationLabelConditionIntegerConditionsToJSON,
} from './CommunicationLabelConditionIntegerConditions';
import {
    CommunicationLabelConditionSelectionConditions,
    CommunicationLabelConditionSelectionConditionsFromJSON,
    CommunicationLabelConditionSelectionConditionsFromJSONTyped,
    CommunicationLabelConditionSelectionConditionsToJSON,
} from './CommunicationLabelConditionSelectionConditions';
import {
    CommunicationLabelConditionSelectionsConditions,
    CommunicationLabelConditionSelectionsConditionsFromJSON,
    CommunicationLabelConditionSelectionsConditionsFromJSONTyped,
    CommunicationLabelConditionSelectionsConditionsToJSON,
} from './CommunicationLabelConditionSelectionsConditions';
import {
    CommunicationLabelConditionTextConditions,
    CommunicationLabelConditionTextConditionsFromJSON,
    CommunicationLabelConditionTextConditionsFromJSONTyped,
    CommunicationLabelConditionTextConditionsToJSON,
} from './CommunicationLabelConditionTextConditions';
import {
    CommunicationLabelConditionTimeConditions,
    CommunicationLabelConditionTimeConditionsFromJSON,
    CommunicationLabelConditionTimeConditionsFromJSONTyped,
    CommunicationLabelConditionTimeConditionsToJSON,
} from './CommunicationLabelConditionTimeConditions';

/**
 * コミュニケーション検索機能で利用するコミュニケーションラベル条件
 * @export
 * @interface CommunicationLabelCondition
 */
export interface CommunicationLabelCondition {
    /**
     * コミュニケーションラベル id
     * @type {number}
     * @memberof CommunicationLabelCondition
     */
    communicationLabelId: number;
    /**
     * 
     * @type {CommunicationLabelConditionTextConditions}
     * @memberof CommunicationLabelCondition
     */
    textConditions?: CommunicationLabelConditionTextConditions;
    /**
     * 
     * @type {CommunicationLabelConditionIntegerConditions}
     * @memberof CommunicationLabelCondition
     */
    integerConditions?: CommunicationLabelConditionIntegerConditions;
    /**
     * 
     * @type {CommunicationLabelConditionSelectionConditions}
     * @memberof CommunicationLabelCondition
     */
    selectionConditions?: CommunicationLabelConditionSelectionConditions;
    /**
     * 
     * @type {CommunicationLabelConditionSelectionsConditions}
     * @memberof CommunicationLabelCondition
     */
    selectionsConditions?: CommunicationLabelConditionSelectionsConditions;
    /**
     * 
     * @type {CommunicationLabelConditionDateConditions}
     * @memberof CommunicationLabelCondition
     */
    dateConditions?: CommunicationLabelConditionDateConditions;
    /**
     * 
     * @type {CommunicationLabelConditionTimeConditions}
     * @memberof CommunicationLabelCondition
     */
    timeConditions?: CommunicationLabelConditionTimeConditions;
}

export function CommunicationLabelConditionFromJSON(json: any): CommunicationLabelCondition {
    return CommunicationLabelConditionFromJSONTyped(json, false);
}

export function CommunicationLabelConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabelCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communicationLabelId': json['communication_label_id'],
        'textConditions': !exists(json, 'text_conditions') ? undefined : CommunicationLabelConditionTextConditionsFromJSON(json['text_conditions']),
        'integerConditions': !exists(json, 'integer_conditions') ? undefined : CommunicationLabelConditionIntegerConditionsFromJSON(json['integer_conditions']),
        'selectionConditions': !exists(json, 'selection_conditions') ? undefined : CommunicationLabelConditionSelectionConditionsFromJSON(json['selection_conditions']),
        'selectionsConditions': !exists(json, 'selections_conditions') ? undefined : CommunicationLabelConditionSelectionsConditionsFromJSON(json['selections_conditions']),
        'dateConditions': !exists(json, 'date_conditions') ? undefined : CommunicationLabelConditionDateConditionsFromJSON(json['date_conditions']),
        'timeConditions': !exists(json, 'time_conditions') ? undefined : CommunicationLabelConditionTimeConditionsFromJSON(json['time_conditions']),
    };
}

export function CommunicationLabelConditionToJSON(value?: CommunicationLabelCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communication_label_id': value.communicationLabelId,
        'text_conditions': CommunicationLabelConditionTextConditionsToJSON(value.textConditions),
        'integer_conditions': CommunicationLabelConditionIntegerConditionsToJSON(value.integerConditions),
        'selection_conditions': CommunicationLabelConditionSelectionConditionsToJSON(value.selectionConditions),
        'selections_conditions': CommunicationLabelConditionSelectionsConditionsToJSON(value.selectionsConditions),
        'date_conditions': CommunicationLabelConditionDateConditionsToJSON(value.dateConditions),
        'time_conditions': CommunicationLabelConditionTimeConditionsToJSON(value.timeConditions),
    };
}

