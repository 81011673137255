import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

type ScrimState = { isShown: false } | { isShown: true; message?: string };

const scrimState = atom<ScrimState>({
  key: 'scrimState',
  default: { isShown: false },
});

export const useScrimState = (): ScrimState => useRecoilValue(scrimState);

export const useScrim = (): {
  show: (message?: string) => void;
  hide: VoidFunction;
} => {
  const setIsShown = useSetRecoilState(scrimState);
  const show = useCallback(
    (message?: string) => setIsShown({ isShown: true, message }),
    [],
  );
  const hide = useCallback(() => setIsShown({ isShown: false }), []);

  return {
    show,
    hide,
  };
};
