import { useCallback, useEffect } from 'react';
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

type Severity = 'error' | 'success';

type ToastState =
  | { isShown: false }
  | {
      isShown: true;
      severity: Severity;
      message: string;
    };

const toastState = atom<ToastState>({
  key: 'toastState',
  default: { isShown: false },
});

export const useToastState = (): ToastState => useRecoilValue(toastState);

export const useShowToast = (): ((
  severity: Severity,
  message: string,
) => void) => {
  const [isShown, setIsShown] = useRecoilState(toastState);

  useEffect(() => {
    if (!isShown) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsShown({ isShown: false });
    }, 3000);

    return () => {
      clearTimeout(timerId);
    };
  }, [isShown]);

  return useCallback(
    (severity, message) => setIsShown({ isShown: true, severity, message }),
    [],
  );
};

export const useHideToast = (): VoidFunction => {
  const setIsShown = useSetRecoilState(toastState);

  return useCallback(() => setIsShown({ isShown: false }), []);
};
