/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertParameter,
    AlertParameterFromJSON,
    AlertParameterFromJSONTyped,
    AlertParameterToJSON,
} from './AlertParameter';
import {
    AlertParameterGroup,
    AlertParameterGroupFromJSON,
    AlertParameterGroupFromJSONTyped,
    AlertParameterGroupToJSON,
} from './AlertParameterGroup';

/**
 * アラート条件のパラメータ
 * @export
 * @interface AlertConditionParameters
 */
export interface AlertConditionParameters {
    /**
     * アラート発生条件
     * @type {Array<AlertParameter>}
     * @memberof AlertConditionParameters
     */
    triggers: Array<AlertParameter>;
    /**
     * アラート絞り込み条件
     * @type {Array<AlertParameterGroup>}
     * @memberof AlertConditionParameters
     */
    filters: Array<AlertParameterGroup> | null;
}

export function AlertConditionParametersFromJSON(json: any): AlertConditionParameters {
    return AlertConditionParametersFromJSONTyped(json, false);
}

export function AlertConditionParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'triggers': ((json['triggers'] as Array<any>).map(AlertParameterFromJSON)),
        'filters': (json['filters'] === null ? null : (json['filters'] as Array<any>).map(AlertParameterGroupFromJSON)),
    };
}

export function AlertConditionParametersToJSON(value?: AlertConditionParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'triggers': ((value.triggers as Array<any>).map(AlertParameterToJSON)),
        'filters': (value.filters === null ? null : (value.filters as Array<any>).map(AlertParameterGroupToJSON)),
    };
}

