/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationImport,
    CommunicationImportFromJSON,
    CommunicationImportFromJSONTyped,
    CommunicationImportToJSON,
} from './CommunicationImport';

/**
 * 
 * @export
 * @interface GetImportsCommunicationsResponse
 */
export interface GetImportsCommunicationsResponse {
    /**
     * 紹介データファイルの一覧
     * @type {Array<CommunicationImport>}
     * @memberof GetImportsCommunicationsResponse
     */
    imports: Array<CommunicationImport>;
}

export function GetImportsCommunicationsResponseFromJSON(json: any): GetImportsCommunicationsResponse {
    return GetImportsCommunicationsResponseFromJSONTyped(json, false);
}

export function GetImportsCommunicationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetImportsCommunicationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imports': ((json['imports'] as Array<any>).map(CommunicationImportFromJSON)),
    };
}

export function GetImportsCommunicationsResponseToJSON(value?: GetImportsCommunicationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imports': ((value.imports as Array<any>).map(CommunicationImportToJSON)),
    };
}

