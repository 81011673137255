/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdsSearchCondition,
    IdsSearchConditionFromJSON,
    IdsSearchConditionFromJSONTyped,
    IdsSearchConditionToJSON,
} from './IdsSearchCondition';
import {
    LabelSearchCondition,
    LabelSearchConditionFromJSON,
    LabelSearchConditionFromJSONTyped,
    LabelSearchConditionToJSON,
} from './LabelSearchCondition';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionSearchRequest
 */
export interface PostMedicalInstitutionSearchRequest {
    /**
     * ページ番号（１始まり）
     * @type {number}
     * @memberof PostMedicalInstitutionSearchRequest
     */
    page: number | null;
    /**
     * 病院名
     * @type {string}
     * @memberof PostMedicalInstitutionSearchRequest
     */
    medicalInstitutionName: string | null;
    /**
     * 管理者名
     * @type {string}
     * @memberof PostMedicalInstitutionSearchRequest
     */
    administratorName: string | null;
    /**
     * 都道府県
     * @type {string}
     * @memberof PostMedicalInstitutionSearchRequest
     */
    prefecture: string | null;
    /**
     * 地域
     * @type {string}
     * @memberof PostMedicalInstitutionSearchRequest
     */
    area: string | null;
    /**
     * 医療機関タイプ(施設タイプ)ID
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionSearchRequest
     */
    facilityTypeIds: Array<string> | null;
    /**
     * 診療科目ID
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionSearchRequest
     */
    departmentIds: Array<string> | null;
    /**
     * タグID
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionSearchRequest
     */
    tagIds: Array<string> | null;
    /**
     * 
     * @type {IdsSearchCondition}
     * @memberof PostMedicalInstitutionSearchRequest
     */
    tagIdsSearchCondition: IdsSearchCondition | null;
    /**
     * ラベル検索条件
     * @type {Array<LabelSearchCondition>}
     * @memberof PostMedicalInstitutionSearchRequest
     */
    labels: Array<LabelSearchCondition> | null;
}

export function PostMedicalInstitutionSearchRequestFromJSON(json: any): PostMedicalInstitutionSearchRequest {
    return PostMedicalInstitutionSearchRequestFromJSONTyped(json, false);
}

export function PostMedicalInstitutionSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'medicalInstitutionName': json['medical_institution_name'],
        'administratorName': json['administrator_name'],
        'prefecture': json['prefecture'],
        'area': json['area'],
        'facilityTypeIds': json['facility_type_ids'],
        'departmentIds': json['department_ids'],
        'tagIds': json['tag_ids'],
        'tagIdsSearchCondition': IdsSearchConditionFromJSON(json['tag_ids_search_condition']),
        'labels': (json['labels'] === null ? null : (json['labels'] as Array<any>).map(LabelSearchConditionFromJSON)),
    };
}

export function PostMedicalInstitutionSearchRequestToJSON(value?: PostMedicalInstitutionSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'medical_institution_name': value.medicalInstitutionName,
        'administrator_name': value.administratorName,
        'prefecture': value.prefecture,
        'area': value.area,
        'facility_type_ids': value.facilityTypeIds,
        'department_ids': value.departmentIds,
        'tag_ids': value.tagIds,
        'tag_ids_search_condition': IdsSearchConditionToJSON(value.tagIdsSearchCondition),
        'labels': (value.labels === null ? null : (value.labels as Array<any>).map(LabelSearchConditionToJSON)),
    };
}

