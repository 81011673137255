/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionRunningStatusEnum,
    MedicalInstitutionRunningStatusEnumFromJSON,
    MedicalInstitutionRunningStatusEnumFromJSONTyped,
    MedicalInstitutionRunningStatusEnumToJSON,
} from './MedicalInstitutionRunningStatusEnum';

/**
 * 医療機関
 * @export
 * @interface MedicalInstitutionForActionList
 */
export interface MedicalInstitutionForActionList {
    /**
     * 医療機関ID
     * @type {number}
     * @memberof MedicalInstitutionForActionList
     */
    id: number;
    /**
     * 名前
     * @type {string}
     * @memberof MedicalInstitutionForActionList
     */
    name: string;
    /**
     * 
     * @type {MedicalInstitutionRunningStatusEnum}
     * @memberof MedicalInstitutionForActionList
     */
    runningStatus: MedicalInstitutionRunningStatusEnum;
    /**
     * 住所
     * @type {string}
     * @memberof MedicalInstitutionForActionList
     */
    address: string;
    /**
     * 診療科目一覧
     * @type {Array<string>}
     * @memberof MedicalInstitutionForActionList
     */
    departments: Array<string>;
    /**
     * 最終訪問日
     * @type {string}
     * @memberof MedicalInstitutionForActionList
     */
    lastVisitDate: string;
    /**
     * ステータス
     * @type {string}
     * @memberof MedicalInstitutionForActionList
     */
    status: MedicalInstitutionForActionListStatusEnum;
    /**
     * 紐付いているタグ名一覧
     * @type {Array<string>}
     * @memberof MedicalInstitutionForActionList
     */
    tags: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum MedicalInstitutionForActionListStatusEnum {
    Actioned = 'actioned',
    NotActioned = 'not_actioned'
}

export function MedicalInstitutionForActionListFromJSON(json: any): MedicalInstitutionForActionList {
    return MedicalInstitutionForActionListFromJSONTyped(json, false);
}

export function MedicalInstitutionForActionListFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionForActionList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'runningStatus': MedicalInstitutionRunningStatusEnumFromJSON(json['running_status']),
        'address': json['address'],
        'departments': json['departments'],
        'lastVisitDate': json['last_visit_date'],
        'status': json['status'],
        'tags': json['tags'],
    };
}

export function MedicalInstitutionForActionListToJSON(value?: MedicalInstitutionForActionList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'running_status': MedicalInstitutionRunningStatusEnumToJSON(value.runningStatus),
        'address': value.address,
        'departments': value.departments,
        'last_visit_date': value.lastVisitDate,
        'status': value.status,
        'tags': value.tags,
    };
}

