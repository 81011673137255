/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel,
    MedicalInstitutionReportItemDefinitionMedicalInstitutionLabelFromJSON,
    MedicalInstitutionReportItemDefinitionMedicalInstitutionLabelFromJSONTyped,
    MedicalInstitutionReportItemDefinitionMedicalInstitutionLabelToJSON,
} from './MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel';
import {
    MedicalInstitutionReportItemDefinitionPreset,
    MedicalInstitutionReportItemDefinitionPresetFromJSON,
    MedicalInstitutionReportItemDefinitionPresetFromJSONTyped,
    MedicalInstitutionReportItemDefinitionPresetToJSON,
} from './MedicalInstitutionReportItemDefinitionPreset';

/**
 * 
 * @export
 * @interface GetMedicalInstitutionReportItemDefinitionsResponse
 */
export interface GetMedicalInstitutionReportItemDefinitionsResponse {
    /**
     * 連携先レポート項目定義、プリセット項目
     * @type {Array<MedicalInstitutionReportItemDefinitionPreset>}
     * @memberof GetMedicalInstitutionReportItemDefinitionsResponse
     */
    presets: Array<MedicalInstitutionReportItemDefinitionPreset>;
    /**
     * 連携先レポート項目定義、医療機関ラベル項目
     * @type {Array<MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel>}
     * @memberof GetMedicalInstitutionReportItemDefinitionsResponse
     */
    medicalInstitutionLabels: Array<MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel>;
}

export function GetMedicalInstitutionReportItemDefinitionsResponseFromJSON(json: any): GetMedicalInstitutionReportItemDefinitionsResponse {
    return GetMedicalInstitutionReportItemDefinitionsResponseFromJSONTyped(json, false);
}

export function GetMedicalInstitutionReportItemDefinitionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMedicalInstitutionReportItemDefinitionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presets': ((json['presets'] as Array<any>).map(MedicalInstitutionReportItemDefinitionPresetFromJSON)),
        'medicalInstitutionLabels': ((json['medical_institution_labels'] as Array<any>).map(MedicalInstitutionReportItemDefinitionMedicalInstitutionLabelFromJSON)),
    };
}

export function GetMedicalInstitutionReportItemDefinitionsResponseToJSON(value?: GetMedicalInstitutionReportItemDefinitionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'presets': ((value.presets as Array<any>).map(MedicalInstitutionReportItemDefinitionPresetToJSON)),
        'medical_institution_labels': ((value.medicalInstitutionLabels as Array<any>).map(MedicalInstitutionReportItemDefinitionMedicalInstitutionLabelToJSON)),
    };
}

