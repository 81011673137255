/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostDataAnalysisRankingReferralAnnualRequest
 */
export interface PostDataAnalysisRankingReferralAnnualRequest {
    /**
     * ページ番号 (1始まり)
     * @type {number}
     * @memberof PostDataAnalysisRankingReferralAnnualRequest
     */
    page: number;
    /**
     * 都道府県
     * @type {string}
     * @memberof PostDataAnalysisRankingReferralAnnualRequest
     */
    prefecture: string | null;
    /**
     * 地域
     * @type {string}
     * @memberof PostDataAnalysisRankingReferralAnnualRequest
     */
    area: string | null;
    /**
     * 医療機関タイプ(施設タイプ)ID
     * @type {Array<string>}
     * @memberof PostDataAnalysisRankingReferralAnnualRequest
     */
    facilityTypeIds: Array<string> | null;
    /**
     * 診療科目ID
     * @type {Array<string>}
     * @memberof PostDataAnalysisRankingReferralAnnualRequest
     */
    departmentIds: Array<string> | null;
    /**
     * 増減数の並び順
     * @type {string}
     * @memberof PostDataAnalysisRankingReferralAnnualRequest
     */
    sort: PostDataAnalysisRankingReferralAnnualRequestSortEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostDataAnalysisRankingReferralAnnualRequestSortEnum {
    Desc = 'desc',
    Asc = 'asc'
}

export function PostDataAnalysisRankingReferralAnnualRequestFromJSON(json: any): PostDataAnalysisRankingReferralAnnualRequest {
    return PostDataAnalysisRankingReferralAnnualRequestFromJSONTyped(json, false);
}

export function PostDataAnalysisRankingReferralAnnualRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisRankingReferralAnnualRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'prefecture': json['prefecture'],
        'area': json['area'],
        'facilityTypeIds': json['facility_type_ids'],
        'departmentIds': json['department_ids'],
        'sort': json['sort'],
    };
}

export function PostDataAnalysisRankingReferralAnnualRequestToJSON(value?: PostDataAnalysisRankingReferralAnnualRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'prefecture': value.prefecture,
        'area': value.area,
        'facility_type_ids': value.facilityTypeIds,
        'department_ids': value.departmentIds,
        'sort': value.sort,
    };
}

