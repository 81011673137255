/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionType,
    AlertConditionTypeFromJSON,
    AlertConditionTypeFromJSONTyped,
    AlertConditionTypeToJSON,
} from './AlertConditionType';
import {
    PostNewAlertConditionFilters,
    PostNewAlertConditionFiltersFromJSON,
    PostNewAlertConditionFiltersFromJSONTyped,
    PostNewAlertConditionFiltersToJSON,
} from './PostNewAlertConditionFilters';
import {
    PostNewAlertConditionTriggers,
    PostNewAlertConditionTriggersFromJSON,
    PostNewAlertConditionTriggersFromJSONTyped,
    PostNewAlertConditionTriggersToJSON,
} from './PostNewAlertConditionTriggers';

/**
 * 
 * @export
 * @interface PostNewAlertCondition
 */
export interface PostNewAlertCondition {
    /**
     * アラート名
     * @type {string}
     * @memberof PostNewAlertCondition
     */
    name: string;
    /**
     * 
     * @type {AlertConditionType}
     * @memberof PostNewAlertCondition
     */
    type: AlertConditionType;
    /**
     * 
     * @type {PostNewAlertConditionTriggers}
     * @memberof PostNewAlertCondition
     */
    triggers: PostNewAlertConditionTriggers;
    /**
     * 
     * @type {PostNewAlertConditionFilters}
     * @memberof PostNewAlertCondition
     */
    filters: PostNewAlertConditionFilters;
}

export function PostNewAlertConditionFromJSON(json: any): PostNewAlertCondition {
    return PostNewAlertConditionFromJSONTyped(json, false);
}

export function PostNewAlertConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostNewAlertCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': AlertConditionTypeFromJSON(json['type']),
        'triggers': PostNewAlertConditionTriggersFromJSON(json['triggers']),
        'filters': PostNewAlertConditionFiltersFromJSON(json['filters']),
    };
}

export function PostNewAlertConditionToJSON(value?: PostNewAlertCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': AlertConditionTypeToJSON(value.type),
        'triggers': PostNewAlertConditionTriggersToJSON(value.triggers),
        'filters': PostNewAlertConditionFiltersToJSON(value.filters),
    };
}

