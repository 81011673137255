/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommunicationLabelConditionIntegerConditions
 */
export interface CommunicationLabelConditionIntegerConditions {
    /**
     * 数値型のラベル条件。指定した数値以上の場合にマッチする条件
     * @type {number}
     * @memberof CommunicationLabelConditionIntegerConditions
     */
    moreEqual?: number | null;
    /**
     * 数値型のラベル条件。指定した数値以下の場合にマッチする条件。暗黙的に値を指定していないデータを含める。
     * @type {number}
     * @memberof CommunicationLabelConditionIntegerConditions
     */
    lessEqual?: number | null;
}

export function CommunicationLabelConditionIntegerConditionsFromJSON(json: any): CommunicationLabelConditionIntegerConditions {
    return CommunicationLabelConditionIntegerConditionsFromJSONTyped(json, false);
}

export function CommunicationLabelConditionIntegerConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabelConditionIntegerConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'moreEqual': !exists(json, 'more_equal') ? undefined : json['more_equal'],
        'lessEqual': !exists(json, 'less_equal') ? undefined : json['less_equal'],
    };
}

export function CommunicationLabelConditionIntegerConditionsToJSON(value?: CommunicationLabelConditionIntegerConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'more_equal': value.moreEqual,
        'less_equal': value.lessEqual,
    };
}

