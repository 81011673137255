/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsRequestPeriod,
    PostChartReferralsRequestPeriodFromJSON,
    PostChartReferralsRequestPeriodFromJSONTyped,
    PostChartReferralsRequestPeriodToJSON,
} from './PostChartReferralsRequestPeriod';

/**
 * 
 * @export
 * @interface PostGroupsChartReferralsRequest
 */
export interface PostGroupsChartReferralsRequest {
    /**
     * グループ病院のID
     * @type {Array<number>}
     * @memberof PostGroupsChartReferralsRequest
     */
    groupMedicalInstitutionIds: Array<number>;
    /**
     * 
     * @type {PostChartReferralsRequestPeriod}
     * @memberof PostGroupsChartReferralsRequest
     */
    period: PostChartReferralsRequestPeriod;
    /**
     * 紹介数の集計時間単位
     * `week`, `month`, `year`
     * @type {string}
     * @memberof PostGroupsChartReferralsRequest
     */
    unitType: PostGroupsChartReferralsRequestUnitTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostGroupsChartReferralsRequestUnitTypeEnum {
    Week = 'week',
    Month = 'month',
    Year = 'year'
}

export function PostGroupsChartReferralsRequestFromJSON(json: any): PostGroupsChartReferralsRequest {
    return PostGroupsChartReferralsRequestFromJSONTyped(json, false);
}

export function PostGroupsChartReferralsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostGroupsChartReferralsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupMedicalInstitutionIds': json['group_medical_institution_ids'],
        'period': PostChartReferralsRequestPeriodFromJSON(json['period']),
        'unitType': json['unit_type'],
    };
}

export function PostGroupsChartReferralsRequestToJSON(value?: PostGroupsChartReferralsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_medical_institution_ids': value.groupMedicalInstitutionIds,
        'period': PostChartReferralsRequestPeriodToJSON(value.period),
        'unit_type': value.unitType,
    };
}

