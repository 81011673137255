/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PostGroupsChartReferralsComparisonAnnualRequest,
    PostGroupsChartReferralsComparisonAnnualRequestFromJSON,
    PostGroupsChartReferralsComparisonAnnualRequestToJSON,
    PostGroupsChartReferralsComparisonAnnualResponse,
    PostGroupsChartReferralsComparisonAnnualResponseFromJSON,
    PostGroupsChartReferralsComparisonAnnualResponseToJSON,
    PostGroupsChartReferralsComparisonMonthlyRequest,
    PostGroupsChartReferralsComparisonMonthlyRequestFromJSON,
    PostGroupsChartReferralsComparisonMonthlyRequestToJSON,
    PostGroupsChartReferralsComparisonMonthlyResponse,
    PostGroupsChartReferralsComparisonMonthlyResponseFromJSON,
    PostGroupsChartReferralsComparisonMonthlyResponseToJSON,
    PostGroupsChartReferralsComparisonRequest,
    PostGroupsChartReferralsComparisonRequestFromJSON,
    PostGroupsChartReferralsComparisonRequestToJSON,
    PostGroupsChartReferralsComparisonResponse,
    PostGroupsChartReferralsComparisonResponseFromJSON,
    PostGroupsChartReferralsComparisonResponseToJSON,
    PostGroupsChartReferralsRequest,
    PostGroupsChartReferralsRequestFromJSON,
    PostGroupsChartReferralsRequestToJSON,
    PostGroupsChartReferralsResponse,
    PostGroupsChartReferralsResponseFromJSON,
    PostGroupsChartReferralsResponseToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface PostGroupsChartReferralsOperationRequest {
    postGroupsChartReferralsRequest: PostGroupsChartReferralsRequest;
}

export interface PostGroupsChartReferralsComparisonOperationRequest {
    postGroupsChartReferralsComparisonRequest: PostGroupsChartReferralsComparisonRequest;
}

export interface PostGroupsChartReferralsComparisonAnnualOperationRequest {
    postGroupsChartReferralsComparisonAnnualRequest: PostGroupsChartReferralsComparisonAnnualRequest;
}

export interface PostGroupsChartReferralsComparisonMonthlyOperationRequest {
    postGroupsChartReferralsComparisonMonthlyRequest: PostGroupsChartReferralsComparisonMonthlyRequest;
}

/**
 * GroupsApi - interface
 * 
 * @export
 * @interface GroupsApiInterface
 */
export interface GroupsApiInterface {
    /**
     * 
     * @summary <グループ病院向け>紹介数推移データの生成(データの取得)
     * @param {PostGroupsChartReferralsRequest} postGroupsChartReferralsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApiInterface
     */
    postGroupsChartReferralsRaw(requestParameters: PostGroupsChartReferralsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostGroupsChartReferralsResponse>>;

    /**
     * <グループ病院向け>紹介数推移データの生成(データの取得)
     */
    postGroupsChartReferrals(requestParameters: PostGroupsChartReferralsOperationRequest, initOverrides?: RequestInit): Promise<PostGroupsChartReferralsResponse>;

    /**
     * 
     * @summary <グループ病院向け>紹介数の期間指定比較データの生成(データの取得)
     * @param {PostGroupsChartReferralsComparisonRequest} postGroupsChartReferralsComparisonRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApiInterface
     */
    postGroupsChartReferralsComparisonRaw(requestParameters: PostGroupsChartReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostGroupsChartReferralsComparisonResponse>>;

    /**
     * <グループ病院向け>紹介数の期間指定比較データの生成(データの取得)
     */
    postGroupsChartReferralsComparison(requestParameters: PostGroupsChartReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<PostGroupsChartReferralsComparisonResponse>;

    /**
     * 
     * @summary <グループ病院向け>紹介数の年次比較データの生成(データの取得)
     * @param {PostGroupsChartReferralsComparisonAnnualRequest} postGroupsChartReferralsComparisonAnnualRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApiInterface
     */
    postGroupsChartReferralsComparisonAnnualRaw(requestParameters: PostGroupsChartReferralsComparisonAnnualOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostGroupsChartReferralsComparisonAnnualResponse>>;

    /**
     * <グループ病院向け>紹介数の年次比較データの生成(データの取得)
     */
    postGroupsChartReferralsComparisonAnnual(requestParameters: PostGroupsChartReferralsComparisonAnnualOperationRequest, initOverrides?: RequestInit): Promise<PostGroupsChartReferralsComparisonAnnualResponse>;

    /**
     * 
     * @summary <グループ病院向け>紹介数の月次比較データの生成(データの取得)
     * @param {PostGroupsChartReferralsComparisonMonthlyRequest} postGroupsChartReferralsComparisonMonthlyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApiInterface
     */
    postGroupsChartReferralsComparisonMonthlyRaw(requestParameters: PostGroupsChartReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostGroupsChartReferralsComparisonMonthlyResponse>>;

    /**
     * <グループ病院向け>紹介数の月次比較データの生成(データの取得)
     */
    postGroupsChartReferralsComparisonMonthly(requestParameters: PostGroupsChartReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<PostGroupsChartReferralsComparisonMonthlyResponse>;

}

/**
 * 
 */
export class GroupsApi extends runtime.BaseAPI implements GroupsApiInterface {

    /**
     * <グループ病院向け>紹介数推移データの生成(データの取得)
     */
    async postGroupsChartReferralsRaw(requestParameters: PostGroupsChartReferralsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostGroupsChartReferralsResponse>> {
        if (requestParameters.postGroupsChartReferralsRequest === null || requestParameters.postGroupsChartReferralsRequest === undefined) {
            throw new runtime.RequiredError('postGroupsChartReferralsRequest','Required parameter requestParameters.postGroupsChartReferralsRequest was null or undefined when calling postGroupsChartReferrals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/groups/charts/referrals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostGroupsChartReferralsRequestToJSON(requestParameters.postGroupsChartReferralsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostGroupsChartReferralsResponseFromJSON(jsonValue));
    }

    /**
     * <グループ病院向け>紹介数推移データの生成(データの取得)
     */
    async postGroupsChartReferrals(requestParameters: PostGroupsChartReferralsOperationRequest, initOverrides?: RequestInit): Promise<PostGroupsChartReferralsResponse> {
        const response = await this.postGroupsChartReferralsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * <グループ病院向け>紹介数の期間指定比較データの生成(データの取得)
     */
    async postGroupsChartReferralsComparisonRaw(requestParameters: PostGroupsChartReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostGroupsChartReferralsComparisonResponse>> {
        if (requestParameters.postGroupsChartReferralsComparisonRequest === null || requestParameters.postGroupsChartReferralsComparisonRequest === undefined) {
            throw new runtime.RequiredError('postGroupsChartReferralsComparisonRequest','Required parameter requestParameters.postGroupsChartReferralsComparisonRequest was null or undefined when calling postGroupsChartReferralsComparison.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/groups/charts/referrals/comparison`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostGroupsChartReferralsComparisonRequestToJSON(requestParameters.postGroupsChartReferralsComparisonRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostGroupsChartReferralsComparisonResponseFromJSON(jsonValue));
    }

    /**
     * <グループ病院向け>紹介数の期間指定比較データの生成(データの取得)
     */
    async postGroupsChartReferralsComparison(requestParameters: PostGroupsChartReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<PostGroupsChartReferralsComparisonResponse> {
        const response = await this.postGroupsChartReferralsComparisonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * <グループ病院向け>紹介数の年次比較データの生成(データの取得)
     */
    async postGroupsChartReferralsComparisonAnnualRaw(requestParameters: PostGroupsChartReferralsComparisonAnnualOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostGroupsChartReferralsComparisonAnnualResponse>> {
        if (requestParameters.postGroupsChartReferralsComparisonAnnualRequest === null || requestParameters.postGroupsChartReferralsComparisonAnnualRequest === undefined) {
            throw new runtime.RequiredError('postGroupsChartReferralsComparisonAnnualRequest','Required parameter requestParameters.postGroupsChartReferralsComparisonAnnualRequest was null or undefined when calling postGroupsChartReferralsComparisonAnnual.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/groups/charts/referrals/comparison/annual`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostGroupsChartReferralsComparisonAnnualRequestToJSON(requestParameters.postGroupsChartReferralsComparisonAnnualRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostGroupsChartReferralsComparisonAnnualResponseFromJSON(jsonValue));
    }

    /**
     * <グループ病院向け>紹介数の年次比較データの生成(データの取得)
     */
    async postGroupsChartReferralsComparisonAnnual(requestParameters: PostGroupsChartReferralsComparisonAnnualOperationRequest, initOverrides?: RequestInit): Promise<PostGroupsChartReferralsComparisonAnnualResponse> {
        const response = await this.postGroupsChartReferralsComparisonAnnualRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * <グループ病院向け>紹介数の月次比較データの生成(データの取得)
     */
    async postGroupsChartReferralsComparisonMonthlyRaw(requestParameters: PostGroupsChartReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostGroupsChartReferralsComparisonMonthlyResponse>> {
        if (requestParameters.postGroupsChartReferralsComparisonMonthlyRequest === null || requestParameters.postGroupsChartReferralsComparisonMonthlyRequest === undefined) {
            throw new runtime.RequiredError('postGroupsChartReferralsComparisonMonthlyRequest','Required parameter requestParameters.postGroupsChartReferralsComparisonMonthlyRequest was null or undefined when calling postGroupsChartReferralsComparisonMonthly.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/groups/charts/referrals/comparison/monthly`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostGroupsChartReferralsComparisonMonthlyRequestToJSON(requestParameters.postGroupsChartReferralsComparisonMonthlyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostGroupsChartReferralsComparisonMonthlyResponseFromJSON(jsonValue));
    }

    /**
     * <グループ病院向け>紹介数の月次比較データの生成(データの取得)
     */
    async postGroupsChartReferralsComparisonMonthly(requestParameters: PostGroupsChartReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<PostGroupsChartReferralsComparisonMonthlyResponse> {
        const response = await this.postGroupsChartReferralsComparisonMonthlyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
