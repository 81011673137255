import { Link } from '@/src/components/foundations/Utils/Link';
import { ExtractColor } from '@/src/types/styles';

import styles from './styles.module.scss';

import clsx from 'clsx';
import { CSSProperties, PropsWithChildren } from 'react';

type Props = ButtonProps | IconOnlyButtonProps;
export const Button: React.FC<Props> = (props) => {
  if (props.type === 'iconOnly') {
    return <IconOnlyButton {...props} />;
  } else {
    return <GeneralButton {...props} />;
  }
};

type OutlineColor = ExtractColor<'primary' | 'secondary' | 'danger'>;
type Color = OutlineColor | ExtractColor<'basic' | 'danger'>;

export type ButtonProps = PropsWithChildren<
  {
    type?: 'normal' | 'skeleton';
    color?: Color | `outline${Capitalize<OutlineColor>}`;
    size?: 'medium' | 'small';
    icon?: {
      body: React.ReactElement;
      position?: 'right' | 'left';
    };
    className?: string;
    active?: boolean;
  } & (
    | ({
        isLink?: false;
        isDisabled?: boolean;
        submitType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
      } & Pick<React.HTMLAttributes<HTMLButtonElement>, 'onClick'>)
    | { isLink: true; href: string; isOpenAnotherTab?: boolean }
  )
>;

const GeneralButton: React.FC<ButtonProps> = (props) => {
  const style = clsx(
    styles.base,
    styles[props.type ?? 'normal'],
    styles[props.size || 'medium'],
    !props.isLink && props.isDisabled
      ? styles.disable
      : styles[props.color ?? 'primary'],
    props.className,
    props.active && styles.active,
  );

  const Icon = <div className={styles.iconWrapper}>{props.icon?.body}</div>;
  const children = (
    <>
      {props.icon?.position === 'left' && Icon}
      {props.children}
      {props.icon?.position === 'right' && Icon}
    </>
  );

  if (props.isLink) {
    return (
      <Link
        href={props.href}
        isOpenAnotherTab={props.isOpenAnotherTab}
        className={style}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      type={props.submitType}
      className={style}
      disabled={props.isDisabled}
      onClick={props.onClick}
    >
      {children}
    </button>
  );
};

type IconOnlyButtonProps = {
  // variant: 'iconOnly'
  // union のタグで利用したいので type にしている
  type: 'iconOnly';
  size?: 'medium' | 'small';
  /**
   * @description foundations/DesignToken/Icons で export されているアイコンコンポーネントを指定を想定している
   * @example <Button icon={<Clear />} />
   **/
  icon: React.ReactElement;
  children?: never;
  disabled?: boolean;
} & (
  | ({
      isLink?: false;
      isDisabled?: boolean;
      submitType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
      cursor?: CSSProperties['cursor'];
    } & Pick<React.HTMLAttributes<HTMLButtonElement>, 'onClick'>)
  | { isLink: true; href: string; isOpenAnotherTab?: boolean }
);

const IconOnlyButton: React.FC<IconOnlyButtonProps> = (props) => {
  const SvgIcon = props.icon;
  const style = clsx(styles.iconOnlyButtonBase, styles[props.size ?? 'medium']);

  if (props.isLink) {
    return (
      <Link
        href={props.href}
        isOpenAnotherTab={props.isOpenAnotherTab}
        className={style}
      >
        {SvgIcon}
      </Link>
    );
  }

  return (
    <button
      className={style}
      style={{ cursor: props.cursor }}
      type="button"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {SvgIcon}
    </button>
  );
};
