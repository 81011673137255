/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionLabelComponentType,
    MedicalInstitutionLabelComponentTypeFromJSON,
    MedicalInstitutionLabelComponentTypeFromJSONTyped,
    MedicalInstitutionLabelComponentTypeToJSON,
} from './MedicalInstitutionLabelComponentType';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionLabelsRequest
 */
export interface PostMedicalInstitutionLabelsRequest {
    /**
     * 
     * @type {MedicalInstitutionLabelComponentType}
     * @memberof PostMedicalInstitutionLabelsRequest
     */
    componentType: MedicalInstitutionLabelComponentType;
}

export function PostMedicalInstitutionLabelsRequestFromJSON(json: any): PostMedicalInstitutionLabelsRequest {
    return PostMedicalInstitutionLabelsRequestFromJSONTyped(json, false);
}

export function PostMedicalInstitutionLabelsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionLabelsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'componentType': MedicalInstitutionLabelComponentTypeFromJSON(json['component_type']),
    };
}

export function PostMedicalInstitutionLabelsRequestToJSON(value?: PostMedicalInstitutionLabelsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component_type': MedicalInstitutionLabelComponentTypeToJSON(value.componentType),
    };
}

