/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostChartBothReferralsComparisonResponseItems
 */
export interface PostChartBothReferralsComparisonResponseItems {
    /**
     * 紹介か逆紹介か
     * @type {string}
     * @memberof PostChartBothReferralsComparisonResponseItems
     */
    key: PostChartBothReferralsComparisonResponseItemsKeyEnum;
    /**
     * 紹介数または逆紹介数
     * @type {number}
     * @memberof PostChartBothReferralsComparisonResponseItems
     */
    value: number;
}

/**
* @export
* @enum {string}
*/
export enum PostChartBothReferralsComparisonResponseItemsKeyEnum {
    Referral = 'referral',
    ReverseReferral = 'reverse_referral'
}

export function PostChartBothReferralsComparisonResponseItemsFromJSON(json: any): PostChartBothReferralsComparisonResponseItems {
    return PostChartBothReferralsComparisonResponseItemsFromJSONTyped(json, false);
}

export function PostChartBothReferralsComparisonResponseItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartBothReferralsComparisonResponseItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': json['value'],
    };
}

export function PostChartBothReferralsComparisonResponseItemsToJSON(value?: PostChartBothReferralsComparisonResponseItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
    };
}

