/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableMedicalInstitution,
    NullableMedicalInstitutionFromJSON,
    NullableMedicalInstitutionFromJSONTyped,
    NullableMedicalInstitutionToJSON,
} from './NullableMedicalInstitution';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * タスクID
     * @type {number}
     * @memberof Task
     */
    id: number;
    /**
     * 作成日
     * @type {string}
     * @memberof Task
     */
    createdAt: string;
    /**
     * 期日
     * @type {string}
     * @memberof Task
     */
    deadline: string | null;
    /**
     * タイトル
     * @type {string}
     * @memberof Task
     */
    title: string;
    /**
     * 
     * @type {NullableMedicalInstitution}
     * @memberof Task
     */
    medicalInstitution: NullableMedicalInstitution | null;
    /**
     * 完了済みか
     * @type {string}
     * @memberof Task
     */
    status: TaskStatusEnum;
    /**
     * 
     * @type {NullableStaff}
     * @memberof Task
     */
    staff: NullableStaff | null;
    /**
     * コミュニケーションID
     * @type {number}
     * @memberof Task
     */
    communicationId: number | null;
}

/**
* @export
* @enum {string}
*/
export enum TaskStatusEnum {
    Incomplete = 'incomplete',
    Finished = 'finished'
}

export function TaskFromJSON(json: any): Task {
    return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Task {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['created_at'],
        'deadline': json['deadline'],
        'title': json['title'],
        'medicalInstitution': NullableMedicalInstitutionFromJSON(json['medical_institution']),
        'status': json['status'],
        'staff': NullableStaffFromJSON(json['staff']),
        'communicationId': json['communication_id'],
    };
}

export function TaskToJSON(value?: Task | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_at': value.createdAt,
        'deadline': value.deadline,
        'title': value.title,
        'medical_institution': NullableMedicalInstitutionToJSON(value.medicalInstitution),
        'status': value.status,
        'staff': NullableStaffToJSON(value.staff),
        'communication_id': value.communicationId,
    };
}

