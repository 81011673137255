/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportAlert,
    MedicalInstitutionReportAlertFromJSON,
    MedicalInstitutionReportAlertFromJSONTyped,
    MedicalInstitutionReportAlertToJSON,
} from './MedicalInstitutionReportAlert';
import {
    MedicalInstitutionReportDefinitionDisplayItem,
    MedicalInstitutionReportDefinitionDisplayItemFromJSON,
    MedicalInstitutionReportDefinitionDisplayItemFromJSONTyped,
    MedicalInstitutionReportDefinitionDisplayItemToJSON,
} from './MedicalInstitutionReportDefinitionDisplayItem';
import {
    MedicalInstitutionReportFilterCondition,
    MedicalInstitutionReportFilterConditionFromJSON,
    MedicalInstitutionReportFilterConditionFromJSONTyped,
    MedicalInstitutionReportFilterConditionToJSON,
} from './MedicalInstitutionReportFilterCondition';
import {
    MedicalInstitutionReportSortCondition,
    MedicalInstitutionReportSortConditionFromJSON,
    MedicalInstitutionReportSortConditionFromJSONTyped,
    MedicalInstitutionReportSortConditionToJSON,
} from './MedicalInstitutionReportSortCondition';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * 連携先レポート詳細。display_items, filter_conditions, sort_conditions まで含めたもの
 * @export
 * @interface MedicalInstitutionReportDefinitionDetail
 */
export interface MedicalInstitutionReportDefinitionDetail {
    /**
     * 連携先レポートID
     * @type {number}
     * @memberof MedicalInstitutionReportDefinitionDetail
     */
    id: number;
    /**
     * レポート名
     * @type {string}
     * @memberof MedicalInstitutionReportDefinitionDetail
     */
    name: string;
    /**
     * 表示項目一覧
     * @type {Array<MedicalInstitutionReportDefinitionDisplayItem>}
     * @memberof MedicalInstitutionReportDefinitionDetail
     */
    displayItems: Array<MedicalInstitutionReportDefinitionDisplayItem>;
    /**
     * 連携先絞り込み条件一覧
     * @type {Array<MedicalInstitutionReportFilterCondition>}
     * @memberof MedicalInstitutionReportDefinitionDetail
     */
    filterConditions: Array<MedicalInstitutionReportFilterCondition>;
    /**
     * 並び替え条件一覧
     * @type {Array<MedicalInstitutionReportSortCondition>}
     * @memberof MedicalInstitutionReportDefinitionDetail
     */
    sortConditions: Array<MedicalInstitutionReportSortCondition>;
    /**
     * 
     * @type {NullableStaff}
     * @memberof MedicalInstitutionReportDefinitionDetail
     */
    createdBy: NullableStaff | null;
    /**
     * 
     * @type {MedicalInstitutionReportAlert}
     * @memberof MedicalInstitutionReportDefinitionDetail
     */
    latestAlert: MedicalInstitutionReportAlert | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof MedicalInstitutionReportDefinitionDetail
     */
    createdAt: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof MedicalInstitutionReportDefinitionDetail
     */
    updatedAt: string;
}

export function MedicalInstitutionReportDefinitionDetailFromJSON(json: any): MedicalInstitutionReportDefinitionDetail {
    return MedicalInstitutionReportDefinitionDetailFromJSONTyped(json, false);
}

export function MedicalInstitutionReportDefinitionDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportDefinitionDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'displayItems': ((json['display_items'] as Array<any>).map(MedicalInstitutionReportDefinitionDisplayItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(MedicalInstitutionReportFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(MedicalInstitutionReportSortConditionFromJSON)),
        'createdBy': NullableStaffFromJSON(json['created_by']),
        'latestAlert': MedicalInstitutionReportAlertFromJSON(json['latest_alert']),
        'createdAt': json['created_at'],
        'updatedAt': json['updated_at'],
    };
}

export function MedicalInstitutionReportDefinitionDetailToJSON(value?: MedicalInstitutionReportDefinitionDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'display_items': ((value.displayItems as Array<any>).map(MedicalInstitutionReportDefinitionDisplayItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(MedicalInstitutionReportFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(MedicalInstitutionReportSortConditionToJSON)),
        'created_by': NullableStaffToJSON(value.createdBy),
        'latest_alert': MedicalInstitutionReportAlertToJSON(value.latestAlert),
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
    };
}

