/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetEmailStatsResponse
 */
export interface GetEmailStatsResponse {
    /**
     * 送信先数
     * @type {number}
     * @memberof GetEmailStatsResponse
     */
    destinationCount: number;
    /**
     * 送信数(実際にメールを配信できた数)
     * @type {number}
     * @memberof GetEmailStatsResponse
     */
    deliveredCount: number;
    /**
     * 不備のある宛先数
     * @type {number}
     * @memberof GetEmailStatsResponse
     */
    invalidDestinationCount: number;
    /**
     * 開封数
     * @type {number}
     * @memberof GetEmailStatsResponse
     */
    openedCount: number;
    /**
     * クリック数
     * @type {number}
     * @memberof GetEmailStatsResponse
     */
    clickedCount: number;
}

export function GetEmailStatsResponseFromJSON(json: any): GetEmailStatsResponse {
    return GetEmailStatsResponseFromJSONTyped(json, false);
}

export function GetEmailStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEmailStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'destinationCount': json['destination_count'],
        'deliveredCount': json['delivered_count'],
        'invalidDestinationCount': json['invalid_destination_count'],
        'openedCount': json['opened_count'],
        'clickedCount': json['clicked_count'],
    };
}

export function GetEmailStatsResponseToJSON(value?: GetEmailStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'destination_count': value.destinationCount,
        'delivered_count': value.deliveredCount,
        'invalid_destination_count': value.invalidDestinationCount,
        'opened_count': value.openedCount,
        'clicked_count': value.clickedCount,
    };
}

