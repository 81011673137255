/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CommunicationReportDefinitionDetail,
    CommunicationReportDefinitionDetailFromJSON,
    CommunicationReportDefinitionDetailToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    GetCommunicationReportDefinitionsResponse,
    GetCommunicationReportDefinitionsResponseFromJSON,
    GetCommunicationReportDefinitionsResponseToJSON,
    GetCommunicationReportItemDefinitionsResponse,
    GetCommunicationReportItemDefinitionsResponseFromJSON,
    GetCommunicationReportItemDefinitionsResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostCommunicationReportAggregationResultsRequest,
    PostCommunicationReportAggregationResultsRequestFromJSON,
    PostCommunicationReportAggregationResultsRequestToJSON,
    PostCommunicationReportDefinitionRequest,
    PostCommunicationReportDefinitionRequestFromJSON,
    PostCommunicationReportDefinitionRequestToJSON,
    PostCommunicationReportResultsRequest,
    PostCommunicationReportResultsRequestFromJSON,
    PostCommunicationReportResultsRequestToJSON,
    PostCommunicationReportResultsResponse,
    PostCommunicationReportResultsResponseFromJSON,
    PostCommunicationReportResultsResponseToJSON,
    PutCommunicationReportDefinitionRequest,
    PutCommunicationReportDefinitionRequestFromJSON,
    PutCommunicationReportDefinitionRequestToJSON,
    ReportAggregationResults,
    ReportAggregationResultsFromJSON,
    ReportAggregationResultsToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteCommunicationReportDefinitionRequest {
    id: number;
}

export interface GetCommunicationReportDefinitionRequest {
    id: number;
}

export interface PostCommunicationReportAggregationResultsOperationRequest {
    postCommunicationReportAggregationResultsRequest: PostCommunicationReportAggregationResultsRequest;
}

export interface PostCommunicationReportDefinitionOperationRequest {
    postCommunicationReportDefinitionRequest: PostCommunicationReportDefinitionRequest;
}

export interface PostCommunicationReportResultsOperationRequest {
    postCommunicationReportResultsRequest: PostCommunicationReportResultsRequest;
}

export interface PutCommunicationReportDefinitionOperationRequest {
    id: number;
    putCommunicationReportDefinitionRequest: PutCommunicationReportDefinitionRequest;
}

/**
 * CommunicationReportsApi - interface
 * 
 * @export
 * @interface CommunicationReportsApiInterface
 */
export interface CommunicationReportsApiInterface {
    /**
     * 
     * @summary コミュニケーションレポート定義の削除
     * @param {number} id コミュニケーションレポート定義ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationReportsApiInterface
     */
    deleteCommunicationReportDefinitionRaw(requestParameters: DeleteCommunicationReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * コミュニケーションレポート定義の削除
     */
    deleteCommunicationReportDefinition(requestParameters: DeleteCommunicationReportDefinitionRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary コミュニケーションレポート定義の取得
     * @param {number} id コミュニケーションレポート定義ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationReportsApiInterface
     */
    getCommunicationReportDefinitionRaw(requestParameters: GetCommunicationReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationReportDefinitionDetail>>;

    /**
     * コミュニケーションレポート定義の取得
     */
    getCommunicationReportDefinition(requestParameters: GetCommunicationReportDefinitionRequest, initOverrides?: RequestInit): Promise<CommunicationReportDefinitionDetail>;

    /**
     * 
     * @summary コミュニケーションレポート定義一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationReportsApiInterface
     */
    getCommunicationReportDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCommunicationReportDefinitionsResponse>>;

    /**
     * コミュニケーションレポート定義一覧の取得
     */
    getCommunicationReportDefinitions(initOverrides?: RequestInit): Promise<GetCommunicationReportDefinitionsResponse>;

    /**
     * 
     * @summary コミュニケーションレポート項目マスタ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationReportsApiInterface
     */
    getCommunicationReportItemDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCommunicationReportItemDefinitionsResponse>>;

    /**
     * コミュニケーションレポート項目マスタ一覧
     */
    getCommunicationReportItemDefinitions(initOverrides?: RequestInit): Promise<GetCommunicationReportItemDefinitionsResponse>;

    /**
     * 
     * @summary コミュニケーションレポート集計結果生成
     * @param {PostCommunicationReportAggregationResultsRequest} postCommunicationReportAggregationResultsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationReportsApiInterface
     */
    postCommunicationReportAggregationResultsRaw(requestParameters: PostCommunicationReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReportAggregationResults>>;

    /**
     * コミュニケーションレポート集計結果生成
     */
    postCommunicationReportAggregationResults(requestParameters: PostCommunicationReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<ReportAggregationResults>;

    /**
     * 
     * @summary コミュニケーションレポート定義の登録
     * @param {PostCommunicationReportDefinitionRequest} postCommunicationReportDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationReportsApiInterface
     */
    postCommunicationReportDefinitionRaw(requestParameters: PostCommunicationReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationReportDefinitionDetail>>;

    /**
     * コミュニケーションレポート定義の登録
     */
    postCommunicationReportDefinition(requestParameters: PostCommunicationReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<CommunicationReportDefinitionDetail>;

    /**
     * 
     * @summary コミュニケーションレポート結果生成
     * @param {PostCommunicationReportResultsRequest} postCommunicationReportResultsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationReportsApiInterface
     */
    postCommunicationReportResultsRaw(requestParameters: PostCommunicationReportResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostCommunicationReportResultsResponse>>;

    /**
     * コミュニケーションレポート結果生成
     */
    postCommunicationReportResults(requestParameters: PostCommunicationReportResultsOperationRequest, initOverrides?: RequestInit): Promise<PostCommunicationReportResultsResponse>;

    /**
     * 
     * @summary コミュニケーションレポート定義の更新
     * @param {number} id コミュニケーションレポート定義ID
     * @param {PutCommunicationReportDefinitionRequest} putCommunicationReportDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationReportsApiInterface
     */
    putCommunicationReportDefinitionRaw(requestParameters: PutCommunicationReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationReportDefinitionDetail>>;

    /**
     * コミュニケーションレポート定義の更新
     */
    putCommunicationReportDefinition(requestParameters: PutCommunicationReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<CommunicationReportDefinitionDetail>;

}

/**
 * 
 */
export class CommunicationReportsApi extends runtime.BaseAPI implements CommunicationReportsApiInterface {

    /**
     * コミュニケーションレポート定義の削除
     */
    async deleteCommunicationReportDefinitionRaw(requestParameters: DeleteCommunicationReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCommunicationReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * コミュニケーションレポート定義の削除
     */
    async deleteCommunicationReportDefinition(requestParameters: DeleteCommunicationReportDefinitionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteCommunicationReportDefinitionRaw(requestParameters, initOverrides);
    }

    /**
     * コミュニケーションレポート定義の取得
     */
    async getCommunicationReportDefinitionRaw(requestParameters: GetCommunicationReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationReportDefinitionDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCommunicationReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * コミュニケーションレポート定義の取得
     */
    async getCommunicationReportDefinition(requestParameters: GetCommunicationReportDefinitionRequest, initOverrides?: RequestInit): Promise<CommunicationReportDefinitionDetail> {
        const response = await this.getCommunicationReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションレポート定義一覧の取得
     */
    async getCommunicationReportDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCommunicationReportDefinitionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_reports/definitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCommunicationReportDefinitionsResponseFromJSON(jsonValue));
    }

    /**
     * コミュニケーションレポート定義一覧の取得
     */
    async getCommunicationReportDefinitions(initOverrides?: RequestInit): Promise<GetCommunicationReportDefinitionsResponse> {
        const response = await this.getCommunicationReportDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションレポート項目マスタ一覧
     */
    async getCommunicationReportItemDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCommunicationReportItemDefinitionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_reports/item_definitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCommunicationReportItemDefinitionsResponseFromJSON(jsonValue));
    }

    /**
     * コミュニケーションレポート項目マスタ一覧
     */
    async getCommunicationReportItemDefinitions(initOverrides?: RequestInit): Promise<GetCommunicationReportItemDefinitionsResponse> {
        const response = await this.getCommunicationReportItemDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションレポート集計結果生成
     */
    async postCommunicationReportAggregationResultsRaw(requestParameters: PostCommunicationReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReportAggregationResults>> {
        if (requestParameters.postCommunicationReportAggregationResultsRequest === null || requestParameters.postCommunicationReportAggregationResultsRequest === undefined) {
            throw new runtime.RequiredError('postCommunicationReportAggregationResultsRequest','Required parameter requestParameters.postCommunicationReportAggregationResultsRequest was null or undefined when calling postCommunicationReportAggregationResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_reports/aggregation_results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCommunicationReportAggregationResultsRequestToJSON(requestParameters.postCommunicationReportAggregationResultsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportAggregationResultsFromJSON(jsonValue));
    }

    /**
     * コミュニケーションレポート集計結果生成
     */
    async postCommunicationReportAggregationResults(requestParameters: PostCommunicationReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<ReportAggregationResults> {
        const response = await this.postCommunicationReportAggregationResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションレポート定義の登録
     */
    async postCommunicationReportDefinitionRaw(requestParameters: PostCommunicationReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationReportDefinitionDetail>> {
        if (requestParameters.postCommunicationReportDefinitionRequest === null || requestParameters.postCommunicationReportDefinitionRequest === undefined) {
            throw new runtime.RequiredError('postCommunicationReportDefinitionRequest','Required parameter requestParameters.postCommunicationReportDefinitionRequest was null or undefined when calling postCommunicationReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_reports/definitions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCommunicationReportDefinitionRequestToJSON(requestParameters.postCommunicationReportDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * コミュニケーションレポート定義の登録
     */
    async postCommunicationReportDefinition(requestParameters: PostCommunicationReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<CommunicationReportDefinitionDetail> {
        const response = await this.postCommunicationReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションレポート結果生成
     */
    async postCommunicationReportResultsRaw(requestParameters: PostCommunicationReportResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostCommunicationReportResultsResponse>> {
        if (requestParameters.postCommunicationReportResultsRequest === null || requestParameters.postCommunicationReportResultsRequest === undefined) {
            throw new runtime.RequiredError('postCommunicationReportResultsRequest','Required parameter requestParameters.postCommunicationReportResultsRequest was null or undefined when calling postCommunicationReportResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_reports/results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCommunicationReportResultsRequestToJSON(requestParameters.postCommunicationReportResultsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostCommunicationReportResultsResponseFromJSON(jsonValue));
    }

    /**
     * コミュニケーションレポート結果生成
     */
    async postCommunicationReportResults(requestParameters: PostCommunicationReportResultsOperationRequest, initOverrides?: RequestInit): Promise<PostCommunicationReportResultsResponse> {
        const response = await this.postCommunicationReportResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションレポート定義の更新
     */
    async putCommunicationReportDefinitionRaw(requestParameters: PutCommunicationReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationReportDefinitionDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putCommunicationReportDefinition.');
        }

        if (requestParameters.putCommunicationReportDefinitionRequest === null || requestParameters.putCommunicationReportDefinitionRequest === undefined) {
            throw new runtime.RequiredError('putCommunicationReportDefinitionRequest','Required parameter requestParameters.putCommunicationReportDefinitionRequest was null or undefined when calling putCommunicationReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutCommunicationReportDefinitionRequestToJSON(requestParameters.putCommunicationReportDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * コミュニケーションレポート定義の更新
     */
    async putCommunicationReportDefinition(requestParameters: PutCommunicationReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<CommunicationReportDefinitionDetail> {
        const response = await this.putCommunicationReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
