/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommunicationLabelConditionSelectionConditions
 */
export interface CommunicationLabelConditionSelectionConditions {
    /**
     * 単一選択型のラベル条件。数値が指定したいずれかと合致する場合にマッチする条件
     * @type {Array<number>}
     * @memberof CommunicationLabelConditionSelectionConditions
     */
    _in?: Array<number>;
    /**
     * 単一選択型のラベル条件。数値が指定したいずれも含まない場合にマッチする条件 (ラベルタイプ checkbox type 以外)
     * @type {Array<number>}
     * @memberof CommunicationLabelConditionSelectionConditions
     */
    notIn?: Array<number>;
}

export function CommunicationLabelConditionSelectionConditionsFromJSON(json: any): CommunicationLabelConditionSelectionConditions {
    return CommunicationLabelConditionSelectionConditionsFromJSONTyped(json, false);
}

export function CommunicationLabelConditionSelectionConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabelConditionSelectionConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'not_in') ? undefined : json['not_in'],
    };
}

export function CommunicationLabelConditionSelectionConditionsToJSON(value?: CommunicationLabelConditionSelectionConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'in': value._in,
        'not_in': value.notIn,
    };
}

