/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartVisitsDoctorInterviewRatesResponseItems,
    PostChartVisitsDoctorInterviewRatesResponseItemsFromJSON,
    PostChartVisitsDoctorInterviewRatesResponseItemsFromJSONTyped,
    PostChartVisitsDoctorInterviewRatesResponseItemsToJSON,
} from './PostChartVisitsDoctorInterviewRatesResponseItems';

/**
 * 
 * @export
 * @interface PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRates
 */
export interface PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRates {
    /**
     * 集計期間(月,年)
     * @type {string}
     * @memberof PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRates
     */
    label: string;
    /**
     * 担当者の医師面談設定率
     * @type {Array<PostChartVisitsDoctorInterviewRatesResponseItems>}
     * @memberof PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRates
     */
    items: Array<PostChartVisitsDoctorInterviewRatesResponseItems>;
}

export function PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRatesFromJSON(json: any): PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRates {
    return PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRatesFromJSONTyped(json, false);
}

export function PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRates {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'items': ((json['items'] as Array<any>).map(PostChartVisitsDoctorInterviewRatesResponseItemsFromJSON)),
    };
}

export function PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRatesToJSON(value?: PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRates | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'items': ((value.items as Array<any>).map(PostChartVisitsDoctorInterviewRatesResponseItemsToJSON)),
    };
}

