/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * レポートプリセット項目定義を特定するキー名
 * @export
 * @enum {string}
 */
export enum OwnDepartmentReportItemKey {
    Id = 'id',
    ReferralCount = 'referral_count',
    ReferralAdmissionCount = 'referral_admission_count',
    ReferralAdmissionRate = 'referral_admission_rate',
    ReferralOperationCount = 'referral_operation_count',
    ReferralOperationRate = 'referral_operation_rate',
    ReferralAdmissionDiagnosisCount = 'referral_admission_diagnosis_count',
    ReverseReferralCount = 'reverse_referral_count',
    StandardRevenue = 'standard_revenue',
    FormulaDiff = 'formula_diff',
    FormulaRate = 'formula_rate',
    CommunicationCount = 'communication_count'
}

export function OwnDepartmentReportItemKeyFromJSON(json: any): OwnDepartmentReportItemKey {
    return OwnDepartmentReportItemKeyFromJSONTyped(json, false);
}

export function OwnDepartmentReportItemKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportItemKey {
    return json as OwnDepartmentReportItemKey;
}

export function OwnDepartmentReportItemKeyToJSON(value?: OwnDepartmentReportItemKey | null): any {
    return value as any;
}

