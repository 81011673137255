/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReverseReferralsComparisonDepartmentsResponseItems,
    PostChartReverseReferralsComparisonDepartmentsResponseItemsFromJSON,
    PostChartReverseReferralsComparisonDepartmentsResponseItemsFromJSONTyped,
    PostChartReverseReferralsComparisonDepartmentsResponseItemsToJSON,
} from './PostChartReverseReferralsComparisonDepartmentsResponseItems';

/**
 * 
 * @export
 * @interface PostChartReverseReferralsComparisonDepartmentsResponseReverseReferrals
 */
export interface PostChartReverseReferralsComparisonDepartmentsResponseReverseReferrals {
    /**
     * 月
     * @type {string}
     * @memberof PostChartReverseReferralsComparisonDepartmentsResponseReverseReferrals
     */
    label: string;
    /**
     * 月ごとの診療科別逆紹介数データ
     * @type {Array<PostChartReverseReferralsComparisonDepartmentsResponseItems>}
     * @memberof PostChartReverseReferralsComparisonDepartmentsResponseReverseReferrals
     */
    items: Array<PostChartReverseReferralsComparisonDepartmentsResponseItems>;
}

export function PostChartReverseReferralsComparisonDepartmentsResponseReverseReferralsFromJSON(json: any): PostChartReverseReferralsComparisonDepartmentsResponseReverseReferrals {
    return PostChartReverseReferralsComparisonDepartmentsResponseReverseReferralsFromJSONTyped(json, false);
}

export function PostChartReverseReferralsComparisonDepartmentsResponseReverseReferralsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReverseReferralsComparisonDepartmentsResponseReverseReferrals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'items': ((json['items'] as Array<any>).map(PostChartReverseReferralsComparisonDepartmentsResponseItemsFromJSON)),
    };
}

export function PostChartReverseReferralsComparisonDepartmentsResponseReverseReferralsToJSON(value?: PostChartReverseReferralsComparisonDepartmentsResponseReverseReferrals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'items': ((value.items as Array<any>).map(PostChartReverseReferralsComparisonDepartmentsResponseItemsToJSON)),
    };
}

