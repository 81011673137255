/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostDataAnalysisRankingBothReferralsMonthlyRequest
 */
export interface PostDataAnalysisRankingBothReferralsMonthlyRequest {
    /**
     * ページ番号 (1始まり)
     * @type {number}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyRequest
     */
    page: number;
    /**
     * 訪問月
     * @type {string}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyRequest
     */
    month: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyRequest
     */
    prefecture: string | null;
    /**
     * 地域
     * @type {string}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyRequest
     */
    area: string | null;
    /**
     * 医療機関タイプ(施設タイプ)ID
     * @type {Array<string>}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyRequest
     */
    facilityTypeIds: Array<string> | null;
    /**
     * 診療科目ID
     * @type {Array<string>}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyRequest
     */
    ownDepartmentIds: Array<string> | null;
    /**
     * 差分の並び順
     * @type {string}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyRequest
     */
    sort: PostDataAnalysisRankingBothReferralsMonthlyRequestSortEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostDataAnalysisRankingBothReferralsMonthlyRequestSortEnum {
    Desc = 'desc',
    Asc = 'asc'
}

export function PostDataAnalysisRankingBothReferralsMonthlyRequestFromJSON(json: any): PostDataAnalysisRankingBothReferralsMonthlyRequest {
    return PostDataAnalysisRankingBothReferralsMonthlyRequestFromJSONTyped(json, false);
}

export function PostDataAnalysisRankingBothReferralsMonthlyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisRankingBothReferralsMonthlyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'month': json['month'],
        'prefecture': json['prefecture'],
        'area': json['area'],
        'facilityTypeIds': json['facility_type_ids'],
        'ownDepartmentIds': json['own_department_ids'],
        'sort': json['sort'],
    };
}

export function PostDataAnalysisRankingBothReferralsMonthlyRequestToJSON(value?: PostDataAnalysisRankingBothReferralsMonthlyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'month': value.month,
        'prefecture': value.prefecture,
        'area': value.area,
        'facility_type_ids': value.facilityTypeIds,
        'own_department_ids': value.ownDepartmentIds,
        'sort': value.sort,
    };
}

