/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartBothReferralsComparisonResponseBothReferrals,
    PostChartBothReferralsComparisonResponseBothReferralsFromJSON,
    PostChartBothReferralsComparisonResponseBothReferralsFromJSONTyped,
    PostChartBothReferralsComparisonResponseBothReferralsToJSON,
} from './PostChartBothReferralsComparisonResponseBothReferrals';

/**
 * 
 * @export
 * @interface PostChartBothReferralsComparisonResponse
 */
export interface PostChartBothReferralsComparisonResponse {
    /**
     * 紹介数・逆紹介数チャートデータ
     * @type {Array<PostChartBothReferralsComparisonResponseBothReferrals>}
     * @memberof PostChartBothReferralsComparisonResponse
     */
    bothReferrals: Array<PostChartBothReferralsComparisonResponseBothReferrals>;
}

export function PostChartBothReferralsComparisonResponseFromJSON(json: any): PostChartBothReferralsComparisonResponse {
    return PostChartBothReferralsComparisonResponseFromJSONTyped(json, false);
}

export function PostChartBothReferralsComparisonResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartBothReferralsComparisonResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bothReferrals': ((json['both_referrals'] as Array<any>).map(PostChartBothReferralsComparisonResponseBothReferralsFromJSON)),
    };
}

export function PostChartBothReferralsComparisonResponseToJSON(value?: PostChartBothReferralsComparisonResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'both_referrals': ((value.bothReferrals as Array<any>).map(PostChartBothReferralsComparisonResponseBothReferralsToJSON)),
    };
}

