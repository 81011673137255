/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum,
    MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumFromJSON,
    MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumFromJSONTyped,
    MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumToJSON,
} from './MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum';
import {
    MedicalInstitutionReportItemDefinitionEnabledFiltersEnum,
    MedicalInstitutionReportItemDefinitionEnabledFiltersEnumFromJSON,
    MedicalInstitutionReportItemDefinitionEnabledFiltersEnumFromJSONTyped,
    MedicalInstitutionReportItemDefinitionEnabledFiltersEnumToJSON,
} from './MedicalInstitutionReportItemDefinitionEnabledFiltersEnum';
import {
    MedicalInstitutionReportItemDefinitionFormatEnum,
    MedicalInstitutionReportItemDefinitionFormatEnumFromJSON,
    MedicalInstitutionReportItemDefinitionFormatEnumFromJSONTyped,
    MedicalInstitutionReportItemDefinitionFormatEnumToJSON,
} from './MedicalInstitutionReportItemDefinitionFormatEnum';
import {
    MedicalInstitutionReportItemDefinitionValueTypeEnum,
    MedicalInstitutionReportItemDefinitionValueTypeEnumFromJSON,
    MedicalInstitutionReportItemDefinitionValueTypeEnumFromJSONTyped,
    MedicalInstitutionReportItemDefinitionValueTypeEnumToJSON,
} from './MedicalInstitutionReportItemDefinitionValueTypeEnum';
import {
    MedicalInstitutionReportItemKey,
    MedicalInstitutionReportItemKeyFromJSON,
    MedicalInstitutionReportItemKeyFromJSONTyped,
    MedicalInstitutionReportItemKeyToJSON,
} from './MedicalInstitutionReportItemKey';

/**
 * 連携先レポート項目（プリセット項目）定義
 * @export
 * @interface MedicalInstitutionReportItemDefinitionPreset
 */
export interface MedicalInstitutionReportItemDefinitionPreset {
    /**
     * クライアントでタグ付きユニオンとして扱うためのタグ
     * @type {string}
     * @memberof MedicalInstitutionReportItemDefinitionPreset
     */
    type: MedicalInstitutionReportItemDefinitionPresetTypeEnum;
    /**
     * 
     * @type {MedicalInstitutionReportItemKey}
     * @memberof MedicalInstitutionReportItemDefinitionPreset
     */
    key: MedicalInstitutionReportItemKey;
    /**
     * 項目ラベル
     * @type {string}
     * @memberof MedicalInstitutionReportItemDefinitionPreset
     */
    label: string;
    /**
     * 
     * @type {MedicalInstitutionReportItemDefinitionValueTypeEnum}
     * @memberof MedicalInstitutionReportItemDefinitionPreset
     */
    valueType: MedicalInstitutionReportItemDefinitionValueTypeEnum;
    /**
     * 
     * @type {MedicalInstitutionReportItemDefinitionFormatEnum}
     * @memberof MedicalInstitutionReportItemDefinitionPreset
     */
    format: MedicalInstitutionReportItemDefinitionFormatEnum;
    /**
     * 集計方法の変更可否
     * @type {boolean}
     * @memberof MedicalInstitutionReportItemDefinitionPreset
     */
    calculable: boolean;
    /**
     * この項目で利用可能なフィルタ条件
     * @type {Array<MedicalInstitutionReportItemDefinitionEnabledFiltersEnum>}
     * @memberof MedicalInstitutionReportItemDefinitionPreset
     */
    enabledFilters: Array<MedicalInstitutionReportItemDefinitionEnabledFiltersEnum>;
    /**
     * この項目で利用可能な集計指定
     * @type {Array<MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum>}
     * @memberof MedicalInstitutionReportItemDefinitionPreset
     */
    enabledAggregations?: Array<MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum> | null;
}

/**
* @export
* @enum {string}
*/
export enum MedicalInstitutionReportItemDefinitionPresetTypeEnum {
    Preset = 'preset'
}

export function MedicalInstitutionReportItemDefinitionPresetFromJSON(json: any): MedicalInstitutionReportItemDefinitionPreset {
    return MedicalInstitutionReportItemDefinitionPresetFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemDefinitionPresetFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemDefinitionPreset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'key': MedicalInstitutionReportItemKeyFromJSON(json['key']),
        'label': json['label'],
        'valueType': MedicalInstitutionReportItemDefinitionValueTypeEnumFromJSON(json['value_type']),
        'format': MedicalInstitutionReportItemDefinitionFormatEnumFromJSON(json['format']),
        'calculable': json['calculable'],
        'enabledFilters': ((json['enabled_filters'] as Array<any>).map(MedicalInstitutionReportItemDefinitionEnabledFiltersEnumFromJSON)),
        'enabledAggregations': !exists(json, 'enabled_aggregations') ? undefined : (json['enabled_aggregations'] === null ? null : (json['enabled_aggregations'] as Array<any>).map(MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumFromJSON)),
    };
}

export function MedicalInstitutionReportItemDefinitionPresetToJSON(value?: MedicalInstitutionReportItemDefinitionPreset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'key': MedicalInstitutionReportItemKeyToJSON(value.key),
        'label': value.label,
        'value_type': MedicalInstitutionReportItemDefinitionValueTypeEnumToJSON(value.valueType),
        'format': MedicalInstitutionReportItemDefinitionFormatEnumToJSON(value.format),
        'calculable': value.calculable,
        'enabled_filters': ((value.enabledFilters as Array<any>).map(MedicalInstitutionReportItemDefinitionEnabledFiltersEnumToJSON)),
        'enabled_aggregations': value.enabledAggregations === undefined ? undefined : (value.enabledAggregations === null ? null : (value.enabledAggregations as Array<any>).map(MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumToJSON)),
    };
}

