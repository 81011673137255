/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSON,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSONTyped,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionToJSON,
} from './PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution';

/**
 * 
 * @export
 * @interface PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications
 */
export interface PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications {
    /**
     * 訪問した日付
     * @type {string}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications
     */
    visitedAt: string;
    /**
     * 
     * @type {PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications
     */
    medicalInstitution: PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution;
    /**
     * 30日前の紹介数
     * @type {number}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications
     */
    referralCountBefore: number;
    /**
     * 30日後の紹介数(当日も含む)
     * @type {number}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications
     */
    referralCountAfter: number;
    /**
     * 増加数
     * @type {number}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications
     */
    increaseCount: number;
    /**
     * 医師面談（あり: true, なし: false) ※「訪問」以外のタブ選択時はnullを返す
     * @type {boolean}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications
     */
    isDoctorAttended?: boolean | null;
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerificationsFromJSON(json: any): PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications {
    return PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerificationsFromJSONTyped(json, false);
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerificationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visitedAt': json['visited_at'],
        'medicalInstitution': PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSON(json['medical_institution']),
        'referralCountBefore': json['referral_count_before'],
        'referralCountAfter': json['referral_count_after'],
        'increaseCount': json['increase_count'],
        'isDoctorAttended': !exists(json, 'is_doctor_attended') ? undefined : json['is_doctor_attended'],
    };
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerificationsToJSON(value?: PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visited_at': value.visitedAt,
        'medical_institution': PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionToJSON(value.medicalInstitution),
        'referral_count_before': value.referralCountBefore,
        'referral_count_after': value.referralCountAfter,
        'increase_count': value.increaseCount,
        'is_doctor_attended': value.isDoctorAttended,
    };
}

