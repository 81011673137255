/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnDepartmentReportDefinitionDisplayItem,
    OwnDepartmentReportDefinitionDisplayItemFromJSON,
    OwnDepartmentReportDefinitionDisplayItemFromJSONTyped,
    OwnDepartmentReportDefinitionDisplayItemToJSON,
} from './OwnDepartmentReportDefinitionDisplayItem';
import {
    OwnDepartmentReportFilterCondition,
    OwnDepartmentReportFilterConditionFromJSON,
    OwnDepartmentReportFilterConditionFromJSONTyped,
    OwnDepartmentReportFilterConditionToJSON,
} from './OwnDepartmentReportFilterCondition';
import {
    OwnDepartmentReportSortCondition,
    OwnDepartmentReportSortConditionFromJSON,
    OwnDepartmentReportSortConditionFromJSONTyped,
    OwnDepartmentReportSortConditionToJSON,
} from './OwnDepartmentReportSortCondition';

/**
 * 
 * @export
 * @interface PutOwnDepartmentReportDefinitionRequest
 */
export interface PutOwnDepartmentReportDefinitionRequest {
    /**
     * レポート名
     * @type {string}
     * @memberof PutOwnDepartmentReportDefinitionRequest
     */
    name: string;
    /**
     * 表示項目一覧
     * @type {Array<OwnDepartmentReportDefinitionDisplayItem>}
     * @memberof PutOwnDepartmentReportDefinitionRequest
     */
    displayItems: Array<OwnDepartmentReportDefinitionDisplayItem>;
    /**
     * 絞り込み条件一覧
     * @type {Array<OwnDepartmentReportFilterCondition>}
     * @memberof PutOwnDepartmentReportDefinitionRequest
     */
    filterConditions: Array<OwnDepartmentReportFilterCondition>;
    /**
     * 並び替え条件一覧
     * @type {Array<OwnDepartmentReportSortCondition>}
     * @memberof PutOwnDepartmentReportDefinitionRequest
     */
    sortConditions: Array<OwnDepartmentReportSortCondition>;
}

export function PutOwnDepartmentReportDefinitionRequestFromJSON(json: any): PutOwnDepartmentReportDefinitionRequest {
    return PutOwnDepartmentReportDefinitionRequestFromJSONTyped(json, false);
}

export function PutOwnDepartmentReportDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutOwnDepartmentReportDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'displayItems': ((json['display_items'] as Array<any>).map(OwnDepartmentReportDefinitionDisplayItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(OwnDepartmentReportFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(OwnDepartmentReportSortConditionFromJSON)),
    };
}

export function PutOwnDepartmentReportDefinitionRequestToJSON(value?: PutOwnDepartmentReportDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'display_items': ((value.displayItems as Array<any>).map(OwnDepartmentReportDefinitionDisplayItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(OwnDepartmentReportFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(OwnDepartmentReportSortConditionToJSON)),
    };
}

