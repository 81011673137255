/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrentUserTenant,
    CurrentUserTenantFromJSON,
    CurrentUserTenantFromJSONTyped,
    CurrentUserTenantToJSON,
} from './CurrentUserTenant';

/**
 * 
 * @export
 * @interface CurrentUser
 */
export interface CurrentUser {
    /**
     * ユーザーID
     * @type {number}
     * @memberof CurrentUser
     */
    id: number | null;
    /**
     * ユーザー名
     * @type {string}
     * @memberof CurrentUser
     */
    username: string | null;
    /**
     * Eメール
     * @type {string}
     * @memberof CurrentUser
     */
    email: string | null;
    /**
     * ログインID
     * @type {string}
     * @memberof CurrentUser
     */
    loginName: string | null;
    /**
     * 権限
     * @type {string}
     * @memberof CurrentUser
     */
    role: CurrentUserRoleEnum;
    /**
     * CSアカウントの場合true
     * @type {boolean}
     * @memberof CurrentUser
     */
    csAccount: boolean | null;
    /**
     * 
     * @type {CurrentUserTenant}
     * @memberof CurrentUser
     */
    tenant: CurrentUserTenant | null;
    /**
     * 使用可能な機能のリスト
     * @type {Array<string>}
     * @memberof CurrentUser
     */
    features: Array<CurrentUserFeaturesEnum>;
    /**
     * ユーザー指定の自院の診療科ID (null の場合は指定なし)
     * @type {number}
     * @memberof CurrentUser
     */
    ownDepartmentId: number | null;
    /**
     * 施設タイプによる絞り込みを開いた時の、デフォルトで選択されている施設タイプのid
     * @type {Array<number>}
     * @memberof CurrentUser
     */
    defaultFacilityTypeIds: Array<number>;
    /**
     * HTMLメール送信元アドレスの許可リスト
     * @type {Array<string>}
     * @memberof CurrentUser
     */
    emailSenderAllowedAddresses: Array<string> | null;
}

/**
* @export
* @enum {string}
*/
export enum CurrentUserRoleEnum {
    Member = 'member',
    Owner = 'owner'
}/**
* @export
* @enum {string}
*/
export enum CurrentUserFeaturesEnum {
    DataAnalysis = 'data_analysis',
    SaleMap = 'sale_map',
    MedicalInstitutionsSearch = 'medical_institutions_search',
    ActionLists = 'action_lists',
    Emails = 'emails',
    Communications = 'communications',
    Alerts = 'alerts',
    Tasks = 'tasks',
    Accounts = 'accounts',
    Tags = 'tags',
    DataUploads = 'data_uploads',
    ReverseReferrals = 'reverse_referrals',
    Report = 'report',
    MedicalInstitutionReport = 'medical_institution_report',
    OwnDepartmentReport = 'own_department_report',
    AreaReport = 'area_report',
    CommunicationReport = 'communication_report',
    Dashboard = 'dashboard',
    Labels = 'labels',
    MedicalInstitutionLabels = 'medical_institution_labels',
    CommunicationLabels = 'communication_labels',
    DiagnosisAnalysis = 'diagnosis_analysis',
    EmailSenderRestricted = 'email_sender_restricted'
}

export function CurrentUserFromJSON(json: any): CurrentUser {
    return CurrentUserFromJSONTyped(json, false);
}

export function CurrentUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'email': json['email'],
        'loginName': json['login_name'],
        'role': json['role'],
        'csAccount': json['cs_account'],
        'tenant': CurrentUserTenantFromJSON(json['tenant']),
        'features': json['features'],
        'ownDepartmentId': json['own_department_id'],
        'defaultFacilityTypeIds': json['default_facility_type_ids'],
        'emailSenderAllowedAddresses': json['email_sender_allowed_addresses'],
    };
}

export function CurrentUserToJSON(value?: CurrentUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'email': value.email,
        'login_name': value.loginName,
        'role': value.role,
        'cs_account': value.csAccount,
        'tenant': CurrentUserTenantToJSON(value.tenant),
        'features': value.features,
        'own_department_id': value.ownDepartmentId,
        'default_facility_type_ids': value.defaultFacilityTypeIds,
        'email_sender_allowed_addresses': value.emailSenderAllowedAddresses,
    };
}

