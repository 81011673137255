import { Popover } from '@/src/components/foundations/Utils/Popover';
import { PopoverMenu } from '@/src/components/foundations/Utils/PopoverMenu';

import styles from './styles.module.scss';
import { PresenterProps } from './types';

import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import Dehaze from '@mui/icons-material/Dehaze';
import ExitToApp from '@mui/icons-material/ExitToApp';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

export const NavigationPresenter: React.FC<PresenterProps> = (props) => {
  const menus: React.ComponentProps<typeof PopoverMenu>['menus'] = [
    {
      label: '設定',
      type: 'basic',
      icon: <SettingsOutlinedIcon />,
      onClick: props.onClickGoToProfileNotification,
    },
    {
      label: 'ログアウト',
      type: 'basic',
      icon: <ExitToApp />,
      onClick: props.onClickLogoutButton,
    },
  ];

  return (
    <nav className={styles.base}>
      <div className={styles.menuItem}>
        <button className={styles.menuButton} onClick={props.onClickMenuButton}>
          <Dehaze className={styles.menuIcon} />
        </button>
      </div>
      <p className={styles.title}>{props.title}</p>
      <Popover
        trigger={
          <button className={styles.account} onClick={props.showPopover}>
            <AccountCircleOutlined className={styles.accountIcon} />
            <span className={styles.accountName}>{props.name}</span>
          </button>
        }
        placement="right"
        isShown={props.isShownPopover}
        hide={props.hidePopover}
      >
        <PopoverMenu menus={menus} />
      </Popover>
    </nav>
  );
};
