/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionFiltersFacilityTypeGroupsItems,
    AlertConditionFiltersFacilityTypeGroupsItemsFromJSON,
    AlertConditionFiltersFacilityTypeGroupsItemsFromJSONTyped,
    AlertConditionFiltersFacilityTypeGroupsItemsToJSON,
} from './AlertConditionFiltersFacilityTypeGroupsItems';

/**
 * 
 * @export
 * @interface AlertConditionFiltersFacilityTypeGroups
 */
export interface AlertConditionFiltersFacilityTypeGroups {
    /**
     * 医療機関の絞り込み要素のグルーピングした施設タイプ一覧
     * @type {Array<AlertConditionFiltersFacilityTypeGroupsItems>}
     * @memberof AlertConditionFiltersFacilityTypeGroups
     */
    items: Array<AlertConditionFiltersFacilityTypeGroupsItems>;
    /**
     * 無効データの有無
     * @type {boolean}
     * @memberof AlertConditionFiltersFacilityTypeGroups
     */
    isInvalid: boolean;
}

export function AlertConditionFiltersFacilityTypeGroupsFromJSON(json: any): AlertConditionFiltersFacilityTypeGroups {
    return AlertConditionFiltersFacilityTypeGroupsFromJSONTyped(json, false);
}

export function AlertConditionFiltersFacilityTypeGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionFiltersFacilityTypeGroups {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(AlertConditionFiltersFacilityTypeGroupsItemsFromJSON)),
        'isInvalid': json['is_invalid'],
    };
}

export function AlertConditionFiltersFacilityTypeGroupsToJSON(value?: AlertConditionFiltersFacilityTypeGroups | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(AlertConditionFiltersFacilityTypeGroupsItemsToJSON)),
        'is_invalid': value.isInvalid,
    };
}

