/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionFilters,
    AlertConditionFiltersFromJSON,
    AlertConditionFiltersFromJSONTyped,
    AlertConditionFiltersToJSON,
} from './AlertConditionFilters';
import {
    AlertConditionTriggers,
    AlertConditionTriggersFromJSON,
    AlertConditionTriggersFromJSONTyped,
    AlertConditionTriggersToJSON,
} from './AlertConditionTriggers';
import {
    AlertConditionType,
    AlertConditionTypeFromJSON,
    AlertConditionTypeFromJSONTyped,
    AlertConditionTypeToJSON,
} from './AlertConditionType';

/**
 * 
 * @export
 * @interface AlertCondition
 */
export interface AlertCondition {
    /**
     * アラート条件ID
     * @type {number}
     * @memberof AlertCondition
     */
    id: number;
    /**
     * アラート名
     * @type {string}
     * @memberof AlertCondition
     */
    name: string;
    /**
     * 
     * @type {AlertConditionType}
     * @memberof AlertCondition
     */
    type: AlertConditionType;
    /**
     * 
     * @type {AlertConditionTriggers}
     * @memberof AlertCondition
     */
    triggers: AlertConditionTriggers;
    /**
     * 
     * @type {AlertConditionFilters}
     * @memberof AlertCondition
     */
    filters: AlertConditionFilters;
    /**
     * アラート条件の中に無効なデータが含まれるかどうかのフラグ
     * @type {boolean}
     * @memberof AlertCondition
     */
    hasInvalid: boolean;
    /**
     * アラート条件作成日
     * @type {string}
     * @memberof AlertCondition
     */
    createdDate: string;
}

export function AlertConditionFromJSON(json: any): AlertCondition {
    return AlertConditionFromJSONTyped(json, false);
}

export function AlertConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': AlertConditionTypeFromJSON(json['type']),
        'triggers': AlertConditionTriggersFromJSON(json['triggers']),
        'filters': AlertConditionFiltersFromJSON(json['filters']),
        'hasInvalid': json['has_invalid'],
        'createdDate': json['created_date'],
    };
}

export function AlertConditionToJSON(value?: AlertCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': AlertConditionTypeToJSON(value.type),
        'triggers': AlertConditionTriggersToJSON(value.triggers),
        'filters': AlertConditionFiltersToJSON(value.filters),
        'has_invalid': value.hasInvalid,
        'created_date': value.createdDate,
    };
}

