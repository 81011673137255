/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Email,
    EmailFromJSON,
    EmailToJSON,
    GetEmailReportsResponse,
    GetEmailReportsResponseFromJSON,
    GetEmailReportsResponseToJSON,
    GetEmailStatsResponse,
    GetEmailStatsResponseFromJSON,
    GetEmailStatsResponseToJSON,
    GetEmailsResponse,
    GetEmailsResponseFromJSON,
    GetEmailsResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostEmailIdCopyResponse,
    PostEmailIdCopyResponseFromJSON,
    PostEmailIdCopyResponseToJSON,
    PostEmailIdSendForTestRequest,
    PostEmailIdSendForTestRequestFromJSON,
    PostEmailIdSendForTestRequestToJSON,
    PostEmailRequestStringified,
    PostEmailRequestStringifiedFromJSON,
    PostEmailRequestStringifiedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteEmailRequest {
    id: number;
}

export interface GetEmailIdRequest {
    id: number;
}

export interface GetEmailReportsRequest {
    id: number;
    page?: number;
}

export interface GetEmailStatsRequest {
    id: number;
}

export interface GetEmailsRequest {
    page?: number;
    status?: GetEmailsStatusEnum;
}

export interface PostEmailIdCopyRequest {
    id: number;
}

export interface PostEmailIdSendRequest {
    id: number;
}

export interface PostEmailIdSendForTestOperationRequest {
    id: number;
    postEmailIdSendForTestRequest: PostEmailIdSendForTestRequest;
}

export interface PostEmailOperationRequest {
    stringified: PostEmailRequestStringified;
    recipientsFile?: Blob;
}

export interface PutEmailIdOperationRequest {
    id: number;
    stringified: PostEmailRequestStringified;
    recipientsFile?: Blob;
}

/**
 * EmailsApi - interface
 * 
 * @export
 * @interface EmailsApiInterface
 */
export interface EmailsApiInterface {
    /**
     * メールを削除する 
     * @summary メールの削除
     * @param {number} id メールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApiInterface
     */
    deleteEmailRaw(requestParameters: DeleteEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * メールを削除する 
     * メールの削除
     */
    deleteEmail(requestParameters: DeleteEmailRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * メールを取得する 
     * @summary メールの取得
     * @param {number} id メールのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApiInterface
     */
    getEmailIdRaw(requestParameters: GetEmailIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Email>>;

    /**
     * メールを取得する 
     * メールの取得
     */
    getEmailId(requestParameters: GetEmailIdRequest, initOverrides?: RequestInit): Promise<Email>;

    /**
     * メールの詳細レポート。     送信した宛先リストと開封率, クリック率などが取得できる   
     * @summary メール詳細レポートの取得
     * @param {number} id メールID
     * @param {number} [page] ページ番号 (1始まり)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApiInterface
     */
    getEmailReportsRaw(requestParameters: GetEmailReportsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetEmailReportsResponse>>;

    /**
     * メールの詳細レポート。     送信した宛先リストと開封率, クリック率などが取得できる   
     * メール詳細レポートの取得
     */
    getEmailReports(requestParameters: GetEmailReportsRequest, initOverrides?: RequestInit): Promise<GetEmailReportsResponse>;

    /**
     * 指定したメールの統計データを取得できる 
     * @summary メールの統計データの取得
     * @param {number} id メールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApiInterface
     */
    getEmailStatsRaw(requestParameters: GetEmailStatsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetEmailStatsResponse>>;

    /**
     * 指定したメールの統計データを取得できる 
     * メールの統計データの取得
     */
    getEmailStats(requestParameters: GetEmailStatsRequest, initOverrides?: RequestInit): Promise<GetEmailStatsResponse>;

    /**
     * メールの作成日降順で取得できる。   status の指定で、保存済み(未送信)一覧と送信済み一覧を取得することも可能。 
     * @summary メール一覧の取得
     * @param {number} [page] ページ番号 (1始まり)
     * @param {'sent' | 'unsent'} [status] 絞り込みステータス (デフォルトで sent)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApiInterface
     */
    getEmailsRaw(requestParameters: GetEmailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetEmailsResponse>>;

    /**
     * メールの作成日降順で取得できる。   status の指定で、保存済み(未送信)一覧と送信済み一覧を取得することも可能。 
     * メール一覧の取得
     */
    getEmails(requestParameters: GetEmailsRequest, initOverrides?: RequestInit): Promise<GetEmailsResponse>;

    /**
     * メールのコピーを作成する 
     * @summary メールのコピーを作成
     * @param {number} id メールID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApiInterface
     */
    postEmailIdCopyRaw(requestParameters: PostEmailIdCopyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostEmailIdCopyResponse>>;

    /**
     * メールのコピーを作成する 
     * メールのコピーを作成
     */
    postEmailIdCopy(requestParameters: PostEmailIdCopyRequest, initOverrides?: RequestInit): Promise<PostEmailIdCopyResponse>;

    /**
     * メールを送信する 
     * @summary メールの送信
     * @param {number} id メールのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApiInterface
     */
    postEmailIdSendRaw(requestParameters: PostEmailIdSendRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * メールを送信する 
     * メールの送信
     */
    postEmailIdSend(requestParameters: PostEmailIdSendRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * メールをテスト送信する 
     * @summary メールのテスト送信
     * @param {number} id メールのID
     * @param {PostEmailIdSendForTestRequest} postEmailIdSendForTestRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApiInterface
     */
    postEmailIdSendForTestRaw(requestParameters: PostEmailIdSendForTestOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * メールをテスト送信する 
     * メールのテスト送信
     */
    postEmailIdSendForTest(requestParameters: PostEmailIdSendForTestOperationRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary メールの作成
     * @param {PostEmailRequestStringified} stringified 
     * @param {Blob} [recipientsFile] 宛先ファイル(CSV) ※宛先ファイルが未設定の場合は本key をリクエストに含めないこと
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApiInterface
     */
    postEmailOperationRaw(requestParameters: PostEmailOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Email>>;

    /**
     * メールの作成
     */
    postEmailOperation(requestParameters: PostEmailOperationRequest, initOverrides?: RequestInit): Promise<Email>;

    /**
     * メールを変更する 
     * @summary メールの更新
     * @param {number} id メールのID
     * @param {PostEmailRequestStringified} stringified 
     * @param {Blob} [recipientsFile] 宛先ファイル(CSV) ※ 宛先ファイルが未設定の場合は本key をリクエストに含めないこと
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApiInterface
     */
    putEmailIdOperationRaw(requestParameters: PutEmailIdOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Email>>;

    /**
     * メールを変更する 
     * メールの更新
     */
    putEmailIdOperation(requestParameters: PutEmailIdOperationRequest, initOverrides?: RequestInit): Promise<Email>;

}

/**
 * 
 */
export class EmailsApi extends runtime.BaseAPI implements EmailsApiInterface {

    /**
     * メールを削除する 
     * メールの削除
     */
    async deleteEmailRaw(requestParameters: DeleteEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * メールを削除する 
     * メールの削除
     */
    async deleteEmail(requestParameters: DeleteEmailRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteEmailRaw(requestParameters, initOverrides);
    }

    /**
     * メールを取得する 
     * メールの取得
     */
    async getEmailIdRaw(requestParameters: GetEmailIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Email>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getEmailId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailFromJSON(jsonValue));
    }

    /**
     * メールを取得する 
     * メールの取得
     */
    async getEmailId(requestParameters: GetEmailIdRequest, initOverrides?: RequestInit): Promise<Email> {
        const response = await this.getEmailIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * メールの詳細レポート。     送信した宛先リストと開封率, クリック率などが取得できる   
     * メール詳細レポートの取得
     */
    async getEmailReportsRaw(requestParameters: GetEmailReportsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetEmailReportsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getEmailReports.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/emails/{id}/reports`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailReportsResponseFromJSON(jsonValue));
    }

    /**
     * メールの詳細レポート。     送信した宛先リストと開封率, クリック率などが取得できる   
     * メール詳細レポートの取得
     */
    async getEmailReports(requestParameters: GetEmailReportsRequest, initOverrides?: RequestInit): Promise<GetEmailReportsResponse> {
        const response = await this.getEmailReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 指定したメールの統計データを取得できる 
     * メールの統計データの取得
     */
    async getEmailStatsRaw(requestParameters: GetEmailStatsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetEmailStatsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getEmailStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/emails/{id}/stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailStatsResponseFromJSON(jsonValue));
    }

    /**
     * 指定したメールの統計データを取得できる 
     * メールの統計データの取得
     */
    async getEmailStats(requestParameters: GetEmailStatsRequest, initOverrides?: RequestInit): Promise<GetEmailStatsResponse> {
        const response = await this.getEmailStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * メールの作成日降順で取得できる。   status の指定で、保存済み(未送信)一覧と送信済み一覧を取得することも可能。 
     * メール一覧の取得
     */
    async getEmailsRaw(requestParameters: GetEmailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetEmailsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/emails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailsResponseFromJSON(jsonValue));
    }

    /**
     * メールの作成日降順で取得できる。   status の指定で、保存済み(未送信)一覧と送信済み一覧を取得することも可能。 
     * メール一覧の取得
     */
    async getEmails(requestParameters: GetEmailsRequest = {}, initOverrides?: RequestInit): Promise<GetEmailsResponse> {
        const response = await this.getEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * メールのコピーを作成する 
     * メールのコピーを作成
     */
    async postEmailIdCopyRaw(requestParameters: PostEmailIdCopyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostEmailIdCopyResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postEmailIdCopy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/emails/{id}/copy`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostEmailIdCopyResponseFromJSON(jsonValue));
    }

    /**
     * メールのコピーを作成する 
     * メールのコピーを作成
     */
    async postEmailIdCopy(requestParameters: PostEmailIdCopyRequest, initOverrides?: RequestInit): Promise<PostEmailIdCopyResponse> {
        const response = await this.postEmailIdCopyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * メールを送信する 
     * メールの送信
     */
    async postEmailIdSendRaw(requestParameters: PostEmailIdSendRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postEmailIdSend.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/emails/{id}/send`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * メールを送信する 
     * メールの送信
     */
    async postEmailIdSend(requestParameters: PostEmailIdSendRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postEmailIdSendRaw(requestParameters, initOverrides);
    }

    /**
     * メールをテスト送信する 
     * メールのテスト送信
     */
    async postEmailIdSendForTestRaw(requestParameters: PostEmailIdSendForTestOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postEmailIdSendForTest.');
        }

        if (requestParameters.postEmailIdSendForTestRequest === null || requestParameters.postEmailIdSendForTestRequest === undefined) {
            throw new runtime.RequiredError('postEmailIdSendForTestRequest','Required parameter requestParameters.postEmailIdSendForTestRequest was null or undefined when calling postEmailIdSendForTest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/emails/{id}/send_for_test`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostEmailIdSendForTestRequestToJSON(requestParameters.postEmailIdSendForTestRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * メールをテスト送信する 
     * メールのテスト送信
     */
    async postEmailIdSendForTest(requestParameters: PostEmailIdSendForTestOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postEmailIdSendForTestRaw(requestParameters, initOverrides);
    }

    /**
     * メールの作成
     */
    async postEmailOperationRaw(requestParameters: PostEmailOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Email>> {
        if (requestParameters.stringified === null || requestParameters.stringified === undefined) {
            throw new runtime.RequiredError('stringified','Required parameter requestParameters.stringified was null or undefined when calling postEmailOperation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.recipientsFile !== undefined) {
            formParams.append('recipients_file', requestParameters.recipientsFile as any);
        }

        if (requestParameters.stringified !== undefined) {
            formParams.append('stringified', new Blob([JSON.stringify(PostEmailRequestStringifiedToJSON(requestParameters.stringified))], { type: "application/json", }));
                    }

        const response = await this.request({
            path: `/api/emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailFromJSON(jsonValue));
    }

    /**
     * メールの作成
     */
    async postEmailOperation(requestParameters: PostEmailOperationRequest, initOverrides?: RequestInit): Promise<Email> {
        const response = await this.postEmailOperationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * メールを変更する 
     * メールの更新
     */
    async putEmailIdOperationRaw(requestParameters: PutEmailIdOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Email>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putEmailIdOperation.');
        }

        if (requestParameters.stringified === null || requestParameters.stringified === undefined) {
            throw new runtime.RequiredError('stringified','Required parameter requestParameters.stringified was null or undefined when calling putEmailIdOperation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.recipientsFile !== undefined) {
            formParams.append('recipients_file', requestParameters.recipientsFile as any);
        }

        if (requestParameters.stringified !== undefined) {
            formParams.append('stringified', new Blob([JSON.stringify(PostEmailRequestStringifiedToJSON(requestParameters.stringified))], { type: "application/json", }));
                    }

        const response = await this.request({
            path: `/api/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailFromJSON(jsonValue));
    }

    /**
     * メールを変更する 
     * メールの更新
     */
    async putEmailIdOperation(requestParameters: PutEmailIdOperationRequest, initOverrides?: RequestInit): Promise<Email> {
        const response = await this.putEmailIdOperationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetEmailsStatusEnum {
    Sent = 'sent',
    Unsent = 'unsent'
}
