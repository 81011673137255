/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * コミュニケーションラベルの設定値
 * @export
 * @interface CommunicationLabelValue
 */
export interface CommunicationLabelValue {
    /**
     * コミュニケーションラベルマスタID
     * @type {number}
     * @memberof CommunicationLabelValue
     */
    labelId: number;
    /**
     * テキスト値
     * @type {string}
     * @memberof CommunicationLabelValue
     */
    textValue: string | null;
    /**
     * 数値
     * @type {number}
     * @memberof CommunicationLabelValue
     */
    integerValue: number | null;
    /**
     * 日付値
     * @type {string}
     * @memberof CommunicationLabelValue
     */
    dateValue: string | null;
    /**
     * 時刻値
     * @type {string}
     * @memberof CommunicationLabelValue
     */
    timeValue: string | null;
    /**
     * コミュニケーションラベル選択肢ID
     * @type {number}
     * @memberof CommunicationLabelValue
     */
    selectionId: number | null;
    /**
     * コミュニケーションラベル選択肢IDのリスト
     * @type {Array<number>}
     * @memberof CommunicationLabelValue
     */
    selectionIds: Array<number> | null;
    /**
     * 自院の診療科ID
     * @type {number}
     * @memberof CommunicationLabelValue
     */
    ownDepartmentId: number | null;
}

export function CommunicationLabelValueFromJSON(json: any): CommunicationLabelValue {
    return CommunicationLabelValueFromJSONTyped(json, false);
}

export function CommunicationLabelValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabelValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labelId': json['label_id'],
        'textValue': json['text_value'],
        'integerValue': json['integer_value'],
        'dateValue': json['date_value'],
        'timeValue': json['time_value'],
        'selectionId': json['selection_id'],
        'selectionIds': json['selection_ids'],
        'ownDepartmentId': json['own_department_id'],
    };
}

export function CommunicationLabelValueToJSON(value?: CommunicationLabelValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label_id': value.labelId,
        'text_value': value.textValue,
        'integer_value': value.integerValue,
        'date_value': value.dateValue,
        'time_value': value.timeValue,
        'selection_id': value.selectionId,
        'selection_ids': value.selectionIds,
        'own_department_id': value.ownDepartmentId,
    };
}

