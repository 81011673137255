/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TagOption,
    TagOptionFromJSON,
    TagOptionFromJSONTyped,
    TagOptionToJSON,
} from './TagOption';

/**
 * タググループ (属しているタグ一覧を含む)
 *   - 属しているタグがない場合は、tags は空配列になる
 *   - id, name が null の場合は、グループに紐付いていないタグをまとめたもの
 *   - tags の ソートは、tag_id の降順
 * @export
 * @interface TagOptionGroup
 */
export interface TagOptionGroup {
    /**
     * タググループのid
     * @type {string}
     * @memberof TagOptionGroup
     */
    id: string | null;
    /**
     * タググループ名
     * @type {string}
     * @memberof TagOptionGroup
     */
    name: string | null;
    /**
     * タググループの説明
     * @type {string}
     * @memberof TagOptionGroup
     */
    description: string | null;
    /**
     * 
     * @type {Array<TagOption>}
     * @memberof TagOptionGroup
     */
    tags: Array<TagOption>;
}

export function TagOptionGroupFromJSON(json: any): TagOptionGroup {
    return TagOptionGroupFromJSONTyped(json, false);
}

export function TagOptionGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagOptionGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'tags': ((json['tags'] as Array<any>).map(TagOptionFromJSON)),
    };
}

export function TagOptionGroupToJSON(value?: TagOptionGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'tags': ((value.tags as Array<any>).map(TagOptionToJSON)),
    };
}

