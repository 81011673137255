/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostDataAnalysisRankingBothReferralsMonthlyResponseRankings,
    PostDataAnalysisRankingBothReferralsMonthlyResponseRankingsFromJSON,
    PostDataAnalysisRankingBothReferralsMonthlyResponseRankingsFromJSONTyped,
    PostDataAnalysisRankingBothReferralsMonthlyResponseRankingsToJSON,
} from './PostDataAnalysisRankingBothReferralsMonthlyResponseRankings';

/**
 * 
 * @export
 * @interface PostDataAnalysisRankingBothReferralsMonthlyResponse
 */
export interface PostDataAnalysisRankingBothReferralsMonthlyResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyResponse
     */
    totalPage: number;
    /**
     * ランキングデータ
     * @type {Array<PostDataAnalysisRankingBothReferralsMonthlyResponseRankings>}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyResponse
     */
    rankings: Array<PostDataAnalysisRankingBothReferralsMonthlyResponseRankings>;
}

export function PostDataAnalysisRankingBothReferralsMonthlyResponseFromJSON(json: any): PostDataAnalysisRankingBothReferralsMonthlyResponse {
    return PostDataAnalysisRankingBothReferralsMonthlyResponseFromJSONTyped(json, false);
}

export function PostDataAnalysisRankingBothReferralsMonthlyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisRankingBothReferralsMonthlyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'rankings': ((json['rankings'] as Array<any>).map(PostDataAnalysisRankingBothReferralsMonthlyResponseRankingsFromJSON)),
    };
}

export function PostDataAnalysisRankingBothReferralsMonthlyResponseToJSON(value?: PostDataAnalysisRankingBothReferralsMonthlyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'rankings': ((value.rankings as Array<any>).map(PostDataAnalysisRankingBothReferralsMonthlyResponseRankingsToJSON)),
    };
}

