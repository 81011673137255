/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsRequestPeriod,
    PostChartReferralsRequestPeriodFromJSON,
    PostChartReferralsRequestPeriodFromJSONTyped,
    PostChartReferralsRequestPeriodToJSON,
} from './PostChartReferralsRequestPeriod';

/**
 * 
 * @export
 * @interface PostDataAnalysisRankingReferralTotalCountRequest
 */
export interface PostDataAnalysisRankingReferralTotalCountRequest {
    /**
     * ページ番号 (1始まり)
     * @type {number}
     * @memberof PostDataAnalysisRankingReferralTotalCountRequest
     */
    page: number;
    /**
     * 
     * @type {PostChartReferralsRequestPeriod}
     * @memberof PostDataAnalysisRankingReferralTotalCountRequest
     */
    period: PostChartReferralsRequestPeriod;
    /**
     * 都道府県
     * @type {string}
     * @memberof PostDataAnalysisRankingReferralTotalCountRequest
     */
    prefecture: string | null;
    /**
     * 地域
     * @type {string}
     * @memberof PostDataAnalysisRankingReferralTotalCountRequest
     */
    area: string | null;
    /**
     * 医療機関タイプ(施設タイプ)ID
     * @type {Array<string>}
     * @memberof PostDataAnalysisRankingReferralTotalCountRequest
     */
    facilityTypeIds: Array<string> | null;
    /**
     * 診療科目ID
     * @type {Array<string>}
     * @memberof PostDataAnalysisRankingReferralTotalCountRequest
     */
    departmentIds: Array<string> | null;
    /**
     * 紹介数の並び順
     * @type {string}
     * @memberof PostDataAnalysisRankingReferralTotalCountRequest
     */
    sort: PostDataAnalysisRankingReferralTotalCountRequestSortEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostDataAnalysisRankingReferralTotalCountRequestSortEnum {
    Desc = 'desc',
    Asc = 'asc'
}

export function PostDataAnalysisRankingReferralTotalCountRequestFromJSON(json: any): PostDataAnalysisRankingReferralTotalCountRequest {
    return PostDataAnalysisRankingReferralTotalCountRequestFromJSONTyped(json, false);
}

export function PostDataAnalysisRankingReferralTotalCountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisRankingReferralTotalCountRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'period': PostChartReferralsRequestPeriodFromJSON(json['period']),
        'prefecture': json['prefecture'],
        'area': json['area'],
        'facilityTypeIds': json['facility_type_ids'],
        'departmentIds': json['department_ids'],
        'sort': json['sort'],
    };
}

export function PostDataAnalysisRankingReferralTotalCountRequestToJSON(value?: PostDataAnalysisRankingReferralTotalCountRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'period': PostChartReferralsRequestPeriodToJSON(value.period),
        'prefecture': value.prefecture,
        'area': value.area,
        'facility_type_ids': value.facilityTypeIds,
        'department_ids': value.departmentIds,
        'sort': value.sort,
    };
}

