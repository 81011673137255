/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * メール
 * @export
 * @interface PostEmailRequestStringified
 */
export interface PostEmailRequestStringified {
    /**
     * 送信元の名前
     * @type {string}
     * @memberof PostEmailRequestStringified
     */
    senderName: string | null;
    /**
     * 送信元のメールアドレス
     * @type {string}
     * @memberof PostEmailRequestStringified
     */
    senderEmail: string | null;
    /**
     * タイトル
     * @type {string}
     * @memberof PostEmailRequestStringified
     */
    title: string;
    /**
     * メール本文
     * @type {string}
     * @memberof PostEmailRequestStringified
     */
    mainContent: string | null;
    /**
     * 添付ファイルのID (添付ファイルがない場合は null)
     * @type {number}
     * @memberof PostEmailRequestStringified
     */
    attachmentId: number | null;
}

export function PostEmailRequestStringifiedFromJSON(json: any): PostEmailRequestStringified {
    return PostEmailRequestStringifiedFromJSONTyped(json, false);
}

export function PostEmailRequestStringifiedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostEmailRequestStringified {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'senderName': json['sender_name'],
        'senderEmail': json['sender_email'],
        'title': json['title'],
        'mainContent': json['main_content'],
        'attachmentId': json['attachment_id'],
    };
}

export function PostEmailRequestStringifiedToJSON(value?: PostEmailRequestStringified | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sender_name': value.senderName,
        'sender_email': value.senderEmail,
        'title': value.title,
        'main_content': value.mainContent,
        'attachment_id': value.attachmentId,
    };
}

