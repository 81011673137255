/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationReportItemKey,
    CommunicationReportItemKeyFromJSON,
    CommunicationReportItemKeyFromJSONTyped,
    CommunicationReportItemKeyToJSON,
} from './CommunicationReportItemKey';

/**
 * コミュニケーションレポート項目（プリセット項目）定義
 * @export
 * @interface CommunicationReportItemDefinitionPreset
 */
export interface CommunicationReportItemDefinitionPreset {
    /**
     * クライアントでタグ付きユニオンとして扱うためのタグ
     * @type {string}
     * @memberof CommunicationReportItemDefinitionPreset
     */
    type: CommunicationReportItemDefinitionPresetTypeEnum;
    /**
     * 
     * @type {CommunicationReportItemKey}
     * @memberof CommunicationReportItemDefinitionPreset
     */
    key: CommunicationReportItemKey;
    /**
     * デフォルトの項目名
     * @type {string}
     * @memberof CommunicationReportItemDefinitionPreset
     */
    label: string;
}

/**
* @export
* @enum {string}
*/
export enum CommunicationReportItemDefinitionPresetTypeEnum {
    Preset = 'preset'
}

export function CommunicationReportItemDefinitionPresetFromJSON(json: any): CommunicationReportItemDefinitionPreset {
    return CommunicationReportItemDefinitionPresetFromJSONTyped(json, false);
}

export function CommunicationReportItemDefinitionPresetFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportItemDefinitionPreset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'key': CommunicationReportItemKeyFromJSON(json['key']),
        'label': json['label'],
    };
}

export function CommunicationReportItemDefinitionPresetToJSON(value?: CommunicationReportItemDefinitionPreset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'key': CommunicationReportItemKeyToJSON(value.key),
        'label': value.label,
    };
}

