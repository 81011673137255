/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionRunningStatusEnum,
    MedicalInstitutionRunningStatusEnumFromJSON,
    MedicalInstitutionRunningStatusEnumFromJSONTyped,
    MedicalInstitutionRunningStatusEnumToJSON,
} from './MedicalInstitutionRunningStatusEnum';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionReportResultsResponseResults
 */
export interface PostMedicalInstitutionReportResultsResponseResults {
    /**
     * 医療機関ID
     * @type {number}
     * @memberof PostMedicalInstitutionReportResultsResponseResults
     */
    id: number;
    /**
     * 
     * @type {MedicalInstitutionRunningStatusEnum}
     * @memberof PostMedicalInstitutionReportResultsResponseResults
     */
    runningStatus: MedicalInstitutionRunningStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionReportResultsResponseResults
     */
    values: Array<string>;
}

export function PostMedicalInstitutionReportResultsResponseResultsFromJSON(json: any): PostMedicalInstitutionReportResultsResponseResults {
    return PostMedicalInstitutionReportResultsResponseResultsFromJSONTyped(json, false);
}

export function PostMedicalInstitutionReportResultsResponseResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionReportResultsResponseResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'runningStatus': MedicalInstitutionRunningStatusEnumFromJSON(json['running_status']),
        'values': json['values'],
    };
}

export function PostMedicalInstitutionReportResultsResponseResultsToJSON(value?: PostMedicalInstitutionReportResultsResponseResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'running_status': MedicalInstitutionRunningStatusEnumToJSON(value.runningStatus),
        'values': value.values,
    };
}

