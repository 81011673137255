/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MedicalInstitutionReportAlert
 */
export interface MedicalInstitutionReportAlert {
    /**
     * アラート発行日
     * @type {string}
     * @memberof MedicalInstitutionReportAlert
     */
    alertDate: string;
    /**
     * 該当件数
     * @type {number}
     * @memberof MedicalInstitutionReportAlert
     */
    targetCount: number;
}

export function MedicalInstitutionReportAlertFromJSON(json: any): MedicalInstitutionReportAlert {
    return MedicalInstitutionReportAlertFromJSONTyped(json, false);
}

export function MedicalInstitutionReportAlertFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportAlert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alertDate': json['alert_date'],
        'targetCount': json['target_count'],
    };
}

export function MedicalInstitutionReportAlertToJSON(value?: MedicalInstitutionReportAlert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_date': value.alertDate,
        'target_count': value.targetCount,
    };
}

