/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriod,
    PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriodFromJSON,
    PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriodFromJSONTyped,
    PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriodToJSON,
} from './PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriod';
import {
    PostMedicalInstitutionSearchForSalesMapRequestReferralPeriod,
    PostMedicalInstitutionSearchForSalesMapRequestReferralPeriodFromJSON,
    PostMedicalInstitutionSearchForSalesMapRequestReferralPeriodFromJSONTyped,
    PostMedicalInstitutionSearchForSalesMapRequestReferralPeriodToJSON,
} from './PostMedicalInstitutionSearchForSalesMapRequestReferralPeriod';

/**
 * 紹介数絞り込みの絞り込み条件
 * @export
 * @interface PostMedicalInstitutionSearchForSalesMapRequestReferral
 */
export interface PostMedicalInstitutionSearchForSalesMapRequestReferral {
    /**
     * 紹介タイプ
     * @type {string}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestReferral
     */
    referralType: PostMedicalInstitutionSearchForSalesMapRequestReferralReferralTypeEnum;
    /**
     * 
     * @type {PostMedicalInstitutionSearchForSalesMapRequestReferralPeriod}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestReferral
     */
    period: PostMedicalInstitutionSearchForSalesMapRequestReferralPeriod;
    /**
     * 
     * @type {PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriod}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestReferral
     */
    comparisonPeriod: PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriod;
    /**
     * (紹介を受けた)自院の診療科目ID
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestReferral
     */
    ownDepartmentIds: Array<string> | null;
}

/**
* @export
* @enum {string}
*/
export enum PostMedicalInstitutionSearchForSalesMapRequestReferralReferralTypeEnum {
    Referral = 'referral'
}

export function PostMedicalInstitutionSearchForSalesMapRequestReferralFromJSON(json: any): PostMedicalInstitutionSearchForSalesMapRequestReferral {
    return PostMedicalInstitutionSearchForSalesMapRequestReferralFromJSONTyped(json, false);
}

export function PostMedicalInstitutionSearchForSalesMapRequestReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionSearchForSalesMapRequestReferral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referralType': json['referral_type'],
        'period': PostMedicalInstitutionSearchForSalesMapRequestReferralPeriodFromJSON(json['period']),
        'comparisonPeriod': PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriodFromJSON(json['comparison_period']),
        'ownDepartmentIds': json['own_department_ids'],
    };
}

export function PostMedicalInstitutionSearchForSalesMapRequestReferralToJSON(value?: PostMedicalInstitutionSearchForSalesMapRequestReferral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referral_type': value.referralType,
        'period': PostMedicalInstitutionSearchForSalesMapRequestReferralPeriodToJSON(value.period),
        'comparison_period': PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriodToJSON(value.comparisonPeriod),
        'own_department_ids': value.ownDepartmentIds,
    };
}

