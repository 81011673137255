/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitude,
    PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitudeFromJSON,
    PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitudeFromJSONTyped,
    PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitudeToJSON,
} from './PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitude';
import {
    PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitude,
    PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitudeFromJSON,
    PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitudeFromJSONTyped,
    PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitudeToJSON,
} from './PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitude';

/**
 * 境界値の指定
 * @export
 * @interface PostMedicalInstitutionSearchForSalesMapRequestBounds
 */
export interface PostMedicalInstitutionSearchForSalesMapRequestBounds {
    /**
     * 
     * @type {PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitude}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestBounds
     */
    longitude: PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitude;
    /**
     * 
     * @type {PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitude}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestBounds
     */
    latitude: PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitude;
}

export function PostMedicalInstitutionSearchForSalesMapRequestBoundsFromJSON(json: any): PostMedicalInstitutionSearchForSalesMapRequestBounds {
    return PostMedicalInstitutionSearchForSalesMapRequestBoundsFromJSONTyped(json, false);
}

export function PostMedicalInstitutionSearchForSalesMapRequestBoundsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionSearchForSalesMapRequestBounds {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'longitude': PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitudeFromJSON(json['longitude']),
        'latitude': PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitudeFromJSON(json['latitude']),
    };
}

export function PostMedicalInstitutionSearchForSalesMapRequestBoundsToJSON(value?: PostMedicalInstitutionSearchForSalesMapRequestBounds | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'longitude': PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitudeToJSON(value.longitude),
        'latitude': PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitudeToJSON(value.latitude),
    };
}

