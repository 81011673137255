/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 医療機関ラベルエリア住所設定
 * @export
 * @interface MedicalInstitutionLabelAreaAddress
 */
export interface MedicalInstitutionLabelAreaAddress {
    /**
     * 都道府県
     * @type {string}
     * @memberof MedicalInstitutionLabelAreaAddress
     */
    prefecture: string;
    /**
     * 市区町村
     * @type {string}
     * @memberof MedicalInstitutionLabelAreaAddress
     */
    cityOrLocality: string;
}

export function MedicalInstitutionLabelAreaAddressFromJSON(json: any): MedicalInstitutionLabelAreaAddress {
    return MedicalInstitutionLabelAreaAddressFromJSONTyped(json, false);
}

export function MedicalInstitutionLabelAreaAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionLabelAreaAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prefecture': json['prefecture'],
        'cityOrLocality': json['city_or_locality'],
    };
}

export function MedicalInstitutionLabelAreaAddressToJSON(value?: MedicalInstitutionLabelAreaAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prefecture': value.prefecture,
        'city_or_locality': value.cityOrLocality,
    };
}

