/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionLabelArea,
    MedicalInstitutionLabelAreaFromJSON,
    MedicalInstitutionLabelAreaFromJSONTyped,
    MedicalInstitutionLabelAreaToJSON,
} from './MedicalInstitutionLabelArea';
import {
    MedicalInstitutionLabelComponentType,
    MedicalInstitutionLabelComponentTypeFromJSON,
    MedicalInstitutionLabelComponentTypeFromJSONTyped,
    MedicalInstitutionLabelComponentTypeToJSON,
} from './MedicalInstitutionLabelComponentType';
import {
    MedicalInstitutionLabelSelection,
    MedicalInstitutionLabelSelectionFromJSON,
    MedicalInstitutionLabelSelectionFromJSONTyped,
    MedicalInstitutionLabelSelectionToJSON,
} from './MedicalInstitutionLabelSelection';

/**
 * 医療機関ラベルマスタ設定
 * @export
 * @interface MedicalInstitutionLabel
 */
export interface MedicalInstitutionLabel {
    /**
     * 医療機関ラベルマスタID
     * @type {number}
     * @memberof MedicalInstitutionLabel
     */
    id: number;
    /**
     * 項目名
     * @type {string}
     * @memberof MedicalInstitutionLabel
     */
    name: string;
    /**
     * 
     * @type {MedicalInstitutionLabelComponentType}
     * @memberof MedicalInstitutionLabel
     */
    componentType: MedicalInstitutionLabelComponentType;
    /**
     * 並び順(昇順)
     * @type {number}
     * @memberof MedicalInstitutionLabel
     */
    order: number;
    /**
     * 説明
     * @type {string}
     * @memberof MedicalInstitutionLabel
     */
    description?: string | null;
    /**
     * 選択肢リスト（コンポーネント種別が'select','checkbox'の場合のみ）
     * @type {Array<MedicalInstitutionLabelSelection>}
     * @memberof MedicalInstitutionLabel
     */
    selections?: Array<MedicalInstitutionLabelSelection> | null;
    /**
     * エリア設定リスト（コンポーネント種別が'area'の場合のみ）
     * @type {Array<MedicalInstitutionLabelArea>}
     * @memberof MedicalInstitutionLabel
     */
    areas?: Array<MedicalInstitutionLabelArea> | null;
}

export function MedicalInstitutionLabelFromJSON(json: any): MedicalInstitutionLabel {
    return MedicalInstitutionLabelFromJSONTyped(json, false);
}

export function MedicalInstitutionLabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionLabel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'componentType': MedicalInstitutionLabelComponentTypeFromJSON(json['component_type']),
        'order': json['order'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'selections': !exists(json, 'selections') ? undefined : (json['selections'] === null ? null : (json['selections'] as Array<any>).map(MedicalInstitutionLabelSelectionFromJSON)),
        'areas': !exists(json, 'areas') ? undefined : (json['areas'] === null ? null : (json['areas'] as Array<any>).map(MedicalInstitutionLabelAreaFromJSON)),
    };
}

export function MedicalInstitutionLabelToJSON(value?: MedicalInstitutionLabel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'component_type': MedicalInstitutionLabelComponentTypeToJSON(value.componentType),
        'order': value.order,
        'description': value.description,
        'selections': value.selections === undefined ? undefined : (value.selections === null ? null : (value.selections as Array<any>).map(MedicalInstitutionLabelSelectionToJSON)),
        'areas': value.areas === undefined ? undefined : (value.areas === null ? null : (value.areas as Array<any>).map(MedicalInstitutionLabelAreaToJSON)),
    };
}

