/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    GetOwnDepartmentReportDefinitionsResponse,
    GetOwnDepartmentReportDefinitionsResponseFromJSON,
    GetOwnDepartmentReportDefinitionsResponseToJSON,
    GetOwnDepartmentReportItemDefinitionsResponse,
    GetOwnDepartmentReportItemDefinitionsResponseFromJSON,
    GetOwnDepartmentReportItemDefinitionsResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    OwnDepartmentReportDefinitionDetail,
    OwnDepartmentReportDefinitionDetailFromJSON,
    OwnDepartmentReportDefinitionDetailToJSON,
    PostOwnDepartmentReportDefinitionRequest,
    PostOwnDepartmentReportDefinitionRequestFromJSON,
    PostOwnDepartmentReportDefinitionRequestToJSON,
    PostOwnDepartmentReportResultsRequest,
    PostOwnDepartmentReportResultsRequestFromJSON,
    PostOwnDepartmentReportResultsRequestToJSON,
    PostOwnDepartmentReportResultsResponse,
    PostOwnDepartmentReportResultsResponseFromJSON,
    PostOwnDepartmentReportResultsResponseToJSON,
    PutOwnDepartmentReportDefinitionRequest,
    PutOwnDepartmentReportDefinitionRequestFromJSON,
    PutOwnDepartmentReportDefinitionRequestToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteOwnDepartmentReportDefinitionRequest {
    id: number;
}

export interface GetOwnDepartmentReportDefinitionRequest {
    id: number;
}

export interface PostOwnDepartmentReportDefinitionOperationRequest {
    postOwnDepartmentReportDefinitionRequest: PostOwnDepartmentReportDefinitionRequest;
}

export interface PostOwnDepartmentReportResultsOperationRequest {
    postOwnDepartmentReportResultsRequest: PostOwnDepartmentReportResultsRequest;
}

export interface PutOwnDepartmentReportDefinitionOperationRequest {
    id: number;
    putOwnDepartmentReportDefinitionRequest: PutOwnDepartmentReportDefinitionRequest;
}

/**
 * OwnDepartmentReportsApi - interface
 * 
 * @export
 * @interface OwnDepartmentReportsApiInterface
 */
export interface OwnDepartmentReportsApiInterface {
    /**
     * 
     * @summary 診療科レポート定義の削除
     * @param {number} id 診療科レポート定義ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnDepartmentReportsApiInterface
     */
    deleteOwnDepartmentReportDefinitionRaw(requestParameters: DeleteOwnDepartmentReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * 診療科レポート定義の削除
     */
    deleteOwnDepartmentReportDefinition(requestParameters: DeleteOwnDepartmentReportDefinitionRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary 診療科レポート定義の取得
     * @param {number} id 診療科レポート定義ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnDepartmentReportsApiInterface
     */
    getOwnDepartmentReportDefinitionRaw(requestParameters: GetOwnDepartmentReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OwnDepartmentReportDefinitionDetail>>;

    /**
     * 診療科レポート定義の取得
     */
    getOwnDepartmentReportDefinition(requestParameters: GetOwnDepartmentReportDefinitionRequest, initOverrides?: RequestInit): Promise<OwnDepartmentReportDefinitionDetail>;

    /**
     * 
     * @summary 診療科レポート定義一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnDepartmentReportsApiInterface
     */
    getOwnDepartmentReportDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetOwnDepartmentReportDefinitionsResponse>>;

    /**
     * 診療科レポート定義一覧の取得
     */
    getOwnDepartmentReportDefinitions(initOverrides?: RequestInit): Promise<GetOwnDepartmentReportDefinitionsResponse>;

    /**
     * 
     * @summary 診療科レポート項目マスタ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnDepartmentReportsApiInterface
     */
    getOwnDepartmentReportItemDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetOwnDepartmentReportItemDefinitionsResponse>>;

    /**
     * 診療科レポート項目マスタ一覧
     */
    getOwnDepartmentReportItemDefinitions(initOverrides?: RequestInit): Promise<GetOwnDepartmentReportItemDefinitionsResponse>;

    /**
     * 
     * @summary 診療科レポート定義の登録
     * @param {PostOwnDepartmentReportDefinitionRequest} postOwnDepartmentReportDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnDepartmentReportsApiInterface
     */
    postOwnDepartmentReportDefinitionRaw(requestParameters: PostOwnDepartmentReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OwnDepartmentReportDefinitionDetail>>;

    /**
     * 診療科レポート定義の登録
     */
    postOwnDepartmentReportDefinition(requestParameters: PostOwnDepartmentReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<OwnDepartmentReportDefinitionDetail>;

    /**
     * 
     * @summary 診療科レポート結果生成
     * @param {PostOwnDepartmentReportResultsRequest} postOwnDepartmentReportResultsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnDepartmentReportsApiInterface
     */
    postOwnDepartmentReportResultsRaw(requestParameters: PostOwnDepartmentReportResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostOwnDepartmentReportResultsResponse>>;

    /**
     * 診療科レポート結果生成
     */
    postOwnDepartmentReportResults(requestParameters: PostOwnDepartmentReportResultsOperationRequest, initOverrides?: RequestInit): Promise<PostOwnDepartmentReportResultsResponse>;

    /**
     * 
     * @summary 診療科レポート定義の更新
     * @param {number} id 診療科レポート定義ID
     * @param {PutOwnDepartmentReportDefinitionRequest} putOwnDepartmentReportDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnDepartmentReportsApiInterface
     */
    putOwnDepartmentReportDefinitionRaw(requestParameters: PutOwnDepartmentReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OwnDepartmentReportDefinitionDetail>>;

    /**
     * 診療科レポート定義の更新
     */
    putOwnDepartmentReportDefinition(requestParameters: PutOwnDepartmentReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<OwnDepartmentReportDefinitionDetail>;

}

/**
 * 
 */
export class OwnDepartmentReportsApi extends runtime.BaseAPI implements OwnDepartmentReportsApiInterface {

    /**
     * 診療科レポート定義の削除
     */
    async deleteOwnDepartmentReportDefinitionRaw(requestParameters: DeleteOwnDepartmentReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOwnDepartmentReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/own_department_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 診療科レポート定義の削除
     */
    async deleteOwnDepartmentReportDefinition(requestParameters: DeleteOwnDepartmentReportDefinitionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteOwnDepartmentReportDefinitionRaw(requestParameters, initOverrides);
    }

    /**
     * 診療科レポート定義の取得
     */
    async getOwnDepartmentReportDefinitionRaw(requestParameters: GetOwnDepartmentReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OwnDepartmentReportDefinitionDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOwnDepartmentReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/own_department_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnDepartmentReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * 診療科レポート定義の取得
     */
    async getOwnDepartmentReportDefinition(requestParameters: GetOwnDepartmentReportDefinitionRequest, initOverrides?: RequestInit): Promise<OwnDepartmentReportDefinitionDetail> {
        const response = await this.getOwnDepartmentReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 診療科レポート定義一覧の取得
     */
    async getOwnDepartmentReportDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetOwnDepartmentReportDefinitionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/own_department_reports/definitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOwnDepartmentReportDefinitionsResponseFromJSON(jsonValue));
    }

    /**
     * 診療科レポート定義一覧の取得
     */
    async getOwnDepartmentReportDefinitions(initOverrides?: RequestInit): Promise<GetOwnDepartmentReportDefinitionsResponse> {
        const response = await this.getOwnDepartmentReportDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 診療科レポート項目マスタ一覧
     */
    async getOwnDepartmentReportItemDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetOwnDepartmentReportItemDefinitionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/own_department_reports/item_definitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOwnDepartmentReportItemDefinitionsResponseFromJSON(jsonValue));
    }

    /**
     * 診療科レポート項目マスタ一覧
     */
    async getOwnDepartmentReportItemDefinitions(initOverrides?: RequestInit): Promise<GetOwnDepartmentReportItemDefinitionsResponse> {
        const response = await this.getOwnDepartmentReportItemDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 診療科レポート定義の登録
     */
    async postOwnDepartmentReportDefinitionRaw(requestParameters: PostOwnDepartmentReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OwnDepartmentReportDefinitionDetail>> {
        if (requestParameters.postOwnDepartmentReportDefinitionRequest === null || requestParameters.postOwnDepartmentReportDefinitionRequest === undefined) {
            throw new runtime.RequiredError('postOwnDepartmentReportDefinitionRequest','Required parameter requestParameters.postOwnDepartmentReportDefinitionRequest was null or undefined when calling postOwnDepartmentReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/own_department_reports/definitions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostOwnDepartmentReportDefinitionRequestToJSON(requestParameters.postOwnDepartmentReportDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnDepartmentReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * 診療科レポート定義の登録
     */
    async postOwnDepartmentReportDefinition(requestParameters: PostOwnDepartmentReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<OwnDepartmentReportDefinitionDetail> {
        const response = await this.postOwnDepartmentReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 診療科レポート結果生成
     */
    async postOwnDepartmentReportResultsRaw(requestParameters: PostOwnDepartmentReportResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostOwnDepartmentReportResultsResponse>> {
        if (requestParameters.postOwnDepartmentReportResultsRequest === null || requestParameters.postOwnDepartmentReportResultsRequest === undefined) {
            throw new runtime.RequiredError('postOwnDepartmentReportResultsRequest','Required parameter requestParameters.postOwnDepartmentReportResultsRequest was null or undefined when calling postOwnDepartmentReportResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/own_department_reports/results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostOwnDepartmentReportResultsRequestToJSON(requestParameters.postOwnDepartmentReportResultsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostOwnDepartmentReportResultsResponseFromJSON(jsonValue));
    }

    /**
     * 診療科レポート結果生成
     */
    async postOwnDepartmentReportResults(requestParameters: PostOwnDepartmentReportResultsOperationRequest, initOverrides?: RequestInit): Promise<PostOwnDepartmentReportResultsResponse> {
        const response = await this.postOwnDepartmentReportResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 診療科レポート定義の更新
     */
    async putOwnDepartmentReportDefinitionRaw(requestParameters: PutOwnDepartmentReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OwnDepartmentReportDefinitionDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putOwnDepartmentReportDefinition.');
        }

        if (requestParameters.putOwnDepartmentReportDefinitionRequest === null || requestParameters.putOwnDepartmentReportDefinitionRequest === undefined) {
            throw new runtime.RequiredError('putOwnDepartmentReportDefinitionRequest','Required parameter requestParameters.putOwnDepartmentReportDefinitionRequest was null or undefined when calling putOwnDepartmentReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/own_department_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutOwnDepartmentReportDefinitionRequestToJSON(requestParameters.putOwnDepartmentReportDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnDepartmentReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * 診療科レポート定義の更新
     */
    async putOwnDepartmentReportDefinition(requestParameters: PutOwnDepartmentReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<OwnDepartmentReportDefinitionDetail> {
        const response = await this.putOwnDepartmentReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
