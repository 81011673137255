/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommunicationReportDefinitionAggregationData
 */
export interface CommunicationReportDefinitionAggregationData {
    /**
     * 
     * @type {string}
     * @memberof CommunicationReportDefinitionAggregationData
     */
    aggregationMethod: CommunicationReportDefinitionAggregationDataAggregationMethodEnum;
    /**
     * 集計対象の項目のキー。レコード数集計の場合は指定しない
     * @type {string}
     * @memberof CommunicationReportDefinitionAggregationData
     */
    itemKey?: string;
    /**
     * 同一の医療機関を集計対象から除外するかどうか。trueの場合除外する
     * @type {boolean}
     * @memberof CommunicationReportDefinitionAggregationData
     */
    deduplicateWithMedicalInstitution?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CommunicationReportDefinitionAggregationDataAggregationMethodEnum {
    Sum = 'sum',
    Count = 'count'
}

export function CommunicationReportDefinitionAggregationDataFromJSON(json: any): CommunicationReportDefinitionAggregationData {
    return CommunicationReportDefinitionAggregationDataFromJSONTyped(json, false);
}

export function CommunicationReportDefinitionAggregationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportDefinitionAggregationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aggregationMethod': json['aggregation_method'],
        'itemKey': !exists(json, 'item_key') ? undefined : json['item_key'],
        'deduplicateWithMedicalInstitution': !exists(json, 'deduplicate_with_medical_institution') ? undefined : json['deduplicate_with_medical_institution'],
    };
}

export function CommunicationReportDefinitionAggregationDataToJSON(value?: CommunicationReportDefinitionAggregationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aggregation_method': value.aggregationMethod,
        'item_key': value.itemKey,
        'deduplicate_with_medical_institution': value.deduplicateWithMedicalInstitution,
    };
}

