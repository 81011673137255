/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 逆紹介 (逆紹介が存在しない場合は、項目がすべてnull)
 * @export
 * @interface NullableReverseReferral
 */
export interface NullableReverseReferral {
    /**
     * 逆紹介ごとに割り当てられた固有のID
     * @type {string}
     * @memberof NullableReverseReferral
     */
    id: string | null;
    /**
     * 逆紹介先医療機関のID
     * @type {number}
     * @memberof NullableReverseReferral
     */
    medicalInstitutionIdTo: number | null;
    /**
     * 逆紹介元医療機関のID (自院の医療機関のID)
     * @type {number}
     * @memberof NullableReverseReferral
     */
    medicalInstitutionIdFrom: number | null;
    /**
     * 診療科目ID
     * @type {number}
     * @memberof NullableReverseReferral
     */
    specialtyId: number | null;
    /**
     * 逆紹介日
     * @type {string}
     * @memberof NullableReverseReferral
     */
    reverseReferralDate: string | null;
    /**
     * 患者ID
     * @type {string}
     * @memberof NullableReverseReferral
     */
    patientId: string | null;
    /**
     * 逆紹介管理ID
     * @type {number}
     * @memberof NullableReverseReferral
     */
    reverseReferralControlId: number | null;
    /**
     * 逆紹介取り込みID
     * @type {number}
     * @memberof NullableReverseReferral
     */
    reverseReferralImportingLogId: number | null;
}

export function NullableReverseReferralFromJSON(json: any): NullableReverseReferral {
    return NullableReverseReferralFromJSONTyped(json, false);
}

export function NullableReverseReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): NullableReverseReferral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'medicalInstitutionIdTo': json['medical_institution_id_to'],
        'medicalInstitutionIdFrom': json['medical_institution_id_from'],
        'specialtyId': json['specialty_id'],
        'reverseReferralDate': json['reverse_referral_date'],
        'patientId': json['patient_id'],
        'reverseReferralControlId': json['reverse_referral_control_id'],
        'reverseReferralImportingLogId': json['reverse_referral_importing_log_id'],
    };
}

export function NullableReverseReferralToJSON(value?: NullableReverseReferral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'medical_institution_id_to': value.medicalInstitutionIdTo,
        'medical_institution_id_from': value.medicalInstitutionIdFrom,
        'specialty_id': value.specialtyId,
        'reverse_referral_date': value.reverseReferralDate,
        'patient_id': value.patientId,
        'reverse_referral_control_id': value.reverseReferralControlId,
        'reverse_referral_importing_log_id': value.reverseReferralImportingLogId,
    };
}

