/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * コミュニケーションレポート項目（医療機関ラベル項目）定義
 * @export
 * @interface CommunicationReportItemDefinitionMedicalInstitutionLabel
 */
export interface CommunicationReportItemDefinitionMedicalInstitutionLabel {
    /**
     * クライアントでタグ付きユニオンとして扱うためのタグ
     * @type {string}
     * @memberof CommunicationReportItemDefinitionMedicalInstitutionLabel
     */
    type: CommunicationReportItemDefinitionMedicalInstitutionLabelTypeEnum;
    /**
     * 連携先レポート項目定義を一意に特定するキー名
     * @type {string}
     * @memberof CommunicationReportItemDefinitionMedicalInstitutionLabel
     */
    key: string;
    /**
     * 医療機関ラベルID
     * @type {number}
     * @memberof CommunicationReportItemDefinitionMedicalInstitutionLabel
     */
    medicalInstitutionLabelId: number;
    /**
     * デフォルトの項目名
     * @type {string}
     * @memberof CommunicationReportItemDefinitionMedicalInstitutionLabel
     */
    label: string;
}

/**
* @export
* @enum {string}
*/
export enum CommunicationReportItemDefinitionMedicalInstitutionLabelTypeEnum {
    MedicalInstitutionLabel = 'medical_institution_label'
}

export function CommunicationReportItemDefinitionMedicalInstitutionLabelFromJSON(json: any): CommunicationReportItemDefinitionMedicalInstitutionLabel {
    return CommunicationReportItemDefinitionMedicalInstitutionLabelFromJSONTyped(json, false);
}

export function CommunicationReportItemDefinitionMedicalInstitutionLabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportItemDefinitionMedicalInstitutionLabel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'key': json['key'],
        'medicalInstitutionLabelId': json['medical_institution_label_id'],
        'label': json['label'],
    };
}

export function CommunicationReportItemDefinitionMedicalInstitutionLabelToJSON(value?: CommunicationReportItemDefinitionMedicalInstitutionLabel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'key': value.key,
        'medical_institution_label_id': value.medicalInstitutionLabelId,
        'label': value.label,
    };
}

