/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 医療機関に付与された特定期間の連携先セグメント
 * @export
 * @interface MedicalInstitutionSegment
 */
export interface MedicalInstitutionSegment {
    /**
     * 医療機関ID
     * @type {number}
     * @memberof MedicalInstitutionSegment
     */
    medicalInstitutionId: number;
    /**
     * 連携先セグメントの期間
     * @type {string}
     * @memberof MedicalInstitutionSegment
     */
    period: string;
    /**
     * 連携先セグメントのID
     * @type {string}
     * @memberof MedicalInstitutionSegment
     */
    segmentId: string;
}

export function MedicalInstitutionSegmentFromJSON(json: any): MedicalInstitutionSegment {
    return MedicalInstitutionSegmentFromJSONTyped(json, false);
}

export function MedicalInstitutionSegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionSegment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutionId': json['medical_institution_id'],
        'period': json['period'],
        'segmentId': json['segment_id'],
    };
}

export function MedicalInstitutionSegmentToJSON(value?: MedicalInstitutionSegment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution_id': value.medicalInstitutionId,
        'period': value.period,
        'segment_id': value.segmentId,
    };
}

