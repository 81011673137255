/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 医療機関メモ
 * @export
 * @interface MedicalInstitutionMemo
 */
export interface MedicalInstitutionMemo {
    /**
     * 医療機関メモID
     * 
     * まだ医療機関メモが作成されてない場合にはnullになる
     * @type {number}
     * @memberof MedicalInstitutionMemo
     */
    id: number | null;
    /**
     * メモの内容
     * 
     * まだ医療機関メモが作成されてない場合にはnullになる
     * @type {string}
     * @memberof MedicalInstitutionMemo
     */
    content: string | null;
    /**
     * 医療機関メモの更新日
     * 
     * まだ医療機関メモが作成されてない場合にはnullになる
     * @type {string}
     * @memberof MedicalInstitutionMemo
     */
    lastUpdatedDate: string | null;
}

export function MedicalInstitutionMemoFromJSON(json: any): MedicalInstitutionMemo {
    return MedicalInstitutionMemoFromJSONTyped(json, false);
}

export function MedicalInstitutionMemoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionMemo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'content': json['content'],
        'lastUpdatedDate': json['last_updated_date'],
    };
}

export function MedicalInstitutionMemoToJSON(value?: MedicalInstitutionMemo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content': value.content,
        'last_updated_date': value.lastUpdatedDate,
    };
}

