/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * コミュニケーション種別
 * @export
 * @enum {string}
 */
export enum CommunicationLabelCommunicationType {
    Visit = 'visit',
    NewVisit = 'new_visit',
    Phone = 'phone',
    Email = 'email',
    PostalItem = 'postal_item',
    EventAttendance = 'event_attendance',
    ReferralAcceptance = 'referral_acceptance',
    RefusedReferral = 'refused_referral',
    Other = 'other'
}

export function CommunicationLabelCommunicationTypeFromJSON(json: any): CommunicationLabelCommunicationType {
    return CommunicationLabelCommunicationTypeFromJSONTyped(json, false);
}

export function CommunicationLabelCommunicationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabelCommunicationType {
    return json as CommunicationLabelCommunicationType;
}

export function CommunicationLabelCommunicationTypeToJSON(value?: CommunicationLabelCommunicationType | null): any {
    return value as any;
}

