/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostActionListItemsBulkDeleteRequest
 */
export interface PostActionListItemsBulkDeleteRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PostActionListItemsBulkDeleteRequest
     */
    medicalInstitutionIds: Array<number>;
}

export function PostActionListItemsBulkDeleteRequestFromJSON(json: any): PostActionListItemsBulkDeleteRequest {
    return PostActionListItemsBulkDeleteRequestFromJSONTyped(json, false);
}

export function PostActionListItemsBulkDeleteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostActionListItemsBulkDeleteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutionIds': json['medical_institution_ids'],
    };
}

export function PostActionListItemsBulkDeleteRequestToJSON(value?: PostActionListItemsBulkDeleteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution_ids': value.medicalInstitutionIds,
    };
}

