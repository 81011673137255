import { Img } from '@/src/components/foundations/DataDisplays/Img';
import { Link } from '@/src/components/foundations/Utils/Link';

import styles from './styles.module.scss';

import { ComponentProps } from 'react';

type Props = {
  onClick?: ComponentProps<typeof Link>['onClick'];
};
export const GlobalHeader: React.FC<Props> = (props) => (
  <header className={styles.header}>
    <Link href="/login" onClick={props.onClick}>
      <Img className={styles.logo} alt="logo" src="/logo.png" />
    </Link>
  </header>
);
