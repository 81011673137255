/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostMedicalInstitutionReportResultsResponseResults,
    PostMedicalInstitutionReportResultsResponseResultsFromJSON,
    PostMedicalInstitutionReportResultsResponseResultsFromJSONTyped,
    PostMedicalInstitutionReportResultsResponseResultsToJSON,
} from './PostMedicalInstitutionReportResultsResponseResults';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionReportResultsResponse
 */
export interface PostMedicalInstitutionReportResultsResponse {
    /**
     * 表示中のページ番号
     * @type {number}
     * @memberof PostMedicalInstitutionReportResultsResponse
     */
    page: number;
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof PostMedicalInstitutionReportResultsResponse
     */
    totalPage: number;
    /**
     * 総件数
     * @type {number}
     * @memberof PostMedicalInstitutionReportResultsResponse
     */
    totalCount: number;
    /**
     * レポート集計結果
     * @type {Array<PostMedicalInstitutionReportResultsResponseResults>}
     * @memberof PostMedicalInstitutionReportResultsResponse
     */
    results: Array<PostMedicalInstitutionReportResultsResponseResults>;
}

export function PostMedicalInstitutionReportResultsResponseFromJSON(json: any): PostMedicalInstitutionReportResultsResponse {
    return PostMedicalInstitutionReportResultsResponseFromJSONTyped(json, false);
}

export function PostMedicalInstitutionReportResultsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionReportResultsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'results': ((json['results'] as Array<any>).map(PostMedicalInstitutionReportResultsResponseResultsFromJSON)),
    };
}

export function PostMedicalInstitutionReportResultsResponseToJSON(value?: PostMedicalInstitutionReportResultsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'results': ((value.results as Array<any>).map(PostMedicalInstitutionReportResultsResponseResultsToJSON)),
    };
}

