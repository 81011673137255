/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionLabelValue,
    MedicalInstitutionLabelValueFromJSON,
    MedicalInstitutionLabelValueFromJSONTyped,
    MedicalInstitutionLabelValueToJSON,
} from './MedicalInstitutionLabelValue';

/**
 * 変更後の医療機関ラベル設定値一覧
 * @export
 * @interface PostMedicalInstitutionLabelValuesResponse
 */
export interface PostMedicalInstitutionLabelValuesResponse {
    /**
     * 
     * @type {Array<MedicalInstitutionLabelValue>}
     * @memberof PostMedicalInstitutionLabelValuesResponse
     */
    values: Array<MedicalInstitutionLabelValue>;
}

export function PostMedicalInstitutionLabelValuesResponseFromJSON(json: any): PostMedicalInstitutionLabelValuesResponse {
    return PostMedicalInstitutionLabelValuesResponseFromJSONTyped(json, false);
}

export function PostMedicalInstitutionLabelValuesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionLabelValuesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'values': ((json['values'] as Array<any>).map(MedicalInstitutionLabelValueFromJSON)),
    };
}

export function PostMedicalInstitutionLabelValuesResponseToJSON(value?: PostMedicalInstitutionLabelValuesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'values': ((value.values as Array<any>).map(MedicalInstitutionLabelValueToJSON)),
    };
}

