/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostEmailIdSendForTestRequest
 */
export interface PostEmailIdSendForTestRequest {
    /**
     * テスト用メールアドレス
     * @type {string}
     * @memberof PostEmailIdSendForTestRequest
     */
    email: string;
    /**
     * テスト用施設名称
     * @type {string}
     * @memberof PostEmailIdSendForTestRequest
     */
    hospital: string | null;
    /**
     * テスト用氏名
     * @type {string}
     * @memberof PostEmailIdSendForTestRequest
     */
    name: string | null;
    /**
     * テスト用敬称
     * @type {string}
     * @memberof PostEmailIdSendForTestRequest
     */
    nameTitle: string | null;
}

export function PostEmailIdSendForTestRequestFromJSON(json: any): PostEmailIdSendForTestRequest {
    return PostEmailIdSendForTestRequestFromJSONTyped(json, false);
}

export function PostEmailIdSendForTestRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostEmailIdSendForTestRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'hospital': json['hospital'],
        'name': json['name'],
        'nameTitle': json['name_title'],
    };
}

export function PostEmailIdSendForTestRequestToJSON(value?: PostEmailIdSendForTestRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'hospital': value.hospital,
        'name': value.name,
        'name_title': value.nameTitle,
    };
}

