/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    GetTagGroupsResponse,
    GetTagGroupsResponseFromJSON,
    GetTagGroupsResponseToJSON,
    GetTagsResponse,
    GetTagsResponseFromJSON,
    GetTagsResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostTagRequest,
    PostTagRequestFromJSON,
    PostTagRequestToJSON,
    PutTagRequest,
    PutTagRequestFromJSON,
    PutTagRequestToJSON,
    Tag,
    TagFromJSON,
    TagToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteTagRequest {
    id: number;
}

export interface GetTagsRequest {
    page?: number;
    perPage?: number;
    sort?: GetTagsSortEnum;
}

export interface PostTagOperationRequest {
    postTagRequest: PostTagRequest;
}

export interface PutTagOperationRequest {
    id: number;
    putTagRequest: PutTagRequest;
}

/**
 * TagsApi - interface
 * 
 * @export
 * @interface TagsApiInterface
 */
export interface TagsApiInterface {
    /**
     * タグを削除する。医療機関との紐付けも含めて削除する。 
     * @summary タグの削除
     * @param {number} id タグID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    deleteTagRaw(requestParameters: DeleteTagRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * タグを削除する。医療機関との紐付けも含めて削除する。 
     * タグの削除
     */
    deleteTag(requestParameters: DeleteTagRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * タググループ覧を取得する (各タググループは、属しているタグ一覧を含む)   - items(tag_groups) の ソートは、tag_group_id の降順     - tag_group > tags の ソートは、tag_id の降順   - 属しているタグがない場合は、tags は空配列になる   - id, name が null の場合は、グループに紐付いていないタグをまとめたもの 
     * @summary タググループ覧を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    getTagGroupsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTagGroupsResponse>>;

    /**
     * タググループ覧を取得する (各タググループは、属しているタグ一覧を含む)   - items(tag_groups) の ソートは、tag_group_id の降順     - tag_group > tags の ソートは、tag_id の降順   - 属しているタグがない場合は、tags は空配列になる   - id, name が null の場合は、グループに紐付いていないタグをまとめたもの 
     * タググループ覧を取得
     */
    getTagGroups(initOverrides?: RequestInit): Promise<GetTagGroupsResponse>;

    /**
     * 登録されているタグが全て取得できる 
     * @summary タグ一覧の取得
     * @param {number} [page] ページ番号（１始まり）
     * @param {number} [perPage] １ページあたりの件数
     * @param {'desc' | 'asc'} [sort] ソート順
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    getTagsRaw(requestParameters: GetTagsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTagsResponse>>;

    /**
     * 登録されているタグが全て取得できる 
     * タグ一覧の取得
     */
    getTags(requestParameters: GetTagsRequest, initOverrides?: RequestInit): Promise<GetTagsResponse>;

    /**
     * タグを登録する。(名前は15文字以内。説明は20文字以内とする) 
     * @summary タグの登録
     * @param {PostTagRequest} postTagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    postTagRaw(requestParameters: PostTagOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Tag>>;

    /**
     * タグを登録する。(名前は15文字以内。説明は20文字以内とする) 
     * タグの登録
     */
    postTag(requestParameters: PostTagOperationRequest, initOverrides?: RequestInit): Promise<Tag>;

    /**
     * 
     * @summary タグの更新
     * @param {number} id タグID
     * @param {PutTagRequest} putTagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    putTagRaw(requestParameters: PutTagOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Tag>>;

    /**
     * タグの更新
     */
    putTag(requestParameters: PutTagOperationRequest, initOverrides?: RequestInit): Promise<Tag>;

}

/**
 * 
 */
export class TagsApi extends runtime.BaseAPI implements TagsApiInterface {

    /**
     * タグを削除する。医療機関との紐付けも含めて削除する。 
     * タグの削除
     */
    async deleteTagRaw(requestParameters: DeleteTagRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * タグを削除する。医療機関との紐付けも含めて削除する。 
     * タグの削除
     */
    async deleteTag(requestParameters: DeleteTagRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteTagRaw(requestParameters, initOverrides);
    }

    /**
     * タググループ覧を取得する (各タググループは、属しているタグ一覧を含む)   - items(tag_groups) の ソートは、tag_group_id の降順     - tag_group > tags の ソートは、tag_id の降順   - 属しているタグがない場合は、tags は空配列になる   - id, name が null の場合は、グループに紐付いていないタグをまとめたもの 
     * タググループ覧を取得
     */
    async getTagGroupsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTagGroupsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tag_groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTagGroupsResponseFromJSON(jsonValue));
    }

    /**
     * タググループ覧を取得する (各タググループは、属しているタグ一覧を含む)   - items(tag_groups) の ソートは、tag_group_id の降順     - tag_group > tags の ソートは、tag_id の降順   - 属しているタグがない場合は、tags は空配列になる   - id, name が null の場合は、グループに紐付いていないタグをまとめたもの 
     * タググループ覧を取得
     */
    async getTagGroups(initOverrides?: RequestInit): Promise<GetTagGroupsResponse> {
        const response = await this.getTagGroupsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 登録されているタグが全て取得できる 
     * タグ一覧の取得
     */
    async getTagsRaw(requestParameters: GetTagsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTagsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['per_page'] = requestParameters.perPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTagsResponseFromJSON(jsonValue));
    }

    /**
     * 登録されているタグが全て取得できる 
     * タグ一覧の取得
     */
    async getTags(requestParameters: GetTagsRequest = {}, initOverrides?: RequestInit): Promise<GetTagsResponse> {
        const response = await this.getTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * タグを登録する。(名前は15文字以内。説明は20文字以内とする) 
     * タグの登録
     */
    async postTagRaw(requestParameters: PostTagOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters.postTagRequest === null || requestParameters.postTagRequest === undefined) {
            throw new runtime.RequiredError('postTagRequest','Required parameter requestParameters.postTagRequest was null or undefined when calling postTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostTagRequestToJSON(requestParameters.postTagRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     * タグを登録する。(名前は15文字以内。説明は20文字以内とする) 
     * タグの登録
     */
    async postTag(requestParameters: PostTagOperationRequest, initOverrides?: RequestInit): Promise<Tag> {
        const response = await this.postTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * タグの更新
     */
    async putTagRaw(requestParameters: PutTagOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putTag.');
        }

        if (requestParameters.putTagRequest === null || requestParameters.putTagRequest === undefined) {
            throw new runtime.RequiredError('putTagRequest','Required parameter requestParameters.putTagRequest was null or undefined when calling putTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutTagRequestToJSON(requestParameters.putTagRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     * タグの更新
     */
    async putTag(requestParameters: PutTagOperationRequest, initOverrides?: RequestInit): Promise<Tag> {
        const response = await this.putTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetTagsSortEnum {
    Desc = 'desc',
    Asc = 'asc'
}
