/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReverseReferralsComparisonDepartmentsResponseReverseReferrals,
    PostChartReverseReferralsComparisonDepartmentsResponseReverseReferralsFromJSON,
    PostChartReverseReferralsComparisonDepartmentsResponseReverseReferralsFromJSONTyped,
    PostChartReverseReferralsComparisonDepartmentsResponseReverseReferralsToJSON,
} from './PostChartReverseReferralsComparisonDepartmentsResponseReverseReferrals';

/**
 * 
 * @export
 * @interface PostChartReverseReferralsComparisonDepartmentsResponse
 */
export interface PostChartReverseReferralsComparisonDepartmentsResponse {
    /**
     * 逆紹介数診療科別比較データ
     * @type {Array<PostChartReverseReferralsComparisonDepartmentsResponseReverseReferrals>}
     * @memberof PostChartReverseReferralsComparisonDepartmentsResponse
     */
    reverseReferrals: Array<PostChartReverseReferralsComparisonDepartmentsResponseReverseReferrals>;
}

export function PostChartReverseReferralsComparisonDepartmentsResponseFromJSON(json: any): PostChartReverseReferralsComparisonDepartmentsResponse {
    return PostChartReverseReferralsComparisonDepartmentsResponseFromJSONTyped(json, false);
}

export function PostChartReverseReferralsComparisonDepartmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReverseReferralsComparisonDepartmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reverseReferrals': ((json['reverse_referrals'] as Array<any>).map(PostChartReverseReferralsComparisonDepartmentsResponseReverseReferralsFromJSON)),
    };
}

export function PostChartReverseReferralsComparisonDepartmentsResponseToJSON(value?: PostChartReverseReferralsComparisonDepartmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reverse_referrals': ((value.reverseReferrals as Array<any>).map(PostChartReverseReferralsComparisonDepartmentsResponseReverseReferralsToJSON)),
    };
}

