/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaReportDefinitionDisplayItem,
    AreaReportDefinitionDisplayItemFromJSON,
    AreaReportDefinitionDisplayItemFromJSONTyped,
    AreaReportDefinitionDisplayItemToJSON,
} from './AreaReportDefinitionDisplayItem';
import {
    AreaReportDefinitionFilterCondition,
    AreaReportDefinitionFilterConditionFromJSON,
    AreaReportDefinitionFilterConditionFromJSONTyped,
    AreaReportDefinitionFilterConditionToJSON,
} from './AreaReportDefinitionFilterCondition';
import {
    AreaReportDefinitionSortCondition,
    AreaReportDefinitionSortConditionFromJSON,
    AreaReportDefinitionSortConditionFromJSONTyped,
    AreaReportDefinitionSortConditionToJSON,
} from './AreaReportDefinitionSortCondition';
import {
    AreaReportItem,
    AreaReportItemFromJSON,
    AreaReportItemFromJSONTyped,
    AreaReportItemToJSON,
} from './AreaReportItem';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * 
 * @export
 * @interface AreaReportDefinitionDetail
 */
export interface AreaReportDefinitionDetail {
    /**
     * エリアレポートID
     * @type {number}
     * @memberof AreaReportDefinitionDetail
     */
    id: number;
    /**
     * レポート名
     * @type {string}
     * @memberof AreaReportDefinitionDetail
     */
    name: string;
    /**
     * エリアタイプの医療機関ラベルID
     * @type {number}
     * @memberof AreaReportDefinitionDetail
     */
    medicalInstitutionLabelId: number;
    /**
     * 
     * @type {Array<AreaReportItem>}
     * @memberof AreaReportDefinitionDetail
     */
    items: Array<AreaReportItem>;
    /**
     * 
     * @type {Array<AreaReportDefinitionDisplayItem>}
     * @memberof AreaReportDefinitionDetail
     */
    displayItems: Array<AreaReportDefinitionDisplayItem>;
    /**
     * 
     * @type {Array<AreaReportDefinitionFilterCondition>}
     * @memberof AreaReportDefinitionDetail
     */
    filterConditions: Array<AreaReportDefinitionFilterCondition>;
    /**
     * 
     * @type {Array<AreaReportDefinitionSortCondition>}
     * @memberof AreaReportDefinitionDetail
     */
    sortConditions: Array<AreaReportDefinitionSortCondition>;
    /**
     * 
     * @type {NullableStaff}
     * @memberof AreaReportDefinitionDetail
     */
    createdBy: NullableStaff | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof AreaReportDefinitionDetail
     */
    createdAt: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof AreaReportDefinitionDetail
     */
    updatedAt: string;
}

export function AreaReportDefinitionDetailFromJSON(json: any): AreaReportDefinitionDetail {
    return AreaReportDefinitionDetailFromJSONTyped(json, false);
}

export function AreaReportDefinitionDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaReportDefinitionDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'medicalInstitutionLabelId': json['medical_institution_label_id'],
        'items': ((json['items'] as Array<any>).map(AreaReportItemFromJSON)),
        'displayItems': ((json['display_items'] as Array<any>).map(AreaReportDefinitionDisplayItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(AreaReportDefinitionFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(AreaReportDefinitionSortConditionFromJSON)),
        'createdBy': NullableStaffFromJSON(json['created_by']),
        'createdAt': json['created_at'],
        'updatedAt': json['updated_at'],
    };
}

export function AreaReportDefinitionDetailToJSON(value?: AreaReportDefinitionDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'medical_institution_label_id': value.medicalInstitutionLabelId,
        'items': ((value.items as Array<any>).map(AreaReportItemToJSON)),
        'display_items': ((value.displayItems as Array<any>).map(AreaReportDefinitionDisplayItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(AreaReportDefinitionFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(AreaReportDefinitionSortConditionToJSON)),
        'created_by': NullableStaffToJSON(value.createdBy),
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
    };
}

