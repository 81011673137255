/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 紹介入院セグメント
 * @export
 * @interface ReferralAdmissionSegment
 */
export interface ReferralAdmissionSegment {
    /**
     * セグメントごとに割り当てられた固有のID
     * @type {number}
     * @memberof ReferralAdmissionSegment
     */
    id: number;
    /**
     * セグメント名
     * @type {string}
     * @memberof ReferralAdmissionSegment
     */
    name: string;
    /**
     * セグメントが含む紹介入院数の下限。境界値を含む。
     * @type {number}
     * @memberof ReferralAdmissionSegment
     */
    referralCountOrMore: number | null;
    /**
     * セグメントが含む紹介入院数の上限。境界値を含まない。
     * @type {number}
     * @memberof ReferralAdmissionSegment
     */
    referralCountLessThan: number | null;
    /**
     * セグメントが含む紹介入院率の下限。境界値を含む。
     * @type {number}
     * @memberof ReferralAdmissionSegment
     */
    referralAdmissionRateOrMore: number | null;
    /**
     * セグメントが含む紹介入院率の上限。境界値を含まない。
     * @type {number}
     * @memberof ReferralAdmissionSegment
     */
    referralAdmissionRateLessThan: number | null;
}

export function ReferralAdmissionSegmentFromJSON(json: any): ReferralAdmissionSegment {
    return ReferralAdmissionSegmentFromJSONTyped(json, false);
}

export function ReferralAdmissionSegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralAdmissionSegment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'referralCountOrMore': json['referral_count_or_more'],
        'referralCountLessThan': json['referral_count_less_than'],
        'referralAdmissionRateOrMore': json['referral_admission_rate_or_more'],
        'referralAdmissionRateLessThan': json['referral_admission_rate_less_than'],
    };
}

export function ReferralAdmissionSegmentToJSON(value?: ReferralAdmissionSegment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'referral_count_or_more': value.referralCountOrMore,
        'referral_count_less_than': value.referralCountLessThan,
        'referral_admission_rate_or_more': value.referralAdmissionRateOrMore,
        'referral_admission_rate_less_than': value.referralAdmissionRateLessThan,
    };
}

