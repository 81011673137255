import styles from './styles.module.scss';

import CheckCircle from '@mui/icons-material/CheckCircle';
import Close from '@mui/icons-material/Close';
import Error from '@mui/icons-material/Error';
import clsx from 'clsx';

const SeverityIconsMap = {
  error: Error,
  success: CheckCircle,
};

interface Props {
  severity: 'error' | 'success';
  message: string;
  onClickHideButton: VoidFunction;
}

export const ToastPresenter: React.FC<Props> = (props) => {
  const SeverityIcon = SeverityIconsMap[props.severity];

  return (
    <div className={clsx(styles.base, styles[props.severity])}>
      <div className={styles.severityIconWrapper}>
        <SeverityIcon className={styles[props.severity]} />
      </div>
      <div className={styles.message}>{props.message}</div>
      <button
        className={styles.closeIconWrapper}
        onClick={props.onClickHideButton}
      >
        <Close />
      </button>
    </div>
  );
};
