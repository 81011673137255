/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetOwnDepartmentsResponse,
    GetOwnDepartmentsResponseFromJSON,
    GetOwnDepartmentsResponseToJSON,
} from '../models';

/**
 * OwnMedicalInstitutionApi - interface
 * 
 * @export
 * @interface OwnMedicalInstitutionApiInterface
 */
export interface OwnMedicalInstitutionApiInterface {
    /**
     * 自院の持つ診療科目の一覧をidと名前のセットで返す 
     * @summary 自院の持つ診療科目の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OwnMedicalInstitutionApiInterface
     */
    getOwnMedicalInstitutionDepartmentsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetOwnDepartmentsResponse>>;

    /**
     * 自院の持つ診療科目の一覧をidと名前のセットで返す 
     * 自院の持つ診療科目の取得
     */
    getOwnMedicalInstitutionDepartments(initOverrides?: RequestInit): Promise<GetOwnDepartmentsResponse>;

}

/**
 * 
 */
export class OwnMedicalInstitutionApi extends runtime.BaseAPI implements OwnMedicalInstitutionApiInterface {

    /**
     * 自院の持つ診療科目の一覧をidと名前のセットで返す 
     * 自院の持つ診療科目の取得
     */
    async getOwnMedicalInstitutionDepartmentsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetOwnDepartmentsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/own_medical_institution/departments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOwnDepartmentsResponseFromJSON(jsonValue));
    }

    /**
     * 自院の持つ診療科目の一覧をidと名前のセットで返す 
     * 自院の持つ診療科目の取得
     */
    async getOwnMedicalInstitutionDepartments(initOverrides?: RequestInit): Promise<GetOwnDepartmentsResponse> {
        const response = await this.getOwnMedicalInstitutionDepartmentsRaw(initOverrides);
        return await response.value();
    }

}
