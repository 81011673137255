/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Alert,
    AlertFromJSON,
    AlertToJSON,
    AlertCondition,
    AlertConditionFromJSON,
    AlertConditionToJSON,
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    GetAlertConditionsResponse,
    GetAlertConditionsResponseFromJSON,
    GetAlertConditionsResponseToJSON,
    GetAlertMedicalInstitutionsResponse,
    GetAlertMedicalInstitutionsResponseFromJSON,
    GetAlertMedicalInstitutionsResponseToJSON,
    GetAlertsResponse,
    GetAlertsResponseFromJSON,
    GetAlertsResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostAlertBulkArchiveRequest,
    PostAlertBulkArchiveRequestFromJSON,
    PostAlertBulkArchiveRequestToJSON,
    PostNewAlertCondition,
    PostNewAlertConditionFromJSON,
    PostNewAlertConditionToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteAlertConditionRequest {
    id: number;
}

export interface GetAlertRequest {
    id: number;
}

export interface GetAlertConditionsRequest {
    page?: number;
}

export interface GetAlertMedicalInstitutionsRequest {
    id: number;
    page?: number;
}

export interface GetAlertsRequest {
    page?: number;
    status?: GetAlertsStatusEnum;
}

export interface PostAlertArchiveRequest {
    id: number;
}

export interface PostAlertBulkArchiveOperationRequest {
    postAlertBulkArchiveRequest: PostAlertBulkArchiveRequest;
}

export interface PostNewAlertConditionRequest {
    postNewAlertCondition: PostNewAlertCondition;
}

/**
 * AlertsApi - interface
 * 
 * @export
 * @interface AlertsApiInterface
 */
export interface AlertsApiInterface {
    /**
     * アラート条件を削除する 
     * @summary アラート条件の削除
     * @param {number} id アラート条件ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApiInterface
     */
    deleteAlertConditionRaw(requestParameters: DeleteAlertConditionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * アラート条件を削除する 
     * アラート条件の削除
     */
    deleteAlertCondition(requestParameters: DeleteAlertConditionRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * アラートの詳細情報を取得する 
     * @summary アラート詳細の取得
     * @param {number} id アラートID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApiInterface
     */
    getAlertRaw(requestParameters: GetAlertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Alert>>;

    /**
     * アラートの詳細情報を取得する 
     * アラート詳細の取得
     */
    getAlert(requestParameters: GetAlertRequest, initOverrides?: RequestInit): Promise<Alert>;

    /**
     * アラート条件の一覧を取得する 
     * @summary アラート条件一覧の取得
     * @param {number} [page] ページ番号（１始まり）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApiInterface
     */
    getAlertConditionsRaw(requestParameters: GetAlertConditionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAlertConditionsResponse>>;

    /**
     * アラート条件の一覧を取得する 
     * アラート条件一覧の取得
     */
    getAlertConditions(requestParameters: GetAlertConditionsRequest, initOverrides?: RequestInit): Promise<GetAlertConditionsResponse>;

    /**
     * 
     * @summary アラートの対象となった医療機関一覧を取得する
     * @param {number} id アラートID
     * @param {number} [page] ページ番号（１始まり）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApiInterface
     */
    getAlertMedicalInstitutionsRaw(requestParameters: GetAlertMedicalInstitutionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAlertMedicalInstitutionsResponse>>;

    /**
     * アラートの対象となった医療機関一覧を取得する
     */
    getAlertMedicalInstitutions(requestParameters: GetAlertMedicalInstitutionsRequest, initOverrides?: RequestInit): Promise<GetAlertMedicalInstitutionsResponse>;

    /**
     * アラートの一覧を取得する 
     * @summary アラート一覧の取得
     * @param {number} [page] ページ番号（１始まり）
     * @param {'open' | 'archived'} [status] 絞り込むステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApiInterface
     */
    getAlertsRaw(requestParameters: GetAlertsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAlertsResponse>>;

    /**
     * アラートの一覧を取得する 
     * アラート一覧の取得
     */
    getAlerts(requestParameters: GetAlertsRequest, initOverrides?: RequestInit): Promise<GetAlertsResponse>;

    /**
     * アラートをアーカイブする
     * @summary アラートのアーカイブ
     * @param {number} id アラートID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApiInterface
     */
    postAlertArchiveRaw(requestParameters: PostAlertArchiveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * アラートをアーカイブする
     * アラートのアーカイブ
     */
    postAlertArchive(requestParameters: PostAlertArchiveRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * アラートをまとめてアーカイブする  一つ以上のアラートをアーカイブできた場合には204が返ります もし一つでも存在しないアラートIDがあるか、全てアーカイブ済みだった場合には400が返ります 
     * @summary アラートをまとめてアーカイブ
     * @param {PostAlertBulkArchiveRequest} postAlertBulkArchiveRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApiInterface
     */
    postAlertBulkArchiveRaw(requestParameters: PostAlertBulkArchiveOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * アラートをまとめてアーカイブする  一つ以上のアラートをアーカイブできた場合には204が返ります もし一つでも存在しないアラートIDがあるか、全てアーカイブ済みだった場合には400が返ります 
     * アラートをまとめてアーカイブ
     */
    postAlertBulkArchive(requestParameters: PostAlertBulkArchiveOperationRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary アラート条件の新規作成
     * @param {PostNewAlertCondition} postNewAlertCondition 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApiInterface
     */
    postNewAlertConditionRaw(requestParameters: PostNewAlertConditionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertCondition>>;

    /**
     * アラート条件の新規作成
     */
    postNewAlertCondition(requestParameters: PostNewAlertConditionRequest, initOverrides?: RequestInit): Promise<AlertCondition>;

}

/**
 * 
 */
export class AlertsApi extends runtime.BaseAPI implements AlertsApiInterface {

    /**
     * アラート条件を削除する 
     * アラート条件の削除
     */
    async deleteAlertConditionRaw(requestParameters: DeleteAlertConditionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAlertCondition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/alert_conditions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * アラート条件を削除する 
     * アラート条件の削除
     */
    async deleteAlertCondition(requestParameters: DeleteAlertConditionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteAlertConditionRaw(requestParameters, initOverrides);
    }

    /**
     * アラートの詳細情報を取得する 
     * アラート詳細の取得
     */
    async getAlertRaw(requestParameters: GetAlertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Alert>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/alerts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertFromJSON(jsonValue));
    }

    /**
     * アラートの詳細情報を取得する 
     * アラート詳細の取得
     */
    async getAlert(requestParameters: GetAlertRequest, initOverrides?: RequestInit): Promise<Alert> {
        const response = await this.getAlertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アラート条件の一覧を取得する 
     * アラート条件一覧の取得
     */
    async getAlertConditionsRaw(requestParameters: GetAlertConditionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAlertConditionsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/alert_conditions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAlertConditionsResponseFromJSON(jsonValue));
    }

    /**
     * アラート条件の一覧を取得する 
     * アラート条件一覧の取得
     */
    async getAlertConditions(requestParameters: GetAlertConditionsRequest = {}, initOverrides?: RequestInit): Promise<GetAlertConditionsResponse> {
        const response = await this.getAlertConditionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アラートの対象となった医療機関一覧を取得する
     */
    async getAlertMedicalInstitutionsRaw(requestParameters: GetAlertMedicalInstitutionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAlertMedicalInstitutionsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAlertMedicalInstitutions.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/alerts/{id}/medical_institutions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAlertMedicalInstitutionsResponseFromJSON(jsonValue));
    }

    /**
     * アラートの対象となった医療機関一覧を取得する
     */
    async getAlertMedicalInstitutions(requestParameters: GetAlertMedicalInstitutionsRequest, initOverrides?: RequestInit): Promise<GetAlertMedicalInstitutionsResponse> {
        const response = await this.getAlertMedicalInstitutionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アラートの一覧を取得する 
     * アラート一覧の取得
     */
    async getAlertsRaw(requestParameters: GetAlertsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAlertsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/alerts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAlertsResponseFromJSON(jsonValue));
    }

    /**
     * アラートの一覧を取得する 
     * アラート一覧の取得
     */
    async getAlerts(requestParameters: GetAlertsRequest = {}, initOverrides?: RequestInit): Promise<GetAlertsResponse> {
        const response = await this.getAlertsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アラートをアーカイブする
     * アラートのアーカイブ
     */
    async postAlertArchiveRaw(requestParameters: PostAlertArchiveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postAlertArchive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/alerts/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * アラートをアーカイブする
     * アラートのアーカイブ
     */
    async postAlertArchive(requestParameters: PostAlertArchiveRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postAlertArchiveRaw(requestParameters, initOverrides);
    }

    /**
     * アラートをまとめてアーカイブする  一つ以上のアラートをアーカイブできた場合には204が返ります もし一つでも存在しないアラートIDがあるか、全てアーカイブ済みだった場合には400が返ります 
     * アラートをまとめてアーカイブ
     */
    async postAlertBulkArchiveRaw(requestParameters: PostAlertBulkArchiveOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.postAlertBulkArchiveRequest === null || requestParameters.postAlertBulkArchiveRequest === undefined) {
            throw new runtime.RequiredError('postAlertBulkArchiveRequest','Required parameter requestParameters.postAlertBulkArchiveRequest was null or undefined when calling postAlertBulkArchive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/alerts/bulk_archive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAlertBulkArchiveRequestToJSON(requestParameters.postAlertBulkArchiveRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * アラートをまとめてアーカイブする  一つ以上のアラートをアーカイブできた場合には204が返ります もし一つでも存在しないアラートIDがあるか、全てアーカイブ済みだった場合には400が返ります 
     * アラートをまとめてアーカイブ
     */
    async postAlertBulkArchive(requestParameters: PostAlertBulkArchiveOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postAlertBulkArchiveRaw(requestParameters, initOverrides);
    }

    /**
     * アラート条件の新規作成
     */
    async postNewAlertConditionRaw(requestParameters: PostNewAlertConditionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AlertCondition>> {
        if (requestParameters.postNewAlertCondition === null || requestParameters.postNewAlertCondition === undefined) {
            throw new runtime.RequiredError('postNewAlertCondition','Required parameter requestParameters.postNewAlertCondition was null or undefined when calling postNewAlertCondition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/alert_conditions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostNewAlertConditionToJSON(requestParameters.postNewAlertCondition),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlertConditionFromJSON(jsonValue));
    }

    /**
     * アラート条件の新規作成
     */
    async postNewAlertCondition(requestParameters: PostNewAlertConditionRequest, initOverrides?: RequestInit): Promise<AlertCondition> {
        const response = await this.postNewAlertConditionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetAlertsStatusEnum {
    Open = 'open',
    Archived = 'archived'
}
