/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportItemForFormula,
    MedicalInstitutionReportItemForFormulaFromJSON,
    MedicalInstitutionReportItemForFormulaFromJSONTyped,
    MedicalInstitutionReportItemForFormulaToJSON,
} from './MedicalInstitutionReportItemForFormula';

/**
 * 連携先レポート項目計算式
 * @export
 * @interface MedicalInstitutionReportItemFormula
 */
export interface MedicalInstitutionReportItemFormula {
    /**
     * 演算子
     * @type {string}
     * @memberof MedicalInstitutionReportItemFormula
     */
    operator: MedicalInstitutionReportItemFormulaOperatorEnum;
    /**
     * 
     * @type {MedicalInstitutionReportItemForFormula}
     * @memberof MedicalInstitutionReportItemFormula
     */
    leftItem: MedicalInstitutionReportItemForFormula;
    /**
     * 
     * @type {MedicalInstitutionReportItemForFormula}
     * @memberof MedicalInstitutionReportItemFormula
     */
    rightItem: MedicalInstitutionReportItemForFormula;
}

/**
* @export
* @enum {string}
*/
export enum MedicalInstitutionReportItemFormulaOperatorEnum {
    Minus = '-',
    Slash = '/'
}

export function MedicalInstitutionReportItemFormulaFromJSON(json: any): MedicalInstitutionReportItemFormula {
    return MedicalInstitutionReportItemFormulaFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemFormulaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemFormula {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operator': json['operator'],
        'leftItem': MedicalInstitutionReportItemForFormulaFromJSON(json['left_item']),
        'rightItem': MedicalInstitutionReportItemForFormulaFromJSON(json['right_item']),
    };
}

export function MedicalInstitutionReportItemFormulaToJSON(value?: MedicalInstitutionReportItemFormula | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operator': value.operator,
        'left_item': MedicalInstitutionReportItemForFormulaToJSON(value.leftItem),
        'right_item': MedicalInstitutionReportItemForFormulaToJSON(value.rightItem),
    };
}

