/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Account,
    AccountFromJSON,
    AccountFromJSONTyped,
    AccountToJSON,
} from './Account';

/**
 * 
 * @export
 * @interface GetAccountsResponse
 */
export interface GetAccountsResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof GetAccountsResponse
     */
    totalPage: number;
    /**
     * アカウント数
     * @type {number}
     * @memberof GetAccountsResponse
     */
    totalCount: number;
    /**
     * アカウントデータ
     * @type {Array<Account>}
     * @memberof GetAccountsResponse
     */
    accounts: Array<Account>;
}

export function GetAccountsResponseFromJSON(json: any): GetAccountsResponse {
    return GetAccountsResponseFromJSONTyped(json, false);
}

export function GetAccountsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAccountsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'accounts': ((json['accounts'] as Array<any>).map(AccountFromJSON)),
    };
}

export function GetAccountsResponseToJSON(value?: GetAccountsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'accounts': ((value.accounts as Array<any>).map(AccountToJSON)),
    };
}

