/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 値の型
 * @export
 * @enum {string}
 */
export enum AreaReportItemDefinitionValueTypeEnum {
    String = 'string',
    Number = 'number'
}

export function AreaReportItemDefinitionValueTypeEnumFromJSON(json: any): AreaReportItemDefinitionValueTypeEnum {
    return AreaReportItemDefinitionValueTypeEnumFromJSONTyped(json, false);
}

export function AreaReportItemDefinitionValueTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaReportItemDefinitionValueTypeEnum {
    return json as AreaReportItemDefinitionValueTypeEnum;
}

export function AreaReportItemDefinitionValueTypeEnumToJSON(value?: AreaReportItemDefinitionValueTypeEnum | null): any {
    return value as any;
}

