/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostMedicalInstitutionCommunicationRequestTasks
 */
export interface PostMedicalInstitutionCommunicationRequestTasks {
    /**
     * タイトル
     * @type {string}
     * @memberof PostMedicalInstitutionCommunicationRequestTasks
     */
    title: string;
    /**
     * スタッフID
     * @type {number}
     * @memberof PostMedicalInstitutionCommunicationRequestTasks
     */
    staffId: number | null;
    /**
     * 期日
     * @type {string}
     * @memberof PostMedicalInstitutionCommunicationRequestTasks
     */
    deadline: string | null;
}

export function PostMedicalInstitutionCommunicationRequestTasksFromJSON(json: any): PostMedicalInstitutionCommunicationRequestTasks {
    return PostMedicalInstitutionCommunicationRequestTasksFromJSONTyped(json, false);
}

export function PostMedicalInstitutionCommunicationRequestTasksFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionCommunicationRequestTasks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'staffId': json['staff_id'],
        'deadline': json['deadline'],
    };
}

export function PostMedicalInstitutionCommunicationRequestTasksToJSON(value?: PostMedicalInstitutionCommunicationRequestTasks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'staff_id': value.staffId,
        'deadline': value.deadline,
    };
}

