/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionLabelValue,
    MedicalInstitutionLabelValueFromJSON,
    MedicalInstitutionLabelValueFromJSONTyped,
    MedicalInstitutionLabelValueToJSON,
} from './MedicalInstitutionLabelValue';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionLabelValueRequest
 */
export interface PostMedicalInstitutionLabelValueRequest {
    /**
     * 
     * @type {Array<MedicalInstitutionLabelValue>}
     * @memberof PostMedicalInstitutionLabelValueRequest
     */
    values: Array<MedicalInstitutionLabelValue>;
}

export function PostMedicalInstitutionLabelValueRequestFromJSON(json: any): PostMedicalInstitutionLabelValueRequest {
    return PostMedicalInstitutionLabelValueRequestFromJSONTyped(json, false);
}

export function PostMedicalInstitutionLabelValueRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionLabelValueRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'values': ((json['values'] as Array<any>).map(MedicalInstitutionLabelValueFromJSON)),
    };
}

export function PostMedicalInstitutionLabelValueRequestToJSON(value?: PostMedicalInstitutionLabelValueRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'values': ((value.values as Array<any>).map(MedicalInstitutionLabelValueToJSON)),
    };
}

