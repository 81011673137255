/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * メール (communication_type が 'email' のみ有効)
 * @export
 * @interface CommunicationEmail
 */
export interface CommunicationEmail {
    /**
     * メールID
     * @type {number}
     * @memberof CommunicationEmail
     */
    id: number;
    /**
     * メールタイトル
     * @type {string}
     * @memberof CommunicationEmail
     */
    title: string;
}

export function CommunicationEmailFromJSON(json: any): CommunicationEmail {
    return CommunicationEmailFromJSONTyped(json, false);
}

export function CommunicationEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationEmail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
    };
}

export function CommunicationEmailToJSON(value?: CommunicationEmail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
    };
}

