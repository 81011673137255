/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationReportDefinitionFilterConditionMatchingGroup,
    CommunicationReportDefinitionFilterConditionMatchingGroupFromJSON,
    CommunicationReportDefinitionFilterConditionMatchingGroupFromJSONTyped,
    CommunicationReportDefinitionFilterConditionMatchingGroupToJSON,
} from './CommunicationReportDefinitionFilterConditionMatchingGroup';

/**
 * 絞り込み条件
 * @export
 * @interface CommunicationReportDefinitionFilterCondition
 */
export interface CommunicationReportDefinitionFilterCondition {
    /**
     * UUID
     * @type {string}
     * @memberof CommunicationReportDefinitionFilterCondition
     */
    itemKey: string;
    /**
     * 項目に対する絞り込み条件リスト
     * @type {Array<CommunicationReportDefinitionFilterConditionMatchingGroup>}
     * @memberof CommunicationReportDefinitionFilterCondition
     */
    matchingGroups: Array<CommunicationReportDefinitionFilterConditionMatchingGroup>;
}

export function CommunicationReportDefinitionFilterConditionFromJSON(json: any): CommunicationReportDefinitionFilterCondition {
    return CommunicationReportDefinitionFilterConditionFromJSONTyped(json, false);
}

export function CommunicationReportDefinitionFilterConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportDefinitionFilterCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemKey': json['item_key'],
        'matchingGroups': ((json['matching_groups'] as Array<any>).map(CommunicationReportDefinitionFilterConditionMatchingGroupFromJSON)),
    };
}

export function CommunicationReportDefinitionFilterConditionToJSON(value?: CommunicationReportDefinitionFilterCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_key': value.itemKey,
        'matching_groups': ((value.matchingGroups as Array<any>).map(CommunicationReportDefinitionFilterConditionMatchingGroupToJSON)),
    };
}

