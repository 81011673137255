/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    PostMedicalInstitutionSearchForSalesMapRequest,
    PostMedicalInstitutionSearchForSalesMapRequestFromJSON,
    PostMedicalInstitutionSearchForSalesMapRequestToJSON,
    PostMedicalInstitutionSearchForSalesMapResponse,
    PostMedicalInstitutionSearchForSalesMapResponseFromJSON,
    PostMedicalInstitutionSearchForSalesMapResponseToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface PostMedicalInstitutionSearchForSalesMapOperationRequest {
    postMedicalInstitutionSearchForSalesMapRequest: PostMedicalInstitutionSearchForSalesMapRequest;
}

/**
 * SalesMapApi - interface
 * 
 * @export
 * @interface SalesMapApiInterface
 */
export interface SalesMapApiInterface {
    /**
     * 営業分析マップ用に医療機関を検索できる   (通常の医療機関検索とは指定できる条件や結果に含まれるパラメータが若干違う)   条件を複数つけることで、絞り込み可能   - 複数条件による絞り込みは、条件同士でAND検索   - 配列型の各絞り込みは、要素同士でOR検索       - tag_ids については、tag_ids_search_conditionを\"and\"に指定することで、 AND検索が可能     - tag_ids については、tag_ids_search_conditionを\"nand\"に指定することで、 NOT AND検索が可能 
     * @summary 営業分析マップに表示する医療機関の検索
     * @param {PostMedicalInstitutionSearchForSalesMapRequest} postMedicalInstitutionSearchForSalesMapRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesMapApiInterface
     */
    postMedicalInstitutionSearchForSalesMapRaw(requestParameters: PostMedicalInstitutionSearchForSalesMapOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionSearchForSalesMapResponse>>;

    /**
     * 営業分析マップ用に医療機関を検索できる   (通常の医療機関検索とは指定できる条件や結果に含まれるパラメータが若干違う)   条件を複数つけることで、絞り込み可能   - 複数条件による絞り込みは、条件同士でAND検索   - 配列型の各絞り込みは、要素同士でOR検索       - tag_ids については、tag_ids_search_conditionを\"and\"に指定することで、 AND検索が可能     - tag_ids については、tag_ids_search_conditionを\"nand\"に指定することで、 NOT AND検索が可能 
     * 営業分析マップに表示する医療機関の検索
     */
    postMedicalInstitutionSearchForSalesMap(requestParameters: PostMedicalInstitutionSearchForSalesMapOperationRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionSearchForSalesMapResponse>;

}

/**
 * 
 */
export class SalesMapApi extends runtime.BaseAPI implements SalesMapApiInterface {

    /**
     * 営業分析マップ用に医療機関を検索できる   (通常の医療機関検索とは指定できる条件や結果に含まれるパラメータが若干違う)   条件を複数つけることで、絞り込み可能   - 複数条件による絞り込みは、条件同士でAND検索   - 配列型の各絞り込みは、要素同士でOR検索       - tag_ids については、tag_ids_search_conditionを\"and\"に指定することで、 AND検索が可能     - tag_ids については、tag_ids_search_conditionを\"nand\"に指定することで、 NOT AND検索が可能 
     * 営業分析マップに表示する医療機関の検索
     */
    async postMedicalInstitutionSearchForSalesMapRaw(requestParameters: PostMedicalInstitutionSearchForSalesMapOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionSearchForSalesMapResponse>> {
        if (requestParameters.postMedicalInstitutionSearchForSalesMapRequest === null || requestParameters.postMedicalInstitutionSearchForSalesMapRequest === undefined) {
            throw new runtime.RequiredError('postMedicalInstitutionSearchForSalesMapRequest','Required parameter requestParameters.postMedicalInstitutionSearchForSalesMapRequest was null or undefined when calling postMedicalInstitutionSearchForSalesMap.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/search_for_sales_map_results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostMedicalInstitutionSearchForSalesMapRequestToJSON(requestParameters.postMedicalInstitutionSearchForSalesMapRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostMedicalInstitutionSearchForSalesMapResponseFromJSON(jsonValue));
    }

    /**
     * 営業分析マップ用に医療機関を検索できる   (通常の医療機関検索とは指定できる条件や結果に含まれるパラメータが若干違う)   条件を複数つけることで、絞り込み可能   - 複数条件による絞り込みは、条件同士でAND検索   - 配列型の各絞り込みは、要素同士でOR検索       - tag_ids については、tag_ids_search_conditionを\"and\"に指定することで、 AND検索が可能     - tag_ids については、tag_ids_search_conditionを\"nand\"に指定することで、 NOT AND検索が可能 
     * 営業分析マップに表示する医療機関の検索
     */
    async postMedicalInstitutionSearchForSalesMap(requestParameters: PostMedicalInstitutionSearchForSalesMapOperationRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionSearchForSalesMapResponse> {
        const response = await this.postMedicalInstitutionSearchForSalesMapRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
