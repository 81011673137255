/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutMedicalInstitutionLabelIdRequestAreas,
    PutMedicalInstitutionLabelIdRequestAreasFromJSON,
    PutMedicalInstitutionLabelIdRequestAreasFromJSONTyped,
    PutMedicalInstitutionLabelIdRequestAreasToJSON,
} from './PutMedicalInstitutionLabelIdRequestAreas';
import {
    PutMedicalInstitutionLabelIdRequestSelections,
    PutMedicalInstitutionLabelIdRequestSelectionsFromJSON,
    PutMedicalInstitutionLabelIdRequestSelectionsFromJSONTyped,
    PutMedicalInstitutionLabelIdRequestSelectionsToJSON,
} from './PutMedicalInstitutionLabelIdRequestSelections';

/**
 * 
 * @export
 * @interface PutMedicalInstitutionLabelIdRequest
 */
export interface PutMedicalInstitutionLabelIdRequest {
    /**
     * 項目名
     * @type {string}
     * @memberof PutMedicalInstitutionLabelIdRequest
     */
    name?: string;
    /**
     * 説明
     * @type {string}
     * @memberof PutMedicalInstitutionLabelIdRequest
     */
    description?: string | null;
    /**
     * 選択肢リスト（コンポーネント種別が'select','checkbox'の場合のみ）
     * @type {Array<PutMedicalInstitutionLabelIdRequestSelections>}
     * @memberof PutMedicalInstitutionLabelIdRequest
     */
    selections?: Array<PutMedicalInstitutionLabelIdRequestSelections> | null;
    /**
     * エリア設定リスト（コンポーネント種別が'area'の場合のみ）
     * @type {Array<PutMedicalInstitutionLabelIdRequestAreas>}
     * @memberof PutMedicalInstitutionLabelIdRequest
     */
    areas?: Array<PutMedicalInstitutionLabelIdRequestAreas> | null;
}

export function PutMedicalInstitutionLabelIdRequestFromJSON(json: any): PutMedicalInstitutionLabelIdRequest {
    return PutMedicalInstitutionLabelIdRequestFromJSONTyped(json, false);
}

export function PutMedicalInstitutionLabelIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutMedicalInstitutionLabelIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'selections': !exists(json, 'selections') ? undefined : (json['selections'] === null ? null : (json['selections'] as Array<any>).map(PutMedicalInstitutionLabelIdRequestSelectionsFromJSON)),
        'areas': !exists(json, 'areas') ? undefined : (json['areas'] === null ? null : (json['areas'] as Array<any>).map(PutMedicalInstitutionLabelIdRequestAreasFromJSON)),
    };
}

export function PutMedicalInstitutionLabelIdRequestToJSON(value?: PutMedicalInstitutionLabelIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'selections': value.selections === undefined ? undefined : (value.selections === null ? null : (value.selections as Array<any>).map(PutMedicalInstitutionLabelIdRequestSelectionsToJSON)),
        'areas': value.areas === undefined ? undefined : (value.areas === null ? null : (value.areas as Array<any>).map(PutMedicalInstitutionLabelIdRequestAreasToJSON)),
    };
}

