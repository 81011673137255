/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUsersMeNotificationsResponse
 */
export interface GetUsersMeNotificationsResponse {
    /**
     * メールアドレス未設定の判定
     * @type {boolean}
     * @memberof GetUsersMeNotificationsResponse
     */
    enabledNotification: boolean;
    /**
     * いいね、コメント通知の設定状況
     * @type {boolean}
     * @memberof GetUsersMeNotificationsResponse
     */
    enabledCommunicationReactionsSummary: boolean;
    /**
     * アラート通知の設定状況
     * @type {boolean}
     * @memberof GetUsersMeNotificationsResponse
     */
    enabledAlertsSummary: boolean;
    /**
     * 連携先レポートアラート通知の設定状況
     * @type {boolean}
     * @memberof GetUsersMeNotificationsResponse
     */
    enabledMedicalInstitutionReportAlert?: boolean;
}

export function GetUsersMeNotificationsResponseFromJSON(json: any): GetUsersMeNotificationsResponse {
    return GetUsersMeNotificationsResponseFromJSONTyped(json, false);
}

export function GetUsersMeNotificationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUsersMeNotificationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabledNotification': json['enabled_notification'],
        'enabledCommunicationReactionsSummary': json['enabled_communication_reactions_summary'],
        'enabledAlertsSummary': json['enabled_alerts_summary'],
        'enabledMedicalInstitutionReportAlert': !exists(json, 'enabled_medical_institution_report_alert') ? undefined : json['enabled_medical_institution_report_alert'],
    };
}

export function GetUsersMeNotificationsResponseToJSON(value?: GetUsersMeNotificationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled_notification': value.enabledNotification,
        'enabled_communication_reactions_summary': value.enabledCommunicationReactionsSummary,
        'enabled_alerts_summary': value.enabledAlertsSummary,
        'enabled_medical_institution_report_alert': value.enabledMedicalInstitutionReportAlert,
    };
}

