/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 通知設定
 * @export
 * @interface PutUsersMeNotificationsRequest
 */
export interface PutUsersMeNotificationsRequest {
    /**
     * コミュニケーションのいいね、コメント通知
     * @type {boolean}
     * @memberof PutUsersMeNotificationsRequest
     */
    enabledCommunicationReactionsSummary: boolean;
    /**
     * アラート通知
     * @type {boolean}
     * @memberof PutUsersMeNotificationsRequest
     */
    enabledAlertsSummary: boolean;
    /**
     * 連携先レポートアラート通知の設定状況
     * @type {boolean}
     * @memberof PutUsersMeNotificationsRequest
     */
    enabledMedicalInstitutionReportAlert: boolean;
}

export function PutUsersMeNotificationsRequestFromJSON(json: any): PutUsersMeNotificationsRequest {
    return PutUsersMeNotificationsRequestFromJSONTyped(json, false);
}

export function PutUsersMeNotificationsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutUsersMeNotificationsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabledCommunicationReactionsSummary': json['enabled_communication_reactions_summary'],
        'enabledAlertsSummary': json['enabled_alerts_summary'],
        'enabledMedicalInstitutionReportAlert': json['enabled_medical_institution_report_alert'],
    };
}

export function PutUsersMeNotificationsRequestToJSON(value?: PutUsersMeNotificationsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled_communication_reactions_summary': value.enabledCommunicationReactionsSummary,
        'enabled_alerts_summary': value.enabledAlertsSummary,
        'enabled_medical_institution_report_alert': value.enabledMedicalInstitutionReportAlert,
    };
}

