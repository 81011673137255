/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportAggregationResultsResults,
    ReportAggregationResultsResultsFromJSON,
    ReportAggregationResultsResultsFromJSONTyped,
    ReportAggregationResultsResultsToJSON,
} from './ReportAggregationResultsResults';

/**
 * レポート集計結果
 * @export
 * @interface ReportAggregationResults
 */
export interface ReportAggregationResults {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportAggregationResults
     */
    columnValues: Array<string>;
    /**
     * 
     * @type {Array<ReportAggregationResultsResults>}
     * @memberof ReportAggregationResults
     */
    results: Array<ReportAggregationResultsResults>;
}

export function ReportAggregationResultsFromJSON(json: any): ReportAggregationResults {
    return ReportAggregationResultsFromJSONTyped(json, false);
}

export function ReportAggregationResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportAggregationResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'columnValues': json['column_values'],
        'results': ((json['results'] as Array<any>).map(ReportAggregationResultsResultsFromJSON)),
    };
}

export function ReportAggregationResultsToJSON(value?: ReportAggregationResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'column_values': value.columnValues,
        'results': ((value.results as Array<any>).map(ReportAggregationResultsResultsToJSON)),
    };
}

