/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsResponseItems,
    PostChartReferralsResponseItemsFromJSON,
    PostChartReferralsResponseItemsFromJSONTyped,
    PostChartReferralsResponseItemsToJSON,
} from './PostChartReferralsResponseItems';

/**
 * 
 * @export
 * @interface PostChartReferralsResponseReferrals
 */
export interface PostChartReferralsResponseReferrals {
    /**
     * 集計期間(週,月,年)
     * @type {string}
     * @memberof PostChartReferralsResponseReferrals
     */
    label: string;
    /**
     * 集計期間の開始日。当日を含む
     * @type {string}
     * @memberof PostChartReferralsResponseReferrals
     */
    startDate?: string;
    /**
     * 集計期間の終了日。当日を含む
     * @type {string}
     * @memberof PostChartReferralsResponseReferrals
     */
    endDate?: string;
    /**
     * 診療科の紹介数
     * @type {Array<PostChartReferralsResponseItems>}
     * @memberof PostChartReferralsResponseReferrals
     */
    items: Array<PostChartReferralsResponseItems>;
}

export function PostChartReferralsResponseReferralsFromJSON(json: any): PostChartReferralsResponseReferrals {
    return PostChartReferralsResponseReferralsFromJSONTyped(json, false);
}

export function PostChartReferralsResponseReferralsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReferralsResponseReferrals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'startDate': !exists(json, 'start_date') ? undefined : json['start_date'],
        'endDate': !exists(json, 'end_date') ? undefined : json['end_date'],
        'items': ((json['items'] as Array<any>).map(PostChartReferralsResponseItemsFromJSON)),
    };
}

export function PostChartReferralsResponseReferralsToJSON(value?: PostChartReferralsResponseReferrals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'start_date': value.startDate,
        'end_date': value.endDate,
        'items': ((value.items as Array<any>).map(PostChartReferralsResponseItemsToJSON)),
    };
}

