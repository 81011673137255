/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionTriggersCommunicationVisit,
    AlertConditionTriggersCommunicationVisitFromJSON,
    AlertConditionTriggersCommunicationVisitFromJSONTyped,
    AlertConditionTriggersCommunicationVisitToJSON,
} from './AlertConditionTriggersCommunicationVisit';
import {
    AlertConditionTriggersReferralAverage,
    AlertConditionTriggersReferralAverageFromJSON,
    AlertConditionTriggersReferralAverageFromJSONTyped,
    AlertConditionTriggersReferralAverageToJSON,
} from './AlertConditionTriggersReferralAverage';
import {
    AlertConditionTriggersReferralChangeCount,
    AlertConditionTriggersReferralChangeCountFromJSON,
    AlertConditionTriggersReferralChangeCountFromJSONTyped,
    AlertConditionTriggersReferralChangeCountToJSON,
} from './AlertConditionTriggersReferralChangeCount';
import {
    AlertConditionTriggersReferralChangeRate,
    AlertConditionTriggersReferralChangeRateFromJSON,
    AlertConditionTriggersReferralChangeRateFromJSONTyped,
    AlertConditionTriggersReferralChangeRateToJSON,
} from './AlertConditionTriggersReferralChangeRate';
import {
    AlertConditionTriggersReferralCount,
    AlertConditionTriggersReferralCountFromJSON,
    AlertConditionTriggersReferralCountFromJSONTyped,
    AlertConditionTriggersReferralCountToJSON,
} from './AlertConditionTriggersReferralCount';

/**
 * type に対応したオブジェクトに値が格納される
 * @export
 * @interface AlertConditionTriggers
 */
export interface AlertConditionTriggers {
    /**
     * 
     * @type {AlertConditionTriggersReferralChangeCount}
     * @memberof AlertConditionTriggers
     */
    referralChangeCount?: AlertConditionTriggersReferralChangeCount;
    /**
     * 
     * @type {AlertConditionTriggersReferralChangeRate}
     * @memberof AlertConditionTriggers
     */
    referralChangeRate?: AlertConditionTriggersReferralChangeRate;
    /**
     * 
     * @type {AlertConditionTriggersReferralCount}
     * @memberof AlertConditionTriggers
     */
    referralCount?: AlertConditionTriggersReferralCount;
    /**
     * 
     * @type {AlertConditionTriggersReferralAverage}
     * @memberof AlertConditionTriggers
     */
    referralAverage?: AlertConditionTriggersReferralAverage;
    /**
     * 
     * @type {AlertConditionTriggersCommunicationVisit}
     * @memberof AlertConditionTriggers
     */
    communicationVisit?: AlertConditionTriggersCommunicationVisit;
}

export function AlertConditionTriggersFromJSON(json: any): AlertConditionTriggers {
    return AlertConditionTriggersFromJSONTyped(json, false);
}

export function AlertConditionTriggersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionTriggers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referralChangeCount': !exists(json, 'referral_change_count') ? undefined : AlertConditionTriggersReferralChangeCountFromJSON(json['referral_change_count']),
        'referralChangeRate': !exists(json, 'referral_change_rate') ? undefined : AlertConditionTriggersReferralChangeRateFromJSON(json['referral_change_rate']),
        'referralCount': !exists(json, 'referral_count') ? undefined : AlertConditionTriggersReferralCountFromJSON(json['referral_count']),
        'referralAverage': !exists(json, 'referral_average') ? undefined : AlertConditionTriggersReferralAverageFromJSON(json['referral_average']),
        'communicationVisit': !exists(json, 'communication_visit') ? undefined : AlertConditionTriggersCommunicationVisitFromJSON(json['communication_visit']),
    };
}

export function AlertConditionTriggersToJSON(value?: AlertConditionTriggers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referral_change_count': AlertConditionTriggersReferralChangeCountToJSON(value.referralChangeCount),
        'referral_change_rate': AlertConditionTriggersReferralChangeRateToJSON(value.referralChangeRate),
        'referral_count': AlertConditionTriggersReferralCountToJSON(value.referralCount),
        'referral_average': AlertConditionTriggersReferralAverageToJSON(value.referralAverage),
        'communication_visit': AlertConditionTriggersCommunicationVisitToJSON(value.communicationVisit),
    };
}

