/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostGroupsChartReferralsComparisonResponseReferrals
 */
export interface PostGroupsChartReferralsComparisonResponseReferrals {
    /**
     * 年度
     * @type {string}
     * @memberof PostGroupsChartReferralsComparisonResponseReferrals
     */
    label: string;
    /**
     * 集計期間
     * @type {string}
     * @memberof PostGroupsChartReferralsComparisonResponseReferrals
     */
    key: string;
    /**
     * 紹介数
     * @type {number}
     * @memberof PostGroupsChartReferralsComparisonResponseReferrals
     */
    value: number;
    /**
     * 診療科のID
     * @type {number}
     * @memberof PostGroupsChartReferralsComparisonResponseReferrals
     */
    groupMedicalInstitutionId: number;
}

export function PostGroupsChartReferralsComparisonResponseReferralsFromJSON(json: any): PostGroupsChartReferralsComparisonResponseReferrals {
    return PostGroupsChartReferralsComparisonResponseReferralsFromJSONTyped(json, false);
}

export function PostGroupsChartReferralsComparisonResponseReferralsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostGroupsChartReferralsComparisonResponseReferrals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'key': json['key'],
        'value': json['value'],
        'groupMedicalInstitutionId': json['group_medical_institution_id'],
    };
}

export function PostGroupsChartReferralsComparisonResponseReferralsToJSON(value?: PostGroupsChartReferralsComparisonResponseReferrals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'key': value.key,
        'value': value.value,
        'group_medical_institution_id': value.groupMedicalInstitutionId,
    };
}

