/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostChartMedicalInstitutionsReferralsResponseSummary
 */
export interface PostChartMedicalInstitutionsReferralsResponseSummary {
    /**
     * 紹介数
     * @type {number}
     * @memberof PostChartMedicalInstitutionsReferralsResponseSummary
     */
    referralCount: number;
    /**
     * 入院数
     * @type {number}
     * @memberof PostChartMedicalInstitutionsReferralsResponseSummary
     */
    admissionCount: number;
    /**
     * 手術数
     * @type {number}
     * @memberof PostChartMedicalInstitutionsReferralsResponseSummary
     */
    operationCount: number;
    /**
     * 逆紹介数
     * @type {number}
     * @memberof PostChartMedicalInstitutionsReferralsResponseSummary
     */
    reverseReferralCount: number;
    /**
     * 入院率 (紹介数が0の場合はnull)
     * @type {number}
     * @memberof PostChartMedicalInstitutionsReferralsResponseSummary
     */
    admissionRate: number | null;
    /**
     * 手術率 (紹介数が0の場合はnull)
     * @type {number}
     * @memberof PostChartMedicalInstitutionsReferralsResponseSummary
     */
    operationRate: number | null;
}

export function PostChartMedicalInstitutionsReferralsResponseSummaryFromJSON(json: any): PostChartMedicalInstitutionsReferralsResponseSummary {
    return PostChartMedicalInstitutionsReferralsResponseSummaryFromJSONTyped(json, false);
}

export function PostChartMedicalInstitutionsReferralsResponseSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartMedicalInstitutionsReferralsResponseSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referralCount': json['referral_count'],
        'admissionCount': json['admission_count'],
        'operationCount': json['operation_count'],
        'reverseReferralCount': json['reverse_referral_count'],
        'admissionRate': json['admission_rate'],
        'operationRate': json['operation_rate'],
    };
}

export function PostChartMedicalInstitutionsReferralsResponseSummaryToJSON(value?: PostChartMedicalInstitutionsReferralsResponseSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referral_count': value.referralCount,
        'admission_count': value.admissionCount,
        'operation_count': value.operationCount,
        'reverse_referral_count': value.reverseReferralCount,
        'admission_rate': value.admissionRate,
        'operation_rate': value.operationRate,
    };
}

