/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostDashboardRequestContents
 */
export interface PostDashboardRequestContents {
    /**
     * レポートの種類
     * @type {string}
     * @memberof PostDashboardRequestContents
     */
    reportType: PostDashboardRequestContentsReportTypeEnum;
    /**
     * レポートID
     * @type {number}
     * @memberof PostDashboardRequestContents
     */
    reportId: number;
}

/**
* @export
* @enum {string}
*/
export enum PostDashboardRequestContentsReportTypeEnum {
    MedicalInstitutionReports = 'medical_institution_reports',
    OwnDepartmentReports = 'own_department_reports',
    AreaReports = 'area_reports',
    CommunicationReports = 'communication_reports'
}

export function PostDashboardRequestContentsFromJSON(json: any): PostDashboardRequestContents {
    return PostDashboardRequestContentsFromJSONTyped(json, false);
}

export function PostDashboardRequestContentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDashboardRequestContents {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportType': json['report_type'],
        'reportId': json['report_id'],
    };
}

export function PostDashboardRequestContentsToJSON(value?: PostDashboardRequestContents | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'report_type': value.reportType,
        'report_id': value.reportId,
    };
}

