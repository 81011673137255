/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 訪問数
 * @export
 * @interface PostMedicalInstitutionSearchForSalesMapRequestVisits
 */
export interface PostMedicalInstitutionSearchForSalesMapRequestVisits {
    /**
     * ◯日以内
     * @type {number}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestVisits
     */
    withinDays: number;
    /**
     * 指定した日数以内に訪問した施設「のみ」or 「除外」の選択
     * @type {string}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestVisits
     */
    logic: PostMedicalInstitutionSearchForSalesMapRequestVisitsLogicEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostMedicalInstitutionSearchForSalesMapRequestVisitsLogicEnum {
    Include = 'include',
    Exclude = 'exclude'
}

export function PostMedicalInstitutionSearchForSalesMapRequestVisitsFromJSON(json: any): PostMedicalInstitutionSearchForSalesMapRequestVisits {
    return PostMedicalInstitutionSearchForSalesMapRequestVisitsFromJSONTyped(json, false);
}

export function PostMedicalInstitutionSearchForSalesMapRequestVisitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionSearchForSalesMapRequestVisits {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'withinDays': json['within_days'],
        'logic': json['logic'],
    };
}

export function PostMedicalInstitutionSearchForSalesMapRequestVisitsToJSON(value?: PostMedicalInstitutionSearchForSalesMapRequestVisits | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'within_days': value.withinDays,
        'logic': value.logic,
    };
}

