/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetMedicalInstitutionLabelsResponse,
    GetMedicalInstitutionLabelsResponseFromJSON,
    GetMedicalInstitutionLabelsResponseToJSON,
    MedicalInstitutionLabel,
    MedicalInstitutionLabelFromJSON,
    MedicalInstitutionLabelToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostMedicalInstitutionLabelsRequest,
    PostMedicalInstitutionLabelsRequestFromJSON,
    PostMedicalInstitutionLabelsRequestToJSON,
    PostMedicalInstitutionSearchForLabelsResponse,
    PostMedicalInstitutionSearchForLabelsResponseFromJSON,
    PostMedicalInstitutionSearchForLabelsResponseToJSON,
    PutMedicalInstitutionLabelIdRequest,
    PutMedicalInstitutionLabelIdRequestFromJSON,
    PutMedicalInstitutionLabelIdRequestToJSON,
    PutMedicalInstitutionLabelsOrdersRequest,
    PutMedicalInstitutionLabelsOrdersRequestFromJSON,
    PutMedicalInstitutionLabelsOrdersRequestToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteMedicalInstitutionLabelIdRequest {
    id: number;
}

export interface PostMedicalInstitutionLabelsOperationRequest {
    postMedicalInstitutionLabelsRequest: PostMedicalInstitutionLabelsRequest;
}

export interface PutMedicalInstitutionLabelIdOperationRequest {
    id: number;
    putMedicalInstitutionLabelIdRequest: PutMedicalInstitutionLabelIdRequest;
}

export interface PutMedicalInstitutionLabelsOrdersOperationRequest {
    putMedicalInstitutionLabelsOrdersRequest: PutMedicalInstitutionLabelsOrdersRequest;
}

/**
 * MedicalInstitutionLabelsApi - interface
 * 
 * @export
 * @interface MedicalInstitutionLabelsApiInterface
 */
export interface MedicalInstitutionLabelsApiInterface {
    /**
     * 自身のテナントにおける、IDで指定された医療機関ラベルマスタを1件削除する 
     * @summary 医療機関ラベルマスタの削除
     * @param {number} id 操作対象の医療機関ラベルマスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionLabelsApiInterface
     */
    deleteMedicalInstitutionLabelIdRaw(requestParameters: DeleteMedicalInstitutionLabelIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * 自身のテナントにおける、IDで指定された医療機関ラベルマスタを1件削除する 
     * 医療機関ラベルマスタの削除
     */
    deleteMedicalInstitutionLabelId(requestParameters: DeleteMedicalInstitutionLabelIdRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 自身のテナントにおける医療機関ラベルマスタの一覧を取得する 
     * @summary 医療機関ラベルマスタの一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionLabelsApiInterface
     */
    getMedicalInstitutionLabelsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionLabelsResponse>>;

    /**
     * 自身のテナントにおける医療機関ラベルマスタの一覧を取得する 
     * 医療機関ラベルマスタの一覧を取得する
     */
    getMedicalInstitutionLabels(initOverrides?: RequestInit): Promise<GetMedicalInstitutionLabelsResponse>;

    /**
     * 医療機関ラベルマスタを１件登録する。 項目名、選択肢、並び順は初期値が入力される。 
     * @summary 医療機関ラベルマスタの登録
     * @param {PostMedicalInstitutionLabelsRequest} postMedicalInstitutionLabelsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionLabelsApiInterface
     */
    postMedicalInstitutionLabelsRaw(requestParameters: PostMedicalInstitutionLabelsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionLabel>>;

    /**
     * 医療機関ラベルマスタを１件登録する。 項目名、選択肢、並び順は初期値が入力される。 
     * 医療機関ラベルマスタの登録
     */
    postMedicalInstitutionLabels(requestParameters: PostMedicalInstitutionLabelsOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionLabel>;

    /**
     * 
     * @summary 医療機関ラベルCSVダウンロード用の医療機関検索
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionLabelsApiInterface
     */
    postMedicalInstitutionSearchForLabelsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionSearchForLabelsResponse>>;

    /**
     * 医療機関ラベルCSVダウンロード用の医療機関検索
     */
    postMedicalInstitutionSearchForLabels(initOverrides?: RequestInit): Promise<PostMedicalInstitutionSearchForLabelsResponse>;

    /**
     * 自身のテナントにおける、IDで指定された医療機関ラベルマスタを1件更新する 
     * @summary 医療機関ラベルマスタの更新
     * @param {number} id 操作対象の医療機関ラベルマスタID
     * @param {PutMedicalInstitutionLabelIdRequest} putMedicalInstitutionLabelIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionLabelsApiInterface
     */
    putMedicalInstitutionLabelIdRaw(requestParameters: PutMedicalInstitutionLabelIdOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionLabel>>;

    /**
     * 自身のテナントにおける、IDで指定された医療機関ラベルマスタを1件更新する 
     * 医療機関ラベルマスタの更新
     */
    putMedicalInstitutionLabelId(requestParameters: PutMedicalInstitutionLabelIdOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionLabel>;

    /**
     * 医療機関ラベルマスタを渡されたIDの順に並び替える 
     * @summary 医療機関ラベルマスタの並び替え
     * @param {PutMedicalInstitutionLabelsOrdersRequest} putMedicalInstitutionLabelsOrdersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionLabelsApiInterface
     */
    putMedicalInstitutionLabelsOrdersRaw(requestParameters: PutMedicalInstitutionLabelsOrdersOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionLabelsResponse>>;

    /**
     * 医療機関ラベルマスタを渡されたIDの順に並び替える 
     * 医療機関ラベルマスタの並び替え
     */
    putMedicalInstitutionLabelsOrders(requestParameters: PutMedicalInstitutionLabelsOrdersOperationRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionLabelsResponse>;

}

/**
 * 
 */
export class MedicalInstitutionLabelsApi extends runtime.BaseAPI implements MedicalInstitutionLabelsApiInterface {

    /**
     * 自身のテナントにおける、IDで指定された医療機関ラベルマスタを1件削除する 
     * 医療機関ラベルマスタの削除
     */
    async deleteMedicalInstitutionLabelIdRaw(requestParameters: DeleteMedicalInstitutionLabelIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMedicalInstitutionLabelId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_labels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 自身のテナントにおける、IDで指定された医療機関ラベルマスタを1件削除する 
     * 医療機関ラベルマスタの削除
     */
    async deleteMedicalInstitutionLabelId(requestParameters: DeleteMedicalInstitutionLabelIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteMedicalInstitutionLabelIdRaw(requestParameters, initOverrides);
    }

    /**
     * 自身のテナントにおける医療機関ラベルマスタの一覧を取得する 
     * 医療機関ラベルマスタの一覧を取得する
     */
    async getMedicalInstitutionLabelsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionLabelsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_labels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicalInstitutionLabelsResponseFromJSON(jsonValue));
    }

    /**
     * 自身のテナントにおける医療機関ラベルマスタの一覧を取得する 
     * 医療機関ラベルマスタの一覧を取得する
     */
    async getMedicalInstitutionLabels(initOverrides?: RequestInit): Promise<GetMedicalInstitutionLabelsResponse> {
        const response = await this.getMedicalInstitutionLabelsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 医療機関ラベルマスタを１件登録する。 項目名、選択肢、並び順は初期値が入力される。 
     * 医療機関ラベルマスタの登録
     */
    async postMedicalInstitutionLabelsRaw(requestParameters: PostMedicalInstitutionLabelsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionLabel>> {
        if (requestParameters.postMedicalInstitutionLabelsRequest === null || requestParameters.postMedicalInstitutionLabelsRequest === undefined) {
            throw new runtime.RequiredError('postMedicalInstitutionLabelsRequest','Required parameter requestParameters.postMedicalInstitutionLabelsRequest was null or undefined when calling postMedicalInstitutionLabels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_labels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostMedicalInstitutionLabelsRequestToJSON(requestParameters.postMedicalInstitutionLabelsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionLabelFromJSON(jsonValue));
    }

    /**
     * 医療機関ラベルマスタを１件登録する。 項目名、選択肢、並び順は初期値が入力される。 
     * 医療機関ラベルマスタの登録
     */
    async postMedicalInstitutionLabels(requestParameters: PostMedicalInstitutionLabelsOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionLabel> {
        const response = await this.postMedicalInstitutionLabelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 医療機関ラベルCSVダウンロード用の医療機関検索
     */
    async postMedicalInstitutionSearchForLabelsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionSearchForLabelsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/search_for_labels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostMedicalInstitutionSearchForLabelsResponseFromJSON(jsonValue));
    }

    /**
     * 医療機関ラベルCSVダウンロード用の医療機関検索
     */
    async postMedicalInstitutionSearchForLabels(initOverrides?: RequestInit): Promise<PostMedicalInstitutionSearchForLabelsResponse> {
        const response = await this.postMedicalInstitutionSearchForLabelsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 自身のテナントにおける、IDで指定された医療機関ラベルマスタを1件更新する 
     * 医療機関ラベルマスタの更新
     */
    async putMedicalInstitutionLabelIdRaw(requestParameters: PutMedicalInstitutionLabelIdOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionLabel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putMedicalInstitutionLabelId.');
        }

        if (requestParameters.putMedicalInstitutionLabelIdRequest === null || requestParameters.putMedicalInstitutionLabelIdRequest === undefined) {
            throw new runtime.RequiredError('putMedicalInstitutionLabelIdRequest','Required parameter requestParameters.putMedicalInstitutionLabelIdRequest was null or undefined when calling putMedicalInstitutionLabelId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_labels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutMedicalInstitutionLabelIdRequestToJSON(requestParameters.putMedicalInstitutionLabelIdRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionLabelFromJSON(jsonValue));
    }

    /**
     * 自身のテナントにおける、IDで指定された医療機関ラベルマスタを1件更新する 
     * 医療機関ラベルマスタの更新
     */
    async putMedicalInstitutionLabelId(requestParameters: PutMedicalInstitutionLabelIdOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionLabel> {
        const response = await this.putMedicalInstitutionLabelIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 医療機関ラベルマスタを渡されたIDの順に並び替える 
     * 医療機関ラベルマスタの並び替え
     */
    async putMedicalInstitutionLabelsOrdersRaw(requestParameters: PutMedicalInstitutionLabelsOrdersOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionLabelsResponse>> {
        if (requestParameters.putMedicalInstitutionLabelsOrdersRequest === null || requestParameters.putMedicalInstitutionLabelsOrdersRequest === undefined) {
            throw new runtime.RequiredError('putMedicalInstitutionLabelsOrdersRequest','Required parameter requestParameters.putMedicalInstitutionLabelsOrdersRequest was null or undefined when calling putMedicalInstitutionLabelsOrders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_labels/orders`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutMedicalInstitutionLabelsOrdersRequestToJSON(requestParameters.putMedicalInstitutionLabelsOrdersRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicalInstitutionLabelsResponseFromJSON(jsonValue));
    }

    /**
     * 医療機関ラベルマスタを渡されたIDの順に並び替える 
     * 医療機関ラベルマスタの並び替え
     */
    async putMedicalInstitutionLabelsOrders(requestParameters: PutMedicalInstitutionLabelsOrdersOperationRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionLabelsResponse> {
        const response = await this.putMedicalInstitutionLabelsOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
