/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaCondition,
    AreaConditionFromJSON,
    AreaConditionFromJSONTyped,
    AreaConditionToJSON,
} from './AreaCondition';
import {
    IntegerCondition,
    IntegerConditionFromJSON,
    IntegerConditionFromJSONTyped,
    IntegerConditionToJSON,
} from './IntegerCondition';
import {
    SelectionCondition,
    SelectionConditionFromJSON,
    SelectionConditionFromJSONTyped,
    SelectionConditionToJSON,
} from './SelectionCondition';
import {
    TextCondition,
    TextConditionFromJSON,
    TextConditionFromJSONTyped,
    TextConditionToJSON,
} from './TextCondition';

/**
 * 各医療機関ラベルの検索条件
 * @export
 * @interface LabelSearchCondition
 */
export interface LabelSearchCondition {
    /**
     * 
     * @type {number}
     * @memberof LabelSearchCondition
     */
    labelId: number;
    /**
     * 
     * @type {TextCondition}
     * @memberof LabelSearchCondition
     */
    textConditions?: TextCondition;
    /**
     * 
     * @type {IntegerCondition}
     * @memberof LabelSearchCondition
     */
    integerConditions?: IntegerCondition;
    /**
     * 
     * @type {SelectionCondition}
     * @memberof LabelSearchCondition
     */
    selectionConditions?: SelectionCondition;
    /**
     * 
     * @type {AreaCondition}
     * @memberof LabelSearchCondition
     */
    areaConditions?: AreaCondition;
}

export function LabelSearchConditionFromJSON(json: any): LabelSearchCondition {
    return LabelSearchConditionFromJSONTyped(json, false);
}

export function LabelSearchConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelSearchCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labelId': json['label_id'],
        'textConditions': !exists(json, 'text_conditions') ? undefined : TextConditionFromJSON(json['text_conditions']),
        'integerConditions': !exists(json, 'integer_conditions') ? undefined : IntegerConditionFromJSON(json['integer_conditions']),
        'selectionConditions': !exists(json, 'selection_conditions') ? undefined : SelectionConditionFromJSON(json['selection_conditions']),
        'areaConditions': !exists(json, 'area_conditions') ? undefined : AreaConditionFromJSON(json['area_conditions']),
    };
}

export function LabelSearchConditionToJSON(value?: LabelSearchCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label_id': value.labelId,
        'text_conditions': TextConditionToJSON(value.textConditions),
        'integer_conditions': IntegerConditionToJSON(value.integerConditions),
        'selection_conditions': SelectionConditionToJSON(value.selectionConditions),
        'area_conditions': AreaConditionToJSON(value.areaConditions),
    };
}

