/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MedicalInstitutionReportDefinitionAggregationData
 */
export interface MedicalInstitutionReportDefinitionAggregationData {
    /**
     * 
     * @type {string}
     * @memberof MedicalInstitutionReportDefinitionAggregationData
     */
    aggregationMethod: MedicalInstitutionReportDefinitionAggregationDataAggregationMethodEnum;
    /**
     * 集計対象の項目のキー。レコード数集計の場合は指定しない
     * @type {string}
     * @memberof MedicalInstitutionReportDefinitionAggregationData
     */
    itemKey?: string;
}

/**
* @export
* @enum {string}
*/
export enum MedicalInstitutionReportDefinitionAggregationDataAggregationMethodEnum {
    Sum = 'sum',
    Count = 'count'
}

export function MedicalInstitutionReportDefinitionAggregationDataFromJSON(json: any): MedicalInstitutionReportDefinitionAggregationData {
    return MedicalInstitutionReportDefinitionAggregationDataFromJSONTyped(json, false);
}

export function MedicalInstitutionReportDefinitionAggregationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportDefinitionAggregationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aggregationMethod': json['aggregation_method'],
        'itemKey': !exists(json, 'item_key') ? undefined : json['item_key'],
    };
}

export function MedicalInstitutionReportDefinitionAggregationDataToJSON(value?: MedicalInstitutionReportDefinitionAggregationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aggregation_method': value.aggregationMethod,
        'item_key': value.itemKey,
    };
}

