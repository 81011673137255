import { FetchError, StatusCode } from '@/src/error/fetchError/FetchError';

import { useState, useCallback } from 'react';

type ThrowFetchError = (statusCode: StatusCode | FetchError) => never;

export const useFetchError = (): ThrowFetchError => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setState] = useState();

  const throwFetchError = useCallback(
    (statusCode: Parameters<ThrowFetchError>[0]) => {
      setState(() => {
        if (statusCode instanceof FetchError) {
          throw statusCode;
        }
        throw new FetchError(statusCode);
      });
    },
    [],
  ) as ThrowFetchError;

  return throwFetchError;
};
