/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationLabelComponentType,
    CommunicationLabelComponentTypeFromJSON,
    CommunicationLabelComponentTypeFromJSONTyped,
    CommunicationLabelComponentTypeToJSON,
} from './CommunicationLabelComponentType';
import {
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
} from './CommunicationType';
import {
    PostCommunicationLabelsRequestSelections,
    PostCommunicationLabelsRequestSelectionsFromJSON,
    PostCommunicationLabelsRequestSelectionsFromJSONTyped,
    PostCommunicationLabelsRequestSelectionsToJSON,
} from './PostCommunicationLabelsRequestSelections';

/**
 * 
 * @export
 * @interface PostCommunicationLabelsRequest
 */
export interface PostCommunicationLabelsRequest {
    /**
     * 
     * @type {CommunicationType}
     * @memberof PostCommunicationLabelsRequest
     */
    communicationType: CommunicationType;
    /**
     * 項目名
     * @type {string}
     * @memberof PostCommunicationLabelsRequest
     */
    name: string;
    /**
     * 
     * @type {CommunicationLabelComponentType}
     * @memberof PostCommunicationLabelsRequest
     */
    componentType: CommunicationLabelComponentType;
    /**
     * 説明
     * @type {string}
     * @memberof PostCommunicationLabelsRequest
     */
    description: string | null;
    /**
     * 選択肢リスト（コンポーネント種別が'select','checkbox'の場合のみ）
     * @type {Array<PostCommunicationLabelsRequestSelections>}
     * @memberof PostCommunicationLabelsRequest
     */
    selections: Array<PostCommunicationLabelsRequestSelections> | null;
    /**
     * 必須入力かどうか
     * @type {boolean}
     * @memberof PostCommunicationLabelsRequest
     */
    isRequired: boolean | null;
}

export function PostCommunicationLabelsRequestFromJSON(json: any): PostCommunicationLabelsRequest {
    return PostCommunicationLabelsRequestFromJSONTyped(json, false);
}

export function PostCommunicationLabelsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCommunicationLabelsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communicationType': CommunicationTypeFromJSON(json['communication_type']),
        'name': json['name'],
        'componentType': CommunicationLabelComponentTypeFromJSON(json['component_type']),
        'description': json['description'],
        'selections': (json['selections'] === null ? null : (json['selections'] as Array<any>).map(PostCommunicationLabelsRequestSelectionsFromJSON)),
        'isRequired': json['is_required'],
    };
}

export function PostCommunicationLabelsRequestToJSON(value?: PostCommunicationLabelsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communication_type': CommunicationTypeToJSON(value.communicationType),
        'name': value.name,
        'component_type': CommunicationLabelComponentTypeToJSON(value.componentType),
        'description': value.description,
        'selections': (value.selections === null ? null : (value.selections as Array<any>).map(PostCommunicationLabelsRequestSelectionsToJSON)),
        'is_required': value.isRequired,
    };
}

