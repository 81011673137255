/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostGroupsChartReferralsResponseItems,
    PostGroupsChartReferralsResponseItemsFromJSON,
    PostGroupsChartReferralsResponseItemsFromJSONTyped,
    PostGroupsChartReferralsResponseItemsToJSON,
} from './PostGroupsChartReferralsResponseItems';

/**
 * 
 * @export
 * @interface PostGroupsChartReferralsResponseReferrals
 */
export interface PostGroupsChartReferralsResponseReferrals {
    /**
     * 集計期間(週,月,年)
     * @type {string}
     * @memberof PostGroupsChartReferralsResponseReferrals
     */
    label: string;
    /**
     * グループ病院ごとの紹介数
     * @type {Array<PostGroupsChartReferralsResponseItems>}
     * @memberof PostGroupsChartReferralsResponseReferrals
     */
    items: Array<PostGroupsChartReferralsResponseItems>;
}

export function PostGroupsChartReferralsResponseReferralsFromJSON(json: any): PostGroupsChartReferralsResponseReferrals {
    return PostGroupsChartReferralsResponseReferralsFromJSONTyped(json, false);
}

export function PostGroupsChartReferralsResponseReferralsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostGroupsChartReferralsResponseReferrals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'items': ((json['items'] as Array<any>).map(PostGroupsChartReferralsResponseItemsFromJSON)),
    };
}

export function PostGroupsChartReferralsResponseReferralsToJSON(value?: PostGroupsChartReferralsResponseReferrals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'items': ((value.items as Array<any>).map(PostGroupsChartReferralsResponseItemsToJSON)),
    };
}

