import { Link } from '@/src/components/foundations/Utils/Link';

import styles from './styles.module.scss';

import Copyright from '@mui/icons-material/Copyright';
import clsx from 'clsx';

export const FooterPresenter: React.FC = () => (
  <footer className={styles.base}>
    <div className={styles.policies}>
      <Link
        href="https://www.foro-crm.jp/privacy/"
        isOpenAnotherTab={true}
        className={clsx(styles.link, styles.policyLink)}
      >
        プライバシーポリシー
      </Link>
      <Link
        href="https://medup.notion.site/bc9c7f53619843f28b4fd1636262859f"
        isOpenAnotherTab={true}
        className={clsx(styles.link, styles.policyLink)}
      >
        クッキーポリシー
      </Link>
    </div>

    <div className={styles.corporate}>
      <Copyright className={styles.icon} />
      <span className={clsx(styles.text, styles.marginRight)}>2021</span>
      <Link
        href="https://medup.jp/"
        isOpenAnotherTab={true}
        className={clsx(styles.link, styles.marginRight)}
      >
        MedUp, Inc.
      </Link>
      <span className={styles.text}>ALL RIGHTS RESERVED.</span>
    </div>
  </footer>
);
