/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostGroupsChartReferralsResponseItems,
    PostGroupsChartReferralsResponseItemsFromJSON,
    PostGroupsChartReferralsResponseItemsFromJSONTyped,
    PostGroupsChartReferralsResponseItemsToJSON,
} from './PostGroupsChartReferralsResponseItems';

/**
 * 
 * @export
 * @interface PostGroupsChartReferralsComparisonAnnualResponseReferrals
 */
export interface PostGroupsChartReferralsComparisonAnnualResponseReferrals {
    /**
     * 年度
     * @type {string}
     * @memberof PostGroupsChartReferralsComparisonAnnualResponseReferrals
     */
    label: string;
    /**
     * 年度内の紹介数データ
     * @type {Array<PostGroupsChartReferralsResponseItems>}
     * @memberof PostGroupsChartReferralsComparisonAnnualResponseReferrals
     */
    items: Array<PostGroupsChartReferralsResponseItems>;
}

export function PostGroupsChartReferralsComparisonAnnualResponseReferralsFromJSON(json: any): PostGroupsChartReferralsComparisonAnnualResponseReferrals {
    return PostGroupsChartReferralsComparisonAnnualResponseReferralsFromJSONTyped(json, false);
}

export function PostGroupsChartReferralsComparisonAnnualResponseReferralsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostGroupsChartReferralsComparisonAnnualResponseReferrals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'items': ((json['items'] as Array<any>).map(PostGroupsChartReferralsResponseItemsFromJSON)),
    };
}

export function PostGroupsChartReferralsComparisonAnnualResponseReferralsToJSON(value?: PostGroupsChartReferralsComparisonAnnualResponseReferrals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'items': ((value.items as Array<any>).map(PostGroupsChartReferralsResponseItemsToJSON)),
    };
}

