/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaReportDefinitionDisplayItem,
    AreaReportDefinitionDisplayItemFromJSON,
    AreaReportDefinitionDisplayItemFromJSONTyped,
    AreaReportDefinitionDisplayItemToJSON,
} from './AreaReportDefinitionDisplayItem';
import {
    AreaReportDefinitionFilterCondition,
    AreaReportDefinitionFilterConditionFromJSON,
    AreaReportDefinitionFilterConditionFromJSONTyped,
    AreaReportDefinitionFilterConditionToJSON,
} from './AreaReportDefinitionFilterCondition';
import {
    AreaReportDefinitionSortCondition,
    AreaReportDefinitionSortConditionFromJSON,
    AreaReportDefinitionSortConditionFromJSONTyped,
    AreaReportDefinitionSortConditionToJSON,
} from './AreaReportDefinitionSortCondition';
import {
    AreaReportItem,
    AreaReportItemFromJSON,
    AreaReportItemFromJSONTyped,
    AreaReportItemToJSON,
} from './AreaReportItem';

/**
 * 
 * @export
 * @interface PutAreaReportDefinitionRequest
 */
export interface PutAreaReportDefinitionRequest {
    /**
     * レポート名
     * @type {string}
     * @memberof PutAreaReportDefinitionRequest
     */
    name: string;
    /**
     * 
     * @type {Array<AreaReportItem>}
     * @memberof PutAreaReportDefinitionRequest
     */
    items: Array<AreaReportItem>;
    /**
     * エリアタイプの医療機関ラベルID
     * @type {number}
     * @memberof PutAreaReportDefinitionRequest
     */
    medicalInstitutionLabelId: number;
    /**
     * 
     * @type {Array<AreaReportDefinitionDisplayItem>}
     * @memberof PutAreaReportDefinitionRequest
     */
    displayItems: Array<AreaReportDefinitionDisplayItem>;
    /**
     * 
     * @type {Array<AreaReportDefinitionFilterCondition>}
     * @memberof PutAreaReportDefinitionRequest
     */
    filterConditions: Array<AreaReportDefinitionFilterCondition>;
    /**
     * 
     * @type {Array<AreaReportDefinitionSortCondition>}
     * @memberof PutAreaReportDefinitionRequest
     */
    sortConditions: Array<AreaReportDefinitionSortCondition>;
}

export function PutAreaReportDefinitionRequestFromJSON(json: any): PutAreaReportDefinitionRequest {
    return PutAreaReportDefinitionRequestFromJSONTyped(json, false);
}

export function PutAreaReportDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutAreaReportDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'items': ((json['items'] as Array<any>).map(AreaReportItemFromJSON)),
        'medicalInstitutionLabelId': json['medical_institution_label_id'],
        'displayItems': ((json['display_items'] as Array<any>).map(AreaReportDefinitionDisplayItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(AreaReportDefinitionFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(AreaReportDefinitionSortConditionFromJSON)),
    };
}

export function PutAreaReportDefinitionRequestToJSON(value?: PutAreaReportDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'items': ((value.items as Array<any>).map(AreaReportItemToJSON)),
        'medical_institution_label_id': value.medicalInstitutionLabelId,
        'display_items': ((value.displayItems as Array<any>).map(AreaReportDefinitionDisplayItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(AreaReportDefinitionFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(AreaReportDefinitionSortConditionToJSON)),
    };
}

