/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerificationsFromJSON,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerificationsFromJSONTyped,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerificationsToJSON,
} from './PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications';

/**
 * 
 * @export
 * @interface PostDataAnalysisEffectivenessVerificationCommunicationsResponse
 */
export interface PostDataAnalysisEffectivenessVerificationCommunicationsResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponse
     */
    totalPage: number;
    /**
     * 集計期間(日)
     * @type {number}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponse
     */
    aggregationPeriod: number;
    /**
     * 個別効果検証データ
     * @type {Array<PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications>}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponse
     */
    effectivenessVerifications: Array<PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerifications>;
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsResponseFromJSON(json: any): PostDataAnalysisEffectivenessVerificationCommunicationsResponse {
    return PostDataAnalysisEffectivenessVerificationCommunicationsResponseFromJSONTyped(json, false);
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisEffectivenessVerificationCommunicationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'aggregationPeriod': json['aggregation_period'],
        'effectivenessVerifications': ((json['effectiveness_verifications'] as Array<any>).map(PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerificationsFromJSON)),
    };
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsResponseToJSON(value?: PostDataAnalysisEffectivenessVerificationCommunicationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'aggregation_period': value.aggregationPeriod,
        'effectiveness_verifications': ((value.effectivenessVerifications as Array<any>).map(PostDataAnalysisEffectivenessVerificationCommunicationsResponseEffectivenessVerificationsToJSON)),
    };
}

