/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Mdc,
    MdcFromJSON,
    MdcFromJSONTyped,
    MdcToJSON,
} from './Mdc';

/**
 * 
 * @export
 * @interface GetMdcsResponse
 */
export interface GetMdcsResponse {
    /**
     * 
     * @type {Array<Mdc>}
     * @memberof GetMdcsResponse
     */
    mdcs: Array<Mdc>;
}

export function GetMdcsResponseFromJSON(json: any): GetMdcsResponse {
    return GetMdcsResponseFromJSONTyped(json, false);
}

export function GetMdcsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMdcsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mdcs': ((json['mdcs'] as Array<any>).map(MdcFromJSON)),
    };
}

export function GetMdcsResponseToJSON(value?: GetMdcsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mdcs': ((value.mdcs as Array<any>).map(MdcToJSON)),
    };
}

