/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetReferralAdmissionSegmentsResponse,
    GetReferralAdmissionSegmentsResponseFromJSON,
    GetReferralAdmissionSegmentsResponseToJSON,
    NullableReferral,
    NullableReferralFromJSON,
    NullableReferralToJSON,
} from '../models';

/**
 * ReferralsApi - interface
 * 
 * @export
 * @interface ReferralsApiInterface
 */
export interface ReferralsApiInterface {
    /**
     * 
     * @summary 最古の紹介情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    getFirstReferralRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<NullableReferral>>;

    /**
     * 最古の紹介情報を取得
     */
    getFirstReferral(initOverrides?: RequestInit): Promise<NullableReferral>;

    /**
     * 
     * @summary 最新の紹介情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    getLastReferralRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<NullableReferral>>;

    /**
     * 最新の紹介情報を取得
     */
    getLastReferral(initOverrides?: RequestInit): Promise<NullableReferral>;

    /**
     * テナントで設定されている紹介入院セグメントが取得できる。 
     * @summary 紹介入院セグメント一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralsApiInterface
     */
    getReferralAdmissionSegmtnsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetReferralAdmissionSegmentsResponse>>;

    /**
     * テナントで設定されている紹介入院セグメントが取得できる。 
     * 紹介入院セグメント一覧の取得
     */
    getReferralAdmissionSegmtns(initOverrides?: RequestInit): Promise<GetReferralAdmissionSegmentsResponse>;

}

/**
 * 
 */
export class ReferralsApi extends runtime.BaseAPI implements ReferralsApiInterface {

    /**
     * 最古の紹介情報を取得
     */
    async getFirstReferralRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<NullableReferral>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/referrals/first`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NullableReferralFromJSON(jsonValue));
    }

    /**
     * 最古の紹介情報を取得
     */
    async getFirstReferral(initOverrides?: RequestInit): Promise<NullableReferral> {
        const response = await this.getFirstReferralRaw(initOverrides);
        return await response.value();
    }

    /**
     * 最新の紹介情報を取得
     */
    async getLastReferralRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<NullableReferral>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/referrals/last`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NullableReferralFromJSON(jsonValue));
    }

    /**
     * 最新の紹介情報を取得
     */
    async getLastReferral(initOverrides?: RequestInit): Promise<NullableReferral> {
        const response = await this.getLastReferralRaw(initOverrides);
        return await response.value();
    }

    /**
     * テナントで設定されている紹介入院セグメントが取得できる。 
     * 紹介入院セグメント一覧の取得
     */
    async getReferralAdmissionSegmtnsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetReferralAdmissionSegmentsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/referral_admission_segments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReferralAdmissionSegmentsResponseFromJSON(jsonValue));
    }

    /**
     * テナントで設定されている紹介入院セグメントが取得できる。 
     * 紹介入院セグメント一覧の取得
     */
    async getReferralAdmissionSegmtns(initOverrides?: RequestInit): Promise<GetReferralAdmissionSegmentsResponse> {
        const response = await this.getReferralAdmissionSegmtnsRaw(initOverrides);
        return await response.value();
    }

}
