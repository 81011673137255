/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutMedicalInstitutionLabelsOrdersRequest
 */
export interface PutMedicalInstitutionLabelsOrdersRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PutMedicalInstitutionLabelsOrdersRequest
     */
    labelIds: Array<number>;
}

export function PutMedicalInstitutionLabelsOrdersRequestFromJSON(json: any): PutMedicalInstitutionLabelsOrdersRequest {
    return PutMedicalInstitutionLabelsOrdersRequestFromJSONTyped(json, false);
}

export function PutMedicalInstitutionLabelsOrdersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutMedicalInstitutionLabelsOrdersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labelIds': json['label_ids'],
    };
}

export function PutMedicalInstitutionLabelsOrdersRequestToJSON(value?: PutMedicalInstitutionLabelsOrdersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label_ids': value.labelIds,
    };
}

