/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportDefinition,
    MedicalInstitutionReportDefinitionFromJSON,
    MedicalInstitutionReportDefinitionFromJSONTyped,
    MedicalInstitutionReportDefinitionToJSON,
} from './MedicalInstitutionReportDefinition';

/**
 * 
 * @export
 * @interface GetMedicalInstitutionReportDefinitionsResponse
 */
export interface GetMedicalInstitutionReportDefinitionsResponse {
    /**
     * 連携先レポート定義
     * @type {Array<MedicalInstitutionReportDefinition>}
     * @memberof GetMedicalInstitutionReportDefinitionsResponse
     */
    definitions: Array<MedicalInstitutionReportDefinition>;
}

export function GetMedicalInstitutionReportDefinitionsResponseFromJSON(json: any): GetMedicalInstitutionReportDefinitionsResponse {
    return GetMedicalInstitutionReportDefinitionsResponseFromJSONTyped(json, false);
}

export function GetMedicalInstitutionReportDefinitionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMedicalInstitutionReportDefinitionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definitions': ((json['definitions'] as Array<any>).map(MedicalInstitutionReportDefinitionFromJSON)),
    };
}

export function GetMedicalInstitutionReportDefinitionsResponseToJSON(value?: GetMedicalInstitutionReportDefinitionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definitions': ((value.definitions as Array<any>).map(MedicalInstitutionReportDefinitionToJSON)),
    };
}

