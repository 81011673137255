/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableSegmentationSetting,
    NullableSegmentationSettingFromJSON,
    NullableSegmentationSettingFromJSONTyped,
    NullableSegmentationSettingToJSON,
} from './NullableSegmentationSetting';

/**
 * 
 * @export
 * @interface GetSegmentationSettingResponse
 */
export interface GetSegmentationSettingResponse {
    /**
     * 
     * @type {NullableSegmentationSetting}
     * @memberof GetSegmentationSettingResponse
     */
    segmentationSetting: NullableSegmentationSetting | null;
}

export function GetSegmentationSettingResponseFromJSON(json: any): GetSegmentationSettingResponse {
    return GetSegmentationSettingResponseFromJSONTyped(json, false);
}

export function GetSegmentationSettingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSegmentationSettingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'segmentationSetting': NullableSegmentationSettingFromJSON(json['segmentation_setting']),
    };
}

export function GetSegmentationSettingResponseToJSON(value?: GetSegmentationSettingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'segmentation_setting': NullableSegmentationSettingToJSON(value.segmentationSetting),
    };
}

