import { Img } from '@/src/components/foundations/DataDisplays/Img';
import {
  Support,
  Menu,
  MenuOpen,
} from '@/src/components/foundations/DesignToken/Icons';
import { Link } from '@/src/components/foundations/Utils/Link';

import styles from './styles.module.scss';
import { PresenterProps } from './types';

import clsx from 'clsx';
import { useState } from 'react';

export const SideMenuPresenter: React.FC<PresenterProps> = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <nav className={clsx(styles.base, !isOpen && styles.collapsed)}>
      <div className={clsx(styles.logoSection, !isOpen && styles.collapsed)}>
        {isOpen && (
          <Link
            href="/data_analysis"
            onClick={() => props.onClickSideMenu('/data_analysis')}
            className={styles.logo}
          >
            <Img alt="logo" src="/logo.png" height={20} />
          </Link>
        )}
        <button
          type="button"
          className={styles.toggleButton}
          onClick={() => setIsOpen((currentVaule) => !currentVaule)}
        >
          {isOpen ? (
            <MenuOpen color="white" size={21} />
          ) : (
            <Menu color="white" size={21} />
          )}
        </button>
      </div>
      <ul className={clsx(styles.menuItemSection, !isOpen && styles.collapsed)}>
        {props.menus.map((menu) => (
          <li key={menu.text}>
            <Link
              href={menu.urls[0]}
              onClick={() => props.onClickSideMenu(menu.urls[0])}
              className={clsx(
                styles.menuItem,
                !isOpen && styles.collapsed,
                menu.urls.some((url) =>
                  props.currentPathname.startsWith(url),
                ) && styles.active,
              )}
            >
              <menu.icon size={21} />
              {isOpen && menu.text}
            </Link>
          </li>
        ))}
      </ul>
      <div className={styles.supportLinkSection}>
        <Link
          href="https://medup.zendesk.com/hc/ja"
          isOpenAnotherTab
          className={clsx(styles.supportLink, !isOpen && styles.collapsed)}
        >
          {isOpen ? 'サポートページ' : <Support size={21} />}
        </Link>
      </div>
    </nav>
  );
};
