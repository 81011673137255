/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionRunningStatusEnum,
    MedicalInstitutionRunningStatusEnumFromJSON,
    MedicalInstitutionRunningStatusEnumFromJSONTyped,
    MedicalInstitutionRunningStatusEnumToJSON,
} from './MedicalInstitutionRunningStatusEnum';

/**
 * エリアレポートの一行
 * @export
 * @interface PostCommunicationReportResultsResponseResults
 */
export interface PostCommunicationReportResultsResponseResults {
    /**
     * コミュニケーションID
     * @type {number}
     * @memberof PostCommunicationReportResultsResponseResults
     */
    communicationId: number;
    /**
     * コミュニケーションが紐づく医療機関ID
     * @type {number}
     * @memberof PostCommunicationReportResultsResponseResults
     */
    medicalInstitutionId: number;
    /**
     * コミュニケーションが紐づく医療機関名
     * @type {string}
     * @memberof PostCommunicationReportResultsResponseResults
     */
    medicalInstitutionName: string;
    /**
     * 
     * @type {MedicalInstitutionRunningStatusEnum}
     * @memberof PostCommunicationReportResultsResponseResults
     */
    medicalInstitutionRunningStatus: MedicalInstitutionRunningStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostCommunicationReportResultsResponseResults
     */
    values: Array<string>;
}

export function PostCommunicationReportResultsResponseResultsFromJSON(json: any): PostCommunicationReportResultsResponseResults {
    return PostCommunicationReportResultsResponseResultsFromJSONTyped(json, false);
}

export function PostCommunicationReportResultsResponseResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCommunicationReportResultsResponseResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communicationId': json['communication_id'],
        'medicalInstitutionId': json['medical_institution_id'],
        'medicalInstitutionName': json['medical_institution_name'],
        'medicalInstitutionRunningStatus': MedicalInstitutionRunningStatusEnumFromJSON(json['medical_institution_running_status']),
        'values': json['values'],
    };
}

export function PostCommunicationReportResultsResponseResultsToJSON(value?: PostCommunicationReportResultsResponseResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communication_id': value.communicationId,
        'medical_institution_id': value.medicalInstitutionId,
        'medical_institution_name': value.medicalInstitutionName,
        'medical_institution_running_status': MedicalInstitutionRunningStatusEnumToJSON(value.medicalInstitutionRunningStatus),
        'values': value.values,
    };
}

