/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetOwnDepartmentsResponseDepartments,
    GetOwnDepartmentsResponseDepartmentsFromJSON,
    GetOwnDepartmentsResponseDepartmentsFromJSONTyped,
    GetOwnDepartmentsResponseDepartmentsToJSON,
} from './GetOwnDepartmentsResponseDepartments';

/**
 * 
 * @export
 * @interface PostChartMedicalInstitutionsReferralsDepartmentsResponse
 */
export interface PostChartMedicalInstitutionsReferralsDepartmentsResponse {
    /**
     * 
     * @type {Array<GetOwnDepartmentsResponseDepartments>}
     * @memberof PostChartMedicalInstitutionsReferralsDepartmentsResponse
     */
    departments: Array<GetOwnDepartmentsResponseDepartments>;
}

export function PostChartMedicalInstitutionsReferralsDepartmentsResponseFromJSON(json: any): PostChartMedicalInstitutionsReferralsDepartmentsResponse {
    return PostChartMedicalInstitutionsReferralsDepartmentsResponseFromJSONTyped(json, false);
}

export function PostChartMedicalInstitutionsReferralsDepartmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartMedicalInstitutionsReferralsDepartmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'departments': ((json['departments'] as Array<any>).map(GetOwnDepartmentsResponseDepartmentsFromJSON)),
    };
}

export function PostChartMedicalInstitutionsReferralsDepartmentsResponseToJSON(value?: PostChartMedicalInstitutionsReferralsDepartmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'departments': ((value.departments as Array<any>).map(GetOwnDepartmentsResponseDepartmentsToJSON)),
    };
}

