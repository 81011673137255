/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertConditionFiltersTagGroupsItems
 */
export interface AlertConditionFiltersTagGroupsItems {
    /**
     * タグのグルーピング名（グループ外のものはnullになる）
     * @type {string}
     * @memberof AlertConditionFiltersTagGroupsItems
     */
    value: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AlertConditionFiltersTagGroupsItems
     */
    options: Array<string>;
}

export function AlertConditionFiltersTagGroupsItemsFromJSON(json: any): AlertConditionFiltersTagGroupsItems {
    return AlertConditionFiltersTagGroupsItemsFromJSONTyped(json, false);
}

export function AlertConditionFiltersTagGroupsItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionFiltersTagGroupsItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'options': json['options'],
    };
}

export function AlertConditionFiltersTagGroupsItemsToJSON(value?: AlertConditionFiltersTagGroupsItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'options': value.options,
    };
}

