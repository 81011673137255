/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 以上/以下
 * @export
 * @enum {string}
 */
export enum AlertConditionMoreLess {
    More = 'more',
    Less = 'less'
}

export function AlertConditionMoreLessFromJSON(json: any): AlertConditionMoreLess {
    return AlertConditionMoreLessFromJSONTyped(json, false);
}

export function AlertConditionMoreLessFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionMoreLess {
    return json as AlertConditionMoreLess;
}

export function AlertConditionMoreLessToJSON(value?: AlertConditionMoreLess | null): any {
    return value as any;
}

