/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostTagRequest
 */
export interface PostTagRequest {
    /**
     * タグ名
     * @type {string}
     * @memberof PostTagRequest
     */
    name: string;
    /**
     * タグの説明
     * @type {string}
     * @memberof PostTagRequest
     */
    description: string | null;
}

export function PostTagRequestFromJSON(json: any): PostTagRequest {
    return PostTagRequestFromJSONTyped(json, false);
}

export function PostTagRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostTagRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
    };
}

export function PostTagRequestToJSON(value?: PostTagRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
    };
}

