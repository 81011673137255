/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 項目に対して適用するフィルタ条件。複数指定された場合はすべて満たす必要ものにマッチする。
 * @export
 * @interface OwnDepartmentReportFilterConditionMatcher
 */
export interface OwnDepartmentReportFilterConditionMatcher {
    /**
     * trueにすると値が存在するものにのみマッチする。falseにすると値が存在しない（null）対象も含めてマッチする。
     * @type {boolean}
     * @memberof OwnDepartmentReportFilterConditionMatcher
     */
    _exists?: boolean;
    /**
     * 指定した数値以上の場合にマッチする条件
     * @type {number}
     * @memberof OwnDepartmentReportFilterConditionMatcher
     */
    moreEqual?: number | null;
    /**
     * 指定した数値以下の場合にマッチする条件
     * @type {number}
     * @memberof OwnDepartmentReportFilterConditionMatcher
     */
    lessEqual?: number | null;
    /**
     * 数値が指定したいずれかと合致する場合にマッチする条件
     * @type {Array<number>}
     * @memberof OwnDepartmentReportFilterConditionMatcher
     */
    _in?: Array<number>;
    /**
     * 数値が指定したいずれも含まない場合にマッチする条件
     * @type {Array<number>}
     * @memberof OwnDepartmentReportFilterConditionMatcher
     */
    notIn?: Array<number>;
}

export function OwnDepartmentReportFilterConditionMatcherFromJSON(json: any): OwnDepartmentReportFilterConditionMatcher {
    return OwnDepartmentReportFilterConditionMatcherFromJSONTyped(json, false);
}

export function OwnDepartmentReportFilterConditionMatcherFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportFilterConditionMatcher {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_exists': !exists(json, 'exists') ? undefined : json['exists'],
        'moreEqual': !exists(json, 'more_equal') ? undefined : json['more_equal'],
        'lessEqual': !exists(json, 'less_equal') ? undefined : json['less_equal'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'not_in') ? undefined : json['not_in'],
    };
}

export function OwnDepartmentReportFilterConditionMatcherToJSON(value?: OwnDepartmentReportFilterConditionMatcher | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exists': value._exists,
        'more_equal': value.moreEqual,
        'less_equal': value.lessEqual,
        'in': value._in,
        'not_in': value.notIn,
    };
}

