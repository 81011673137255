import { FetchError } from '@/src/error/fetchError/FetchError';
import { checkIsCloudApp } from '@/src/utils/checkIsAppEnv';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

export const setupErrorCapture = (): void => {
  if (checkIsCloudApp()) {
    Sentry.init({
      dsn: process.env.SENTRY_KEY,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      denyUrls: ['src/error/fetchError/hooks/useFetchError.ts'],
    });
  }
};

export const errorCapture = (
  err: Error | FetchError,
  errInfo: React.ErrorInfo,
): void => {
  if (checkIsCloudApp()) {
    if (err instanceof FetchError && err.statusCode >= 500) {
      Sentry.configureScope((scope) => {
        scope.setExtra('componentStack', errInfo.componentStack);
      });
      Sentry.captureException(err);
    }
    return;
  }

  console.error({ message: err.message, stack: errInfo.componentStack });
};
