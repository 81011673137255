/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsComparisonResponseReferrals,
    PostChartReferralsComparisonResponseReferralsFromJSON,
    PostChartReferralsComparisonResponseReferralsFromJSONTyped,
    PostChartReferralsComparisonResponseReferralsToJSON,
} from './PostChartReferralsComparisonResponseReferrals';

/**
 * 
 * @export
 * @interface PostChartReferralsComparisonResponse
 */
export interface PostChartReferralsComparisonResponse {
    /**
     * 紹介数比較データ
     * @type {Array<PostChartReferralsComparisonResponseReferrals>}
     * @memberof PostChartReferralsComparisonResponse
     */
    referrals: Array<PostChartReferralsComparisonResponseReferrals>;
}

export function PostChartReferralsComparisonResponseFromJSON(json: any): PostChartReferralsComparisonResponse {
    return PostChartReferralsComparisonResponseFromJSONTyped(json, false);
}

export function PostChartReferralsComparisonResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReferralsComparisonResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrals': ((json['referrals'] as Array<any>).map(PostChartReferralsComparisonResponseReferralsFromJSON)),
    };
}

export function PostChartReferralsComparisonResponseToJSON(value?: PostChartReferralsComparisonResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrals': ((value.referrals as Array<any>).map(PostChartReferralsComparisonResponseReferralsToJSON)),
    };
}

