/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostChartReferralsComparisonAnnualRequest
 */
export interface PostChartReferralsComparisonAnnualRequest {
    /**
     * 診療科目のID (null の場合は全診療科目の合算)
     * @type {Array<string>}
     * @memberof PostChartReferralsComparisonAnnualRequest
     */
    ownDepartmentIds: Array<string> | null;
}

export function PostChartReferralsComparisonAnnualRequestFromJSON(json: any): PostChartReferralsComparisonAnnualRequest {
    return PostChartReferralsComparisonAnnualRequestFromJSONTyped(json, false);
}

export function PostChartReferralsComparisonAnnualRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReferralsComparisonAnnualRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ownDepartmentIds': json['own_department_ids'],
    };
}

export function PostChartReferralsComparisonAnnualRequestToJSON(value?: PostChartReferralsComparisonAnnualRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'own_department_ids': value.ownDepartmentIds,
    };
}

