/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetStaffsResponse,
    GetStaffsResponseFromJSON,
    GetStaffsResponseToJSON,
} from '../models';

export interface GetStaffsRequest {
    includeCsAccounts?: boolean;
    includeInactive?: boolean;
}

/**
 * TenantsApi - interface
 * 
 * @export
 * @interface TenantsApiInterface
 */
export interface TenantsApiInterface {
    /**
     * 
     * @summary 同一テナント内のスタッフ一覧の取得
     * @param {boolean} [includeCsAccounts] CSアカウントを含める場合true
     * @param {boolean} [includeInactive] 削除済みアカウントを含める場合true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApiInterface
     */
    getStaffsRaw(requestParameters: GetStaffsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetStaffsResponse>>;

    /**
     * 同一テナント内のスタッフ一覧の取得
     */
    getStaffs(requestParameters: GetStaffsRequest, initOverrides?: RequestInit): Promise<GetStaffsResponse>;

}

/**
 * 
 */
export class TenantsApi extends runtime.BaseAPI implements TenantsApiInterface {

    /**
     * 同一テナント内のスタッフ一覧の取得
     */
    async getStaffsRaw(requestParameters: GetStaffsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetStaffsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.includeCsAccounts !== undefined) {
            queryParameters['include_cs_accounts'] = requestParameters.includeCsAccounts;
        }

        if (requestParameters.includeInactive !== undefined) {
            queryParameters['include_inactive'] = requestParameters.includeInactive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/staffs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStaffsResponseFromJSON(jsonValue));
    }

    /**
     * 同一テナント内のスタッフ一覧の取得
     */
    async getStaffs(requestParameters: GetStaffsRequest = {}, initOverrides?: RequestInit): Promise<GetStaffsResponse> {
        const response = await this.getStaffsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
