/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnDepartmentReportItemDefinitionPreset,
    OwnDepartmentReportItemDefinitionPresetFromJSON,
    OwnDepartmentReportItemDefinitionPresetFromJSONTyped,
    OwnDepartmentReportItemDefinitionPresetToJSON,
} from './OwnDepartmentReportItemDefinitionPreset';

/**
 * 
 * @export
 * @interface GetOwnDepartmentReportItemDefinitionsResponse
 */
export interface GetOwnDepartmentReportItemDefinitionsResponse {
    /**
     * 診療科レポート項目定義、プリセット項目
     * @type {Array<OwnDepartmentReportItemDefinitionPreset>}
     * @memberof GetOwnDepartmentReportItemDefinitionsResponse
     */
    presets: Array<OwnDepartmentReportItemDefinitionPreset>;
}

export function GetOwnDepartmentReportItemDefinitionsResponseFromJSON(json: any): GetOwnDepartmentReportItemDefinitionsResponse {
    return GetOwnDepartmentReportItemDefinitionsResponseFromJSONTyped(json, false);
}

export function GetOwnDepartmentReportItemDefinitionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOwnDepartmentReportItemDefinitionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presets': ((json['presets'] as Array<any>).map(OwnDepartmentReportItemDefinitionPresetFromJSON)),
    };
}

export function GetOwnDepartmentReportItemDefinitionsResponseToJSON(value?: GetOwnDepartmentReportItemDefinitionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'presets': ((value.presets as Array<any>).map(OwnDepartmentReportItemDefinitionPresetToJSON)),
    };
}

