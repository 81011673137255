/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionParameters,
    AlertConditionParametersFromJSON,
    AlertConditionParametersFromJSONTyped,
    AlertConditionParametersToJSON,
} from './AlertConditionParameters';

/**
 * 
 * @export
 * @interface Alert
 */
export interface Alert {
    /**
     * アラート条件ID
     * @type {number}
     * @memberof Alert
     */
    id: number;
    /**
     * アラート名
     * @type {string}
     * @memberof Alert
     */
    name: string;
    /**
     * アラート条件の要約
     * @type {string}
     * @memberof Alert
     */
    conditionSummary?: string | null;
    /**
     * 
     * @type {AlertConditionParameters}
     * @memberof Alert
     */
    conditionParameters?: AlertConditionParameters | null;
    /**
     * アラート発生日
     * @type {string}
     * @memberof Alert
     */
    alertDate: string;
    /**
     * アラートが出た施設数
     * @type {number}
     * @memberof Alert
     */
    targetCount: number;
    /**
     * アラートがアーカイブ済みであることを判定
     * @type {boolean}
     * @memberof Alert
     */
    isArchived: boolean;
}

export function AlertFromJSON(json: any): Alert {
    return AlertFromJSONTyped(json, false);
}

export function AlertFromJSONTyped(json: any, ignoreDiscriminator: boolean): Alert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'conditionSummary': !exists(json, 'condition_summary') ? undefined : json['condition_summary'],
        'conditionParameters': !exists(json, 'condition_parameters') ? undefined : AlertConditionParametersFromJSON(json['condition_parameters']),
        'alertDate': json['alert_date'],
        'targetCount': json['target_count'],
        'isArchived': json['is_archived'],
    };
}

export function AlertToJSON(value?: Alert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'condition_summary': value.conditionSummary,
        'condition_parameters': AlertConditionParametersToJSON(value.conditionParameters),
        'alert_date': value.alertDate,
        'target_count': value.targetCount,
        'is_archived': value.isArchived,
    };
}

