/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 集計設定での日付項目の集計方法
 * @export
 * @enum {string}
 */
export enum CommunicationReportDefinitionAggregationDateGroupingEnum {
    Month = 'month',
    FiscalYear = 'fiscal_year'
}

export function CommunicationReportDefinitionAggregationDateGroupingEnumFromJSON(json: any): CommunicationReportDefinitionAggregationDateGroupingEnum {
    return CommunicationReportDefinitionAggregationDateGroupingEnumFromJSONTyped(json, false);
}

export function CommunicationReportDefinitionAggregationDateGroupingEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportDefinitionAggregationDateGroupingEnum {
    return json as CommunicationReportDefinitionAggregationDateGroupingEnum;
}

export function CommunicationReportDefinitionAggregationDateGroupingEnumToJSON(value?: CommunicationReportDefinitionAggregationDateGroupingEnum | null): any {
    return value as any;
}

