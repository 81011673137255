/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportItemAggregationCondition,
    MedicalInstitutionReportItemAggregationConditionFromJSON,
    MedicalInstitutionReportItemAggregationConditionFromJSONTyped,
    MedicalInstitutionReportItemAggregationConditionToJSON,
} from './MedicalInstitutionReportItemAggregationCondition';
import {
    MedicalInstitutionReportItemEnabledAggregationMethodEnum,
    MedicalInstitutionReportItemEnabledAggregationMethodEnumFromJSON,
    MedicalInstitutionReportItemEnabledAggregationMethodEnumFromJSONTyped,
    MedicalInstitutionReportItemEnabledAggregationMethodEnumToJSON,
} from './MedicalInstitutionReportItemEnabledAggregationMethodEnum';

/**
 * 連携先レポート項目
 * @export
 * @interface MedicalInstitutionReportItemForFormula
 */
export interface MedicalInstitutionReportItemForFormula {
    /**
     * 連携先レポート項目定義を一意に特定するキー名
     * @type {string}
     * @memberof MedicalInstitutionReportItemForFormula
     */
    key: string;
    /**
     * 
     * @type {MedicalInstitutionReportItemEnabledAggregationMethodEnum}
     * @memberof MedicalInstitutionReportItemForFormula
     */
    aggregationMethod?: MedicalInstitutionReportItemEnabledAggregationMethodEnum;
    /**
     * 
     * @type {MedicalInstitutionReportItemAggregationCondition}
     * @memberof MedicalInstitutionReportItemForFormula
     */
    aggregationConditions?: MedicalInstitutionReportItemAggregationCondition;
}

export function MedicalInstitutionReportItemForFormulaFromJSON(json: any): MedicalInstitutionReportItemForFormula {
    return MedicalInstitutionReportItemForFormulaFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemForFormulaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemForFormula {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'aggregationMethod': !exists(json, 'aggregation_method') ? undefined : MedicalInstitutionReportItemEnabledAggregationMethodEnumFromJSON(json['aggregation_method']),
        'aggregationConditions': !exists(json, 'aggregation_conditions') ? undefined : MedicalInstitutionReportItemAggregationConditionFromJSON(json['aggregation_conditions']),
    };
}

export function MedicalInstitutionReportItemForFormulaToJSON(value?: MedicalInstitutionReportItemForFormula | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'aggregation_method': MedicalInstitutionReportItemEnabledAggregationMethodEnumToJSON(value.aggregationMethod),
        'aggregation_conditions': MedicalInstitutionReportItemAggregationConditionToJSON(value.aggregationConditions),
    };
}

