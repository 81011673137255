/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionRunningStatusEnum,
    MedicalInstitutionRunningStatusEnumFromJSON,
    MedicalInstitutionRunningStatusEnumFromJSONTyped,
    MedicalInstitutionRunningStatusEnumToJSON,
} from './MedicalInstitutionRunningStatusEnum';

/**
 * 医療機関
 * @export
 * @interface MedicalInstitutionForAlert
 */
export interface MedicalInstitutionForAlert {
    /**
     * 医療機関ID
     * @type {number}
     * @memberof MedicalInstitutionForAlert
     */
    id: number;
    /**
     * 名前
     * @type {string}
     * @memberof MedicalInstitutionForAlert
     */
    name: string;
    /**
     * 
     * @type {MedicalInstitutionRunningStatusEnum}
     * @memberof MedicalInstitutionForAlert
     */
    runningStatus: MedicalInstitutionRunningStatusEnum;
    /**
     * 住所
     * @type {string}
     * @memberof MedicalInstitutionForAlert
     */
    address: string;
    /**
     * 診療科目一覧
     * @type {Array<string>}
     * @memberof MedicalInstitutionForAlert
     */
    departments: Array<string>;
    /**
     * 最終訪問日
     * @type {string}
     * @memberof MedicalInstitutionForAlert
     */
    lastVisitDate: string;
}

export function MedicalInstitutionForAlertFromJSON(json: any): MedicalInstitutionForAlert {
    return MedicalInstitutionForAlertFromJSONTyped(json, false);
}

export function MedicalInstitutionForAlertFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionForAlert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'runningStatus': MedicalInstitutionRunningStatusEnumFromJSON(json['running_status']),
        'address': json['address'],
        'departments': json['departments'],
        'lastVisitDate': json['last_visit_date'],
    };
}

export function MedicalInstitutionForAlertToJSON(value?: MedicalInstitutionForAlert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'running_status': MedicalInstitutionRunningStatusEnumToJSON(value.runningStatus),
        'address': value.address,
        'departments': value.departments,
        'last_visit_date': value.lastVisitDate,
    };
}

