/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationLabelCondition,
    CommunicationLabelConditionFromJSON,
    CommunicationLabelConditionFromJSONTyped,
    CommunicationLabelConditionToJSON,
} from './CommunicationLabelCondition';
import {
    PostCommunicationsSearchResultsRequestPeriod,
    PostCommunicationsSearchResultsRequestPeriodFromJSON,
    PostCommunicationsSearchResultsRequestPeriodFromJSONTyped,
    PostCommunicationsSearchResultsRequestPeriodToJSON,
} from './PostCommunicationsSearchResultsRequestPeriod';

/**
 * 
 * @export
 * @interface PostCommunicationsSearchResultsRequest
 */
export interface PostCommunicationsSearchResultsRequest {
    /**
     * ページ番号（１始まり）
     * @type {number}
     * @memberof PostCommunicationsSearchResultsRequest
     */
    page: number | null;
    /**
     * １ページあたりの件数
     * @type {number}
     * @memberof PostCommunicationsSearchResultsRequest
     */
    perPage: number | null;
    /**
     * 
     * @type {PostCommunicationsSearchResultsRequestPeriod}
     * @memberof PostCommunicationsSearchResultsRequest
     */
    period: PostCommunicationsSearchResultsRequestPeriod | null;
    /**
     * 医療機関検索キーワード
     * @type {string}
     * @memberof PostCommunicationsSearchResultsRequest
     */
    medicalInstitutionName: string | null;
    /**
     * スタッフID
     * @type {number}
     * @memberof PostCommunicationsSearchResultsRequest
     */
    staffId: number | null;
    /**
     * コミュニケーションタイプ
     * @type {string}
     * @memberof PostCommunicationsSearchResultsRequest
     */
    communicationType: PostCommunicationsSearchResultsRequestCommunicationTypeEnum;
    /**
     * コミュニケーションラベルによる検索条件。指定がない場合は空配列を設定する。
     * @type {Array<CommunicationLabelCondition>}
     * @memberof PostCommunicationsSearchResultsRequest
     */
    communicationLabels: Array<CommunicationLabelCondition>;
}

/**
* @export
* @enum {string}
*/
export enum PostCommunicationsSearchResultsRequestCommunicationTypeEnum {
    All = 'all',
    Visit = 'visit',
    NewVisit = 'new_visit',
    Phone = 'phone',
    Email = 'email',
    PostalItem = 'postal_item',
    EventAttendance = 'event_attendance',
    ReferralAcceptance = 'referral_acceptance',
    RefusedReferral = 'refused_referral',
    Other = 'other'
}

export function PostCommunicationsSearchResultsRequestFromJSON(json: any): PostCommunicationsSearchResultsRequest {
    return PostCommunicationsSearchResultsRequestFromJSONTyped(json, false);
}

export function PostCommunicationsSearchResultsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCommunicationsSearchResultsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'perPage': json['per_page'],
        'period': PostCommunicationsSearchResultsRequestPeriodFromJSON(json['period']),
        'medicalInstitutionName': json['medical_institution_name'],
        'staffId': json['staff_id'],
        'communicationType': json['communication_type'],
        'communicationLabels': ((json['communication_labels'] as Array<any>).map(CommunicationLabelConditionFromJSON)),
    };
}

export function PostCommunicationsSearchResultsRequestToJSON(value?: PostCommunicationsSearchResultsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'per_page': value.perPage,
        'period': PostCommunicationsSearchResultsRequestPeriodToJSON(value.period),
        'medical_institution_name': value.medicalInstitutionName,
        'staff_id': value.staffId,
        'communication_type': value.communicationType,
        'communication_labels': ((value.communicationLabels as Array<any>).map(CommunicationLabelConditionToJSON)),
    };
}

