/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 数値型のラベル検索条件
 * @export
 * @interface IntegerCondition
 */
export interface IntegerCondition {
    /**
     * 数値条件（From）
     * @type {number}
     * @memberof IntegerCondition
     */
    moreEqual?: number | null;
    /**
     * 数値条件（To）
     * @type {number}
     * @memberof IntegerCondition
     */
    lessEqual?: number | null;
}

export function IntegerConditionFromJSON(json: any): IntegerCondition {
    return IntegerConditionFromJSONTyped(json, false);
}

export function IntegerConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegerCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'moreEqual': !exists(json, 'more_equal') ? undefined : json['more_equal'],
        'lessEqual': !exists(json, 'less_equal') ? undefined : json['less_equal'],
    };
}

export function IntegerConditionToJSON(value?: IntegerCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'more_equal': value.moreEqual,
        'less_equal': value.lessEqual,
    };
}

