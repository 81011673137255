/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationReportDefinitionDisplayItem,
    CommunicationReportDefinitionDisplayItemFromJSON,
    CommunicationReportDefinitionDisplayItemFromJSONTyped,
    CommunicationReportDefinitionDisplayItemToJSON,
} from './CommunicationReportDefinitionDisplayItem';
import {
    CommunicationReportDefinitionFilterCondition,
    CommunicationReportDefinitionFilterConditionFromJSON,
    CommunicationReportDefinitionFilterConditionFromJSONTyped,
    CommunicationReportDefinitionFilterConditionToJSON,
} from './CommunicationReportDefinitionFilterCondition';
import {
    CommunicationReportDefinitionSortCondition,
    CommunicationReportDefinitionSortConditionFromJSON,
    CommunicationReportDefinitionSortConditionFromJSONTyped,
    CommunicationReportDefinitionSortConditionToJSON,
} from './CommunicationReportDefinitionSortCondition';
import {
    CommunicationReportItem,
    CommunicationReportItemFromJSON,
    CommunicationReportItemFromJSONTyped,
    CommunicationReportItemToJSON,
} from './CommunicationReportItem';
import {
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
} from './CommunicationType';

/**
 * 
 * @export
 * @interface PostCommunicationReportResultsRequest
 */
export interface PostCommunicationReportResultsRequest {
    /**
     * 
     * @type {CommunicationType}
     * @memberof PostCommunicationReportResultsRequest
     */
    communicationType: CommunicationType;
    /**
     * レポート項目リスト
     * @type {Array<CommunicationReportItem>}
     * @memberof PostCommunicationReportResultsRequest
     */
    items: Array<CommunicationReportItem>;
    /**
     * レポート項目の表示設定リスト
     * @type {Array<CommunicationReportDefinitionDisplayItem>}
     * @memberof PostCommunicationReportResultsRequest
     */
    displayItems: Array<CommunicationReportDefinitionDisplayItem>;
    /**
     * 絞り込み条件リスト
     * @type {Array<CommunicationReportDefinitionFilterCondition>}
     * @memberof PostCommunicationReportResultsRequest
     */
    filterConditions: Array<CommunicationReportDefinitionFilterCondition>;
    /**
     * 並び替え条件リスト
     * @type {Array<CommunicationReportDefinitionSortCondition>}
     * @memberof PostCommunicationReportResultsRequest
     */
    sortConditions: Array<CommunicationReportDefinitionSortCondition>;
    /**
     * ページ番号。1から始まる。
     * @type {number}
     * @memberof PostCommunicationReportResultsRequest
     */
    page: number;
    /**
     * 1ページあたりの表示件数。
     * @type {number}
     * @memberof PostCommunicationReportResultsRequest
     */
    perPage: number;
}

export function PostCommunicationReportResultsRequestFromJSON(json: any): PostCommunicationReportResultsRequest {
    return PostCommunicationReportResultsRequestFromJSONTyped(json, false);
}

export function PostCommunicationReportResultsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCommunicationReportResultsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communicationType': CommunicationTypeFromJSON(json['communication_type']),
        'items': ((json['items'] as Array<any>).map(CommunicationReportItemFromJSON)),
        'displayItems': ((json['display_items'] as Array<any>).map(CommunicationReportDefinitionDisplayItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(CommunicationReportDefinitionFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(CommunicationReportDefinitionSortConditionFromJSON)),
        'page': json['page'],
        'perPage': json['per_page'],
    };
}

export function PostCommunicationReportResultsRequestToJSON(value?: PostCommunicationReportResultsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communication_type': CommunicationTypeToJSON(value.communicationType),
        'items': ((value.items as Array<any>).map(CommunicationReportItemToJSON)),
        'display_items': ((value.displayItems as Array<any>).map(CommunicationReportDefinitionDisplayItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(CommunicationReportDefinitionFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(CommunicationReportDefinitionSortConditionToJSON)),
        'page': value.page,
        'per_page': value.perPage,
    };
}

