/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsComparisonMonthlyResponseReferrals,
    PostChartReferralsComparisonMonthlyResponseReferralsFromJSON,
    PostChartReferralsComparisonMonthlyResponseReferralsFromJSONTyped,
    PostChartReferralsComparisonMonthlyResponseReferralsToJSON,
} from './PostChartReferralsComparisonMonthlyResponseReferrals';

/**
 * 
 * @export
 * @interface PostChartReferralsComparisonMonthlyResponse
 */
export interface PostChartReferralsComparisonMonthlyResponse {
    /**
     * 紹介数月次比較データ
     * @type {Array<PostChartReferralsComparisonMonthlyResponseReferrals>}
     * @memberof PostChartReferralsComparisonMonthlyResponse
     */
    referrals: Array<PostChartReferralsComparisonMonthlyResponseReferrals>;
}

export function PostChartReferralsComparisonMonthlyResponseFromJSON(json: any): PostChartReferralsComparisonMonthlyResponse {
    return PostChartReferralsComparisonMonthlyResponseFromJSONTyped(json, false);
}

export function PostChartReferralsComparisonMonthlyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReferralsComparisonMonthlyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrals': ((json['referrals'] as Array<any>).map(PostChartReferralsComparisonMonthlyResponseReferralsFromJSON)),
    };
}

export function PostChartReferralsComparisonMonthlyResponseToJSON(value?: PostChartReferralsComparisonMonthlyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrals': ((value.referrals as Array<any>).map(PostChartReferralsComparisonMonthlyResponseReferralsToJSON)),
    };
}

