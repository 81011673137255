/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 値のフォーマット (フロント側のフォーマットのため必要だったが、現在は利用されていない)
 * @export
 * @enum {string}
 */
export enum MedicalInstitutionReportItemDefinitionFormatEnum {
    String = 'string',
    Integer = 'integer',
    Percentage = 'percentage',
    Date = 'date',
    Name = 'name',
    Address = 'address',
    Url = 'url',
    FacilityTypes = 'facility_types',
    Specialties = 'specialties',
    Tags = 'tags',
    ReferralAdmissionSegments = 'referral_admission_segments',
    SegmentId = 'segment_id',
    Nps = 'nps',
    Formula = 'formula',
    MedicalInstitutionLabelSelection = 'medical_institution_label_selection',
    MedicalInstitutionLabelArea = 'medical_institution_label_area'
}

export function MedicalInstitutionReportItemDefinitionFormatEnumFromJSON(json: any): MedicalInstitutionReportItemDefinitionFormatEnum {
    return MedicalInstitutionReportItemDefinitionFormatEnumFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemDefinitionFormatEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemDefinitionFormatEnum {
    return json as MedicalInstitutionReportItemDefinitionFormatEnum;
}

export function MedicalInstitutionReportItemDefinitionFormatEnumToJSON(value?: MedicalInstitutionReportItemDefinitionFormatEnum | null): any {
    return value as any;
}

