/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferralDetail,
    ReferralDetailFromJSON,
    ReferralDetailFromJSONTyped,
    ReferralDetailToJSON,
} from './ReferralDetail';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionReferralsResponse
 */
export interface PostMedicalInstitutionReferralsResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof PostMedicalInstitutionReferralsResponse
     */
    totalPage: number;
    /**
     * 一覧の総要素数
     * @type {number}
     * @memberof PostMedicalInstitutionReferralsResponse
     */
    totalCount: number;
    /**
     * 紹介の一覧
     * @type {Array<ReferralDetail>}
     * @memberof PostMedicalInstitutionReferralsResponse
     */
    referrals: Array<ReferralDetail>;
}

export function PostMedicalInstitutionReferralsResponseFromJSON(json: any): PostMedicalInstitutionReferralsResponse {
    return PostMedicalInstitutionReferralsResponseFromJSONTyped(json, false);
}

export function PostMedicalInstitutionReferralsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionReferralsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'referrals': ((json['referrals'] as Array<any>).map(ReferralDetailFromJSON)),
    };
}

export function PostMedicalInstitutionReferralsResponseToJSON(value?: PostMedicalInstitutionReferralsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'referrals': ((value.referrals as Array<any>).map(ReferralDetailToJSON)),
    };
}

