/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * 
 * @export
 * @interface OwnDepartmentReportDefinition
 */
export interface OwnDepartmentReportDefinition {
    /**
     * レポートID
     * @type {number}
     * @memberof OwnDepartmentReportDefinition
     */
    id: number;
    /**
     * レポート名
     * @type {string}
     * @memberof OwnDepartmentReportDefinition
     */
    name: string;
    /**
     * 
     * @type {NullableStaff}
     * @memberof OwnDepartmentReportDefinition
     */
    createdBy: NullableStaff | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof OwnDepartmentReportDefinition
     */
    createdAt: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof OwnDepartmentReportDefinition
     */
    updatedAt: string;
}

export function OwnDepartmentReportDefinitionFromJSON(json: any): OwnDepartmentReportDefinition {
    return OwnDepartmentReportDefinitionFromJSONTyped(json, false);
}

export function OwnDepartmentReportDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'createdBy': NullableStaffFromJSON(json['created_by']),
        'createdAt': json['created_at'],
        'updatedAt': json['updated_at'],
    };
}

export function OwnDepartmentReportDefinitionToJSON(value?: OwnDepartmentReportDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'created_by': NullableStaffToJSON(value.createdBy),
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
    };
}

