/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionForAlert,
    MedicalInstitutionForAlertFromJSON,
    MedicalInstitutionForAlertFromJSONTyped,
    MedicalInstitutionForAlertToJSON,
} from './MedicalInstitutionForAlert';

/**
 * 
 * @export
 * @interface GetAlertMedicalInstitutionsResponse
 */
export interface GetAlertMedicalInstitutionsResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof GetAlertMedicalInstitutionsResponse
     */
    totalPage: number;
    /**
     * 一覧の総要素数
     * @type {number}
     * @memberof GetAlertMedicalInstitutionsResponse
     */
    totalCount: number;
    /**
     * 医療機関の一覧
     * @type {Array<MedicalInstitutionForAlert>}
     * @memberof GetAlertMedicalInstitutionsResponse
     */
    medicalInstitutions: Array<MedicalInstitutionForAlert>;
}

export function GetAlertMedicalInstitutionsResponseFromJSON(json: any): GetAlertMedicalInstitutionsResponse {
    return GetAlertMedicalInstitutionsResponseFromJSONTyped(json, false);
}

export function GetAlertMedicalInstitutionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAlertMedicalInstitutionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'medicalInstitutions': ((json['medical_institutions'] as Array<any>).map(MedicalInstitutionForAlertFromJSON)),
    };
}

export function GetAlertMedicalInstitutionsResponseToJSON(value?: GetAlertMedicalInstitutionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'medical_institutions': ((value.medicalInstitutions as Array<any>).map(MedicalInstitutionForAlertToJSON)),
    };
}

