/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 紹介
 * @export
 * @interface NullableReferral
 */
export interface NullableReferral {
    /**
     * 紹介ごとに割り当てられた固有のID
     * @type {string}
     * @memberof NullableReferral
     */
    id: string | null;
    /**
     * 紹介日
     * @type {string}
     * @memberof NullableReferral
     */
    referralDate: string | null;
    /**
     * 紹介先医療機関のID
     * @type {number}
     * @memberof NullableReferral
     */
    medicalInstitutionIdTo: number | null;
    /**
     * 初再診フラグ
     * `first_visit`, `return_visit`
     * @type {string}
     * @memberof NullableReferral
     */
    firstVisitFlag: NullableReferralFirstVisitFlagEnum;
}

/**
* @export
* @enum {string}
*/
export enum NullableReferralFirstVisitFlagEnum {
    FirstVisit = 'first_visit',
    ReturnVisit = 'return_visit'
}

export function NullableReferralFromJSON(json: any): NullableReferral {
    return NullableReferralFromJSONTyped(json, false);
}

export function NullableReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): NullableReferral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'referralDate': json['referral_date'],
        'medicalInstitutionIdTo': json['medical_institution_id_to'],
        'firstVisitFlag': json['first_visit_flag'],
    };
}

export function NullableReferralToJSON(value?: NullableReferral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'referral_date': value.referralDate,
        'medical_institution_id_to': value.medicalInstitutionIdTo,
        'first_visit_flag': value.firstVisitFlag,
    };
}

