/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertParameterSubParameters
 */
export interface AlertParameterSubParameters {
    /**
     * サブパラメータのタイプ
     * @type {string}
     * @memberof AlertParameterSubParameters
     */
    subType: AlertParameterSubParametersSubTypeEnum;
    /**
     * サブパラメータのタイトル
     * @type {string}
     * @memberof AlertParameterSubParameters
     */
    subKey: string;
    /**
     * サブパラメータの単一値
     * @type {string}
     * @memberof AlertParameterSubParameters
     */
    subValue?: string;
    /**
     * サブパラメータの配列値
     * @type {Array<string>}
     * @memberof AlertParameterSubParameters
     */
    subValues?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum AlertParameterSubParametersSubTypeEnum {
    Value = 'sub_value',
    Values = 'sub_values'
}

export function AlertParameterSubParametersFromJSON(json: any): AlertParameterSubParameters {
    return AlertParameterSubParametersFromJSONTyped(json, false);
}

export function AlertParameterSubParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertParameterSubParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subType': json['sub_type'],
        'subKey': json['sub_key'],
        'subValue': !exists(json, 'sub_value') ? undefined : json['sub_value'],
        'subValues': !exists(json, 'sub_values') ? undefined : json['sub_values'],
    };
}

export function AlertParameterSubParametersToJSON(value?: AlertParameterSubParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sub_type': value.subType,
        'sub_key': value.subKey,
        'sub_value': value.subValue,
        'sub_values': value.subValues,
    };
}

