/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetOwnDepartmentsResponseDepartments,
    GetOwnDepartmentsResponseDepartmentsFromJSON,
    GetOwnDepartmentsResponseDepartmentsFromJSONTyped,
    GetOwnDepartmentsResponseDepartmentsToJSON,
} from './GetOwnDepartmentsResponseDepartments';

/**
 * 
 * @export
 * @interface GetOwnDepartmentsResponse
 */
export interface GetOwnDepartmentsResponse {
    /**
     * 
     * @type {Array<GetOwnDepartmentsResponseDepartments>}
     * @memberof GetOwnDepartmentsResponse
     */
    departments: Array<GetOwnDepartmentsResponseDepartments>;
}

export function GetOwnDepartmentsResponseFromJSON(json: any): GetOwnDepartmentsResponse {
    return GetOwnDepartmentsResponseFromJSONTyped(json, false);
}

export function GetOwnDepartmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOwnDepartmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'departments': ((json['departments'] as Array<any>).map(GetOwnDepartmentsResponseDepartmentsFromJSON)),
    };
}

export function GetOwnDepartmentsResponseToJSON(value?: GetOwnDepartmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'departments': ((value.departments as Array<any>).map(GetOwnDepartmentsResponseDepartmentsToJSON)),
    };
}

