/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostNewAlertConditionTriggersCommunicationVisit,
    PostNewAlertConditionTriggersCommunicationVisitFromJSON,
    PostNewAlertConditionTriggersCommunicationVisitFromJSONTyped,
    PostNewAlertConditionTriggersCommunicationVisitToJSON,
} from './PostNewAlertConditionTriggersCommunicationVisit';
import {
    PostNewAlertConditionTriggersReferralAverage,
    PostNewAlertConditionTriggersReferralAverageFromJSON,
    PostNewAlertConditionTriggersReferralAverageFromJSONTyped,
    PostNewAlertConditionTriggersReferralAverageToJSON,
} from './PostNewAlertConditionTriggersReferralAverage';
import {
    PostNewAlertConditionTriggersReferralChangeCount,
    PostNewAlertConditionTriggersReferralChangeCountFromJSON,
    PostNewAlertConditionTriggersReferralChangeCountFromJSONTyped,
    PostNewAlertConditionTriggersReferralChangeCountToJSON,
} from './PostNewAlertConditionTriggersReferralChangeCount';
import {
    PostNewAlertConditionTriggersReferralChangeRate,
    PostNewAlertConditionTriggersReferralChangeRateFromJSON,
    PostNewAlertConditionTriggersReferralChangeRateFromJSONTyped,
    PostNewAlertConditionTriggersReferralChangeRateToJSON,
} from './PostNewAlertConditionTriggersReferralChangeRate';
import {
    PostNewAlertConditionTriggersReferralCount,
    PostNewAlertConditionTriggersReferralCountFromJSON,
    PostNewAlertConditionTriggersReferralCountFromJSONTyped,
    PostNewAlertConditionTriggersReferralCountToJSON,
} from './PostNewAlertConditionTriggersReferralCount';

/**
 * type に対応したオブジェクトに値を格納する
 * @export
 * @interface PostNewAlertConditionTriggers
 */
export interface PostNewAlertConditionTriggers {
    /**
     * 
     * @type {PostNewAlertConditionTriggersReferralChangeCount}
     * @memberof PostNewAlertConditionTriggers
     */
    referralChangeCount?: PostNewAlertConditionTriggersReferralChangeCount;
    /**
     * 
     * @type {PostNewAlertConditionTriggersReferralChangeRate}
     * @memberof PostNewAlertConditionTriggers
     */
    referralChangeRate?: PostNewAlertConditionTriggersReferralChangeRate;
    /**
     * 
     * @type {PostNewAlertConditionTriggersReferralCount}
     * @memberof PostNewAlertConditionTriggers
     */
    referralCount?: PostNewAlertConditionTriggersReferralCount;
    /**
     * 
     * @type {PostNewAlertConditionTriggersReferralAverage}
     * @memberof PostNewAlertConditionTriggers
     */
    referralAverage?: PostNewAlertConditionTriggersReferralAverage;
    /**
     * 
     * @type {PostNewAlertConditionTriggersCommunicationVisit}
     * @memberof PostNewAlertConditionTriggers
     */
    communicationVisit?: PostNewAlertConditionTriggersCommunicationVisit;
}

export function PostNewAlertConditionTriggersFromJSON(json: any): PostNewAlertConditionTriggers {
    return PostNewAlertConditionTriggersFromJSONTyped(json, false);
}

export function PostNewAlertConditionTriggersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostNewAlertConditionTriggers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referralChangeCount': !exists(json, 'referral_change_count') ? undefined : PostNewAlertConditionTriggersReferralChangeCountFromJSON(json['referral_change_count']),
        'referralChangeRate': !exists(json, 'referral_change_rate') ? undefined : PostNewAlertConditionTriggersReferralChangeRateFromJSON(json['referral_change_rate']),
        'referralCount': !exists(json, 'referral_count') ? undefined : PostNewAlertConditionTriggersReferralCountFromJSON(json['referral_count']),
        'referralAverage': !exists(json, 'referral_average') ? undefined : PostNewAlertConditionTriggersReferralAverageFromJSON(json['referral_average']),
        'communicationVisit': !exists(json, 'communication_visit') ? undefined : PostNewAlertConditionTriggersCommunicationVisitFromJSON(json['communication_visit']),
    };
}

export function PostNewAlertConditionTriggersToJSON(value?: PostNewAlertConditionTriggers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referral_change_count': PostNewAlertConditionTriggersReferralChangeCountToJSON(value.referralChangeCount),
        'referral_change_rate': PostNewAlertConditionTriggersReferralChangeRateToJSON(value.referralChangeRate),
        'referral_count': PostNewAlertConditionTriggersReferralCountToJSON(value.referralCount),
        'referral_average': PostNewAlertConditionTriggersReferralAverageToJSON(value.referralAverage),
        'communication_visit': PostNewAlertConditionTriggersCommunicationVisitToJSON(value.communicationVisit),
    };
}

