/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostChartReferralsRequestPeriod
 */
export interface PostChartReferralsRequestPeriod {
    /**
     * 紹介を受けた日付の始まり
     * @type {string}
     * @memberof PostChartReferralsRequestPeriod
     */
    startDate: string;
    /**
     * 紹介を受けた日付の終わり
     * @type {string}
     * @memberof PostChartReferralsRequestPeriod
     */
    endDate: string;
}

export function PostChartReferralsRequestPeriodFromJSON(json: any): PostChartReferralsRequestPeriod {
    return PostChartReferralsRequestPeriodFromJSONTyped(json, false);
}

export function PostChartReferralsRequestPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReferralsRequestPeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': json['start_date'],
        'endDate': json['end_date'],
    };
}

export function PostChartReferralsRequestPeriodToJSON(value?: PostChartReferralsRequestPeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': value.startDate,
        'end_date': value.endDate,
    };
}

