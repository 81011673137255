/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 医療機関ラベル選択肢
 * @export
 * @interface MedicalInstitutionLabelSelection
 */
export interface MedicalInstitutionLabelSelection {
    /**
     * 医療機関ラベル選択肢ID
     * @type {number}
     * @memberof MedicalInstitutionLabelSelection
     */
    id: number;
    /**
     * 表示名
     * @type {string}
     * @memberof MedicalInstitutionLabelSelection
     */
    label: string;
    /**
     * 表示順
     * @type {number}
     * @memberof MedicalInstitutionLabelSelection
     */
    order?: number;
}

export function MedicalInstitutionLabelSelectionFromJSON(json: any): MedicalInstitutionLabelSelection {
    return MedicalInstitutionLabelSelectionFromJSONTyped(json, false);
}

export function MedicalInstitutionLabelSelectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionLabelSelection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function MedicalInstitutionLabelSelectionToJSON(value?: MedicalInstitutionLabelSelection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'order': value.order,
    };
}

