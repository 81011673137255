import styles from './styles.module.scss';

import { CircularProgress } from '@mui/material';

type Size = 'small' | 'medium';

const sizeMap: Record<Size, number> = {
  small: 30,
  medium: 53.87,
};

type Props = {
  size?: Size;
};

export const LoadingCircle: React.FC<Props> = (props) => {
  const { size = 'medium' } = props;

  return <CircularProgress size={sizeMap[size]} className={styles.base} />;
};
