/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionLabelAreaAddress,
    MedicalInstitutionLabelAreaAddressFromJSON,
    MedicalInstitutionLabelAreaAddressFromJSONTyped,
    MedicalInstitutionLabelAreaAddressToJSON,
} from './MedicalInstitutionLabelAreaAddress';

/**
 * 医療機関ラベルエリア設定
 * @export
 * @interface MedicalInstitutionLabelArea
 */
export interface MedicalInstitutionLabelArea {
    /**
     * 医療機関ラベルエリアID
     * @type {number}
     * @memberof MedicalInstitutionLabelArea
     */
    id: number;
    /**
     * エリア名
     * @type {string}
     * @memberof MedicalInstitutionLabelArea
     */
    name: string;
    /**
     * 住所リスト（住所がない場合は空配列を返します）
     * @type {Array<MedicalInstitutionLabelAreaAddress>}
     * @memberof MedicalInstitutionLabelArea
     */
    addresses: Array<MedicalInstitutionLabelAreaAddress>;
}

export function MedicalInstitutionLabelAreaFromJSON(json: any): MedicalInstitutionLabelArea {
    return MedicalInstitutionLabelAreaFromJSONTyped(json, false);
}

export function MedicalInstitutionLabelAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionLabelArea {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'addresses': ((json['addresses'] as Array<any>).map(MedicalInstitutionLabelAreaAddressFromJSON)),
    };
}

export function MedicalInstitutionLabelAreaToJSON(value?: MedicalInstitutionLabelArea | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'addresses': ((value.addresses as Array<any>).map(MedicalInstitutionLabelAreaAddressToJSON)),
    };
}

