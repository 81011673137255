/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferralAdmissionSegment,
    ReferralAdmissionSegmentFromJSON,
    ReferralAdmissionSegmentFromJSONTyped,
    ReferralAdmissionSegmentToJSON,
} from './ReferralAdmissionSegment';

/**
 * 
 * @export
 * @interface GetReferralAdmissionSegmentsResponse
 */
export interface GetReferralAdmissionSegmentsResponse {
    /**
     * 
     * @type {Array<ReferralAdmissionSegment>}
     * @memberof GetReferralAdmissionSegmentsResponse
     */
    referralAdmissionSegments: Array<ReferralAdmissionSegment>;
}

export function GetReferralAdmissionSegmentsResponseFromJSON(json: any): GetReferralAdmissionSegmentsResponse {
    return GetReferralAdmissionSegmentsResponseFromJSONTyped(json, false);
}

export function GetReferralAdmissionSegmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReferralAdmissionSegmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referralAdmissionSegments': ((json['referral_admission_segments'] as Array<any>).map(ReferralAdmissionSegmentFromJSON)),
    };
}

export function GetReferralAdmissionSegmentsResponseToJSON(value?: GetReferralAdmissionSegmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referral_admission_segments': ((value.referralAdmissionSegments as Array<any>).map(ReferralAdmissionSegmentToJSON)),
    };
}

