/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum,
    MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumFromJSON,
    MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumFromJSONTyped,
    MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumToJSON,
} from './MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum';
import {
    MedicalInstitutionReportItemDefinitionEnabledFiltersEnum,
    MedicalInstitutionReportItemDefinitionEnabledFiltersEnumFromJSON,
    MedicalInstitutionReportItemDefinitionEnabledFiltersEnumFromJSONTyped,
    MedicalInstitutionReportItemDefinitionEnabledFiltersEnumToJSON,
} from './MedicalInstitutionReportItemDefinitionEnabledFiltersEnum';
import {
    MedicalInstitutionReportItemDefinitionFormatEnum,
    MedicalInstitutionReportItemDefinitionFormatEnumFromJSON,
    MedicalInstitutionReportItemDefinitionFormatEnumFromJSONTyped,
    MedicalInstitutionReportItemDefinitionFormatEnumToJSON,
} from './MedicalInstitutionReportItemDefinitionFormatEnum';
import {
    MedicalInstitutionReportItemDefinitionValueTypeEnum,
    MedicalInstitutionReportItemDefinitionValueTypeEnumFromJSON,
    MedicalInstitutionReportItemDefinitionValueTypeEnumFromJSONTyped,
    MedicalInstitutionReportItemDefinitionValueTypeEnumToJSON,
} from './MedicalInstitutionReportItemDefinitionValueTypeEnum';

/**
 * 連携先レポート項目（医療機関ラベル項目）定義
 * @export
 * @interface MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel
 */
export interface MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel {
    /**
     * クライアントでタグ付きユニオンとして扱うためのタグ
     * @type {string}
     * @memberof MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel
     */
    type: MedicalInstitutionReportItemDefinitionMedicalInstitutionLabelTypeEnum;
    /**
     * 連携先レポート項目定義を一意に特定するキー名
     * @type {string}
     * @memberof MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel
     */
    key: string;
    /**
     * 医療機関ラベルID
     * @type {number}
     * @memberof MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel
     */
    medicalInstitutionLabelId: number;
    /**
     * 項目ラベル
     * @type {string}
     * @memberof MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel
     */
    label: string;
    /**
     * 
     * @type {MedicalInstitutionReportItemDefinitionValueTypeEnum}
     * @memberof MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel
     */
    valueType: MedicalInstitutionReportItemDefinitionValueTypeEnum;
    /**
     * 
     * @type {MedicalInstitutionReportItemDefinitionFormatEnum}
     * @memberof MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel
     */
    format: MedicalInstitutionReportItemDefinitionFormatEnum;
    /**
     * この項目で利用可能なフィルタ条件
     * @type {Array<MedicalInstitutionReportItemDefinitionEnabledFiltersEnum>}
     * @memberof MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel
     */
    enabledFilters: Array<MedicalInstitutionReportItemDefinitionEnabledFiltersEnum>;
    /**
     * この項目で利用可能な集計指定
     * @type {Array<MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum>}
     * @memberof MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel
     */
    enabledAggregations?: Array<MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum> | null;
}

/**
* @export
* @enum {string}
*/
export enum MedicalInstitutionReportItemDefinitionMedicalInstitutionLabelTypeEnum {
    MedicalInstitutionLabel = 'medical_institution_label'
}

export function MedicalInstitutionReportItemDefinitionMedicalInstitutionLabelFromJSON(json: any): MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel {
    return MedicalInstitutionReportItemDefinitionMedicalInstitutionLabelFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemDefinitionMedicalInstitutionLabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'key': json['key'],
        'medicalInstitutionLabelId': json['medical_institution_label_id'],
        'label': json['label'],
        'valueType': MedicalInstitutionReportItemDefinitionValueTypeEnumFromJSON(json['value_type']),
        'format': MedicalInstitutionReportItemDefinitionFormatEnumFromJSON(json['format']),
        'enabledFilters': ((json['enabled_filters'] as Array<any>).map(MedicalInstitutionReportItemDefinitionEnabledFiltersEnumFromJSON)),
        'enabledAggregations': !exists(json, 'enabled_aggregations') ? undefined : (json['enabled_aggregations'] === null ? null : (json['enabled_aggregations'] as Array<any>).map(MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumFromJSON)),
    };
}

export function MedicalInstitutionReportItemDefinitionMedicalInstitutionLabelToJSON(value?: MedicalInstitutionReportItemDefinitionMedicalInstitutionLabel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'key': value.key,
        'medical_institution_label_id': value.medicalInstitutionLabelId,
        'label': value.label,
        'value_type': MedicalInstitutionReportItemDefinitionValueTypeEnumToJSON(value.valueType),
        'format': MedicalInstitutionReportItemDefinitionFormatEnumToJSON(value.format),
        'enabled_filters': ((value.enabledFilters as Array<any>).map(MedicalInstitutionReportItemDefinitionEnabledFiltersEnumToJSON)),
        'enabled_aggregations': value.enabledAggregations === undefined ? undefined : (value.enabledAggregations === null ? null : (value.enabledAggregations as Array<any>).map(MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumToJSON)),
    };
}

