/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * いいね
 * @export
 * @interface CommunicationLike
 */
export interface CommunicationLike {
    /**
     * 
     * @type {NullableStaff}
     * @memberof CommunicationLike
     */
    user: NullableStaff | null;
}

export function CommunicationLikeFromJSON(json: any): CommunicationLike {
    return CommunicationLikeFromJSONTyped(json, false);
}

export function CommunicationLikeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLike {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': NullableStaffFromJSON(json['user']),
    };
}

export function CommunicationLikeToJSON(value?: CommunicationLike | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': NullableStaffToJSON(value.user),
    };
}

