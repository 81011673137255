/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MedicalInstitutionReportItemDefinitionEnabledFiltersEnum {
    StartWith = 'start_with',
    Contain = 'contain',
    NotContain = 'not_contain',
    Exists = 'exists',
    MoreEqual = 'more_equal',
    LessEqual = 'less_equal',
    NotIn = 'not_in',
    In = 'in',
    HaveAny = 'have_any',
    HaveAll = 'have_all',
    NotHaveAll = 'not_have_all',
    StartMonth = 'start_month',
    EndMonth = 'end_month',
    StringIn = 'string_in'
}

export function MedicalInstitutionReportItemDefinitionEnabledFiltersEnumFromJSON(json: any): MedicalInstitutionReportItemDefinitionEnabledFiltersEnum {
    return MedicalInstitutionReportItemDefinitionEnabledFiltersEnumFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemDefinitionEnabledFiltersEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemDefinitionEnabledFiltersEnum {
    return json as MedicalInstitutionReportItemDefinitionEnabledFiltersEnum;
}

export function MedicalInstitutionReportItemDefinitionEnabledFiltersEnumToJSON(value?: MedicalInstitutionReportItemDefinitionEnabledFiltersEnum | null): any {
    return value as any;
}

