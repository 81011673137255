/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 集計設定：行・列
 * @export
 * @interface MedicalInstitutionReportDefinitionAggregationAxis
 */
export interface MedicalInstitutionReportDefinitionAggregationAxis {
    /**
     * 集計対象の項目のキー
     * @type {string}
     * @memberof MedicalInstitutionReportDefinitionAggregationAxis
     */
    itemKey: string;
}

export function MedicalInstitutionReportDefinitionAggregationAxisFromJSON(json: any): MedicalInstitutionReportDefinitionAggregationAxis {
    return MedicalInstitutionReportDefinitionAggregationAxisFromJSONTyped(json, false);
}

export function MedicalInstitutionReportDefinitionAggregationAxisFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportDefinitionAggregationAxis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemKey': json['item_key'],
    };
}

export function MedicalInstitutionReportDefinitionAggregationAxisToJSON(value?: MedicalInstitutionReportDefinitionAggregationAxis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_key': value.itemKey,
    };
}

