/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetSegmentationSettingResponse,
    GetSegmentationSettingResponseFromJSON,
    GetSegmentationSettingResponseToJSON,
} from '../models';

/**
 * SegmentsApi - interface
 * 
 * @export
 * @interface SegmentsApiInterface
 */
export interface SegmentsApiInterface {
    /**
     * テナントに設定されている連携先セグメント設定を取得する 
     * @summary 連携先セグメント取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentsApiInterface
     */
    getSegmentSettingRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetSegmentationSettingResponse>>;

    /**
     * テナントに設定されている連携先セグメント設定を取得する 
     * 連携先セグメント取得
     */
    getSegmentSetting(initOverrides?: RequestInit): Promise<GetSegmentationSettingResponse>;

}

/**
 * 
 */
export class SegmentsApi extends runtime.BaseAPI implements SegmentsApiInterface {

    /**
     * テナントに設定されている連携先セグメント設定を取得する 
     * 連携先セグメント取得
     */
    async getSegmentSettingRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetSegmentationSettingResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/segmentation_setting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSegmentationSettingResponseFromJSON(jsonValue));
    }

    /**
     * テナントに設定されている連携先セグメント設定を取得する 
     * 連携先セグメント取得
     */
    async getSegmentSetting(initOverrides?: RequestInit): Promise<GetSegmentationSettingResponse> {
        const response = await this.getSegmentSettingRaw(initOverrides);
        return await response.value();
    }

}
