/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertConditionTriggersReferralChangeCountOwnDepartments
 */
export interface AlertConditionTriggersReferralChangeCountOwnDepartments {
    /**
     * 自院の診療科目一覧
     * @type {Array<string>}
     * @memberof AlertConditionTriggersReferralChangeCountOwnDepartments
     */
    items: Array<string>;
    /**
     * 無効データの有無
     * @type {boolean}
     * @memberof AlertConditionTriggersReferralChangeCountOwnDepartments
     */
    isInvalid: boolean;
}

export function AlertConditionTriggersReferralChangeCountOwnDepartmentsFromJSON(json: any): AlertConditionTriggersReferralChangeCountOwnDepartments {
    return AlertConditionTriggersReferralChangeCountOwnDepartmentsFromJSONTyped(json, false);
}

export function AlertConditionTriggersReferralChangeCountOwnDepartmentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionTriggersReferralChangeCountOwnDepartments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': json['items'],
        'isInvalid': json['is_invalid'],
    };
}

export function AlertConditionTriggersReferralChangeCountOwnDepartmentsToJSON(value?: AlertConditionTriggersReferralChangeCountOwnDepartments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items,
        'is_invalid': value.isInvalid,
    };
}

