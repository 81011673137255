/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationLabelCommunicationType,
    CommunicationLabelCommunicationTypeFromJSON,
    CommunicationLabelCommunicationTypeFromJSONTyped,
    CommunicationLabelCommunicationTypeToJSON,
} from './CommunicationLabelCommunicationType';
import {
    CommunicationLabelComponentType,
    CommunicationLabelComponentTypeFromJSON,
    CommunicationLabelComponentTypeFromJSONTyped,
    CommunicationLabelComponentTypeToJSON,
} from './CommunicationLabelComponentType';
import {
    CommunicationLabelSelection,
    CommunicationLabelSelectionFromJSON,
    CommunicationLabelSelectionFromJSONTyped,
    CommunicationLabelSelectionToJSON,
} from './CommunicationLabelSelection';

/**
 * コミュニケーションラベルマスタ設定
 * @export
 * @interface CommunicationLabel
 */
export interface CommunicationLabel {
    /**
     * コミュニケーションラベルマスタID
     * @type {number}
     * @memberof CommunicationLabel
     */
    id: number;
    /**
     * 項目名
     * @type {string}
     * @memberof CommunicationLabel
     */
    name: string;
    /**
     * 
     * @type {CommunicationLabelCommunicationType}
     * @memberof CommunicationLabel
     */
    communicationType?: CommunicationLabelCommunicationType;
    /**
     * 
     * @type {CommunicationLabelComponentType}
     * @memberof CommunicationLabel
     */
    componentType: CommunicationLabelComponentType;
    /**
     * 並び順(昇順)
     * @type {number}
     * @memberof CommunicationLabel
     */
    order: number;
    /**
     * 説明
     * @type {string}
     * @memberof CommunicationLabel
     */
    description?: string | null;
    /**
     * 選択肢リスト（コンポーネント種別が'select','radio','checkbox'の場合のみ）
     * @type {Array<CommunicationLabelSelection>}
     * @memberof CommunicationLabel
     */
    selections?: Array<CommunicationLabelSelection> | null;
    /**
     * 必須入力かどうか
     * @type {boolean}
     * @memberof CommunicationLabel
     */
    isRequired: boolean;
}

export function CommunicationLabelFromJSON(json: any): CommunicationLabel {
    return CommunicationLabelFromJSONTyped(json, false);
}

export function CommunicationLabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'communicationType': !exists(json, 'communication_type') ? undefined : CommunicationLabelCommunicationTypeFromJSON(json['communication_type']),
        'componentType': CommunicationLabelComponentTypeFromJSON(json['component_type']),
        'order': json['order'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'selections': !exists(json, 'selections') ? undefined : (json['selections'] === null ? null : (json['selections'] as Array<any>).map(CommunicationLabelSelectionFromJSON)),
        'isRequired': json['is_required'],
    };
}

export function CommunicationLabelToJSON(value?: CommunicationLabel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'communication_type': CommunicationLabelCommunicationTypeToJSON(value.communicationType),
        'component_type': CommunicationLabelComponentTypeToJSON(value.componentType),
        'order': value.order,
        'description': value.description,
        'selections': value.selections === undefined ? undefined : (value.selections === null ? null : (value.selections as Array<any>).map(CommunicationLabelSelectionToJSON)),
        'is_required': value.isRequired,
    };
}

