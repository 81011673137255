import styles from './styles.module.scss';

import clsx from 'clsx';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const Card: React.FC<Props> = (props) => (
  <div className={clsx(styles.base, props.className)}>{props.children}</div>
);
