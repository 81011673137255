/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutCommunicationCommentRequest
 */
export interface PutCommunicationCommentRequest {
    /**
     * コメント
     * @type {string}
     * @memberof PutCommunicationCommentRequest
     */
    comment: string;
}

export function PutCommunicationCommentRequestFromJSON(json: any): PutCommunicationCommentRequest {
    return PutCommunicationCommentRequestFromJSONTyped(json, false);
}

export function PutCommunicationCommentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutCommunicationCommentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': json['comment'],
    };
}

export function PutCommunicationCommentRequestToJSON(value?: PutCommunicationCommentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
    };
}

