/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    GetMedicalInstitutionReportDefinitionsResponse,
    GetMedicalInstitutionReportDefinitionsResponseFromJSON,
    GetMedicalInstitutionReportDefinitionsResponseToJSON,
    GetMedicalInstitutionReportItemDefinitionsResponse,
    GetMedicalInstitutionReportItemDefinitionsResponseFromJSON,
    GetMedicalInstitutionReportItemDefinitionsResponseToJSON,
    MedicalInstitutionReportAlertSetting,
    MedicalInstitutionReportAlertSettingFromJSON,
    MedicalInstitutionReportAlertSettingToJSON,
    MedicalInstitutionReportDefinitionDetail,
    MedicalInstitutionReportDefinitionDetailFromJSON,
    MedicalInstitutionReportDefinitionDetailToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostMedicalInstitutionReportAggregationResultsRequest,
    PostMedicalInstitutionReportAggregationResultsRequestFromJSON,
    PostMedicalInstitutionReportAggregationResultsRequestToJSON,
    PostMedicalInstitutionReportDefinitionRequest,
    PostMedicalInstitutionReportDefinitionRequestFromJSON,
    PostMedicalInstitutionReportDefinitionRequestToJSON,
    PostMedicalInstitutionReportResultsRequest,
    PostMedicalInstitutionReportResultsRequestFromJSON,
    PostMedicalInstitutionReportResultsRequestToJSON,
    PostMedicalInstitutionReportResultsResponse,
    PostMedicalInstitutionReportResultsResponseFromJSON,
    PostMedicalInstitutionReportResultsResponseToJSON,
    PutMedicalInstitutionReportDefinitionRequest,
    PutMedicalInstitutionReportDefinitionRequestFromJSON,
    PutMedicalInstitutionReportDefinitionRequestToJSON,
    ReportAggregationResults,
    ReportAggregationResultsFromJSON,
    ReportAggregationResultsToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteMedicalInstitutionReportDefinitionRequest {
    id: number;
}

export interface GetMedicalInstitutionReportDefinitionRequest {
    id: number;
}

export interface GetMedicalInstitutionReportDefinitionAlertSettingRequest {
    id: number;
}

export interface GetMedicalInstitutionReportDefinitionsRequest {
    sortBy?: GetMedicalInstitutionReportDefinitionsSortByEnum;
}

export interface PostMedicalInstitutionReportAggregationResultsOperationRequest {
    postMedicalInstitutionReportAggregationResultsRequest: PostMedicalInstitutionReportAggregationResultsRequest;
}

export interface PostMedicalInstitutionReportDefinitionOperationRequest {
    postMedicalInstitutionReportDefinitionRequest: PostMedicalInstitutionReportDefinitionRequest;
}

export interface PostMedicalInstitutionReportResultsOperationRequest {
    postMedicalInstitutionReportResultsRequest: PostMedicalInstitutionReportResultsRequest;
}

export interface PutMedicalInstitutionReportDefinitionOperationRequest {
    id: number;
    putMedicalInstitutionReportDefinitionRequest: PutMedicalInstitutionReportDefinitionRequest;
}

export interface PutMedicalInstitutionReportDefinitionAlertSettingRequest {
    id: number;
    medicalInstitutionReportAlertSetting: MedicalInstitutionReportAlertSetting;
}

/**
 * MedicalInstitutionReportsApi - interface
 * 
 * @export
 * @interface MedicalInstitutionReportsApiInterface
 */
export interface MedicalInstitutionReportsApiInterface {
    /**
     * 
     * @summary 連携先レポート定義の削除
     * @param {number} id 連携先レポート定義ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionReportsApiInterface
     */
    deleteMedicalInstitutionReportDefinitionRaw(requestParameters: DeleteMedicalInstitutionReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * 連携先レポート定義の削除
     */
    deleteMedicalInstitutionReportDefinition(requestParameters: DeleteMedicalInstitutionReportDefinitionRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary 連携先レポート定義の取得
     * @param {number} id 連携先レポート定義ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionReportsApiInterface
     */
    getMedicalInstitutionReportDefinitionRaw(requestParameters: GetMedicalInstitutionReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionReportDefinitionDetail>>;

    /**
     * 連携先レポート定義の取得
     */
    getMedicalInstitutionReportDefinition(requestParameters: GetMedicalInstitutionReportDefinitionRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionReportDefinitionDetail>;

    /**
     * 
     * @summary 連携先レポートアラート設定の取得
     * @param {number} id 連携先レポートID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionReportsApiInterface
     */
    getMedicalInstitutionReportDefinitionAlertSettingRaw(requestParameters: GetMedicalInstitutionReportDefinitionAlertSettingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionReportAlertSetting>>;

    /**
     * 連携先レポートアラート設定の取得
     */
    getMedicalInstitutionReportDefinitionAlertSetting(requestParameters: GetMedicalInstitutionReportDefinitionAlertSettingRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionReportAlertSetting>;

    /**
     * 
     * @summary 連携先レポート定義一覧の取得
     * @param {'create_date' | 'alert_date'} [sortBy] 並び替え順
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionReportsApiInterface
     */
    getMedicalInstitutionReportDefinitionsRaw(requestParameters: GetMedicalInstitutionReportDefinitionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionReportDefinitionsResponse>>;

    /**
     * 連携先レポート定義一覧の取得
     */
    getMedicalInstitutionReportDefinitions(requestParameters: GetMedicalInstitutionReportDefinitionsRequest, initOverrides?: RequestInit): Promise<GetMedicalInstitutionReportDefinitionsResponse>;

    /**
     * 
     * @summary 連携先レポート項目マスタ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionReportsApiInterface
     */
    getMedicalInstitutionReportItemDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionReportItemDefinitionsResponse>>;

    /**
     * 連携先レポート項目マスタ一覧
     */
    getMedicalInstitutionReportItemDefinitions(initOverrides?: RequestInit): Promise<GetMedicalInstitutionReportItemDefinitionsResponse>;

    /**
     * 
     * @summary 連携先レポート集計結果生成
     * @param {PostMedicalInstitutionReportAggregationResultsRequest} postMedicalInstitutionReportAggregationResultsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionReportsApiInterface
     */
    postMedicalInstitutionReportAggregationResultsRaw(requestParameters: PostMedicalInstitutionReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReportAggregationResults>>;

    /**
     * 連携先レポート集計結果生成
     */
    postMedicalInstitutionReportAggregationResults(requestParameters: PostMedicalInstitutionReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<ReportAggregationResults>;

    /**
     * 
     * @summary 連携先レポート定義の登録
     * @param {PostMedicalInstitutionReportDefinitionRequest} postMedicalInstitutionReportDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionReportsApiInterface
     */
    postMedicalInstitutionReportDefinitionRaw(requestParameters: PostMedicalInstitutionReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionReportDefinitionDetail>>;

    /**
     * 連携先レポート定義の登録
     */
    postMedicalInstitutionReportDefinition(requestParameters: PostMedicalInstitutionReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionReportDefinitionDetail>;

    /**
     * 
     * @summary 連携先レポート結果生成
     * @param {PostMedicalInstitutionReportResultsRequest} postMedicalInstitutionReportResultsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionReportsApiInterface
     */
    postMedicalInstitutionReportResultsRaw(requestParameters: PostMedicalInstitutionReportResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionReportResultsResponse>>;

    /**
     * 連携先レポート結果生成
     */
    postMedicalInstitutionReportResults(requestParameters: PostMedicalInstitutionReportResultsOperationRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionReportResultsResponse>;

    /**
     * 
     * @summary 連携先レポート定義の更新
     * @param {number} id 連携先レポート定義ID
     * @param {PutMedicalInstitutionReportDefinitionRequest} putMedicalInstitutionReportDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionReportsApiInterface
     */
    putMedicalInstitutionReportDefinitionRaw(requestParameters: PutMedicalInstitutionReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionReportDefinitionDetail>>;

    /**
     * 連携先レポート定義の更新
     */
    putMedicalInstitutionReportDefinition(requestParameters: PutMedicalInstitutionReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionReportDefinitionDetail>;

    /**
     * 
     * @summary 連携先レポートアラート設定の保存
     * @param {number} id 連携先レポートID
     * @param {MedicalInstitutionReportAlertSetting} medicalInstitutionReportAlertSetting 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalInstitutionReportsApiInterface
     */
    putMedicalInstitutionReportDefinitionAlertSettingRaw(requestParameters: PutMedicalInstitutionReportDefinitionAlertSettingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionReportAlertSetting>>;

    /**
     * 連携先レポートアラート設定の保存
     */
    putMedicalInstitutionReportDefinitionAlertSetting(requestParameters: PutMedicalInstitutionReportDefinitionAlertSettingRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionReportAlertSetting>;

}

/**
 * 
 */
export class MedicalInstitutionReportsApi extends runtime.BaseAPI implements MedicalInstitutionReportsApiInterface {

    /**
     * 連携先レポート定義の削除
     */
    async deleteMedicalInstitutionReportDefinitionRaw(requestParameters: DeleteMedicalInstitutionReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMedicalInstitutionReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 連携先レポート定義の削除
     */
    async deleteMedicalInstitutionReportDefinition(requestParameters: DeleteMedicalInstitutionReportDefinitionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteMedicalInstitutionReportDefinitionRaw(requestParameters, initOverrides);
    }

    /**
     * 連携先レポート定義の取得
     */
    async getMedicalInstitutionReportDefinitionRaw(requestParameters: GetMedicalInstitutionReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionReportDefinitionDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMedicalInstitutionReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * 連携先レポート定義の取得
     */
    async getMedicalInstitutionReportDefinition(requestParameters: GetMedicalInstitutionReportDefinitionRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionReportDefinitionDetail> {
        const response = await this.getMedicalInstitutionReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 連携先レポートアラート設定の取得
     */
    async getMedicalInstitutionReportDefinitionAlertSettingRaw(requestParameters: GetMedicalInstitutionReportDefinitionAlertSettingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionReportAlertSetting>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMedicalInstitutionReportDefinitionAlertSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_reports/definitions/{id}/alert_settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionReportAlertSettingFromJSON(jsonValue));
    }

    /**
     * 連携先レポートアラート設定の取得
     */
    async getMedicalInstitutionReportDefinitionAlertSetting(requestParameters: GetMedicalInstitutionReportDefinitionAlertSettingRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionReportAlertSetting> {
        const response = await this.getMedicalInstitutionReportDefinitionAlertSettingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 連携先レポート定義一覧の取得
     */
    async getMedicalInstitutionReportDefinitionsRaw(requestParameters: GetMedicalInstitutionReportDefinitionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionReportDefinitionsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_reports/definitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicalInstitutionReportDefinitionsResponseFromJSON(jsonValue));
    }

    /**
     * 連携先レポート定義一覧の取得
     */
    async getMedicalInstitutionReportDefinitions(requestParameters: GetMedicalInstitutionReportDefinitionsRequest = {}, initOverrides?: RequestInit): Promise<GetMedicalInstitutionReportDefinitionsResponse> {
        const response = await this.getMedicalInstitutionReportDefinitionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 連携先レポート項目マスタ一覧
     */
    async getMedicalInstitutionReportItemDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMedicalInstitutionReportItemDefinitionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_reports/item_definitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMedicalInstitutionReportItemDefinitionsResponseFromJSON(jsonValue));
    }

    /**
     * 連携先レポート項目マスタ一覧
     */
    async getMedicalInstitutionReportItemDefinitions(initOverrides?: RequestInit): Promise<GetMedicalInstitutionReportItemDefinitionsResponse> {
        const response = await this.getMedicalInstitutionReportItemDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 連携先レポート集計結果生成
     */
    async postMedicalInstitutionReportAggregationResultsRaw(requestParameters: PostMedicalInstitutionReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReportAggregationResults>> {
        if (requestParameters.postMedicalInstitutionReportAggregationResultsRequest === null || requestParameters.postMedicalInstitutionReportAggregationResultsRequest === undefined) {
            throw new runtime.RequiredError('postMedicalInstitutionReportAggregationResultsRequest','Required parameter requestParameters.postMedicalInstitutionReportAggregationResultsRequest was null or undefined when calling postMedicalInstitutionReportAggregationResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_reports/aggregation_results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostMedicalInstitutionReportAggregationResultsRequestToJSON(requestParameters.postMedicalInstitutionReportAggregationResultsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportAggregationResultsFromJSON(jsonValue));
    }

    /**
     * 連携先レポート集計結果生成
     */
    async postMedicalInstitutionReportAggregationResults(requestParameters: PostMedicalInstitutionReportAggregationResultsOperationRequest, initOverrides?: RequestInit): Promise<ReportAggregationResults> {
        const response = await this.postMedicalInstitutionReportAggregationResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 連携先レポート定義の登録
     */
    async postMedicalInstitutionReportDefinitionRaw(requestParameters: PostMedicalInstitutionReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionReportDefinitionDetail>> {
        if (requestParameters.postMedicalInstitutionReportDefinitionRequest === null || requestParameters.postMedicalInstitutionReportDefinitionRequest === undefined) {
            throw new runtime.RequiredError('postMedicalInstitutionReportDefinitionRequest','Required parameter requestParameters.postMedicalInstitutionReportDefinitionRequest was null or undefined when calling postMedicalInstitutionReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_reports/definitions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostMedicalInstitutionReportDefinitionRequestToJSON(requestParameters.postMedicalInstitutionReportDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * 連携先レポート定義の登録
     */
    async postMedicalInstitutionReportDefinition(requestParameters: PostMedicalInstitutionReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionReportDefinitionDetail> {
        const response = await this.postMedicalInstitutionReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 連携先レポート結果生成
     */
    async postMedicalInstitutionReportResultsRaw(requestParameters: PostMedicalInstitutionReportResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostMedicalInstitutionReportResultsResponse>> {
        if (requestParameters.postMedicalInstitutionReportResultsRequest === null || requestParameters.postMedicalInstitutionReportResultsRequest === undefined) {
            throw new runtime.RequiredError('postMedicalInstitutionReportResultsRequest','Required parameter requestParameters.postMedicalInstitutionReportResultsRequest was null or undefined when calling postMedicalInstitutionReportResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_reports/results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostMedicalInstitutionReportResultsRequestToJSON(requestParameters.postMedicalInstitutionReportResultsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostMedicalInstitutionReportResultsResponseFromJSON(jsonValue));
    }

    /**
     * 連携先レポート結果生成
     */
    async postMedicalInstitutionReportResults(requestParameters: PostMedicalInstitutionReportResultsOperationRequest, initOverrides?: RequestInit): Promise<PostMedicalInstitutionReportResultsResponse> {
        const response = await this.postMedicalInstitutionReportResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 連携先レポート定義の更新
     */
    async putMedicalInstitutionReportDefinitionRaw(requestParameters: PutMedicalInstitutionReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionReportDefinitionDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putMedicalInstitutionReportDefinition.');
        }

        if (requestParameters.putMedicalInstitutionReportDefinitionRequest === null || requestParameters.putMedicalInstitutionReportDefinitionRequest === undefined) {
            throw new runtime.RequiredError('putMedicalInstitutionReportDefinitionRequest','Required parameter requestParameters.putMedicalInstitutionReportDefinitionRequest was null or undefined when calling putMedicalInstitutionReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutMedicalInstitutionReportDefinitionRequestToJSON(requestParameters.putMedicalInstitutionReportDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * 連携先レポート定義の更新
     */
    async putMedicalInstitutionReportDefinition(requestParameters: PutMedicalInstitutionReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionReportDefinitionDetail> {
        const response = await this.putMedicalInstitutionReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 連携先レポートアラート設定の保存
     */
    async putMedicalInstitutionReportDefinitionAlertSettingRaw(requestParameters: PutMedicalInstitutionReportDefinitionAlertSettingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionReportAlertSetting>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putMedicalInstitutionReportDefinitionAlertSetting.');
        }

        if (requestParameters.medicalInstitutionReportAlertSetting === null || requestParameters.medicalInstitutionReportAlertSetting === undefined) {
            throw new runtime.RequiredError('medicalInstitutionReportAlertSetting','Required parameter requestParameters.medicalInstitutionReportAlertSetting was null or undefined when calling putMedicalInstitutionReportDefinitionAlertSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institution_reports/definitions/{id}/alert_settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MedicalInstitutionReportAlertSettingToJSON(requestParameters.medicalInstitutionReportAlertSetting),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionReportAlertSettingFromJSON(jsonValue));
    }

    /**
     * 連携先レポートアラート設定の保存
     */
    async putMedicalInstitutionReportDefinitionAlertSetting(requestParameters: PutMedicalInstitutionReportDefinitionAlertSettingRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionReportAlertSetting> {
        const response = await this.putMedicalInstitutionReportDefinitionAlertSettingRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetMedicalInstitutionReportDefinitionsSortByEnum {
    CreateDate = 'create_date',
    AlertDate = 'alert_date'
}
