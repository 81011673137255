/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Doctor,
    DoctorFromJSON,
    DoctorFromJSONTyped,
    DoctorToJSON,
} from './Doctor';

/**
 * 
 * @export
 * @interface GetDoctorsResponse
 */
export interface GetDoctorsResponse {
    /**
     * 最終更新日時
     * @type {string}
     * @memberof GetDoctorsResponse
     */
    lastUpdatedDate: string | null;
    /**
     * 所属医師の一覧
     * @type {Array<Doctor>}
     * @memberof GetDoctorsResponse
     */
    doctors: Array<Doctor>;
}

export function GetDoctorsResponseFromJSON(json: any): GetDoctorsResponse {
    return GetDoctorsResponseFromJSONTyped(json, false);
}

export function GetDoctorsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDoctorsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastUpdatedDate': json['last_updated_date'],
        'doctors': ((json['doctors'] as Array<any>).map(DoctorFromJSON)),
    };
}

export function GetDoctorsResponseToJSON(value?: GetDoctorsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last_updated_date': value.lastUpdatedDate,
        'doctors': ((value.doctors as Array<any>).map(DoctorToJSON)),
    };
}

