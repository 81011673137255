/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRates,
    PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRatesFromJSON,
    PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRatesFromJSONTyped,
    PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRatesToJSON,
} from './PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRates';

/**
 * 
 * @export
 * @interface PostChartVisitsDoctorInterviewRatesResponse
 */
export interface PostChartVisitsDoctorInterviewRatesResponse {
    /**
     * 医師面談設定率チャートデータ
     * @type {Array<PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRates>}
     * @memberof PostChartVisitsDoctorInterviewRatesResponse
     */
    doctorInterviewRates: Array<PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRates>;
}

export function PostChartVisitsDoctorInterviewRatesResponseFromJSON(json: any): PostChartVisitsDoctorInterviewRatesResponse {
    return PostChartVisitsDoctorInterviewRatesResponseFromJSONTyped(json, false);
}

export function PostChartVisitsDoctorInterviewRatesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartVisitsDoctorInterviewRatesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'doctorInterviewRates': ((json['doctor_interview_rates'] as Array<any>).map(PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRatesFromJSON)),
    };
}

export function PostChartVisitsDoctorInterviewRatesResponseToJSON(value?: PostChartVisitsDoctorInterviewRatesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'doctor_interview_rates': ((value.doctorInterviewRates as Array<any>).map(PostChartVisitsDoctorInterviewRatesResponseDoctorInterviewRatesToJSON)),
    };
}

