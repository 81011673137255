/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailsListItem
 */
export interface EmailsListItem {
    /**
     * メールID
     * @type {number}
     * @memberof EmailsListItem
     */
    id: number;
    /**
     * タイトル
     * @type {string}
     * @memberof EmailsListItem
     */
    title: string;
    /**
     * メール作成日
     * @type {string}
     * @memberof EmailsListItem
     */
    createdDate: string;
    /**
     * 送付先数(宛先数)
     * @type {number}
     * @memberof EmailsListItem
     */
    destinationCount: number;
    /**
     * 送付数(実際にメールを配信できた数)
     * @type {number}
     * @memberof EmailsListItem
     */
    deliveredCount: number | null;
    /**
     * 開封率
     * @type {number}
     * @memberof EmailsListItem
     */
    openedRate: number | null;
    /**
     * クリック率
     * @type {number}
     * @memberof EmailsListItem
     */
    clickedRate: number | null;
}

export function EmailsListItemFromJSON(json: any): EmailsListItem {
    return EmailsListItemFromJSONTyped(json, false);
}

export function EmailsListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailsListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'createdDate': json['created_date'],
        'destinationCount': json['destination_count'],
        'deliveredCount': json['delivered_count'],
        'openedRate': json['opened_rate'],
        'clickedRate': json['clicked_rate'],
    };
}

export function EmailsListItemToJSON(value?: EmailsListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'created_date': value.createdDate,
        'destination_count': value.destinationCount,
        'delivered_count': value.deliveredCount,
        'opened_rate': value.openedRate,
        'clicked_rate': value.clickedRate,
    };
}

