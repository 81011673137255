/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportFilterConditionMatcher,
    MedicalInstitutionReportFilterConditionMatcherFromJSON,
    MedicalInstitutionReportFilterConditionMatcherFromJSONTyped,
    MedicalInstitutionReportFilterConditionMatcherToJSON,
} from './MedicalInstitutionReportFilterConditionMatcher';
import {
    MedicalInstitutionReportItem,
    MedicalInstitutionReportItemFromJSON,
    MedicalInstitutionReportItemFromJSONTyped,
    MedicalInstitutionReportItemToJSON,
} from './MedicalInstitutionReportItem';

/**
 * 連携先レポート絞り込み条件
 * @export
 * @interface MedicalInstitutionReportFilterCondition
 */
export interface MedicalInstitutionReportFilterCondition {
    /**
     * 
     * @type {MedicalInstitutionReportItem}
     * @memberof MedicalInstitutionReportFilterCondition
     */
    item: MedicalInstitutionReportItem;
    /**
     * 項目に対する絞り込みマッチ条件のグループ。複数指定した場合どれかひとつのグループにマッチすれば抽出される
     * @type {Array<MedicalInstitutionReportFilterConditionMatcher>}
     * @memberof MedicalInstitutionReportFilterCondition
     */
    matchingGroups: Array<MedicalInstitutionReportFilterConditionMatcher>;
}

export function MedicalInstitutionReportFilterConditionFromJSON(json: any): MedicalInstitutionReportFilterCondition {
    return MedicalInstitutionReportFilterConditionFromJSONTyped(json, false);
}

export function MedicalInstitutionReportFilterConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportFilterCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'item': MedicalInstitutionReportItemFromJSON(json['item']),
        'matchingGroups': ((json['matching_groups'] as Array<any>).map(MedicalInstitutionReportFilterConditionMatcherFromJSON)),
    };
}

export function MedicalInstitutionReportFilterConditionToJSON(value?: MedicalInstitutionReportFilterCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': MedicalInstitutionReportItemToJSON(value.item),
        'matching_groups': ((value.matchingGroups as Array<any>).map(MedicalInstitutionReportFilterConditionMatcherToJSON)),
    };
}

