/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationLabelCommunicationType,
    CommunicationLabelCommunicationTypeFromJSON,
    CommunicationLabelCommunicationTypeFromJSONTyped,
    CommunicationLabelCommunicationTypeToJSON,
} from './CommunicationLabelCommunicationType';

/**
 * 
 * @export
 * @interface PutCommunicationLabelsOrdersRequest
 */
export interface PutCommunicationLabelsOrdersRequest {
    /**
     * 
     * @type {CommunicationLabelCommunicationType}
     * @memberof PutCommunicationLabelsOrdersRequest
     */
    communicationType: CommunicationLabelCommunicationType;
    /**
     * 
     * @type {Array<number>}
     * @memberof PutCommunicationLabelsOrdersRequest
     */
    labelIds: Array<number>;
}

export function PutCommunicationLabelsOrdersRequestFromJSON(json: any): PutCommunicationLabelsOrdersRequest {
    return PutCommunicationLabelsOrdersRequestFromJSONTyped(json, false);
}

export function PutCommunicationLabelsOrdersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutCommunicationLabelsOrdersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communicationType': CommunicationLabelCommunicationTypeFromJSON(json['communication_type']),
        'labelIds': json['label_ids'],
    };
}

export function PutCommunicationLabelsOrdersRequestToJSON(value?: PutCommunicationLabelsOrdersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communication_type': CommunicationLabelCommunicationTypeToJSON(value.communicationType),
        'label_ids': value.labelIds,
    };
}

