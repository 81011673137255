/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostGroupsChartReferralsComparisonAnnualRequest
 */
export interface PostGroupsChartReferralsComparisonAnnualRequest {
    /**
     * グループ病院のID
     * @type {Array<number>}
     * @memberof PostGroupsChartReferralsComparisonAnnualRequest
     */
    groupMedicalInstitutionIds: Array<number>;
}

export function PostGroupsChartReferralsComparisonAnnualRequestFromJSON(json: any): PostGroupsChartReferralsComparisonAnnualRequest {
    return PostGroupsChartReferralsComparisonAnnualRequestFromJSONTyped(json, false);
}

export function PostGroupsChartReferralsComparisonAnnualRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostGroupsChartReferralsComparisonAnnualRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupMedicalInstitutionIds': json['group_medical_institution_ids'],
    };
}

export function PostGroupsChartReferralsComparisonAnnualRequestToJSON(value?: PostGroupsChartReferralsComparisonAnnualRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_medical_institution_ids': value.groupMedicalInstitutionIds,
    };
}

