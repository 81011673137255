/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 比較対象期間
 * @export
 * @interface PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriod
 */
export interface PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriod {
    /**
     * 
     * @type {string}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriod
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriod
     */
    endDate: string;
}

export function PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriodFromJSON(json: any): PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriod {
    return PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriodFromJSONTyped(json, false);
}

export function PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': json['start_date'],
        'endDate': json['end_date'],
    };
}

export function PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriodToJSON(value?: PostMedicalInstitutionSearchForSalesMapRequestReferralComparisonPeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': value.startDate,
        'end_date': value.endDate,
    };
}

