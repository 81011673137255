/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartMedicalInstitutionsReferralsRequestPeriod,
    PostChartMedicalInstitutionsReferralsRequestPeriodFromJSON,
    PostChartMedicalInstitutionsReferralsRequestPeriodFromJSONTyped,
    PostChartMedicalInstitutionsReferralsRequestPeriodToJSON,
} from './PostChartMedicalInstitutionsReferralsRequestPeriod';

/**
 * 
 * @export
 * @interface PostChartMedicalInstitutionsReferralsRequest
 */
export interface PostChartMedicalInstitutionsReferralsRequest {
    /**
     * グループ病院のID (null の場合は全グループ病院の合算)
     * @type {Array<string>}
     * @memberof PostChartMedicalInstitutionsReferralsRequest
     */
    groupMedicalInstitutionIds: Array<string> | null;
    /**
     * 初再診フラグ
     * `all`, `first_visit`, `return_visit`
     * @type {string}
     * @memberof PostChartMedicalInstitutionsReferralsRequest
     */
    firstVisitFlag: PostChartMedicalInstitutionsReferralsRequestFirstVisitFlagEnum;
    /**
     * 診療科目のID (null の場合は全診療科目の合算)
     * @type {Array<string>}
     * @memberof PostChartMedicalInstitutionsReferralsRequest
     */
    ownDepartmentIds: Array<string> | null;
    /**
     * 
     * @type {PostChartMedicalInstitutionsReferralsRequestPeriod}
     * @memberof PostChartMedicalInstitutionsReferralsRequest
     */
    period: PostChartMedicalInstitutionsReferralsRequestPeriod;
    /**
     * 紹介数の集計時間単位
     * `week`, `month`, `year`
     * @type {string}
     * @memberof PostChartMedicalInstitutionsReferralsRequest
     */
    unitType: PostChartMedicalInstitutionsReferralsRequestUnitTypeEnum;
    /**
     * 紹介・入院種別
     * `referral`, `admission`, `operation`, `reverse_referral`, `referrals_comparison`
     * @type {string}
     * @memberof PostChartMedicalInstitutionsReferralsRequest
     */
    referralAdmissionType: PostChartMedicalInstitutionsReferralsRequestReferralAdmissionTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostChartMedicalInstitutionsReferralsRequestFirstVisitFlagEnum {
    All = 'all',
    FirstVisit = 'first_visit',
    ReturnVisit = 'return_visit'
}/**
* @export
* @enum {string}
*/
export enum PostChartMedicalInstitutionsReferralsRequestUnitTypeEnum {
    Week = 'week',
    Month = 'month',
    Year = 'year'
}/**
* @export
* @enum {string}
*/
export enum PostChartMedicalInstitutionsReferralsRequestReferralAdmissionTypeEnum {
    Referral = 'referral',
    Admission = 'admission',
    Operation = 'operation',
    ReverseReferral = 'reverse_referral',
    ReferralsComparison = 'referrals_comparison'
}

export function PostChartMedicalInstitutionsReferralsRequestFromJSON(json: any): PostChartMedicalInstitutionsReferralsRequest {
    return PostChartMedicalInstitutionsReferralsRequestFromJSONTyped(json, false);
}

export function PostChartMedicalInstitutionsReferralsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartMedicalInstitutionsReferralsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupMedicalInstitutionIds': json['group_medical_institution_ids'],
        'firstVisitFlag': json['first_visit_flag'],
        'ownDepartmentIds': json['own_department_ids'],
        'period': PostChartMedicalInstitutionsReferralsRequestPeriodFromJSON(json['period']),
        'unitType': json['unit_type'],
        'referralAdmissionType': json['referral_admission_type'],
    };
}

export function PostChartMedicalInstitutionsReferralsRequestToJSON(value?: PostChartMedicalInstitutionsReferralsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_medical_institution_ids': value.groupMedicalInstitutionIds,
        'first_visit_flag': value.firstVisitFlag,
        'own_department_ids': value.ownDepartmentIds,
        'period': PostChartMedicalInstitutionsReferralsRequestPeriodToJSON(value.period),
        'unit_type': value.unitType,
        'referral_admission_type': value.referralAdmissionType,
    };
}

