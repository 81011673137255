import { msalInstance } from '@/src/middlewares/Auth/config';

import styles from './styles.module.scss';

import { Button } from '../../foundations/Buttons/Button';
import { Card } from '../../foundations/DataDisplays/Card';
import { Divider } from '../../foundations/Layouts/Divider';
import { GlobalHeader } from '../Layout/GlobalHeader';

export const NoApplicationAuthorizationError: React.FC = () => {
  const logoutAndGoto = async (url: string) => {
    await msalInstance
      .logoutRedirect({
        account: msalInstance.getActiveAccount(),
        postLogoutRedirectUri: url,
      })
      // ログアウト失敗時はログインできていないため、無視してページ遷移させる
      .catch(() => (window.location.href = url));
  };

  return (
    <div className={styles.layout}>
      <GlobalHeader
        onClick={(e) => {
          e.preventDefault();
          logoutAndGoto('/');
        }}
      />
      <div className={styles.base}>
        <Card>
          <div className={styles.container}>
            <div className={styles.topContainer}>
              <h2 className={styles.statusCode}>403</h2>
            </div>
            <Divider />
            <div className={styles.bottomContainer}>
              <h3 className={styles.summary}>
                指定されたページは表示できません
              </h3>
              <p className={styles.description}>
                ページを表示するには、アクセス権限が必要です。
                <br />
                お手数ですが、貴院のforo CRM管理者までお問い合わせください。
              </p>
              <div className={styles.buttonWrapper}>
                <Button
                  color="primary"
                  onClick={() => logoutAndGoto(process.env.MC_MAP_URL)}
                >
                  foro 連携マップに移動する
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
