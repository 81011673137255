/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertParameterSubParameters,
    AlertParameterSubParametersFromJSON,
    AlertParameterSubParametersFromJSONTyped,
    AlertParameterSubParametersToJSON,
} from './AlertParameterSubParameters';

/**
 * アラート条件のパラメータ
 * @export
 * @interface AlertParameter
 */
export interface AlertParameter {
    /**
     * パラメータのタイプ (type と同名のキーの属性のみ存在する)
     * @type {string}
     * @memberof AlertParameter
     */
    type: AlertParameterTypeEnum;
    /**
     * パラメータのタイトル
     * @type {string}
     * @memberof AlertParameter
     */
    key: string;
    /**
     * パラメータの単一値
     * @type {string}
     * @memberof AlertParameter
     */
    value?: string;
    /**
     * パラメータの配列値
     * @type {Array<string>}
     * @memberof AlertParameter
     */
    values?: Array<string>;
    /**
     * アラート条件のサブパラメータ
     * @type {Array<AlertParameterSubParameters>}
     * @memberof AlertParameter
     */
    subParameters?: Array<AlertParameterSubParameters>;
}

/**
* @export
* @enum {string}
*/
export enum AlertParameterTypeEnum {
    Value = 'value',
    Values = 'values',
    SubParameters = 'sub_parameters'
}

export function AlertParameterFromJSON(json: any): AlertParameter {
    return AlertParameterFromJSONTyped(json, false);
}

export function AlertParameterFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertParameter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'key': json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'values': !exists(json, 'values') ? undefined : json['values'],
        'subParameters': !exists(json, 'sub_parameters') ? undefined : ((json['sub_parameters'] as Array<any>).map(AlertParameterSubParametersFromJSON)),
    };
}

export function AlertParameterToJSON(value?: AlertParameter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'key': value.key,
        'value': value.value,
        'values': value.values,
        'sub_parameters': value.subParameters === undefined ? undefined : ((value.subParameters as Array<any>).map(AlertParameterSubParametersToJSON)),
    };
}

