/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostAccountResponse
 */
export interface PostAccountResponse {
    /**
     * 認証URL
     * @type {string}
     * @memberof PostAccountResponse
     */
    confirmationUrl: string;
    /**
     * 初回ログイン用の仮パスワード。IDaaS移行後、かつログインIDで作成した場合に返す
     * @type {string}
     * @memberof PostAccountResponse
     */
    password: string | null;
}

export function PostAccountResponseFromJSON(json: any): PostAccountResponse {
    return PostAccountResponseFromJSONTyped(json, false);
}

export function PostAccountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostAccountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confirmationUrl': json['confirmation_url'],
        'password': json['password'],
    };
}

export function PostAccountResponseToJSON(value?: PostAccountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confirmation_url': value.confirmationUrl,
        'password': value.password,
    };
}

