/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActionList,
    ActionListFromJSON,
    ActionListToJSON,
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    GetActionListItemsResponse,
    GetActionListItemsResponseFromJSON,
    GetActionListItemsResponseToJSON,
    GetActionListsResponse,
    GetActionListsResponseFromJSON,
    GetActionListsResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostActionListIdRequest,
    PostActionListIdRequestFromJSON,
    PostActionListIdRequestToJSON,
    PostActionListItemMedicalInstitutionRequest,
    PostActionListItemMedicalInstitutionRequestFromJSON,
    PostActionListItemMedicalInstitutionRequestToJSON,
    PostActionListItemsBulkDeleteRequest,
    PostActionListItemsBulkDeleteRequestFromJSON,
    PostActionListItemsBulkDeleteRequestToJSON,
    PostActionListItemsBulkInsertRequest,
    PostActionListItemsBulkInsertRequestFromJSON,
    PostActionListItemsBulkInsertRequestToJSON,
    PostActionListItemsBulkInsertResponse,
    PostActionListItemsBulkInsertResponseFromJSON,
    PostActionListItemsBulkInsertResponseToJSON,
    PostActionListRequest,
    PostActionListRequestFromJSON,
    PostActionListRequestToJSON,
    PutActionListItemsStatusRequest,
    PutActionListItemsStatusRequestFromJSON,
    PutActionListItemsStatusRequestToJSON,
    PutActionListItemsStatusResponse,
    PutActionListItemsStatusResponseFromJSON,
    PutActionListItemsStatusResponseToJSON,
    PutActionListStatusRequest,
    PutActionListStatusRequestFromJSON,
    PutActionListStatusRequestToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteActionListIdRequest {
    id: number;
}

export interface GetActionListItemsRequest {
    id: number;
    page?: number;
    perPage?: number;
    status?: GetActionListItemsStatusEnum;
}

export interface PostActionListOperationRequest {
    postActionListRequest: PostActionListRequest;
}

export interface PostActionListIdOperationRequest {
    id: number;
    postActionListIdRequest: PostActionListIdRequest;
}

export interface PostActionListItemMedicalInstitutionOperationRequest {
    id: number;
    postActionListItemMedicalInstitutionRequest: PostActionListItemMedicalInstitutionRequest;
}

export interface PostActionListItemsBulkDeleteOperationRequest {
    id: number;
    postActionListItemsBulkDeleteRequest: PostActionListItemsBulkDeleteRequest;
}

export interface PostActionListItemsBulkInsertOperationRequest {
    id: number;
    postActionListItemsBulkInsertRequest: PostActionListItemsBulkInsertRequest;
}

export interface PutActionListItemsStatusOperationRequest {
    id: number;
    putActionListItemsStatusRequest: PutActionListItemsStatusRequest;
}

export interface PutActionListStatusOperationRequest {
    id: number;
    putActionListStatusRequest: PutActionListStatusRequest;
}

/**
 * ActionListApi - interface
 * 
 * @export
 * @interface ActionListApiInterface
 */
export interface ActionListApiInterface {
    /**
     * アクションリストを削除する  もしアクションリスト内に医療機関が登録されていた場合でも、そのままアクションリストが消えます。 
     * @summary アクションリストの削除
     * @param {number} id 追加先のアクションリストID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionListApiInterface
     */
    deleteActionListIdRaw(requestParameters: DeleteActionListIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * アクションリストを削除する  もしアクションリスト内に医療機関が登録されていた場合でも、そのままアクションリストが消えます。 
     * アクションリストの削除
     */
    deleteActionListId(requestParameters: DeleteActionListIdRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary アクションリストの医療機関一覧を取得する
     * @param {number} id 追加先のアクションリストID
     * @param {number} [page] ページ番号（１始まり）
     * @param {number} [perPage] １ページあたりの件数
     * @param {'all' | 'actioned' | 'not_actioned'} [status] ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionListApiInterface
     */
    getActionListItemsRaw(requestParameters: GetActionListItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetActionListItemsResponse>>;

    /**
     * アクションリストの医療機関一覧を取得する
     */
    getActionListItems(requestParameters: GetActionListItemsRequest, initOverrides?: RequestInit): Promise<GetActionListItemsResponse>;

    /**
     * アクションリストの一覧を取得する 
     * @summary アクションリスト一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionListApiInterface
     */
    getActionListsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetActionListsResponse>>;

    /**
     * アクションリストの一覧を取得する 
     * アクションリスト一覧の取得
     */
    getActionLists(initOverrides?: RequestInit): Promise<GetActionListsResponse>;

    /**
     * 
     * @summary アクションリストの作成
     * @param {PostActionListRequest} postActionListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionListApiInterface
     */
    postActionListRaw(requestParameters: PostActionListOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionList>>;

    /**
     * アクションリストの作成
     */
    postActionList(requestParameters: PostActionListOperationRequest, initOverrides?: RequestInit): Promise<ActionList>;

    /**
     * アクションリストの名前を変更する 
     * @summary アクションリストの名称変更
     * @param {number} id 追加先のアクションリストID
     * @param {PostActionListIdRequest} postActionListIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionListApiInterface
     */
    postActionListIdRaw(requestParameters: PostActionListIdOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionList>>;

    /**
     * アクションリストの名前を変更する 
     * アクションリストの名称変更
     */
    postActionListId(requestParameters: PostActionListIdOperationRequest, initOverrides?: RequestInit): Promise<ActionList>;

    /**
     * 
     * @summary アクションリストに医療機関を登録する
     * @param {number} id アクションリストID
     * @param {PostActionListItemMedicalInstitutionRequest} postActionListItemMedicalInstitutionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionListApiInterface
     */
    postActionListItemMedicalInstitutionRaw(requestParameters: PostActionListItemMedicalInstitutionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * アクションリストに医療機関を登録する
     */
    postActionListItemMedicalInstitution(requestParameters: PostActionListItemMedicalInstitutionOperationRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * アクションリスト内の医療機関をまとめて削除することができます 
     * @summary アクションリストの医療機関をまとめて削除
     * @param {number} id 追加先のアクションリストID
     * @param {PostActionListItemsBulkDeleteRequest} postActionListItemsBulkDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionListApiInterface
     */
    postActionListItemsBulkDeleteRaw(requestParameters: PostActionListItemsBulkDeleteOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * アクションリスト内の医療機関をまとめて削除することができます 
     * アクションリストの医療機関をまとめて削除
     */
    postActionListItemsBulkDelete(requestParameters: PostActionListItemsBulkDeleteOperationRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * アクションリストに医療機関をまとめて追加することができます  一つ以上医療機関の追加に成功した場合には204が返ります   もし一つでも存在しない医療機関のIDがあるか、全てが追加済みの医療機関IDだった場合には400が返ります 
     * @summary アクションリストに医療機関をまとめて追加
     * @param {number} id 追加先のアクションリストID
     * @param {PostActionListItemsBulkInsertRequest} postActionListItemsBulkInsertRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionListApiInterface
     */
    postActionListItemsBulkInsertRaw(requestParameters: PostActionListItemsBulkInsertOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostActionListItemsBulkInsertResponse>>;

    /**
     * アクションリストに医療機関をまとめて追加することができます  一つ以上医療機関の追加に成功した場合には204が返ります   もし一つでも存在しない医療機関のIDがあるか、全てが追加済みの医療機関IDだった場合には400が返ります 
     * アクションリストに医療機関をまとめて追加
     */
    postActionListItemsBulkInsert(requestParameters: PostActionListItemsBulkInsertOperationRequest, initOverrides?: RequestInit): Promise<PostActionListItemsBulkInsertResponse>;

    /**
     * アクションリスト内の医療機関のステータスを変更します   
     * @summary アクションリストで医療機関のステータスを変更する
     * @param {number} id 追加先のアクションリストID
     * @param {PutActionListItemsStatusRequest} putActionListItemsStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionListApiInterface
     */
    putActionListItemsStatusRaw(requestParameters: PutActionListItemsStatusOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PutActionListItemsStatusResponse>>;

    /**
     * アクションリスト内の医療機関のステータスを変更します   
     * アクションリストで医療機関のステータスを変更する
     */
    putActionListItemsStatus(requestParameters: PutActionListItemsStatusOperationRequest, initOverrides?: RequestInit): Promise<PutActionListItemsStatusResponse>;

    /**
     * アクションリストのステータスを変更します
     * @summary アクションリストのステータスを変更する
     * @param {number} id 追加先のアクションリストID
     * @param {PutActionListStatusRequest} putActionListStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionListApiInterface
     */
    putActionListStatusRaw(requestParameters: PutActionListStatusOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionList>>;

    /**
     * アクションリストのステータスを変更します
     * アクションリストのステータスを変更する
     */
    putActionListStatus(requestParameters: PutActionListStatusOperationRequest, initOverrides?: RequestInit): Promise<ActionList>;

}

/**
 * 
 */
export class ActionListApi extends runtime.BaseAPI implements ActionListApiInterface {

    /**
     * アクションリストを削除する  もしアクションリスト内に医療機関が登録されていた場合でも、そのままアクションリストが消えます。 
     * アクションリストの削除
     */
    async deleteActionListIdRaw(requestParameters: DeleteActionListIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteActionListId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/action_lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * アクションリストを削除する  もしアクションリスト内に医療機関が登録されていた場合でも、そのままアクションリストが消えます。 
     * アクションリストの削除
     */
    async deleteActionListId(requestParameters: DeleteActionListIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteActionListIdRaw(requestParameters, initOverrides);
    }

    /**
     * アクションリストの医療機関一覧を取得する
     */
    async getActionListItemsRaw(requestParameters: GetActionListItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetActionListItemsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getActionListItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['per_page'] = requestParameters.perPage;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/action_lists/{id}/medical_institutions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetActionListItemsResponseFromJSON(jsonValue));
    }

    /**
     * アクションリストの医療機関一覧を取得する
     */
    async getActionListItems(requestParameters: GetActionListItemsRequest, initOverrides?: RequestInit): Promise<GetActionListItemsResponse> {
        const response = await this.getActionListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アクションリストの一覧を取得する 
     * アクションリスト一覧の取得
     */
    async getActionListsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetActionListsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/action_lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetActionListsResponseFromJSON(jsonValue));
    }

    /**
     * アクションリストの一覧を取得する 
     * アクションリスト一覧の取得
     */
    async getActionLists(initOverrides?: RequestInit): Promise<GetActionListsResponse> {
        const response = await this.getActionListsRaw(initOverrides);
        return await response.value();
    }

    /**
     * アクションリストの作成
     */
    async postActionListRaw(requestParameters: PostActionListOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionList>> {
        if (requestParameters.postActionListRequest === null || requestParameters.postActionListRequest === undefined) {
            throw new runtime.RequiredError('postActionListRequest','Required parameter requestParameters.postActionListRequest was null or undefined when calling postActionList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/action_lists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostActionListRequestToJSON(requestParameters.postActionListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionListFromJSON(jsonValue));
    }

    /**
     * アクションリストの作成
     */
    async postActionList(requestParameters: PostActionListOperationRequest, initOverrides?: RequestInit): Promise<ActionList> {
        const response = await this.postActionListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アクションリストの名前を変更する 
     * アクションリストの名称変更
     */
    async postActionListIdRaw(requestParameters: PostActionListIdOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postActionListId.');
        }

        if (requestParameters.postActionListIdRequest === null || requestParameters.postActionListIdRequest === undefined) {
            throw new runtime.RequiredError('postActionListIdRequest','Required parameter requestParameters.postActionListIdRequest was null or undefined when calling postActionListId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/action_lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostActionListIdRequestToJSON(requestParameters.postActionListIdRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionListFromJSON(jsonValue));
    }

    /**
     * アクションリストの名前を変更する 
     * アクションリストの名称変更
     */
    async postActionListId(requestParameters: PostActionListIdOperationRequest, initOverrides?: RequestInit): Promise<ActionList> {
        const response = await this.postActionListIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アクションリストに医療機関を登録する
     */
    async postActionListItemMedicalInstitutionRaw(requestParameters: PostActionListItemMedicalInstitutionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postActionListItemMedicalInstitution.');
        }

        if (requestParameters.postActionListItemMedicalInstitutionRequest === null || requestParameters.postActionListItemMedicalInstitutionRequest === undefined) {
            throw new runtime.RequiredError('postActionListItemMedicalInstitutionRequest','Required parameter requestParameters.postActionListItemMedicalInstitutionRequest was null or undefined when calling postActionListItemMedicalInstitution.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/action_lists/{id}/medical_institutions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostActionListItemMedicalInstitutionRequestToJSON(requestParameters.postActionListItemMedicalInstitutionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * アクションリストに医療機関を登録する
     */
    async postActionListItemMedicalInstitution(requestParameters: PostActionListItemMedicalInstitutionOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postActionListItemMedicalInstitutionRaw(requestParameters, initOverrides);
    }

    /**
     * アクションリスト内の医療機関をまとめて削除することができます 
     * アクションリストの医療機関をまとめて削除
     */
    async postActionListItemsBulkDeleteRaw(requestParameters: PostActionListItemsBulkDeleteOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postActionListItemsBulkDelete.');
        }

        if (requestParameters.postActionListItemsBulkDeleteRequest === null || requestParameters.postActionListItemsBulkDeleteRequest === undefined) {
            throw new runtime.RequiredError('postActionListItemsBulkDeleteRequest','Required parameter requestParameters.postActionListItemsBulkDeleteRequest was null or undefined when calling postActionListItemsBulkDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/action_lists/{id}/medical_institutions/bulk_delete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostActionListItemsBulkDeleteRequestToJSON(requestParameters.postActionListItemsBulkDeleteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * アクションリスト内の医療機関をまとめて削除することができます 
     * アクションリストの医療機関をまとめて削除
     */
    async postActionListItemsBulkDelete(requestParameters: PostActionListItemsBulkDeleteOperationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postActionListItemsBulkDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * アクションリストに医療機関をまとめて追加することができます  一つ以上医療機関の追加に成功した場合には204が返ります   もし一つでも存在しない医療機関のIDがあるか、全てが追加済みの医療機関IDだった場合には400が返ります 
     * アクションリストに医療機関をまとめて追加
     */
    async postActionListItemsBulkInsertRaw(requestParameters: PostActionListItemsBulkInsertOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostActionListItemsBulkInsertResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postActionListItemsBulkInsert.');
        }

        if (requestParameters.postActionListItemsBulkInsertRequest === null || requestParameters.postActionListItemsBulkInsertRequest === undefined) {
            throw new runtime.RequiredError('postActionListItemsBulkInsertRequest','Required parameter requestParameters.postActionListItemsBulkInsertRequest was null or undefined when calling postActionListItemsBulkInsert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/action_lists/{id}/medical_institutions/bulk_insert`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostActionListItemsBulkInsertRequestToJSON(requestParameters.postActionListItemsBulkInsertRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostActionListItemsBulkInsertResponseFromJSON(jsonValue));
    }

    /**
     * アクションリストに医療機関をまとめて追加することができます  一つ以上医療機関の追加に成功した場合には204が返ります   もし一つでも存在しない医療機関のIDがあるか、全てが追加済みの医療機関IDだった場合には400が返ります 
     * アクションリストに医療機関をまとめて追加
     */
    async postActionListItemsBulkInsert(requestParameters: PostActionListItemsBulkInsertOperationRequest, initOverrides?: RequestInit): Promise<PostActionListItemsBulkInsertResponse> {
        const response = await this.postActionListItemsBulkInsertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アクションリスト内の医療機関のステータスを変更します   
     * アクションリストで医療機関のステータスを変更する
     */
    async putActionListItemsStatusRaw(requestParameters: PutActionListItemsStatusOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PutActionListItemsStatusResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putActionListItemsStatus.');
        }

        if (requestParameters.putActionListItemsStatusRequest === null || requestParameters.putActionListItemsStatusRequest === undefined) {
            throw new runtime.RequiredError('putActionListItemsStatusRequest','Required parameter requestParameters.putActionListItemsStatusRequest was null or undefined when calling putActionListItemsStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/action_lists/{id}/medical_institutions/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutActionListItemsStatusRequestToJSON(requestParameters.putActionListItemsStatusRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutActionListItemsStatusResponseFromJSON(jsonValue));
    }

    /**
     * アクションリスト内の医療機関のステータスを変更します   
     * アクションリストで医療機関のステータスを変更する
     */
    async putActionListItemsStatus(requestParameters: PutActionListItemsStatusOperationRequest, initOverrides?: RequestInit): Promise<PutActionListItemsStatusResponse> {
        const response = await this.putActionListItemsStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アクションリストのステータスを変更します
     * アクションリストのステータスを変更する
     */
    async putActionListStatusRaw(requestParameters: PutActionListStatusOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActionList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putActionListStatus.');
        }

        if (requestParameters.putActionListStatusRequest === null || requestParameters.putActionListStatusRequest === undefined) {
            throw new runtime.RequiredError('putActionListStatusRequest','Required parameter requestParameters.putActionListStatusRequest was null or undefined when calling putActionListStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/action_lists/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutActionListStatusRequestToJSON(requestParameters.putActionListStatusRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActionListFromJSON(jsonValue));
    }

    /**
     * アクションリストのステータスを変更します
     * アクションリストのステータスを変更する
     */
    async putActionListStatus(requestParameters: PutActionListStatusOperationRequest, initOverrides?: RequestInit): Promise<ActionList> {
        const response = await this.putActionListStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetActionListItemsStatusEnum {
    All = 'all',
    Actioned = 'actioned',
    NotActioned = 'not_actioned'
}
