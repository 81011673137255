/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostChartReferralsComparisonAnnualResponseItems
 */
export interface PostChartReferralsComparisonAnnualResponseItems {
    /**
     * 集計期間
     * @type {string}
     * @memberof PostChartReferralsComparisonAnnualResponseItems
     */
    key: string;
    /**
     * 紹介数
     * @type {number}
     * @memberof PostChartReferralsComparisonAnnualResponseItems
     */
    value: number;
    /**
     * 診療科目のID
     * @type {number}
     * @memberof PostChartReferralsComparisonAnnualResponseItems
     */
    department?: number;
    /**
     * 残存期間 (前年, 前々年における現在 ~ 3/31までのデータの際にtrueになる想定)
     * @type {boolean}
     * @memberof PostChartReferralsComparisonAnnualResponseItems
     */
    isRemainingPeriod?: boolean;
}

export function PostChartReferralsComparisonAnnualResponseItemsFromJSON(json: any): PostChartReferralsComparisonAnnualResponseItems {
    return PostChartReferralsComparisonAnnualResponseItemsFromJSONTyped(json, false);
}

export function PostChartReferralsComparisonAnnualResponseItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReferralsComparisonAnnualResponseItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': json['value'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'isRemainingPeriod': !exists(json, 'is_remaining_period') ? undefined : json['is_remaining_period'],
    };
}

export function PostChartReferralsComparisonAnnualResponseItemsToJSON(value?: PostChartReferralsComparisonAnnualResponseItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
        'department': value.department,
        'is_remaining_period': value.isRemainingPeriod,
    };
}

