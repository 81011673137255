import { MainLayout } from '@/src/layouts/MainLayout';
import { CustomLayout } from '@/src/types/layout';

export const Layout: React.FC<{
  type?: CustomLayout;
  children: React.ReactNode;
}> = ({ children, type }) => {
  switch (type) {
    case 'none':
      return <>{children}</>;
    case 'no-padding':
      return <MainLayout noPadding>{children}</MainLayout>;
    default:
      return <MainLayout>{children}</MainLayout>;
  }
};
