/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostOwnDepartmentReportResultsResponseResults,
    PostOwnDepartmentReportResultsResponseResultsFromJSON,
    PostOwnDepartmentReportResultsResponseResultsFromJSONTyped,
    PostOwnDepartmentReportResultsResponseResultsToJSON,
} from './PostOwnDepartmentReportResultsResponseResults';

/**
 * 
 * @export
 * @interface PostOwnDepartmentReportResultsResponse
 */
export interface PostOwnDepartmentReportResultsResponse {
    /**
     * 表示中のページ番号
     * @type {number}
     * @memberof PostOwnDepartmentReportResultsResponse
     */
    page: number;
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof PostOwnDepartmentReportResultsResponse
     */
    totalPage: number;
    /**
     * 総件数
     * @type {number}
     * @memberof PostOwnDepartmentReportResultsResponse
     */
    totalCount: number;
    /**
     * レポート集計結果
     * @type {Array<PostOwnDepartmentReportResultsResponseResults>}
     * @memberof PostOwnDepartmentReportResultsResponse
     */
    results: Array<PostOwnDepartmentReportResultsResponseResults>;
    /**
     * すべての診療科に対してのレポート総計の結果配列。index は results と同じ。item はopenapi-generatorの制約でoneOfを使うと壊れるため文字列でも数値でも真偽値でもstringで返す。1番目(診療科)要素の総計は意味がないので null にしている。0/0 など計算できない場合は null を返す。
     * @type {Array<string>}
     * @memberof PostOwnDepartmentReportResultsResponse
     */
    resultsTotal: Array<string>;
}

export function PostOwnDepartmentReportResultsResponseFromJSON(json: any): PostOwnDepartmentReportResultsResponse {
    return PostOwnDepartmentReportResultsResponseFromJSONTyped(json, false);
}

export function PostOwnDepartmentReportResultsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostOwnDepartmentReportResultsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'results': ((json['results'] as Array<any>).map(PostOwnDepartmentReportResultsResponseResultsFromJSON)),
        'resultsTotal': json['results_total'],
    };
}

export function PostOwnDepartmentReportResultsResponseToJSON(value?: PostOwnDepartmentReportResultsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'results': ((value.results as Array<any>).map(PostOwnDepartmentReportResultsResponseResultsToJSON)),
        'results_total': value.resultsTotal,
    };
}

