/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CommunicationLabel,
    CommunicationLabelFromJSON,
    CommunicationLabelToJSON,
    GetCommunicationLabelsResponse,
    GetCommunicationLabelsResponseFromJSON,
    GetCommunicationLabelsResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostCommunicationLabelsRequest,
    PostCommunicationLabelsRequestFromJSON,
    PostCommunicationLabelsRequestToJSON,
    PutCommunicationLabelIdRequest,
    PutCommunicationLabelIdRequestFromJSON,
    PutCommunicationLabelIdRequestToJSON,
    PutCommunicationLabelsOrdersRequest,
    PutCommunicationLabelsOrdersRequestFromJSON,
    PutCommunicationLabelsOrdersRequestToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteCommunicationLabelIdRequest {
    id: number;
}

export interface PostCommunicationLabelsOperationRequest {
    postCommunicationLabelsRequest: PostCommunicationLabelsRequest;
}

export interface PutCommunicationLabelIdOperationRequest {
    id: number;
    putCommunicationLabelIdRequest: PutCommunicationLabelIdRequest;
}

export interface PutCommunicationLabelsOrdersOperationRequest {
    putCommunicationLabelsOrdersRequest: PutCommunicationLabelsOrdersRequest;
}

/**
 * CommunicationLabelsApi - interface
 * 
 * @export
 * @interface CommunicationLabelsApiInterface
 */
export interface CommunicationLabelsApiInterface {
    /**
     * 自身のテナントにおける、IDで指定されたコミュニケーションラベルマスタを1件削除する 
     * @summary コミュニケーションラベルマスタの削除
     * @param {number} id コミュニケーションラベルID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationLabelsApiInterface
     */
    deleteCommunicationLabelIdRaw(requestParameters: DeleteCommunicationLabelIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * 自身のテナントにおける、IDで指定されたコミュニケーションラベルマスタを1件削除する 
     * コミュニケーションラベルマスタの削除
     */
    deleteCommunicationLabelId(requestParameters: DeleteCommunicationLabelIdRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 自身のテナントにおけるコミュニケーションラベルマスタの一覧を取得する 
     * @summary コミュニケーションラベルマスタの一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationLabelsApiInterface
     */
    getCommunicationLabelsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCommunicationLabelsResponse>>;

    /**
     * 自身のテナントにおけるコミュニケーションラベルマスタの一覧を取得する 
     * コミュニケーションラベルマスタの一覧を取得する
     */
    getCommunicationLabels(initOverrides?: RequestInit): Promise<GetCommunicationLabelsResponse>;

    /**
     * コミュニケーションラベルマスタを１件登録する。 
     * @summary コミュニケーションラベルマスタの登録
     * @param {PostCommunicationLabelsRequest} postCommunicationLabelsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationLabelsApiInterface
     */
    postCommunicationLabelsRaw(requestParameters: PostCommunicationLabelsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationLabel>>;

    /**
     * コミュニケーションラベルマスタを１件登録する。 
     * コミュニケーションラベルマスタの登録
     */
    postCommunicationLabels(requestParameters: PostCommunicationLabelsOperationRequest, initOverrides?: RequestInit): Promise<CommunicationLabel>;

    /**
     * 自身のテナントにおける、IDで指定されたコミュニケーションラベルマスタを1件更新する 
     * @summary コミュニケーションラベルマスタの更新
     * @param {number} id コミュニケーションラベルID
     * @param {PutCommunicationLabelIdRequest} putCommunicationLabelIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationLabelsApiInterface
     */
    putCommunicationLabelIdRaw(requestParameters: PutCommunicationLabelIdOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationLabel>>;

    /**
     * 自身のテナントにおける、IDで指定されたコミュニケーションラベルマスタを1件更新する 
     * コミュニケーションラベルマスタの更新
     */
    putCommunicationLabelId(requestParameters: PutCommunicationLabelIdOperationRequest, initOverrides?: RequestInit): Promise<CommunicationLabel>;

    /**
     * コミュニケーションラベルマスタを渡されたIDの順に並び替える 
     * @summary コミュニケーションラベルマスタの並び替え
     * @param {PutCommunicationLabelsOrdersRequest} putCommunicationLabelsOrdersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationLabelsApiInterface
     */
    putCommunicationLabelsOrdersRaw(requestParameters: PutCommunicationLabelsOrdersOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCommunicationLabelsResponse>>;

    /**
     * コミュニケーションラベルマスタを渡されたIDの順に並び替える 
     * コミュニケーションラベルマスタの並び替え
     */
    putCommunicationLabelsOrders(requestParameters: PutCommunicationLabelsOrdersOperationRequest, initOverrides?: RequestInit): Promise<GetCommunicationLabelsResponse>;

}

/**
 * 
 */
export class CommunicationLabelsApi extends runtime.BaseAPI implements CommunicationLabelsApiInterface {

    /**
     * 自身のテナントにおける、IDで指定されたコミュニケーションラベルマスタを1件削除する 
     * コミュニケーションラベルマスタの削除
     */
    async deleteCommunicationLabelIdRaw(requestParameters: DeleteCommunicationLabelIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCommunicationLabelId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_labels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 自身のテナントにおける、IDで指定されたコミュニケーションラベルマスタを1件削除する 
     * コミュニケーションラベルマスタの削除
     */
    async deleteCommunicationLabelId(requestParameters: DeleteCommunicationLabelIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteCommunicationLabelIdRaw(requestParameters, initOverrides);
    }

    /**
     * 自身のテナントにおけるコミュニケーションラベルマスタの一覧を取得する 
     * コミュニケーションラベルマスタの一覧を取得する
     */
    async getCommunicationLabelsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCommunicationLabelsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_labels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCommunicationLabelsResponseFromJSON(jsonValue));
    }

    /**
     * 自身のテナントにおけるコミュニケーションラベルマスタの一覧を取得する 
     * コミュニケーションラベルマスタの一覧を取得する
     */
    async getCommunicationLabels(initOverrides?: RequestInit): Promise<GetCommunicationLabelsResponse> {
        const response = await this.getCommunicationLabelsRaw(initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションラベルマスタを１件登録する。 
     * コミュニケーションラベルマスタの登録
     */
    async postCommunicationLabelsRaw(requestParameters: PostCommunicationLabelsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationLabel>> {
        if (requestParameters.postCommunicationLabelsRequest === null || requestParameters.postCommunicationLabelsRequest === undefined) {
            throw new runtime.RequiredError('postCommunicationLabelsRequest','Required parameter requestParameters.postCommunicationLabelsRequest was null or undefined when calling postCommunicationLabels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_labels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCommunicationLabelsRequestToJSON(requestParameters.postCommunicationLabelsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationLabelFromJSON(jsonValue));
    }

    /**
     * コミュニケーションラベルマスタを１件登録する。 
     * コミュニケーションラベルマスタの登録
     */
    async postCommunicationLabels(requestParameters: PostCommunicationLabelsOperationRequest, initOverrides?: RequestInit): Promise<CommunicationLabel> {
        const response = await this.postCommunicationLabelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 自身のテナントにおける、IDで指定されたコミュニケーションラベルマスタを1件更新する 
     * コミュニケーションラベルマスタの更新
     */
    async putCommunicationLabelIdRaw(requestParameters: PutCommunicationLabelIdOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationLabel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putCommunicationLabelId.');
        }

        if (requestParameters.putCommunicationLabelIdRequest === null || requestParameters.putCommunicationLabelIdRequest === undefined) {
            throw new runtime.RequiredError('putCommunicationLabelIdRequest','Required parameter requestParameters.putCommunicationLabelIdRequest was null or undefined when calling putCommunicationLabelId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_labels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutCommunicationLabelIdRequestToJSON(requestParameters.putCommunicationLabelIdRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationLabelFromJSON(jsonValue));
    }

    /**
     * 自身のテナントにおける、IDで指定されたコミュニケーションラベルマスタを1件更新する 
     * コミュニケーションラベルマスタの更新
     */
    async putCommunicationLabelId(requestParameters: PutCommunicationLabelIdOperationRequest, initOverrides?: RequestInit): Promise<CommunicationLabel> {
        const response = await this.putCommunicationLabelIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションラベルマスタを渡されたIDの順に並び替える 
     * コミュニケーションラベルマスタの並び替え
     */
    async putCommunicationLabelsOrdersRaw(requestParameters: PutCommunicationLabelsOrdersOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetCommunicationLabelsResponse>> {
        if (requestParameters.putCommunicationLabelsOrdersRequest === null || requestParameters.putCommunicationLabelsOrdersRequest === undefined) {
            throw new runtime.RequiredError('putCommunicationLabelsOrdersRequest','Required parameter requestParameters.putCommunicationLabelsOrdersRequest was null or undefined when calling putCommunicationLabelsOrders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communication_labels/orders`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutCommunicationLabelsOrdersRequestToJSON(requestParameters.putCommunicationLabelsOrdersRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCommunicationLabelsResponseFromJSON(jsonValue));
    }

    /**
     * コミュニケーションラベルマスタを渡されたIDの順に並び替える 
     * コミュニケーションラベルマスタの並び替え
     */
    async putCommunicationLabelsOrders(requestParameters: PutCommunicationLabelsOrdersOperationRequest, initOverrides?: RequestInit): Promise<GetCommunicationLabelsResponse> {
        const response = await this.putCommunicationLabelsOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
