/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionFiltersCommunicationVisit,
    AlertConditionFiltersCommunicationVisitFromJSON,
    AlertConditionFiltersCommunicationVisitFromJSONTyped,
    AlertConditionFiltersCommunicationVisitToJSON,
} from './AlertConditionFiltersCommunicationVisit';
import {
    AlertConditionFiltersDepartments,
    AlertConditionFiltersDepartmentsFromJSON,
    AlertConditionFiltersDepartmentsFromJSONTyped,
    AlertConditionFiltersDepartmentsToJSON,
} from './AlertConditionFiltersDepartments';
import {
    AlertConditionFiltersFacilityTypeGroups,
    AlertConditionFiltersFacilityTypeGroupsFromJSON,
    AlertConditionFiltersFacilityTypeGroupsFromJSONTyped,
    AlertConditionFiltersFacilityTypeGroupsToJSON,
} from './AlertConditionFiltersFacilityTypeGroups';
import {
    AlertConditionFiltersReferralAverage,
    AlertConditionFiltersReferralAverageFromJSON,
    AlertConditionFiltersReferralAverageFromJSONTyped,
    AlertConditionFiltersReferralAverageToJSON,
} from './AlertConditionFiltersReferralAverage';
import {
    AlertConditionFiltersReferralCount,
    AlertConditionFiltersReferralCountFromJSON,
    AlertConditionFiltersReferralCountFromJSONTyped,
    AlertConditionFiltersReferralCountToJSON,
} from './AlertConditionFiltersReferralCount';
import {
    AlertConditionFiltersTagGroups,
    AlertConditionFiltersTagGroupsFromJSON,
    AlertConditionFiltersTagGroupsFromJSONTyped,
    AlertConditionFiltersTagGroupsToJSON,
} from './AlertConditionFiltersTagGroups';

/**
 * 
 * @export
 * @interface AlertConditionFilters
 */
export interface AlertConditionFilters {
    /**
     * 医療機関の絞り込み要素の都道府県
     * @type {string}
     * @memberof AlertConditionFilters
     */
    prefecture?: string | null;
    /**
     * 医療機関の絞り込み要素の地域
     * @type {string}
     * @memberof AlertConditionFilters
     */
    area?: string | null;
    /**
     * 
     * @type {AlertConditionFiltersFacilityTypeGroups}
     * @memberof AlertConditionFilters
     */
    facilityTypeGroups?: AlertConditionFiltersFacilityTypeGroups | null;
    /**
     * 
     * @type {AlertConditionFiltersDepartments}
     * @memberof AlertConditionFilters
     */
    departments?: AlertConditionFiltersDepartments | null;
    /**
     * 
     * @type {AlertConditionFiltersTagGroups}
     * @memberof AlertConditionFilters
     */
    tagGroups?: AlertConditionFiltersTagGroups | null;
    /**
     * 
     * @type {AlertConditionFiltersReferralCount}
     * @memberof AlertConditionFilters
     */
    referralCount?: AlertConditionFiltersReferralCount | null;
    /**
     * 
     * @type {AlertConditionFiltersReferralAverage}
     * @memberof AlertConditionFilters
     */
    referralAverage?: AlertConditionFiltersReferralAverage | null;
    /**
     * 
     * @type {AlertConditionFiltersCommunicationVisit}
     * @memberof AlertConditionFilters
     */
    communicationVisit?: AlertConditionFiltersCommunicationVisit | null;
}

export function AlertConditionFiltersFromJSON(json: any): AlertConditionFilters {
    return AlertConditionFiltersFromJSONTyped(json, false);
}

export function AlertConditionFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prefecture': !exists(json, 'prefecture') ? undefined : json['prefecture'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'facilityTypeGroups': !exists(json, 'facility_type_groups') ? undefined : AlertConditionFiltersFacilityTypeGroupsFromJSON(json['facility_type_groups']),
        'departments': !exists(json, 'departments') ? undefined : AlertConditionFiltersDepartmentsFromJSON(json['departments']),
        'tagGroups': !exists(json, 'tag_groups') ? undefined : AlertConditionFiltersTagGroupsFromJSON(json['tag_groups']),
        'referralCount': !exists(json, 'referral_count') ? undefined : AlertConditionFiltersReferralCountFromJSON(json['referral_count']),
        'referralAverage': !exists(json, 'referral_average') ? undefined : AlertConditionFiltersReferralAverageFromJSON(json['referral_average']),
        'communicationVisit': !exists(json, 'communication_visit') ? undefined : AlertConditionFiltersCommunicationVisitFromJSON(json['communication_visit']),
    };
}

export function AlertConditionFiltersToJSON(value?: AlertConditionFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prefecture': value.prefecture,
        'area': value.area,
        'facility_type_groups': AlertConditionFiltersFacilityTypeGroupsToJSON(value.facilityTypeGroups),
        'departments': AlertConditionFiltersDepartmentsToJSON(value.departments),
        'tag_groups': AlertConditionFiltersTagGroupsToJSON(value.tagGroups),
        'referral_count': AlertConditionFiltersReferralCountToJSON(value.referralCount),
        'referral_average': AlertConditionFiltersReferralAverageToJSON(value.referralAverage),
        'communication_visit': AlertConditionFiltersCommunicationVisitToJSON(value.communicationVisit),
    };
}

