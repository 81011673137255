export const Scroll = {
  forbid: (): void => {
    const htmlEl = document.getElementsByTagName('html')[0];

    // NOTE: position: fixed 適用前に、現在のスクロール位置を取得。
    const scrollTop = htmlEl.scrollTop;

    htmlEl.style.overflow = 'hidden';
    // TODO: 以下の追加スタイル郡は SP 用。将来的にこの Scroll 関数は hooks 化し、
    // useResponsive によって追加スタイル郡をデバイスによって分岐させたほうが良い。
    htmlEl.style.position = 'fixed';
    htmlEl.style.top = `-${scrollTop}px`;
    htmlEl.style.width = '100%';
  },
  allow: (): void => {
    const htmlEl = document.getElementsByTagName('html')[0];

    // NOTE: 一旦 top プロパティの数値を保持。
    // スタイル削除後に、forbid 関数発火前にスクロールしていた分を元に戻す。
    const top = htmlEl.style.top;

    htmlEl.style.overflow = '';
    htmlEl.style.position = '';
    htmlEl.style.top = '';
    htmlEl.style.width = '';

    window.scrollTo(0, parseInt(String(top)) * -1);
  },
  scrollTop: (): void => {
    document.getElementById('modal-main')?.scrollTo(0, 0);
  },
};
