/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionComparisonReference,
    AlertConditionComparisonReferenceFromJSON,
    AlertConditionComparisonReferenceFromJSONTyped,
    AlertConditionComparisonReferenceToJSON,
} from './AlertConditionComparisonReference';
import {
    AlertConditionUnitType,
    AlertConditionUnitTypeFromJSON,
    AlertConditionUnitTypeFromJSONTyped,
    AlertConditionUnitTypeToJSON,
} from './AlertConditionUnitType';

/**
 * 
 * @export
 * @interface PostNewAlertConditionTriggersReferralChangeRate
 */
export interface PostNewAlertConditionTriggersReferralChangeRate {
    /**
     * 紹介数の増減率
     * @type {number}
     * @memberof PostNewAlertConditionTriggersReferralChangeRate
     */
    rate: number;
    /**
     * 
     * @type {AlertConditionUnitType}
     * @memberof PostNewAlertConditionTriggersReferralChangeRate
     */
    unitType: AlertConditionUnitType;
    /**
     * 
     * @type {AlertConditionComparisonReference}
     * @memberof PostNewAlertConditionTriggersReferralChangeRate
     */
    comparisonReference: AlertConditionComparisonReference;
    /**
     * 自院の診療科目一覧
     * @type {Array<string>}
     * @memberof PostNewAlertConditionTriggersReferralChangeRate
     */
    ownDepartmentIds: Array<string> | null;
}

export function PostNewAlertConditionTriggersReferralChangeRateFromJSON(json: any): PostNewAlertConditionTriggersReferralChangeRate {
    return PostNewAlertConditionTriggersReferralChangeRateFromJSONTyped(json, false);
}

export function PostNewAlertConditionTriggersReferralChangeRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostNewAlertConditionTriggersReferralChangeRate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rate': json['rate'],
        'unitType': AlertConditionUnitTypeFromJSON(json['unit_type']),
        'comparisonReference': AlertConditionComparisonReferenceFromJSON(json['comparison_reference']),
        'ownDepartmentIds': json['own_department_ids'],
    };
}

export function PostNewAlertConditionTriggersReferralChangeRateToJSON(value?: PostNewAlertConditionTriggersReferralChangeRate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rate': value.rate,
        'unit_type': AlertConditionUnitTypeToJSON(value.unitType),
        'comparison_reference': AlertConditionComparisonReferenceToJSON(value.comparisonReference),
        'own_department_ids': value.ownDepartmentIds,
    };
}

