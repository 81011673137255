/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationLabel,
    CommunicationLabelFromJSON,
    CommunicationLabelFromJSONTyped,
    CommunicationLabelToJSON,
} from './CommunicationLabel';

/**
 * コミュニケーションラベルマスタ一覧のレスポンスレイアウト
 * @export
 * @interface GetCommunicationLabelsResponse
 */
export interface GetCommunicationLabelsResponse {
    /**
     * 
     * @type {Array<CommunicationLabel>}
     * @memberof GetCommunicationLabelsResponse
     */
    visit: Array<CommunicationLabel>;
    /**
     * 
     * @type {Array<CommunicationLabel>}
     * @memberof GetCommunicationLabelsResponse
     */
    newVisit: Array<CommunicationLabel>;
    /**
     * 
     * @type {Array<CommunicationLabel>}
     * @memberof GetCommunicationLabelsResponse
     */
    phone: Array<CommunicationLabel>;
    /**
     * 
     * @type {Array<CommunicationLabel>}
     * @memberof GetCommunicationLabelsResponse
     */
    email: Array<CommunicationLabel>;
    /**
     * 
     * @type {Array<CommunicationLabel>}
     * @memberof GetCommunicationLabelsResponse
     */
    postalItem: Array<CommunicationLabel>;
    /**
     * 
     * @type {Array<CommunicationLabel>}
     * @memberof GetCommunicationLabelsResponse
     */
    eventAttendance: Array<CommunicationLabel>;
    /**
     * 
     * @type {Array<CommunicationLabel>}
     * @memberof GetCommunicationLabelsResponse
     */
    referralAcceptance: Array<CommunicationLabel>;
    /**
     * 
     * @type {Array<CommunicationLabel>}
     * @memberof GetCommunicationLabelsResponse
     */
    refusedReferral: Array<CommunicationLabel>;
    /**
     * 
     * @type {Array<CommunicationLabel>}
     * @memberof GetCommunicationLabelsResponse
     */
    other: Array<CommunicationLabel>;
}

export function GetCommunicationLabelsResponseFromJSON(json: any): GetCommunicationLabelsResponse {
    return GetCommunicationLabelsResponseFromJSONTyped(json, false);
}

export function GetCommunicationLabelsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCommunicationLabelsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visit': ((json['visit'] as Array<any>).map(CommunicationLabelFromJSON)),
        'newVisit': ((json['new_visit'] as Array<any>).map(CommunicationLabelFromJSON)),
        'phone': ((json['phone'] as Array<any>).map(CommunicationLabelFromJSON)),
        'email': ((json['email'] as Array<any>).map(CommunicationLabelFromJSON)),
        'postalItem': ((json['postal_item'] as Array<any>).map(CommunicationLabelFromJSON)),
        'eventAttendance': ((json['event_attendance'] as Array<any>).map(CommunicationLabelFromJSON)),
        'referralAcceptance': ((json['referral_acceptance'] as Array<any>).map(CommunicationLabelFromJSON)),
        'refusedReferral': ((json['refused_referral'] as Array<any>).map(CommunicationLabelFromJSON)),
        'other': ((json['other'] as Array<any>).map(CommunicationLabelFromJSON)),
    };
}

export function GetCommunicationLabelsResponseToJSON(value?: GetCommunicationLabelsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visit': ((value.visit as Array<any>).map(CommunicationLabelToJSON)),
        'new_visit': ((value.newVisit as Array<any>).map(CommunicationLabelToJSON)),
        'phone': ((value.phone as Array<any>).map(CommunicationLabelToJSON)),
        'email': ((value.email as Array<any>).map(CommunicationLabelToJSON)),
        'postal_item': ((value.postalItem as Array<any>).map(CommunicationLabelToJSON)),
        'event_attendance': ((value.eventAttendance as Array<any>).map(CommunicationLabelToJSON)),
        'referral_acceptance': ((value.referralAcceptance as Array<any>).map(CommunicationLabelToJSON)),
        'refused_referral': ((value.refusedReferral as Array<any>).map(CommunicationLabelToJSON)),
        'other': ((value.other as Array<any>).map(CommunicationLabelToJSON)),
    };
}

