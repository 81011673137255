/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaReportItemDefinitionPreset,
    AreaReportItemDefinitionPresetFromJSON,
    AreaReportItemDefinitionPresetFromJSONTyped,
    AreaReportItemDefinitionPresetToJSON,
} from './AreaReportItemDefinitionPreset';

/**
 * 
 * @export
 * @interface GetAreaReportItemDefinitionsResponse
 */
export interface GetAreaReportItemDefinitionsResponse {
    /**
     * 
     * @type {Array<AreaReportItemDefinitionPreset>}
     * @memberof GetAreaReportItemDefinitionsResponse
     */
    presets: Array<AreaReportItemDefinitionPreset>;
}

export function GetAreaReportItemDefinitionsResponseFromJSON(json: any): GetAreaReportItemDefinitionsResponse {
    return GetAreaReportItemDefinitionsResponseFromJSONTyped(json, false);
}

export function GetAreaReportItemDefinitionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAreaReportItemDefinitionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presets': ((json['presets'] as Array<any>).map(AreaReportItemDefinitionPresetFromJSON)),
    };
}

export function GetAreaReportItemDefinitionsResponseToJSON(value?: GetAreaReportItemDefinitionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'presets': ((value.presets as Array<any>).map(AreaReportItemDefinitionPresetToJSON)),
    };
}

