/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportItem,
    MedicalInstitutionReportItemFromJSON,
    MedicalInstitutionReportItemFromJSONTyped,
    MedicalInstitutionReportItemToJSON,
} from './MedicalInstitutionReportItem';

/**
 * 連携先レポート項目
 * @export
 * @interface MedicalInstitutionReportSortCondition
 */
export interface MedicalInstitutionReportSortCondition {
    /**
     * ソート条件演算子
     * @type {string}
     * @memberof MedicalInstitutionReportSortCondition
     */
    operator: MedicalInstitutionReportSortConditionOperatorEnum;
    /**
     * 
     * @type {MedicalInstitutionReportItem}
     * @memberof MedicalInstitutionReportSortCondition
     */
    item: MedicalInstitutionReportItem;
}

/**
* @export
* @enum {string}
*/
export enum MedicalInstitutionReportSortConditionOperatorEnum {
    Asc = 'asc',
    Desc = 'desc'
}

export function MedicalInstitutionReportSortConditionFromJSON(json: any): MedicalInstitutionReportSortCondition {
    return MedicalInstitutionReportSortConditionFromJSONTyped(json, false);
}

export function MedicalInstitutionReportSortConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportSortCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operator': json['operator'],
        'item': MedicalInstitutionReportItemFromJSON(json['item']),
    };
}

export function MedicalInstitutionReportSortConditionToJSON(value?: MedicalInstitutionReportSortCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operator': value.operator,
        'item': MedicalInstitutionReportItemToJSON(value.item),
    };
}

