/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableMedicalInstitutionRunningStatusEnum,
    NullableMedicalInstitutionRunningStatusEnumFromJSON,
    NullableMedicalInstitutionRunningStatusEnumFromJSONTyped,
    NullableMedicalInstitutionRunningStatusEnumToJSON,
} from './NullableMedicalInstitutionRunningStatusEnum';

/**
 * 
 * @export
 * @interface EmailReport
 */
export interface EmailReport {
    /**
     * メールレポートのID
     * @type {number}
     * @memberof EmailReport
     */
    id: number;
    /**
     * 医療機関ID
     * @type {number}
     * @memberof EmailReport
     */
    medicalInstitutionId: number | null;
    /**
     * 医療機関名
     * @type {string}
     * @memberof EmailReport
     */
    medicalInstitutionName: string | null;
    /**
     * 
     * @type {NullableMedicalInstitutionRunningStatusEnum}
     * @memberof EmailReport
     */
    medicalInstitutionRunningStatus: NullableMedicalInstitutionRunningStatusEnum | null;
    /**
     * 宛先
     * @type {string}
     * @memberof EmailReport
     */
    destination: string;
    /**
     * 敬称
     * @type {string}
     * @memberof EmailReport
     */
    honorific: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof EmailReport
     */
    email: string;
    /**
     * 送付済みかどうか
     * @type {boolean}
     * @memberof EmailReport
     */
    isDelivered: boolean;
    /**
     * 開封済みかどうか
     * @type {boolean}
     * @memberof EmailReport
     */
    isOpened: boolean;
    /**
     * クリックしたかどうか
     * @type {boolean}
     * @memberof EmailReport
     */
    isClicked: boolean;
}

export function EmailReportFromJSON(json: any): EmailReport {
    return EmailReportFromJSONTyped(json, false);
}

export function EmailReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'medicalInstitutionId': json['medical_institution_id'],
        'medicalInstitutionName': json['medical_institution_name'],
        'medicalInstitutionRunningStatus': NullableMedicalInstitutionRunningStatusEnumFromJSON(json['medical_institution_running_status']),
        'destination': json['destination'],
        'honorific': json['honorific'],
        'email': json['email'],
        'isDelivered': json['is_delivered'],
        'isOpened': json['is_opened'],
        'isClicked': json['is_clicked'],
    };
}

export function EmailReportToJSON(value?: EmailReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'medical_institution_id': value.medicalInstitutionId,
        'medical_institution_name': value.medicalInstitutionName,
        'medical_institution_running_status': NullableMedicalInstitutionRunningStatusEnumToJSON(value.medicalInstitutionRunningStatus),
        'destination': value.destination,
        'honorific': value.honorific,
        'email': value.email,
        'is_delivered': value.isDelivered,
        'is_opened': value.isOpened,
        'is_clicked': value.isClicked,
    };
}

