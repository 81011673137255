/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnDepartmentReportItemAggregationCondition,
    OwnDepartmentReportItemAggregationConditionFromJSON,
    OwnDepartmentReportItemAggregationConditionFromJSONTyped,
    OwnDepartmentReportItemAggregationConditionToJSON,
} from './OwnDepartmentReportItemAggregationCondition';
import {
    OwnDepartmentReportItemEnabledAggregationMethodEnum,
    OwnDepartmentReportItemEnabledAggregationMethodEnumFromJSON,
    OwnDepartmentReportItemEnabledAggregationMethodEnumFromJSONTyped,
    OwnDepartmentReportItemEnabledAggregationMethodEnumToJSON,
} from './OwnDepartmentReportItemEnabledAggregationMethodEnum';

/**
 * 連携先レポート項目。循環参照を防ぐためにformulaを落としたもの
 * @export
 * @interface OwnDepartmentReportItemForFormula
 */
export interface OwnDepartmentReportItemForFormula {
    /**
     * 連携先レポート項目定義を一意に特定するキー名
     * @type {string}
     * @memberof OwnDepartmentReportItemForFormula
     */
    key: string;
    /**
     * コミュニケーション回数項目を診療科IDで紐づけるためのコミュニケーションラベルID
     * @type {number}
     * @memberof OwnDepartmentReportItemForFormula
     */
    communicationLabelId?: number;
    /**
     * 
     * @type {OwnDepartmentReportItemAggregationCondition}
     * @memberof OwnDepartmentReportItemForFormula
     */
    aggregationConditions?: OwnDepartmentReportItemAggregationCondition;
    /**
     * 
     * @type {OwnDepartmentReportItemEnabledAggregationMethodEnum}
     * @memberof OwnDepartmentReportItemForFormula
     */
    aggregationMethod?: OwnDepartmentReportItemEnabledAggregationMethodEnum;
}

export function OwnDepartmentReportItemForFormulaFromJSON(json: any): OwnDepartmentReportItemForFormula {
    return OwnDepartmentReportItemForFormulaFromJSONTyped(json, false);
}

export function OwnDepartmentReportItemForFormulaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportItemForFormula {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'communicationLabelId': !exists(json, 'communication_label_id') ? undefined : json['communication_label_id'],
        'aggregationConditions': !exists(json, 'aggregation_conditions') ? undefined : OwnDepartmentReportItemAggregationConditionFromJSON(json['aggregation_conditions']),
        'aggregationMethod': !exists(json, 'aggregation_method') ? undefined : OwnDepartmentReportItemEnabledAggregationMethodEnumFromJSON(json['aggregation_method']),
    };
}

export function OwnDepartmentReportItemForFormulaToJSON(value?: OwnDepartmentReportItemForFormula | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'communication_label_id': value.communicationLabelId,
        'aggregation_conditions': OwnDepartmentReportItemAggregationConditionToJSON(value.aggregationConditions),
        'aggregation_method': OwnDepartmentReportItemEnabledAggregationMethodEnumToJSON(value.aggregationMethod),
    };
}

