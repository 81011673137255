import { GlobalHeader } from '@/src/components/features/Layout/GlobalHeader';
import { Button } from '@/src/components/foundations/Buttons/Button';
import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { Divider } from '@/src/components/foundations/Layouts/Divider';
import { FetchError } from '@/src/error/fetchError/FetchError';

import styles from './styles.module.scss';

type Props = {
  fetchError: FetchError;
  onClickButton: VoidFunction;
};

export const ErrorPresenter: React.FC<Props> = (props) => (
  <div className={styles.layout}>
    <GlobalHeader />
    <div className={styles.base}>
      <Card>
        <div className={styles.container}>
          <div className={styles.topContainer}>
            <h2 className={styles.statusCode}>{props.fetchError.statusCode}</h2>
          </div>
          <Divider />
          <div className={styles.bottomContainer}>
            <h3 className={styles.summary}>{props.fetchError.summary()}</h3>
            <p className={styles.description}>
              {props.fetchError.description()}
            </p>
            <div className={styles.buttonWrapper}>
              <Button color="primary" onClick={props.onClickButton}>
                TOPページに戻る
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
);
