/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 緯度の範囲
 * @export
 * @interface PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitude
 */
export interface PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitude {
    /**
     * 緯度(下限)
     * @type {number}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitude
     */
    min: number;
    /**
     * 緯度(上限)
     * @type {number}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitude
     */
    max: number;
}

export function PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitudeFromJSON(json: any): PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitude {
    return PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitudeFromJSONTyped(json, false);
}

export function PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitudeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitude {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'min': json['min'],
        'max': json['max'],
    };
}

export function PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitudeToJSON(value?: PostMedicalInstitutionSearchForSalesMapRequestBoundsLongitude | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'min': value.min,
        'max': value.max,
    };
}

