/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportDefinitionAggregationAxis,
    MedicalInstitutionReportDefinitionAggregationAxisFromJSON,
    MedicalInstitutionReportDefinitionAggregationAxisFromJSONTyped,
    MedicalInstitutionReportDefinitionAggregationAxisToJSON,
} from './MedicalInstitutionReportDefinitionAggregationAxis';
import {
    MedicalInstitutionReportDefinitionAggregationData,
    MedicalInstitutionReportDefinitionAggregationDataFromJSON,
    MedicalInstitutionReportDefinitionAggregationDataFromJSONTyped,
    MedicalInstitutionReportDefinitionAggregationDataToJSON,
} from './MedicalInstitutionReportDefinitionAggregationData';
import {
    MedicalInstitutionReportFilterCondition,
    MedicalInstitutionReportFilterConditionFromJSON,
    MedicalInstitutionReportFilterConditionFromJSONTyped,
    MedicalInstitutionReportFilterConditionToJSON,
} from './MedicalInstitutionReportFilterCondition';
import {
    MedicalInstitutionReportItemForAggregation,
    MedicalInstitutionReportItemForAggregationFromJSON,
    MedicalInstitutionReportItemForAggregationFromJSONTyped,
    MedicalInstitutionReportItemForAggregationToJSON,
} from './MedicalInstitutionReportItemForAggregation';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionReportAggregationResultsRequest
 */
export interface PostMedicalInstitutionReportAggregationResultsRequest {
    /**
     * レポート項目の表示設定リスト
     * @type {Array<MedicalInstitutionReportItemForAggregation>}
     * @memberof PostMedicalInstitutionReportAggregationResultsRequest
     */
    displayItems: Array<MedicalInstitutionReportItemForAggregation>;
    /**
     * 絞り込み条件リスト
     * @type {Array<MedicalInstitutionReportFilterCondition>}
     * @memberof PostMedicalInstitutionReportAggregationResultsRequest
     */
    filterConditions: Array<MedicalInstitutionReportFilterCondition>;
    /**
     * 
     * @type {MedicalInstitutionReportDefinitionAggregationAxis}
     * @memberof PostMedicalInstitutionReportAggregationResultsRequest
     */
    aggregationRow?: MedicalInstitutionReportDefinitionAggregationAxis | null;
    /**
     * 
     * @type {MedicalInstitutionReportDefinitionAggregationAxis}
     * @memberof PostMedicalInstitutionReportAggregationResultsRequest
     */
    aggregationColumn?: MedicalInstitutionReportDefinitionAggregationAxis | null;
    /**
     * 集計設定：値
     * @type {Array<MedicalInstitutionReportDefinitionAggregationData>}
     * @memberof PostMedicalInstitutionReportAggregationResultsRequest
     */
    aggregationData?: Array<MedicalInstitutionReportDefinitionAggregationData>;
}

export function PostMedicalInstitutionReportAggregationResultsRequestFromJSON(json: any): PostMedicalInstitutionReportAggregationResultsRequest {
    return PostMedicalInstitutionReportAggregationResultsRequestFromJSONTyped(json, false);
}

export function PostMedicalInstitutionReportAggregationResultsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionReportAggregationResultsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayItems': ((json['display_items'] as Array<any>).map(MedicalInstitutionReportItemForAggregationFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(MedicalInstitutionReportFilterConditionFromJSON)),
        'aggregationRow': !exists(json, 'aggregation_row') ? undefined : MedicalInstitutionReportDefinitionAggregationAxisFromJSON(json['aggregation_row']),
        'aggregationColumn': !exists(json, 'aggregation_column') ? undefined : MedicalInstitutionReportDefinitionAggregationAxisFromJSON(json['aggregation_column']),
        'aggregationData': !exists(json, 'aggregation_data') ? undefined : ((json['aggregation_data'] as Array<any>).map(MedicalInstitutionReportDefinitionAggregationDataFromJSON)),
    };
}

export function PostMedicalInstitutionReportAggregationResultsRequestToJSON(value?: PostMedicalInstitutionReportAggregationResultsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_items': ((value.displayItems as Array<any>).map(MedicalInstitutionReportItemForAggregationToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(MedicalInstitutionReportFilterConditionToJSON)),
        'aggregation_row': MedicalInstitutionReportDefinitionAggregationAxisToJSON(value.aggregationRow),
        'aggregation_column': MedicalInstitutionReportDefinitionAggregationAxisToJSON(value.aggregationColumn),
        'aggregation_data': value.aggregationData === undefined ? undefined : ((value.aggregationData as Array<any>).map(MedicalInstitutionReportDefinitionAggregationDataToJSON)),
    };
}

