import { Link } from '@/src/components/foundations/Utils/Link';
import { Scroll } from '@/src/utils/scroll';

import styles from './spStyles.module.scss';
import { PresenterProps } from './types';

import Clear from '@mui/icons-material/Clear';
import clsx from 'clsx';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

export const SpSideMenuPresenter: React.FC<PresenterProps> = (props) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={props.isShown}
      nodeRef={nodeRef}
      classNames={{ ...styles }}
      timeout={250}
      unmountOnExit
      onEnter={Scroll.forbid}
      onExited={Scroll.allow}
    >
      <div className={styles.base} ref={nodeRef}>
        <div className={styles.contentWrapper}>
          <nav className={styles.content}>
            <button
              className={styles.closeSideMenuButton}
              onClick={props.onClickCloseSideMenu}
            >
              <Clear />
            </button>
            <ul className={styles.list}>
              {props.menus.map((menu, i) => (
                <li key={i}>
                  <Link
                    href={menu.urls[0]}
                    className={clsx(
                      styles.item,
                      menu.urls.some((url) =>
                        props.currentPathname.startsWith(url),
                      )
                        ? styles.active
                        : styles.inactive,
                    )}
                    onClick={() => {
                      props.onClickSideMenu(menu.urls[0]);
                      props.onClickCloseSideMenu();
                    }}
                  >
                    <menu.icon size={24} />
                    {menu.text}
                  </Link>
                </li>
              ))}
            </ul>
            <div className={styles.support}>
              <Link
                href="https://medup.zendesk.com/hc/ja"
                isOpenAnotherTab
                className={styles.supportLink}
              >
                サポートページ
              </Link>
            </div>
          </nav>
        </div>
        <div className={styles.overlay} onClick={props.onClickCloseSideMenu} />
      </div>
    </CSSTransition>
  );
};
