/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionLabelValueImport,
    MedicalInstitutionLabelValueImportFromJSON,
    MedicalInstitutionLabelValueImportFromJSONTyped,
    MedicalInstitutionLabelValueImportToJSON,
} from './MedicalInstitutionLabelValueImport';

/**
 * 
 * @export
 * @interface GetImportsMedicalInstitutionLabelValuesResponse
 */
export interface GetImportsMedicalInstitutionLabelValuesResponse {
    /**
     * 紹介データファイルの一覧
     * @type {Array<MedicalInstitutionLabelValueImport>}
     * @memberof GetImportsMedicalInstitutionLabelValuesResponse
     */
    imports: Array<MedicalInstitutionLabelValueImport>;
}

export function GetImportsMedicalInstitutionLabelValuesResponseFromJSON(json: any): GetImportsMedicalInstitutionLabelValuesResponse {
    return GetImportsMedicalInstitutionLabelValuesResponseFromJSONTyped(json, false);
}

export function GetImportsMedicalInstitutionLabelValuesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetImportsMedicalInstitutionLabelValuesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imports': ((json['imports'] as Array<any>).map(MedicalInstitutionLabelValueImportFromJSON)),
    };
}

export function GetImportsMedicalInstitutionLabelValuesResponseToJSON(value?: GetImportsMedicalInstitutionLabelValuesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imports': ((value.imports as Array<any>).map(MedicalInstitutionLabelValueImportToJSON)),
    };
}

