/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationReportDefinition,
    CommunicationReportDefinitionFromJSON,
    CommunicationReportDefinitionFromJSONTyped,
    CommunicationReportDefinitionToJSON,
} from './CommunicationReportDefinition';

/**
 * 
 * @export
 * @interface GetCommunicationReportDefinitionsResponse
 */
export interface GetCommunicationReportDefinitionsResponse {
    /**
     * コミュニケーションレポート定義
     * @type {Array<CommunicationReportDefinition>}
     * @memberof GetCommunicationReportDefinitionsResponse
     */
    definitions: Array<CommunicationReportDefinition>;
}

export function GetCommunicationReportDefinitionsResponseFromJSON(json: any): GetCommunicationReportDefinitionsResponse {
    return GetCommunicationReportDefinitionsResponseFromJSONTyped(json, false);
}

export function GetCommunicationReportDefinitionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCommunicationReportDefinitionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definitions': ((json['definitions'] as Array<any>).map(CommunicationReportDefinitionFromJSON)),
    };
}

export function GetCommunicationReportDefinitionsResponseToJSON(value?: GetCommunicationReportDefinitionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definitions': ((value.definitions as Array<any>).map(CommunicationReportDefinitionToJSON)),
    };
}

