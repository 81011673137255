/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostActionListItemsBulkInsertResponse
 */
export interface PostActionListItemsBulkInsertResponse {
    /**
     * 追加に成功した医療機関の数
     * @type {number}
     * @memberof PostActionListItemsBulkInsertResponse
     */
    numInserts: number;
}

export function PostActionListItemsBulkInsertResponseFromJSON(json: any): PostActionListItemsBulkInsertResponse {
    return PostActionListItemsBulkInsertResponseFromJSONTyped(json, false);
}

export function PostActionListItemsBulkInsertResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostActionListItemsBulkInsertResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numInserts': json['num_inserts'],
    };
}

export function PostActionListItemsBulkInsertResponseToJSON(value?: PostActionListItemsBulkInsertResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'num_inserts': value.numInserts,
    };
}

