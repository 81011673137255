/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
    UploadedFile,
    UploadedFileFromJSON,
    UploadedFileToJSON,
} from '../models';

export interface PostUploadedFileOperationRequest {
    file: Blob;
}

/**
 * UploadedFileApi - interface
 * 
 * @export
 * @interface UploadedFileApiInterface
 */
export interface UploadedFileApiInterface {
    /**
     * 対応ファイルフォーマット (pdf zip jpg jpeg png gif)
     * @summary ファイルをクラウドストレージにアップロード
     * @param {Blob} file アップロードするファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadedFileApiInterface
     */
    postUploadedFileOperationRaw(requestParameters: PostUploadedFileOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UploadedFile>>;

    /**
     * 対応ファイルフォーマット (pdf zip jpg jpeg png gif)
     * ファイルをクラウドストレージにアップロード
     */
    postUploadedFileOperation(requestParameters: PostUploadedFileOperationRequest, initOverrides?: RequestInit): Promise<UploadedFile>;

}

/**
 * 
 */
export class UploadedFileApi extends runtime.BaseAPI implements UploadedFileApiInterface {

    /**
     * 対応ファイルフォーマット (pdf zip jpg jpeg png gif)
     * ファイルをクラウドストレージにアップロード
     */
    async postUploadedFileOperationRaw(requestParameters: PostUploadedFileOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UploadedFile>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling postUploadedFileOperation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/uploaded_files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadedFileFromJSON(jsonValue));
    }

    /**
     * 対応ファイルフォーマット (pdf zip jpg jpeg png gif)
     * ファイルをクラウドストレージにアップロード
     */
    async postUploadedFileOperation(requestParameters: PostUploadedFileOperationRequest, initOverrides?: RequestInit): Promise<UploadedFile> {
        const response = await this.postUploadedFileOperationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
