/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReverseReferralDetail,
    ReverseReferralDetailFromJSON,
    ReverseReferralDetailFromJSONTyped,
    ReverseReferralDetailToJSON,
} from './ReverseReferralDetail';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionReverseReferralsResponse
 */
export interface PostMedicalInstitutionReverseReferralsResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof PostMedicalInstitutionReverseReferralsResponse
     */
    totalPage: number;
    /**
     * 一覧の総要素数
     * @type {number}
     * @memberof PostMedicalInstitutionReverseReferralsResponse
     */
    totalCount: number;
    /**
     * 紹介の一覧
     * @type {Array<ReverseReferralDetail>}
     * @memberof PostMedicalInstitutionReverseReferralsResponse
     */
    reverseReferrals: Array<ReverseReferralDetail>;
}

export function PostMedicalInstitutionReverseReferralsResponseFromJSON(json: any): PostMedicalInstitutionReverseReferralsResponse {
    return PostMedicalInstitutionReverseReferralsResponseFromJSONTyped(json, false);
}

export function PostMedicalInstitutionReverseReferralsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionReverseReferralsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'reverseReferrals': ((json['reverse_referrals'] as Array<any>).map(ReverseReferralDetailFromJSON)),
    };
}

export function PostMedicalInstitutionReverseReferralsResponseToJSON(value?: PostMedicalInstitutionReverseReferralsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'reverse_referrals': ((value.reverseReferrals as Array<any>).map(ReverseReferralDetailToJSON)),
    };
}

