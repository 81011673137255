/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsComparisonRequestPeriod,
    PostChartReferralsComparisonRequestPeriodFromJSON,
    PostChartReferralsComparisonRequestPeriodFromJSONTyped,
    PostChartReferralsComparisonRequestPeriodToJSON,
} from './PostChartReferralsComparisonRequestPeriod';

/**
 * 
 * @export
 * @interface PostGroupsChartReferralsComparisonRequest
 */
export interface PostGroupsChartReferralsComparisonRequest {
    /**
     * グループ病院のID
     * @type {Array<number>}
     * @memberof PostGroupsChartReferralsComparisonRequest
     */
    groupMedicalInstitutionIds: Array<number>;
    /**
     * 
     * @type {PostChartReferralsComparisonRequestPeriod}
     * @memberof PostGroupsChartReferralsComparisonRequest
     */
    period: PostChartReferralsComparisonRequestPeriod;
}

export function PostGroupsChartReferralsComparisonRequestFromJSON(json: any): PostGroupsChartReferralsComparisonRequest {
    return PostGroupsChartReferralsComparisonRequestFromJSONTyped(json, false);
}

export function PostGroupsChartReferralsComparisonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostGroupsChartReferralsComparisonRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupMedicalInstitutionIds': json['group_medical_institution_ids'],
        'period': PostChartReferralsComparisonRequestPeriodFromJSON(json['period']),
    };
}

export function PostGroupsChartReferralsComparisonRequestToJSON(value?: PostGroupsChartReferralsComparisonRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_medical_institution_ids': value.groupMedicalInstitutionIds,
        'period': PostChartReferralsComparisonRequestPeriodToJSON(value.period),
    };
}

