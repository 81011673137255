/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostDataAnalysisRankingReferralAnnualResponseRankings,
    PostDataAnalysisRankingReferralAnnualResponseRankingsFromJSON,
    PostDataAnalysisRankingReferralAnnualResponseRankingsFromJSONTyped,
    PostDataAnalysisRankingReferralAnnualResponseRankingsToJSON,
} from './PostDataAnalysisRankingReferralAnnualResponseRankings';

/**
 * 
 * @export
 * @interface PostDataAnalysisRankingReferralAnnualResponse
 */
export interface PostDataAnalysisRankingReferralAnnualResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof PostDataAnalysisRankingReferralAnnualResponse
     */
    totalPage: number;
    /**
     * ランキングデータ
     * @type {Array<PostDataAnalysisRankingReferralAnnualResponseRankings>}
     * @memberof PostDataAnalysisRankingReferralAnnualResponse
     */
    rankings: Array<PostDataAnalysisRankingReferralAnnualResponseRankings>;
}

export function PostDataAnalysisRankingReferralAnnualResponseFromJSON(json: any): PostDataAnalysisRankingReferralAnnualResponse {
    return PostDataAnalysisRankingReferralAnnualResponseFromJSONTyped(json, false);
}

export function PostDataAnalysisRankingReferralAnnualResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisRankingReferralAnnualResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'rankings': ((json['rankings'] as Array<any>).map(PostDataAnalysisRankingReferralAnnualResponseRankingsFromJSON)),
    };
}

export function PostDataAnalysisRankingReferralAnnualResponseToJSON(value?: PostDataAnalysisRankingReferralAnnualResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'rankings': ((value.rankings as Array<any>).map(PostDataAnalysisRankingReferralAnnualResponseRankingsToJSON)),
    };
}

