/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
} from './CommunicationType';

/**
 * コミュニケーションレポート項目（コミュニケーションラベル項目）定義
 * @export
 * @interface CommunicationReportItemDefinitionCommunicationLabel
 */
export interface CommunicationReportItemDefinitionCommunicationLabel {
    /**
     * クライアントでタグ付きユニオンとして扱うためのタグ
     * @type {string}
     * @memberof CommunicationReportItemDefinitionCommunicationLabel
     */
    type: CommunicationReportItemDefinitionCommunicationLabelTypeEnum;
    /**
     * コミュニケーションレポート項目定義を一意に特定するキー名
     * @type {string}
     * @memberof CommunicationReportItemDefinitionCommunicationLabel
     */
    key: string;
    /**
     * 
     * @type {CommunicationType}
     * @memberof CommunicationReportItemDefinitionCommunicationLabel
     */
    communicationType: CommunicationType;
    /**
     * コミュニケーションラベルID
     * @type {number}
     * @memberof CommunicationReportItemDefinitionCommunicationLabel
     */
    communicationLabelId: number;
    /**
     * デフォルトの項目名
     * @type {string}
     * @memberof CommunicationReportItemDefinitionCommunicationLabel
     */
    label: string;
}

/**
* @export
* @enum {string}
*/
export enum CommunicationReportItemDefinitionCommunicationLabelTypeEnum {
    CommunicationLabel = 'communication_label'
}

export function CommunicationReportItemDefinitionCommunicationLabelFromJSON(json: any): CommunicationReportItemDefinitionCommunicationLabel {
    return CommunicationReportItemDefinitionCommunicationLabelFromJSONTyped(json, false);
}

export function CommunicationReportItemDefinitionCommunicationLabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportItemDefinitionCommunicationLabel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'key': json['key'],
        'communicationType': CommunicationTypeFromJSON(json['communication_type']),
        'communicationLabelId': json['communication_label_id'],
        'label': json['label'],
    };
}

export function CommunicationReportItemDefinitionCommunicationLabelToJSON(value?: CommunicationReportItemDefinitionCommunicationLabel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'key': value.key,
        'communication_type': CommunicationTypeToJSON(value.communicationType),
        'communication_label_id': value.communicationLabelId,
        'label': value.label,
    };
}

