/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionLabelValue,
    MedicalInstitutionLabelValueFromJSON,
    MedicalInstitutionLabelValueFromJSONTyped,
    MedicalInstitutionLabelValueToJSON,
} from './MedicalInstitutionLabelValue';
import {
    MedicalInstitutionRunningStatusEnum,
    MedicalInstitutionRunningStatusEnumFromJSON,
    MedicalInstitutionRunningStatusEnumFromJSONTyped,
    MedicalInstitutionRunningStatusEnumToJSON,
} from './MedicalInstitutionRunningStatusEnum';

/**
 * 医療機関
 * @export
 * @interface MedicalInstitutionForLabelSearchResults
 */
export interface MedicalInstitutionForLabelSearchResults {
    /**
     * 医療機関ID
     * @type {number}
     * @memberof MedicalInstitutionForLabelSearchResults
     */
    id: number;
    /**
     * 名前
     * @type {string}
     * @memberof MedicalInstitutionForLabelSearchResults
     */
    name: string;
    /**
     * 
     * @type {MedicalInstitutionRunningStatusEnum}
     * @memberof MedicalInstitutionForLabelSearchResults
     */
    runningStatus: MedicalInstitutionRunningStatusEnum;
    /**
     * 住所
     * @type {string}
     * @memberof MedicalInstitutionForLabelSearchResults
     */
    address: string;
    /**
     * タグ一覧
     * @type {Array<string>}
     * @memberof MedicalInstitutionForLabelSearchResults
     */
    tags: Array<string>;
    /**
     * 医療機関メモ
     * @type {string}
     * @memberof MedicalInstitutionForLabelSearchResults
     */
    memoContent: string | null;
    /**
     * 医療機関ラベル設定値
     * @type {Array<MedicalInstitutionLabelValue>}
     * @memberof MedicalInstitutionForLabelSearchResults
     */
    labelValues: Array<MedicalInstitutionLabelValue>;
}

export function MedicalInstitutionForLabelSearchResultsFromJSON(json: any): MedicalInstitutionForLabelSearchResults {
    return MedicalInstitutionForLabelSearchResultsFromJSONTyped(json, false);
}

export function MedicalInstitutionForLabelSearchResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionForLabelSearchResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'runningStatus': MedicalInstitutionRunningStatusEnumFromJSON(json['running_status']),
        'address': json['address'],
        'tags': json['tags'],
        'memoContent': json['memo_content'],
        'labelValues': ((json['label_values'] as Array<any>).map(MedicalInstitutionLabelValueFromJSON)),
    };
}

export function MedicalInstitutionForLabelSearchResultsToJSON(value?: MedicalInstitutionForLabelSearchResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'running_status': MedicalInstitutionRunningStatusEnumToJSON(value.runningStatus),
        'address': value.address,
        'tags': value.tags,
        'memo_content': value.memoContent,
        'label_values': ((value.labelValues as Array<any>).map(MedicalInstitutionLabelValueToJSON)),
    };
}

