/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 連携先レポートアラート設定
 * @export
 * @interface MedicalInstitutionReportAlertSetting
 */
export interface MedicalInstitutionReportAlertSetting {
    /**
     * 通知送信先のユーザーIDリスト
     * @type {Array<number>}
     * @memberof MedicalInstitutionReportAlertSetting
     */
    userIds: Array<number>;
}

export function MedicalInstitutionReportAlertSettingFromJSON(json: any): MedicalInstitutionReportAlertSetting {
    return MedicalInstitutionReportAlertSettingFromJSONTyped(json, false);
}

export function MedicalInstitutionReportAlertSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportAlertSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userIds': json['user_ids'],
    };
}

export function MedicalInstitutionReportAlertSettingToJSON(value?: MedicalInstitutionReportAlertSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_ids': value.userIds,
    };
}

