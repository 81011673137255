/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartVisitsNextActionRatesResponseNextActionRates,
    PostChartVisitsNextActionRatesResponseNextActionRatesFromJSON,
    PostChartVisitsNextActionRatesResponseNextActionRatesFromJSONTyped,
    PostChartVisitsNextActionRatesResponseNextActionRatesToJSON,
} from './PostChartVisitsNextActionRatesResponseNextActionRates';

/**
 * 
 * @export
 * @interface PostChartVisitsNextActionRatesResponse
 */
export interface PostChartVisitsNextActionRatesResponse {
    /**
     * ネクストアクション設定率チャートデータ
     * @type {Array<PostChartVisitsNextActionRatesResponseNextActionRates>}
     * @memberof PostChartVisitsNextActionRatesResponse
     */
    nextActionRates: Array<PostChartVisitsNextActionRatesResponseNextActionRates>;
}

export function PostChartVisitsNextActionRatesResponseFromJSON(json: any): PostChartVisitsNextActionRatesResponse {
    return PostChartVisitsNextActionRatesResponseFromJSONTyped(json, false);
}

export function PostChartVisitsNextActionRatesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartVisitsNextActionRatesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextActionRates': ((json['next_action_rates'] as Array<any>).map(PostChartVisitsNextActionRatesResponseNextActionRatesFromJSON)),
    };
}

export function PostChartVisitsNextActionRatesResponseToJSON(value?: PostChartVisitsNextActionRatesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_action_rates': ((value.nextActionRates as Array<any>).map(PostChartVisitsNextActionRatesResponseNextActionRatesToJSON)),
    };
}

