/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostDataAnalysisRankingReferralTotalCountResponseRankings,
    PostDataAnalysisRankingReferralTotalCountResponseRankingsFromJSON,
    PostDataAnalysisRankingReferralTotalCountResponseRankingsFromJSONTyped,
    PostDataAnalysisRankingReferralTotalCountResponseRankingsToJSON,
} from './PostDataAnalysisRankingReferralTotalCountResponseRankings';

/**
 * 
 * @export
 * @interface PostDataAnalysisRankingReferralTotalCountResponse
 */
export interface PostDataAnalysisRankingReferralTotalCountResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof PostDataAnalysisRankingReferralTotalCountResponse
     */
    totalPage: number;
    /**
     * ランキングデータ
     * @type {Array<PostDataAnalysisRankingReferralTotalCountResponseRankings>}
     * @memberof PostDataAnalysisRankingReferralTotalCountResponse
     */
    rankings: Array<PostDataAnalysisRankingReferralTotalCountResponseRankings>;
}

export function PostDataAnalysisRankingReferralTotalCountResponseFromJSON(json: any): PostDataAnalysisRankingReferralTotalCountResponse {
    return PostDataAnalysisRankingReferralTotalCountResponseFromJSONTyped(json, false);
}

export function PostDataAnalysisRankingReferralTotalCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisRankingReferralTotalCountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'rankings': ((json['rankings'] as Array<any>).map(PostDataAnalysisRankingReferralTotalCountResponseRankingsFromJSON)),
    };
}

export function PostDataAnalysisRankingReferralTotalCountResponseToJSON(value?: PostDataAnalysisRankingReferralTotalCountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'rankings': ((value.rankings as Array<any>).map(PostDataAnalysisRankingReferralTotalCountResponseRankingsToJSON)),
    };
}

