/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Communication,
    CommunicationFromJSON,
    CommunicationToJSON,
    CommunicationComment,
    CommunicationCommentFromJSON,
    CommunicationCommentToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostCommunicationCommentRequest,
    PostCommunicationCommentRequestFromJSON,
    PostCommunicationCommentRequestToJSON,
    PostCommunicationsSearchResultsRequest,
    PostCommunicationsSearchResultsRequestFromJSON,
    PostCommunicationsSearchResultsRequestToJSON,
    PostCommunicationsSearchResultsResponse,
    PostCommunicationsSearchResultsResponseFromJSON,
    PostCommunicationsSearchResultsResponseToJSON,
    PutCommunicationCommentRequest,
    PutCommunicationCommentRequestFromJSON,
    PutCommunicationCommentRequestToJSON,
    PutCommunicationRequest,
    PutCommunicationRequestFromJSON,
    PutCommunicationRequestToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteCommunicationRequest {
    id: number;
}

export interface DeleteCommunicationCommentRequest {
    communicationId: number;
    id: number;
}

export interface DeleteCommunicationLikeRequest {
    communicationId: number;
}

export interface GetCommunicationRequest {
    id: number;
}

export interface PostCommunicationCommentOperationRequest {
    communicationId: number;
    postCommunicationCommentRequest: PostCommunicationCommentRequest;
}

export interface PostCommunicationsSearchResultsOperationRequest {
    postCommunicationsSearchResultsRequest: PostCommunicationsSearchResultsRequest;
}

export interface PutCommunicationOperationRequest {
    id: number;
    putCommunicationRequest: PutCommunicationRequest;
}

export interface PutCommunicationCommentOperationRequest {
    communicationId: number;
    id: number;
    putCommunicationCommentRequest: PutCommunicationCommentRequest;
}

export interface PutCommunicationLikeRequest {
    communicationId: number;
}

/**
 * CommunicationsApi - interface
 * 
 * @export
 * @interface CommunicationsApiInterface
 */
export interface CommunicationsApiInterface {
    /**
     * 
     * @summary コミュニケーションの削除
     * @param {number} id コミュニケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationsApiInterface
     */
    deleteCommunicationRaw(requestParameters: DeleteCommunicationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * コミュニケーションの削除
     */
    deleteCommunication(requestParameters: DeleteCommunicationRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * コミュニケーションに紐づくコメントを削除する
     * @summary コミュニケーションコメントの削除
     * @param {number} communicationId コミュニケーションID
     * @param {number} id コメントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationsApiInterface
     */
    deleteCommunicationCommentRaw(requestParameters: DeleteCommunicationCommentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * コミュニケーションに紐づくコメントを削除する
     * コミュニケーションコメントの削除
     */
    deleteCommunicationComment(requestParameters: DeleteCommunicationCommentRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * コミュニケーションに対しての「いいね」を取り消す
     * @summary コミュニケーションのいいねを取り消す
     * @param {number} communicationId コミュニケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationsApiInterface
     */
    deleteCommunicationLikeRaw(requestParameters: DeleteCommunicationLikeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * コミュニケーションに対しての「いいね」を取り消す
     * コミュニケーションのいいねを取り消す
     */
    deleteCommunicationLike(requestParameters: DeleteCommunicationLikeRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * コミュニケーション詳細を id 指定で取得する
     * @summary コミュニケーション詳細の取得
     * @param {number} id コミュニケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationsApiInterface
     */
    getCommunicationRaw(requestParameters: GetCommunicationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Communication>>;

    /**
     * コミュニケーション詳細を id 指定で取得する
     * コミュニケーション詳細の取得
     */
    getCommunication(requestParameters: GetCommunicationRequest, initOverrides?: RequestInit): Promise<Communication>;

    /**
     * コミュニケーションに紐づくコメントを登録する
     * @summary コミュニケーションコメントの登録
     * @param {number} communicationId コミュニケーションID
     * @param {PostCommunicationCommentRequest} postCommunicationCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationsApiInterface
     */
    postCommunicationCommentRaw(requestParameters: PostCommunicationCommentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationComment>>;

    /**
     * コミュニケーションに紐づくコメントを登録する
     * コミュニケーションコメントの登録
     */
    postCommunicationComment(requestParameters: PostCommunicationCommentOperationRequest, initOverrides?: RequestInit): Promise<CommunicationComment>;

    /**
     * 全医療機関のコミュニケーションを検索する 
     * @summary コミュニケーションの検索結果取得(グローバル)
     * @param {PostCommunicationsSearchResultsRequest} postCommunicationsSearchResultsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationsApiInterface
     */
    postCommunicationsSearchResultsRaw(requestParameters: PostCommunicationsSearchResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostCommunicationsSearchResultsResponse>>;

    /**
     * 全医療機関のコミュニケーションを検索する 
     * コミュニケーションの検索結果取得(グローバル)
     */
    postCommunicationsSearchResults(requestParameters: PostCommunicationsSearchResultsOperationRequest, initOverrides?: RequestInit): Promise<PostCommunicationsSearchResultsResponse>;

    /**
     * コミュニケーションを更新する。communication_type で指定したタイプに合う属性を設定すること
     * @summary コミュニケーションの更新
     * @param {number} id コミュニケーションID
     * @param {PutCommunicationRequest} putCommunicationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationsApiInterface
     */
    putCommunicationRaw(requestParameters: PutCommunicationOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Communication>>;

    /**
     * コミュニケーションを更新する。communication_type で指定したタイプに合う属性を設定すること
     * コミュニケーションの更新
     */
    putCommunication(requestParameters: PutCommunicationOperationRequest, initOverrides?: RequestInit): Promise<Communication>;

    /**
     * コミュニケーションに紐づくコメントを更新する
     * @summary コミュニケーションコメントの更新
     * @param {number} communicationId コミュニケーションID
     * @param {number} id コメントID
     * @param {PutCommunicationCommentRequest} putCommunicationCommentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationsApiInterface
     */
    putCommunicationCommentRaw(requestParameters: PutCommunicationCommentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationComment>>;

    /**
     * コミュニケーションに紐づくコメントを更新する
     * コミュニケーションコメントの更新
     */
    putCommunicationComment(requestParameters: PutCommunicationCommentOperationRequest, initOverrides?: RequestInit): Promise<CommunicationComment>;

    /**
     * コミュニケーションに対して「いいね」を付ける
     * @summary コミュニケーションにいいねする
     * @param {number} communicationId コミュニケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationsApiInterface
     */
    putCommunicationLikeRaw(requestParameters: PutCommunicationLikeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * コミュニケーションに対して「いいね」を付ける
     * コミュニケーションにいいねする
     */
    putCommunicationLike(requestParameters: PutCommunicationLikeRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class CommunicationsApi extends runtime.BaseAPI implements CommunicationsApiInterface {

    /**
     * コミュニケーションの削除
     */
    async deleteCommunicationRaw(requestParameters: DeleteCommunicationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCommunication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * コミュニケーションの削除
     */
    async deleteCommunication(requestParameters: DeleteCommunicationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteCommunicationRaw(requestParameters, initOverrides);
    }

    /**
     * コミュニケーションに紐づくコメントを削除する
     * コミュニケーションコメントの削除
     */
    async deleteCommunicationCommentRaw(requestParameters: DeleteCommunicationCommentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.communicationId === null || requestParameters.communicationId === undefined) {
            throw new runtime.RequiredError('communicationId','Required parameter requestParameters.communicationId was null or undefined when calling deleteCommunicationComment.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCommunicationComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communications/{communication_id}/comments/{id}`.replace(`{${"communication_id"}}`, encodeURIComponent(String(requestParameters.communicationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * コミュニケーションに紐づくコメントを削除する
     * コミュニケーションコメントの削除
     */
    async deleteCommunicationComment(requestParameters: DeleteCommunicationCommentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteCommunicationCommentRaw(requestParameters, initOverrides);
    }

    /**
     * コミュニケーションに対しての「いいね」を取り消す
     * コミュニケーションのいいねを取り消す
     */
    async deleteCommunicationLikeRaw(requestParameters: DeleteCommunicationLikeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.communicationId === null || requestParameters.communicationId === undefined) {
            throw new runtime.RequiredError('communicationId','Required parameter requestParameters.communicationId was null or undefined when calling deleteCommunicationLike.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communications/{communication_id}/likes`.replace(`{${"communication_id"}}`, encodeURIComponent(String(requestParameters.communicationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * コミュニケーションに対しての「いいね」を取り消す
     * コミュニケーションのいいねを取り消す
     */
    async deleteCommunicationLike(requestParameters: DeleteCommunicationLikeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteCommunicationLikeRaw(requestParameters, initOverrides);
    }

    /**
     * コミュニケーション詳細を id 指定で取得する
     * コミュニケーション詳細の取得
     */
    async getCommunicationRaw(requestParameters: GetCommunicationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Communication>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCommunication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationFromJSON(jsonValue));
    }

    /**
     * コミュニケーション詳細を id 指定で取得する
     * コミュニケーション詳細の取得
     */
    async getCommunication(requestParameters: GetCommunicationRequest, initOverrides?: RequestInit): Promise<Communication> {
        const response = await this.getCommunicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションに紐づくコメントを登録する
     * コミュニケーションコメントの登録
     */
    async postCommunicationCommentRaw(requestParameters: PostCommunicationCommentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationComment>> {
        if (requestParameters.communicationId === null || requestParameters.communicationId === undefined) {
            throw new runtime.RequiredError('communicationId','Required parameter requestParameters.communicationId was null or undefined when calling postCommunicationComment.');
        }

        if (requestParameters.postCommunicationCommentRequest === null || requestParameters.postCommunicationCommentRequest === undefined) {
            throw new runtime.RequiredError('postCommunicationCommentRequest','Required parameter requestParameters.postCommunicationCommentRequest was null or undefined when calling postCommunicationComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communications/{communication_id}/comments`.replace(`{${"communication_id"}}`, encodeURIComponent(String(requestParameters.communicationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCommunicationCommentRequestToJSON(requestParameters.postCommunicationCommentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationCommentFromJSON(jsonValue));
    }

    /**
     * コミュニケーションに紐づくコメントを登録する
     * コミュニケーションコメントの登録
     */
    async postCommunicationComment(requestParameters: PostCommunicationCommentOperationRequest, initOverrides?: RequestInit): Promise<CommunicationComment> {
        const response = await this.postCommunicationCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 全医療機関のコミュニケーションを検索する 
     * コミュニケーションの検索結果取得(グローバル)
     */
    async postCommunicationsSearchResultsRaw(requestParameters: PostCommunicationsSearchResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostCommunicationsSearchResultsResponse>> {
        if (requestParameters.postCommunicationsSearchResultsRequest === null || requestParameters.postCommunicationsSearchResultsRequest === undefined) {
            throw new runtime.RequiredError('postCommunicationsSearchResultsRequest','Required parameter requestParameters.postCommunicationsSearchResultsRequest was null or undefined when calling postCommunicationsSearchResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communications/search_results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCommunicationsSearchResultsRequestToJSON(requestParameters.postCommunicationsSearchResultsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostCommunicationsSearchResultsResponseFromJSON(jsonValue));
    }

    /**
     * 全医療機関のコミュニケーションを検索する 
     * コミュニケーションの検索結果取得(グローバル)
     */
    async postCommunicationsSearchResults(requestParameters: PostCommunicationsSearchResultsOperationRequest, initOverrides?: RequestInit): Promise<PostCommunicationsSearchResultsResponse> {
        const response = await this.postCommunicationsSearchResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションを更新する。communication_type で指定したタイプに合う属性を設定すること
     * コミュニケーションの更新
     */
    async putCommunicationRaw(requestParameters: PutCommunicationOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Communication>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putCommunication.');
        }

        if (requestParameters.putCommunicationRequest === null || requestParameters.putCommunicationRequest === undefined) {
            throw new runtime.RequiredError('putCommunicationRequest','Required parameter requestParameters.putCommunicationRequest was null or undefined when calling putCommunication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutCommunicationRequestToJSON(requestParameters.putCommunicationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationFromJSON(jsonValue));
    }

    /**
     * コミュニケーションを更新する。communication_type で指定したタイプに合う属性を設定すること
     * コミュニケーションの更新
     */
    async putCommunication(requestParameters: PutCommunicationOperationRequest, initOverrides?: RequestInit): Promise<Communication> {
        const response = await this.putCommunicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションに紐づくコメントを更新する
     * コミュニケーションコメントの更新
     */
    async putCommunicationCommentRaw(requestParameters: PutCommunicationCommentOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationComment>> {
        if (requestParameters.communicationId === null || requestParameters.communicationId === undefined) {
            throw new runtime.RequiredError('communicationId','Required parameter requestParameters.communicationId was null or undefined when calling putCommunicationComment.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putCommunicationComment.');
        }

        if (requestParameters.putCommunicationCommentRequest === null || requestParameters.putCommunicationCommentRequest === undefined) {
            throw new runtime.RequiredError('putCommunicationCommentRequest','Required parameter requestParameters.putCommunicationCommentRequest was null or undefined when calling putCommunicationComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communications/{communication_id}/comments/{id}`.replace(`{${"communication_id"}}`, encodeURIComponent(String(requestParameters.communicationId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutCommunicationCommentRequestToJSON(requestParameters.putCommunicationCommentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationCommentFromJSON(jsonValue));
    }

    /**
     * コミュニケーションに紐づくコメントを更新する
     * コミュニケーションコメントの更新
     */
    async putCommunicationComment(requestParameters: PutCommunicationCommentOperationRequest, initOverrides?: RequestInit): Promise<CommunicationComment> {
        const response = await this.putCommunicationCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーションに対して「いいね」を付ける
     * コミュニケーションにいいねする
     */
    async putCommunicationLikeRaw(requestParameters: PutCommunicationLikeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.communicationId === null || requestParameters.communicationId === undefined) {
            throw new runtime.RequiredError('communicationId','Required parameter requestParameters.communicationId was null or undefined when calling putCommunicationLike.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/communications/{communication_id}/likes`.replace(`{${"communication_id"}}`, encodeURIComponent(String(requestParameters.communicationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * コミュニケーションに対して「いいね」を付ける
     * コミュニケーションにいいねする
     */
    async putCommunicationLike(requestParameters: PutCommunicationLikeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.putCommunicationLikeRaw(requestParameters, initOverrides);
    }

}
