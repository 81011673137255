/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnDepartmentReportDefinition,
    OwnDepartmentReportDefinitionFromJSON,
    OwnDepartmentReportDefinitionFromJSONTyped,
    OwnDepartmentReportDefinitionToJSON,
} from './OwnDepartmentReportDefinition';

/**
 * 
 * @export
 * @interface GetOwnDepartmentReportDefinitionsResponse
 */
export interface GetOwnDepartmentReportDefinitionsResponse {
    /**
     * 診療科レポート定義
     * @type {Array<OwnDepartmentReportDefinition>}
     * @memberof GetOwnDepartmentReportDefinitionsResponse
     */
    definitions: Array<OwnDepartmentReportDefinition>;
}

export function GetOwnDepartmentReportDefinitionsResponseFromJSON(json: any): GetOwnDepartmentReportDefinitionsResponse {
    return GetOwnDepartmentReportDefinitionsResponseFromJSONTyped(json, false);
}

export function GetOwnDepartmentReportDefinitionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOwnDepartmentReportDefinitionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definitions': ((json['definitions'] as Array<any>).map(OwnDepartmentReportDefinitionFromJSON)),
    };
}

export function GetOwnDepartmentReportDefinitionsResponseToJSON(value?: GetOwnDepartmentReportDefinitionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definitions': ((value.definitions as Array<any>).map(OwnDepartmentReportDefinitionToJSON)),
    };
}

