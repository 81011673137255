/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationReportItemCommunicationLabelCondition,
    CommunicationReportItemCommunicationLabelConditionFromJSON,
    CommunicationReportItemCommunicationLabelConditionFromJSONTyped,
    CommunicationReportItemCommunicationLabelConditionToJSON,
} from './CommunicationReportItemCommunicationLabelCondition';

/**
 * レポート項目集計条件
 * @export
 * @interface CommunicationReportItemAggregationCondition
 */
export interface CommunicationReportItemAggregationCondition {
    /**
     * 集計範囲日付の開始月。当月含む。指定がない場合、month_end以前すべての範囲を集計します
     * @type {string}
     * @memberof CommunicationReportItemAggregationCondition
     */
    startMonth?: string;
    /**
     * 集計範囲日付の終端月。当月含む。指定がない場合、month_begin以後すべての範囲を集計します
     * @type {string}
     * @memberof CommunicationReportItemAggregationCondition
     */
    endMonth?: string;
    /**
     * 現在からの相対期間指定条件の始端。正数は未来（Nカ月後）、負数は過去（Mカ月前）。
     * @type {number}
     * @memberof CommunicationReportItemAggregationCondition
     */
    startMonthsAway?: number;
    /**
     * 現在からの相対期間指定条件の終端。正数は未来（Nカ月後）、負数は過去（Mカ月前）。
     * @type {number}
     * @memberof CommunicationReportItemAggregationCondition
     */
    endMonthsAway?: number;
    /**
     * 集計対象とする自院の診療科ID。指定がない場合、全ての診療科を集計します
     * @type {Array<number>}
     * @memberof CommunicationReportItemAggregationCondition
     */
    ownDepartmentIds?: Array<number>;
    /**
     * 集計する日付範囲を年度で指定する。(選択年の04/01~翌年の03/31)
     * @type {string}
     * @memberof CommunicationReportItemAggregationCondition
     */
    fiscalYear?: string;
    /**
     * 現在からの相対年度指定条件。正数は未来（Nカ年度後）、負数は過去（Mカ年度前）。
     * @type {number}
     * @memberof CommunicationReportItemAggregationCondition
     */
    fiscalYearsAway?: number;
    /**
     * 集計対象とする自グループ病院の医療機関ID。指定がない場合、グループ内全ての病院を対象に集計します
     * @type {Array<number>}
     * @memberof CommunicationReportItemAggregationCondition
     */
    tenantMedicalInstitutionIds?: Array<number>;
    /**
     * 連携先レポート項目集計条件で利用するコミュニケーションラベルの配列
     * @type {Array<CommunicationReportItemCommunicationLabelCondition>}
     * @memberof CommunicationReportItemAggregationCondition
     */
    communicationLabels?: Array<CommunicationReportItemCommunicationLabelCondition>;
    /**
     * 集計対象とするDPC6桁の配列
     * @type {Array<string>}
     * @memberof CommunicationReportItemAggregationCondition
     */
    dpc6Digits?: Array<string>;
}

export function CommunicationReportItemAggregationConditionFromJSON(json: any): CommunicationReportItemAggregationCondition {
    return CommunicationReportItemAggregationConditionFromJSONTyped(json, false);
}

export function CommunicationReportItemAggregationConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportItemAggregationCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startMonth': !exists(json, 'start_month') ? undefined : json['start_month'],
        'endMonth': !exists(json, 'end_month') ? undefined : json['end_month'],
        'startMonthsAway': !exists(json, 'start_months_away') ? undefined : json['start_months_away'],
        'endMonthsAway': !exists(json, 'end_months_away') ? undefined : json['end_months_away'],
        'ownDepartmentIds': !exists(json, 'own_department_ids') ? undefined : json['own_department_ids'],
        'fiscalYear': !exists(json, 'fiscal_year') ? undefined : json['fiscal_year'],
        'fiscalYearsAway': !exists(json, 'fiscal_years_away') ? undefined : json['fiscal_years_away'],
        'tenantMedicalInstitutionIds': !exists(json, 'tenant_medical_institution_ids') ? undefined : json['tenant_medical_institution_ids'],
        'communicationLabels': !exists(json, 'communication_labels') ? undefined : ((json['communication_labels'] as Array<any>).map(CommunicationReportItemCommunicationLabelConditionFromJSON)),
        'dpc6Digits': !exists(json, 'dpc_6_digits') ? undefined : json['dpc_6_digits'],
    };
}

export function CommunicationReportItemAggregationConditionToJSON(value?: CommunicationReportItemAggregationCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_month': value.startMonth,
        'end_month': value.endMonth,
        'start_months_away': value.startMonthsAway,
        'end_months_away': value.endMonthsAway,
        'own_department_ids': value.ownDepartmentIds,
        'fiscal_year': value.fiscalYear,
        'fiscal_years_away': value.fiscalYearsAway,
        'tenant_medical_institution_ids': value.tenantMedicalInstitutionIds,
        'communication_labels': value.communicationLabels === undefined ? undefined : ((value.communicationLabels as Array<any>).map(CommunicationReportItemCommunicationLabelConditionToJSON)),
        'dpc_6_digits': value.dpc6Digits,
    };
}

