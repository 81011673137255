/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * セレクト型のラベル検索条件
 * @export
 * @interface SelectionCondition
 */
export interface SelectionCondition {
    /**
     * いずれかの選択肢IDを含む場合に取得する条件（OR）
     * @type {Array<number>}
     * @memberof SelectionCondition
     */
    haveAny?: Array<number> | null;
    /**
     * 全ての選択肢IDを含む場合に取得する条件（AND）
     * @type {Array<number>}
     * @memberof SelectionCondition
     */
    haveAll?: Array<number> | null;
    /**
     * 全ての選択肢IDを含まない場合に取得する条件（NAND）
     * @type {Array<number>}
     * @memberof SelectionCondition
     */
    notHaveAll?: Array<number> | null;
}

export function SelectionConditionFromJSON(json: any): SelectionCondition {
    return SelectionConditionFromJSONTyped(json, false);
}

export function SelectionConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectionCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'haveAny': !exists(json, 'have_any') ? undefined : json['have_any'],
        'haveAll': !exists(json, 'have_all') ? undefined : json['have_all'],
        'notHaveAll': !exists(json, 'not_have_all') ? undefined : json['not_have_all'],
    };
}

export function SelectionConditionToJSON(value?: SelectionCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'have_any': value.haveAny,
        'have_all': value.haveAll,
        'not_have_all': value.notHaveAll,
    };
}

