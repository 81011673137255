const fetchErrorStatusCodes = [401, 403, 404, 429, 500, 503] as const;
type FetchErrorStatusCode = (typeof fetchErrorStatusCodes)[number];
const statusCodes = [400, ...fetchErrorStatusCodes] as const;
export type StatusCode = (typeof statusCodes)[number];

const fetchErrorTextMap: Record<
  FetchErrorStatusCode,
  { summary: string; description: string }
> = {
  401: {
    summary: '指定されたページは表示できません',
    description: 'アクセスするには認証が必要です',
  },
  403: {
    summary: '指定されたページは表示できません',
    description: 'アクセスするには、アクセス許可が必要です',
  },
  404: {
    summary: 'ページが見つかりませんでした',
    description: 'リンクに問題があるか、ページが削除された可能性があります',
  },
  429: {
    summary: 'ただいまページに繋がりにくくなっています',
    description: '時間をおいて、再度接続してください',
  },
  500: {
    summary: '一時的なエラーが発生しています',
    description:
      '大変申し訳ございません。管理者に通知されましたので、対応完了までしばらくお待ちください',
  },
  503: {
    summary: 'ただいまページに繋がりにくくなっています',
    description: '時間をおいて、再度接続してください',
  },
};

export const isStatusCode = (code: number): code is StatusCode => {
  // Union Type型配列の`include`は型情報上、引数としてUnion Typeしかとれない。
  // 仕方ないので`as StatusCode`と無理やりキャストしている。
  // @see https://zenn.dev/hokaccha/articles/a665b7406b9773
  return statusCodes.includes(code as StatusCode);
};

export class FetchError extends Error {
  readonly statusCode: FetchErrorStatusCode;
  readonly originalStatusCode: StatusCode;

  constructor(
    statusCode: StatusCode,
    message?: string,
    options?: ErrorOptions,
  ) {
    super(message, options);

    this.originalStatusCode = statusCode;
    this.statusCode = statusCode === 400 ? 500 : statusCode;
  }

  summary(): string {
    return fetchErrorTextMap[this.statusCode].summary;
  }

  description(): string {
    return fetchErrorTextMap[this.statusCode].description;
  }
}
