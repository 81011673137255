import { useScrimState } from '@/src/hooks/useScrim';

import { ScrimPresenter } from './presenter';

import { useEffect, useState } from 'react';

const DEFAULT_MESSAGE = void 0;
const MESSAGE_RENDER_DELAY_TIME = 3000;

export const Scrim: React.FC = () => {
  const scrim = useScrimState();
  const [message, setMessage] = useState<string | undefined>(DEFAULT_MESSAGE);

  useEffect(() => {
    setMessage(DEFAULT_MESSAGE);

    if (scrim.isShown) {
      const timeoutId = setTimeout(() => {
        setMessage(scrim.message);
      }, MESSAGE_RENDER_DELAY_TIME);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [scrim.isShown]);

  if (!scrim.isShown) {
    return null;
  }

  return <ScrimPresenter message={message} />;
};
