import { useMediaQuery } from 'react-responsive';

const SP_BREAK_POINT = 599;
export const TABLET_BREAK_POINT = 1024;

export const useResponsive = (): {
  isSp: boolean;
  isTablet: boolean;
  isPc: boolean;
} => {
  return {
    isSp: useMediaQuery({
      maxWidth: SP_BREAK_POINT,
    }),
    isTablet: useMediaQuery({
      minWidth: SP_BREAK_POINT + 1,
      maxWidth: TABLET_BREAK_POINT,
    }),
    isPc: useMediaQuery({
      minWidth: TABLET_BREAK_POINT + 1,
    }),
  };
};
