/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * コメント
 * @export
 * @interface CommunicationComment
 */
export interface CommunicationComment {
    /**
     * コメントID
     * @type {number}
     * @memberof CommunicationComment
     */
    id: number;
    /**
     * 
     * @type {NullableStaff}
     * @memberof CommunicationComment
     */
    user: NullableStaff | null;
    /**
     * コメント日付
     * @type {string}
     * @memberof CommunicationComment
     */
    createdDate: string;
    /**
     * コメント本文
     * @type {string}
     * @memberof CommunicationComment
     */
    comment: string;
}

export function CommunicationCommentFromJSON(json: any): CommunicationComment {
    return CommunicationCommentFromJSONTyped(json, false);
}

export function CommunicationCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': NullableStaffFromJSON(json['user']),
        'createdDate': json['created_date'],
        'comment': json['comment'],
    };
}

export function CommunicationCommentToJSON(value?: CommunicationComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user': NullableStaffToJSON(value.user),
        'created_date': value.createdDate,
        'comment': value.comment,
    };
}

