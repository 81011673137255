/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostChartVisitsDoctorInterviewRatesResponseItems
 */
export interface PostChartVisitsDoctorInterviewRatesResponseItems {
    /**
     * 担当者のID
     * @type {string}
     * @memberof PostChartVisitsDoctorInterviewRatesResponseItems
     */
    key: string | null;
    /**
     * 医師面談設定率(小数点1桁)
     * @type {number}
     * @memberof PostChartVisitsDoctorInterviewRatesResponseItems
     */
    value: number;
}

export function PostChartVisitsDoctorInterviewRatesResponseItemsFromJSON(json: any): PostChartVisitsDoctorInterviewRatesResponseItems {
    return PostChartVisitsDoctorInterviewRatesResponseItemsFromJSONTyped(json, false);
}

export function PostChartVisitsDoctorInterviewRatesResponseItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartVisitsDoctorInterviewRatesResponseItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': json['value'],
    };
}

export function PostChartVisitsDoctorInterviewRatesResponseItemsToJSON(value?: PostChartVisitsDoctorInterviewRatesResponseItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
    };
}

