/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostCommunicationLabelsRequestSelections
 */
export interface PostCommunicationLabelsRequestSelections {
    /**
     * 選択肢ラベル
     * @type {string}
     * @memberof PostCommunicationLabelsRequestSelections
     */
    label: string;
}

export function PostCommunicationLabelsRequestSelectionsFromJSON(json: any): PostCommunicationLabelsRequestSelections {
    return PostCommunicationLabelsRequestSelectionsFromJSONTyped(json, false);
}

export function PostCommunicationLabelsRequestSelectionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCommunicationLabelsRequestSelections {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
    };
}

export function PostCommunicationLabelsRequestSelectionsToJSON(value?: PostCommunicationLabelsRequestSelections | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
    };
}

