/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsComparisonRequestPeriod,
    PostChartReferralsComparisonRequestPeriodFromJSON,
    PostChartReferralsComparisonRequestPeriodFromJSONTyped,
    PostChartReferralsComparisonRequestPeriodToJSON,
} from './PostChartReferralsComparisonRequestPeriod';

/**
 * 
 * @export
 * @interface PostChartReferralsComparisonRequest
 */
export interface PostChartReferralsComparisonRequest {
    /**
     * 
     * @type {PostChartReferralsComparisonRequestPeriod}
     * @memberof PostChartReferralsComparisonRequest
     */
    period: PostChartReferralsComparisonRequestPeriod;
    /**
     * 診療科目のID (null の場合は全診療科目の合算)
     * @type {Array<string>}
     * @memberof PostChartReferralsComparisonRequest
     */
    ownDepartmentIds: Array<string> | null;
}

export function PostChartReferralsComparisonRequestFromJSON(json: any): PostChartReferralsComparisonRequest {
    return PostChartReferralsComparisonRequestFromJSONTyped(json, false);
}

export function PostChartReferralsComparisonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReferralsComparisonRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'period': PostChartReferralsComparisonRequestPeriodFromJSON(json['period']),
        'ownDepartmentIds': json['own_department_ids'],
    };
}

export function PostChartReferralsComparisonRequestToJSON(value?: PostChartReferralsComparisonRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'period': PostChartReferralsComparisonRequestPeriodToJSON(value.period),
        'own_department_ids': value.ownDepartmentIds,
    };
}

