/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
} from './CommunicationType';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * 
 * @export
 * @interface CommunicationReportDefinition
 */
export interface CommunicationReportDefinition {
    /**
     * エリアレポートID
     * @type {number}
     * @memberof CommunicationReportDefinition
     */
    id: number;
    /**
     * レポート名
     * @type {string}
     * @memberof CommunicationReportDefinition
     */
    name: string;
    /**
     * 
     * @type {CommunicationType}
     * @memberof CommunicationReportDefinition
     */
    communicationType: CommunicationType;
    /**
     * 
     * @type {NullableStaff}
     * @memberof CommunicationReportDefinition
     */
    createdBy: NullableStaff | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof CommunicationReportDefinition
     */
    createdAt: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof CommunicationReportDefinition
     */
    updatedAt: string;
}

export function CommunicationReportDefinitionFromJSON(json: any): CommunicationReportDefinition {
    return CommunicationReportDefinitionFromJSONTyped(json, false);
}

export function CommunicationReportDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'communicationType': CommunicationTypeFromJSON(json['communication_type']),
        'createdBy': NullableStaffFromJSON(json['created_by']),
        'createdAt': json['created_at'],
        'updatedAt': json['updated_at'],
    };
}

export function CommunicationReportDefinitionToJSON(value?: CommunicationReportDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'communication_type': CommunicationTypeToJSON(value.communicationType),
        'created_by': NullableStaffToJSON(value.createdBy),
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
    };
}

