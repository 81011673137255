/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 項目の表示設定
 * @export
 * @interface CommunicationReportDefinitionDisplayItem
 */
export interface CommunicationReportDefinitionDisplayItem {
    /**
     * UUID
     * @type {string}
     * @memberof CommunicationReportDefinitionDisplayItem
     */
    itemKey: string;
    /**
     * 
     * @type {string}
     * @memberof CommunicationReportDefinitionDisplayItem
     */
    label: string;
}

export function CommunicationReportDefinitionDisplayItemFromJSON(json: any): CommunicationReportDefinitionDisplayItem {
    return CommunicationReportDefinitionDisplayItemFromJSONTyped(json, false);
}

export function CommunicationReportDefinitionDisplayItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportDefinitionDisplayItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemKey': json['item_key'],
        'label': json['label'],
    };
}

export function CommunicationReportDefinitionDisplayItemToJSON(value?: CommunicationReportDefinitionDisplayItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_key': value.itemKey,
        'label': value.label,
    };
}

