import { checkIsCloudApp } from '@/src/utils/checkIsAppEnv';

export const GA_TRACKING_ID = process.env.GA_TRACKING_ID ?? '';

export const gaPageview = (
  user_id: number | null,
  tenant_id: number | undefined,
  url: string,
): void => {
  const params: Record<string, string | object> = { page_path: url };

  if (user_id !== null) {
    params.user_id = user_id.toString();
    params.custom_map = {
      dimension1: 'user_id',
      dimension2: 'tenant_id',
    };
    window.gtag('set', {
      user_id: user_id.toString(),
      tenant_id: tenant_id?.toString() ?? '',
    });
  }
  window.gtag('config', GA_TRACKING_ID, params);
};

type GaEventProps = {
  action: string;
  category: string;
  label: string;
  value?: number;
};

const event = (props: GaEventProps): void => {
  if (checkIsCloudApp()) {
    window.gtag('event', props.action, {
      event_category: props.category,
      event_label: props.label,
      value: props.value,
    });
  }
};

export const gaEventClick = (label: string): void => {
  event({
    action: 'click',
    category: 'User clicks on element',
    label,
  });
};
