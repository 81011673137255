/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    GetTasksResponse,
    GetTasksResponseFromJSON,
    GetTasksResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostTaskRequest,
    PostTaskRequestFromJSON,
    PostTaskRequestToJSON,
    PutTaskRequest,
    PutTaskRequestFromJSON,
    PutTaskRequestToJSON,
    Task,
    TaskFromJSON,
    TaskToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteTaskRequest {
    id: number;
}

export interface GetTaskRequest {
    id: number;
}

export interface GetTasksRequest {
    page?: number;
    perPage?: number;
    status?: GetTasksStatusEnum;
    sortBy?: GetTasksSortByEnum;
    sort?: GetTasksSortEnum;
    medicalInstitutionId?: number;
}

export interface PostTaskOperationRequest {
    postTaskRequest: PostTaskRequest;
}

export interface PutTaskOperationRequest {
    id: number;
    putTaskRequest: PutTaskRequest;
}

/**
 * TasksApi - interface
 * 
 * @export
 * @interface TasksApiInterface
 */
export interface TasksApiInterface {
    /**
     * 
     * @summary タスクの削除
     * @param {number} id タスクID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApiInterface
     */
    deleteTaskRaw(requestParameters: DeleteTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * タスクの削除
     */
    deleteTask(requestParameters: DeleteTaskRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary タスクの取得
     * @param {number} id タスクID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApiInterface
     */
    getTaskRaw(requestParameters: GetTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Task>>;

    /**
     * タスクの取得
     */
    getTask(requestParameters: GetTaskRequest, initOverrides?: RequestInit): Promise<Task>;

    /**
     * 未完了のタスクの一覧がタスク作成日降順で取得できる。 statusを指定することで、完了済み一覧を取得することも可能。 
     * @summary タスク一覧の取得
     * @param {number} [page] ページ番号（１始まり）
     * @param {number} [perPage] １ページあたりの件数
     * @param {'all' | 'incomplete' | 'finished'} [status] 絞り込むステータス
     * @param {'created_at' | 'deadline'} [sortBy] ソート項目
     * @param {'desc' | 'asc'} [sort] ソート順
     * @param {number} [medicalInstitutionId] 医療機関ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApiInterface
     */
    getTasksRaw(requestParameters: GetTasksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTasksResponse>>;

    /**
     * 未完了のタスクの一覧がタスク作成日降順で取得できる。 statusを指定することで、完了済み一覧を取得することも可能。 
     * タスク一覧の取得
     */
    getTasks(requestParameters: GetTasksRequest, initOverrides?: RequestInit): Promise<GetTasksResponse>;

    /**
     * 
     * @summary タスクの作成
     * @param {PostTaskRequest} postTaskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApiInterface
     */
    postTaskRaw(requestParameters: PostTaskOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Task>>;

    /**
     * タスクの作成
     */
    postTask(requestParameters: PostTaskOperationRequest, initOverrides?: RequestInit): Promise<Task>;

    /**
     * 
     * @summary タスクの更新
     * @param {number} id タスクID
     * @param {PutTaskRequest} putTaskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApiInterface
     */
    putTaskRaw(requestParameters: PutTaskOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Task>>;

    /**
     * タスクの更新
     */
    putTask(requestParameters: PutTaskOperationRequest, initOverrides?: RequestInit): Promise<Task>;

}

/**
 * 
 */
export class TasksApi extends runtime.BaseAPI implements TasksApiInterface {

    /**
     * タスクの削除
     */
    async deleteTaskRaw(requestParameters: DeleteTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * タスクの削除
     */
    async deleteTask(requestParameters: DeleteTaskRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteTaskRaw(requestParameters, initOverrides);
    }

    /**
     * タスクの取得
     */
    async getTaskRaw(requestParameters: GetTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     * タスクの取得
     */
    async getTask(requestParameters: GetTaskRequest, initOverrides?: RequestInit): Promise<Task> {
        const response = await this.getTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 未完了のタスクの一覧がタスク作成日降順で取得できる。 statusを指定することで、完了済み一覧を取得することも可能。 
     * タスク一覧の取得
     */
    async getTasksRaw(requestParameters: GetTasksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetTasksResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['per_page'] = requestParameters.perPage;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.medicalInstitutionId !== undefined) {
            queryParameters['medical_institution_id'] = requestParameters.medicalInstitutionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTasksResponseFromJSON(jsonValue));
    }

    /**
     * 未完了のタスクの一覧がタスク作成日降順で取得できる。 statusを指定することで、完了済み一覧を取得することも可能。 
     * タスク一覧の取得
     */
    async getTasks(requestParameters: GetTasksRequest = {}, initOverrides?: RequestInit): Promise<GetTasksResponse> {
        const response = await this.getTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * タスクの作成
     */
    async postTaskRaw(requestParameters: PostTaskOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters.postTaskRequest === null || requestParameters.postTaskRequest === undefined) {
            throw new runtime.RequiredError('postTaskRequest','Required parameter requestParameters.postTaskRequest was null or undefined when calling postTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tasks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostTaskRequestToJSON(requestParameters.postTaskRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     * タスクの作成
     */
    async postTask(requestParameters: PostTaskOperationRequest, initOverrides?: RequestInit): Promise<Task> {
        const response = await this.postTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * タスクの更新
     */
    async putTaskRaw(requestParameters: PutTaskOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putTask.');
        }

        if (requestParameters.putTaskRequest === null || requestParameters.putTaskRequest === undefined) {
            throw new runtime.RequiredError('putTaskRequest','Required parameter requestParameters.putTaskRequest was null or undefined when calling putTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutTaskRequestToJSON(requestParameters.putTaskRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     * タスクの更新
     */
    async putTask(requestParameters: PutTaskOperationRequest, initOverrides?: RequestInit): Promise<Task> {
        const response = await this.putTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetTasksStatusEnum {
    All = 'all',
    Incomplete = 'incomplete',
    Finished = 'finished'
}
/**
    * @export
    * @enum {string}
    */
export enum GetTasksSortByEnum {
    CreatedAt = 'created_at',
    Deadline = 'deadline'
}
/**
    * @export
    * @enum {string}
    */
export enum GetTasksSortEnum {
    Desc = 'desc',
    Asc = 'asc'
}
