/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionSegment,
    MedicalInstitutionSegmentFromJSON,
    MedicalInstitutionSegmentFromJSONTyped,
    MedicalInstitutionSegmentToJSON,
} from './MedicalInstitutionSegment';

/**
 * 
 * @export
 * @interface GetMedicalInstitutionSegmentsResponse
 */
export interface GetMedicalInstitutionSegmentsResponse {
    /**
     * 
     * @type {Array<MedicalInstitutionSegment>}
     * @memberof GetMedicalInstitutionSegmentsResponse
     */
    medicalInstitutionSegments: Array<MedicalInstitutionSegment>;
}

export function GetMedicalInstitutionSegmentsResponseFromJSON(json: any): GetMedicalInstitutionSegmentsResponse {
    return GetMedicalInstitutionSegmentsResponseFromJSONTyped(json, false);
}

export function GetMedicalInstitutionSegmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMedicalInstitutionSegmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutionSegments': ((json['medical_institution_segments'] as Array<any>).map(MedicalInstitutionSegmentFromJSON)),
    };
}

export function GetMedicalInstitutionSegmentsResponseToJSON(value?: GetMedicalInstitutionSegmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution_segments': ((value.medicalInstitutionSegments as Array<any>).map(MedicalInstitutionSegmentToJSON)),
    };
}

