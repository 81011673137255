/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsComparisonDepartmentsResponseItems,
    PostChartReferralsComparisonDepartmentsResponseItemsFromJSON,
    PostChartReferralsComparisonDepartmentsResponseItemsFromJSONTyped,
    PostChartReferralsComparisonDepartmentsResponseItemsToJSON,
} from './PostChartReferralsComparisonDepartmentsResponseItems';

/**
 * 
 * @export
 * @interface PostChartReferralsComparisonDepartmentsResponseReferrals
 */
export interface PostChartReferralsComparisonDepartmentsResponseReferrals {
    /**
     * 年度
     * @type {string}
     * @memberof PostChartReferralsComparisonDepartmentsResponseReferrals
     */
    label: string;
    /**
     * 年度ごとの診療科別紹介数データ
     * @type {Array<PostChartReferralsComparisonDepartmentsResponseItems>}
     * @memberof PostChartReferralsComparisonDepartmentsResponseReferrals
     */
    items: Array<PostChartReferralsComparisonDepartmentsResponseItems>;
}

export function PostChartReferralsComparisonDepartmentsResponseReferralsFromJSON(json: any): PostChartReferralsComparisonDepartmentsResponseReferrals {
    return PostChartReferralsComparisonDepartmentsResponseReferralsFromJSONTyped(json, false);
}

export function PostChartReferralsComparisonDepartmentsResponseReferralsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReferralsComparisonDepartmentsResponseReferrals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'items': ((json['items'] as Array<any>).map(PostChartReferralsComparisonDepartmentsResponseItemsFromJSON)),
    };
}

export function PostChartReferralsComparisonDepartmentsResponseReferralsToJSON(value?: PostChartReferralsComparisonDepartmentsResponseReferrals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'items': ((value.items as Array<any>).map(PostChartReferralsComparisonDepartmentsResponseItemsToJSON)),
    };
}

