/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * エリア型のラベル検索条件
 * @export
 * @interface AreaCondition
 */
export interface AreaCondition {
    /**
     * いずれかのエリアIDに含まれる場合に合致する条件（OR）
     * @type {Array<number>}
     * @memberof AreaCondition
     */
    haveAny?: Array<number> | null;
    /**
     * いずれのエリアIDにも含まれない場合に合致する条件（NOT）
     * @type {Array<number>}
     * @memberof AreaCondition
     */
    notHaveAll?: Array<number> | null;
}

export function AreaConditionFromJSON(json: any): AreaCondition {
    return AreaConditionFromJSONTyped(json, false);
}

export function AreaConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'haveAny': !exists(json, 'have_any') ? undefined : json['have_any'],
        'notHaveAll': !exists(json, 'not_have_all') ? undefined : json['not_have_all'],
    };
}

export function AreaConditionToJSON(value?: AreaCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'have_any': value.haveAny,
        'not_have_all': value.notHaveAll,
    };
}

