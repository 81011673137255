/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 連携先レポート項目集計条件で利用するコミュニケーションラベル (集計条件で利用されるコミュニケーションラベルに対して適用するフィルタ条件を含む)
 * @export
 * @interface AreaReportItemCommunicationLabelCondition
 */
export interface AreaReportItemCommunicationLabelCondition {
    /**
     * コミュニケーションラベルID
     * @type {number}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    communicationLabelId: number;
    /**
     * 指定した文字列が含まれる場合にマッチする条件
     * @type {string}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    contain?: string | null;
    /**
     * trueにすると値が存在しないものもマッチする
     * @type {boolean}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    orNotExists?: boolean | null;
    /**
     * 指定した数値以上の場合にマッチする条件
     * @type {number}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    moreEqual?: number | null;
    /**
     * 指定した数値以下の場合にマッチする条件
     * @type {number}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    lessEqual?: number | null;
    /**
     * 数値が指定したいずれかと合致する場合にマッチする条件 (ラベルタイプ checkbox 以外)
     * @type {Array<number>}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    _in?: Array<number>;
    /**
     * 数値が指定したいずれも含まない場合にマッチする条件 (ラベルタイプ checkbox 以外)
     * @type {Array<number>}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    notIn?: Array<number>;
    /**
     * 指定した月、もしくはそれ以降の場合にマッチする条件
     * @type {string}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    startMonth?: string | null;
    /**
     * 指定した月、もしくはそれ以前の場合にマッチする条件
     * @type {string}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    endMonth?: string | null;
    /**
     * 指定した時刻、もしくはそれ以降の場合にマッチする条件
     * @type {string}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    startTime?: string | null;
    /**
     * 指定した時刻、もしくはそれ以前の場合にマッチする条件
     * @type {string}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    endTime?: string | null;
    /**
     * 指定したもののうちどれか一つでも含んでいる場合にマッチする条件。数値配列に指定可能。 (ラベルタイプ checkbox のみ)
     * @type {Array<number>}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    haveAny?: Array<number>;
    /**
     * 指定したものをすべてを含んでいる場合にマッチする条件。数値配列に指定可能。 (ラベルタイプ checkbox のみ)
     * @type {Array<number>}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    haveAll?: Array<number>;
    /**
     * 指定したものをすべてを含んでいない場合にマッチする条件。数値配列に指定可能。 (ラベルタイプ checkbox のみ)
     * @type {Array<number>}
     * @memberof AreaReportItemCommunicationLabelCondition
     */
    notHaveAll?: Array<number>;
}

export function AreaReportItemCommunicationLabelConditionFromJSON(json: any): AreaReportItemCommunicationLabelCondition {
    return AreaReportItemCommunicationLabelConditionFromJSONTyped(json, false);
}

export function AreaReportItemCommunicationLabelConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaReportItemCommunicationLabelCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'communicationLabelId': json['communication_label_id'],
        'contain': !exists(json, 'contain') ? undefined : json['contain'],
        'orNotExists': !exists(json, 'or_not_exists') ? undefined : json['or_not_exists'],
        'moreEqual': !exists(json, 'more_equal') ? undefined : json['more_equal'],
        'lessEqual': !exists(json, 'less_equal') ? undefined : json['less_equal'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'not_in') ? undefined : json['not_in'],
        'startMonth': !exists(json, 'start_month') ? undefined : json['start_month'],
        'endMonth': !exists(json, 'end_month') ? undefined : json['end_month'],
        'startTime': !exists(json, 'start_time') ? undefined : json['start_time'],
        'endTime': !exists(json, 'end_time') ? undefined : json['end_time'],
        'haveAny': !exists(json, 'have_any') ? undefined : json['have_any'],
        'haveAll': !exists(json, 'have_all') ? undefined : json['have_all'],
        'notHaveAll': !exists(json, 'not_have_all') ? undefined : json['not_have_all'],
    };
}

export function AreaReportItemCommunicationLabelConditionToJSON(value?: AreaReportItemCommunicationLabelCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'communication_label_id': value.communicationLabelId,
        'contain': value.contain,
        'or_not_exists': value.orNotExists,
        'more_equal': value.moreEqual,
        'less_equal': value.lessEqual,
        'in': value._in,
        'not_in': value.notIn,
        'start_month': value.startMonth,
        'end_month': value.endMonth,
        'start_time': value.startTime,
        'end_time': value.endTime,
        'have_any': value.haveAny,
        'have_all': value.haveAll,
        'not_have_all': value.notHaveAll,
    };
}

