/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * レポートプリセット項目定義を特定するキー名
 * @export
 * @enum {string}
 */
export enum CommunicationReportItemKey {
    Date = 'date',
    UserId = 'user_id',
    CommunicationType = 'communication_type',
    Category = 'category',
    Purpose = 'purpose',
    Story = 'story',
    Attachments = 'attachments',
    EventName = 'event_name',
    Memo = 'memo',
    OtherMemo = 'other_memo',
    PersonInChargeHospital = 'person_in_charge_hospital',
    Attendee = 'attendee',
    IsDoctorAttended = 'is_doctor_attended',
    MedicalInstitutionName = 'medical_institution_name',
    MedicalInstitutionRunningStatus = 'medical_institution_running_status',
    Address = 'address',
    PostalCode = 'postal_code',
    PhoneNumber = 'phone_number',
    FaxNumber = 'fax_number',
    Url = 'url',
    Administrator = 'administrator',
    FacilityTypeIds = 'facility_type_ids',
    SpecialtyIds = 'specialty_ids',
    TagIds = 'tag_ids',
    MedicalInstitutionMemo = 'medical_institution_memo',
    LastVisitDate = 'last_visit_date',
    LastDoctorVisitDate = 'last_doctor_visit_date',
    LastReferralRefuseDate = 'last_referral_refuse_date',
    ReferralAdmissionSegmentIds = 'referral_admission_segment_ids',
    Nps = 'nps',
    ReferralCount = 'referral_count',
    ReferralAdmissionCount = 'referral_admission_count',
    ReferralAdmissionRate = 'referral_admission_rate',
    ReferralOperationCount = 'referral_operation_count',
    ReferralOperationRate = 'referral_operation_rate',
    ReverseReferralCount = 'reverse_referral_count',
    FormulaDiff = 'formula_diff',
    FormulaRate = 'formula_rate',
    ReferralAdmissionDiagnosisCount = 'referral_admission_diagnosis_count'
}

export function CommunicationReportItemKeyFromJSON(json: any): CommunicationReportItemKey {
    return CommunicationReportItemKeyFromJSONTyped(json, false);
}

export function CommunicationReportItemKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportItemKey {
    return json as CommunicationReportItemKey;
}

export function CommunicationReportItemKeyToJSON(value?: CommunicationReportItemKey | null): any {
    return value as any;
}

