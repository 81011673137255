/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommunicationLabelConditionTextConditions
 */
export interface CommunicationLabelConditionTextConditions {
    /**
     * 文字列型のラベル条件。指定した文字列が含まれる場合にマッチする条件
     * @type {string}
     * @memberof CommunicationLabelConditionTextConditions
     */
    contain?: string | null;
}

export function CommunicationLabelConditionTextConditionsFromJSON(json: any): CommunicationLabelConditionTextConditions {
    return CommunicationLabelConditionTextConditionsFromJSONTyped(json, false);
}

export function CommunicationLabelConditionTextConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabelConditionTextConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contain': !exists(json, 'contain') ? undefined : json['contain'],
    };
}

export function CommunicationLabelConditionTextConditionsToJSON(value?: CommunicationLabelConditionTextConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contain': value.contain,
    };
}

