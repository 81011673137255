/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    PostDataAnalysisEffectivenessVerificationCommunicationsRequest,
    PostDataAnalysisEffectivenessVerificationCommunicationsRequestFromJSON,
    PostDataAnalysisEffectivenessVerificationCommunicationsRequestToJSON,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponse,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseFromJSON,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseToJSON,
    PostDataAnalysisRankingBothReferralsMonthlyRequest,
    PostDataAnalysisRankingBothReferralsMonthlyRequestFromJSON,
    PostDataAnalysisRankingBothReferralsMonthlyRequestToJSON,
    PostDataAnalysisRankingBothReferralsMonthlyResponse,
    PostDataAnalysisRankingBothReferralsMonthlyResponseFromJSON,
    PostDataAnalysisRankingBothReferralsMonthlyResponseToJSON,
    PostDataAnalysisRankingReferralAnnualRequest,
    PostDataAnalysisRankingReferralAnnualRequestFromJSON,
    PostDataAnalysisRankingReferralAnnualRequestToJSON,
    PostDataAnalysisRankingReferralAnnualResponse,
    PostDataAnalysisRankingReferralAnnualResponseFromJSON,
    PostDataAnalysisRankingReferralAnnualResponseToJSON,
    PostDataAnalysisRankingReferralMonthlyRequest,
    PostDataAnalysisRankingReferralMonthlyRequestFromJSON,
    PostDataAnalysisRankingReferralMonthlyRequestToJSON,
    PostDataAnalysisRankingReferralMonthlyResponse,
    PostDataAnalysisRankingReferralMonthlyResponseFromJSON,
    PostDataAnalysisRankingReferralMonthlyResponseToJSON,
    PostDataAnalysisRankingReferralTotalCountRequest,
    PostDataAnalysisRankingReferralTotalCountRequestFromJSON,
    PostDataAnalysisRankingReferralTotalCountRequestToJSON,
    PostDataAnalysisRankingReferralTotalCountResponse,
    PostDataAnalysisRankingReferralTotalCountResponseFromJSON,
    PostDataAnalysisRankingReferralTotalCountResponseToJSON,
} from '../models';

export interface PostDataAnalysisEffectivenessVerificationCommunicationsOperationRequest {
    postDataAnalysisEffectivenessVerificationCommunicationsRequest: PostDataAnalysisEffectivenessVerificationCommunicationsRequest;
}

export interface PostDataAnalysisRankingBothReferralsMonthlyOperationRequest {
    postDataAnalysisRankingBothReferralsMonthlyRequest: PostDataAnalysisRankingBothReferralsMonthlyRequest;
}

export interface PostDataAnalysisRankingReferralAnnualOperationRequest {
    postDataAnalysisRankingReferralAnnualRequest: PostDataAnalysisRankingReferralAnnualRequest;
}

export interface PostDataAnalysisRankingReferralMonthlyOperationRequest {
    postDataAnalysisRankingReferralMonthlyRequest: PostDataAnalysisRankingReferralMonthlyRequest;
}

export interface PostDataAnalysisRankingReferralTotalCountOperationRequest {
    postDataAnalysisRankingReferralTotalCountRequest: PostDataAnalysisRankingReferralTotalCountRequest;
}

/**
 * DataAnalysisApi - interface
 * 
 * @export
 * @interface DataAnalysisApiInterface
 */
export interface DataAnalysisApiInterface {
    /**
     * 
     * @summary 紹介数の個別効果検証データの生成(データの取得)
     * @param {PostDataAnalysisEffectivenessVerificationCommunicationsRequest} postDataAnalysisEffectivenessVerificationCommunicationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalysisApiInterface
     */
    postDataAnalysisEffectivenessVerificationCommunicationsRaw(requestParameters: PostDataAnalysisEffectivenessVerificationCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostDataAnalysisEffectivenessVerificationCommunicationsResponse>>;

    /**
     * 紹介数の個別効果検証データの生成(データの取得)
     */
    postDataAnalysisEffectivenessVerificationCommunications(requestParameters: PostDataAnalysisEffectivenessVerificationCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<PostDataAnalysisEffectivenessVerificationCommunicationsResponse>;

    /**
     * 
     * @summary 紹介数・逆紹介数の差分の月次ランキングを取得
     * @param {PostDataAnalysisRankingBothReferralsMonthlyRequest} postDataAnalysisRankingBothReferralsMonthlyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalysisApiInterface
     */
    postDataAnalysisRankingBothReferralsMonthlyRaw(requestParameters: PostDataAnalysisRankingBothReferralsMonthlyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostDataAnalysisRankingBothReferralsMonthlyResponse>>;

    /**
     * 紹介数・逆紹介数の差分の月次ランキングを取得
     */
    postDataAnalysisRankingBothReferralsMonthly(requestParameters: PostDataAnalysisRankingBothReferralsMonthlyOperationRequest, initOverrides?: RequestInit): Promise<PostDataAnalysisRankingBothReferralsMonthlyResponse>;

    /**
     * 
     * @summary 紹介数の年次ランキングを取得
     * @param {PostDataAnalysisRankingReferralAnnualRequest} postDataAnalysisRankingReferralAnnualRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalysisApiInterface
     */
    postDataAnalysisRankingReferralAnnualRaw(requestParameters: PostDataAnalysisRankingReferralAnnualOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostDataAnalysisRankingReferralAnnualResponse>>;

    /**
     * 紹介数の年次ランキングを取得
     */
    postDataAnalysisRankingReferralAnnual(requestParameters: PostDataAnalysisRankingReferralAnnualOperationRequest, initOverrides?: RequestInit): Promise<PostDataAnalysisRankingReferralAnnualResponse>;

    /**
     * 
     * @summary 紹介数の月次ランキングを取得
     * @param {PostDataAnalysisRankingReferralMonthlyRequest} postDataAnalysisRankingReferralMonthlyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalysisApiInterface
     */
    postDataAnalysisRankingReferralMonthlyRaw(requestParameters: PostDataAnalysisRankingReferralMonthlyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostDataAnalysisRankingReferralMonthlyResponse>>;

    /**
     * 紹介数の月次ランキングを取得
     */
    postDataAnalysisRankingReferralMonthly(requestParameters: PostDataAnalysisRankingReferralMonthlyOperationRequest, initOverrides?: RequestInit): Promise<PostDataAnalysisRankingReferralMonthlyResponse>;

    /**
     * 
     * @summary 指定した期間の紹介数のランキングを取得
     * @param {PostDataAnalysisRankingReferralTotalCountRequest} postDataAnalysisRankingReferralTotalCountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnalysisApiInterface
     */
    postDataAnalysisRankingReferralTotalCountRaw(requestParameters: PostDataAnalysisRankingReferralTotalCountOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostDataAnalysisRankingReferralTotalCountResponse>>;

    /**
     * 指定した期間の紹介数のランキングを取得
     */
    postDataAnalysisRankingReferralTotalCount(requestParameters: PostDataAnalysisRankingReferralTotalCountOperationRequest, initOverrides?: RequestInit): Promise<PostDataAnalysisRankingReferralTotalCountResponse>;

}

/**
 * 
 */
export class DataAnalysisApi extends runtime.BaseAPI implements DataAnalysisApiInterface {

    /**
     * 紹介数の個別効果検証データの生成(データの取得)
     */
    async postDataAnalysisEffectivenessVerificationCommunicationsRaw(requestParameters: PostDataAnalysisEffectivenessVerificationCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostDataAnalysisEffectivenessVerificationCommunicationsResponse>> {
        if (requestParameters.postDataAnalysisEffectivenessVerificationCommunicationsRequest === null || requestParameters.postDataAnalysisEffectivenessVerificationCommunicationsRequest === undefined) {
            throw new runtime.RequiredError('postDataAnalysisEffectivenessVerificationCommunicationsRequest','Required parameter requestParameters.postDataAnalysisEffectivenessVerificationCommunicationsRequest was null or undefined when calling postDataAnalysisEffectivenessVerificationCommunications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/data_analysis/effectiveness_verification/communications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostDataAnalysisEffectivenessVerificationCommunicationsRequestToJSON(requestParameters.postDataAnalysisEffectivenessVerificationCommunicationsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDataAnalysisEffectivenessVerificationCommunicationsResponseFromJSON(jsonValue));
    }

    /**
     * 紹介数の個別効果検証データの生成(データの取得)
     */
    async postDataAnalysisEffectivenessVerificationCommunications(requestParameters: PostDataAnalysisEffectivenessVerificationCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<PostDataAnalysisEffectivenessVerificationCommunicationsResponse> {
        const response = await this.postDataAnalysisEffectivenessVerificationCommunicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 紹介数・逆紹介数の差分の月次ランキングを取得
     */
    async postDataAnalysisRankingBothReferralsMonthlyRaw(requestParameters: PostDataAnalysisRankingBothReferralsMonthlyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostDataAnalysisRankingBothReferralsMonthlyResponse>> {
        if (requestParameters.postDataAnalysisRankingBothReferralsMonthlyRequest === null || requestParameters.postDataAnalysisRankingBothReferralsMonthlyRequest === undefined) {
            throw new runtime.RequiredError('postDataAnalysisRankingBothReferralsMonthlyRequest','Required parameter requestParameters.postDataAnalysisRankingBothReferralsMonthlyRequest was null or undefined when calling postDataAnalysisRankingBothReferralsMonthly.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/data_analysis/ranking/both_referrals/monthly`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostDataAnalysisRankingBothReferralsMonthlyRequestToJSON(requestParameters.postDataAnalysisRankingBothReferralsMonthlyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDataAnalysisRankingBothReferralsMonthlyResponseFromJSON(jsonValue));
    }

    /**
     * 紹介数・逆紹介数の差分の月次ランキングを取得
     */
    async postDataAnalysisRankingBothReferralsMonthly(requestParameters: PostDataAnalysisRankingBothReferralsMonthlyOperationRequest, initOverrides?: RequestInit): Promise<PostDataAnalysisRankingBothReferralsMonthlyResponse> {
        const response = await this.postDataAnalysisRankingBothReferralsMonthlyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 紹介数の年次ランキングを取得
     */
    async postDataAnalysisRankingReferralAnnualRaw(requestParameters: PostDataAnalysisRankingReferralAnnualOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostDataAnalysisRankingReferralAnnualResponse>> {
        if (requestParameters.postDataAnalysisRankingReferralAnnualRequest === null || requestParameters.postDataAnalysisRankingReferralAnnualRequest === undefined) {
            throw new runtime.RequiredError('postDataAnalysisRankingReferralAnnualRequest','Required parameter requestParameters.postDataAnalysisRankingReferralAnnualRequest was null or undefined when calling postDataAnalysisRankingReferralAnnual.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/data_analysis/ranking/referral/annual`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostDataAnalysisRankingReferralAnnualRequestToJSON(requestParameters.postDataAnalysisRankingReferralAnnualRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDataAnalysisRankingReferralAnnualResponseFromJSON(jsonValue));
    }

    /**
     * 紹介数の年次ランキングを取得
     */
    async postDataAnalysisRankingReferralAnnual(requestParameters: PostDataAnalysisRankingReferralAnnualOperationRequest, initOverrides?: RequestInit): Promise<PostDataAnalysisRankingReferralAnnualResponse> {
        const response = await this.postDataAnalysisRankingReferralAnnualRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 紹介数の月次ランキングを取得
     */
    async postDataAnalysisRankingReferralMonthlyRaw(requestParameters: PostDataAnalysisRankingReferralMonthlyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostDataAnalysisRankingReferralMonthlyResponse>> {
        if (requestParameters.postDataAnalysisRankingReferralMonthlyRequest === null || requestParameters.postDataAnalysisRankingReferralMonthlyRequest === undefined) {
            throw new runtime.RequiredError('postDataAnalysisRankingReferralMonthlyRequest','Required parameter requestParameters.postDataAnalysisRankingReferralMonthlyRequest was null or undefined when calling postDataAnalysisRankingReferralMonthly.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/data_analysis/ranking/referral/monthly`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostDataAnalysisRankingReferralMonthlyRequestToJSON(requestParameters.postDataAnalysisRankingReferralMonthlyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDataAnalysisRankingReferralMonthlyResponseFromJSON(jsonValue));
    }

    /**
     * 紹介数の月次ランキングを取得
     */
    async postDataAnalysisRankingReferralMonthly(requestParameters: PostDataAnalysisRankingReferralMonthlyOperationRequest, initOverrides?: RequestInit): Promise<PostDataAnalysisRankingReferralMonthlyResponse> {
        const response = await this.postDataAnalysisRankingReferralMonthlyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 指定した期間の紹介数のランキングを取得
     */
    async postDataAnalysisRankingReferralTotalCountRaw(requestParameters: PostDataAnalysisRankingReferralTotalCountOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostDataAnalysisRankingReferralTotalCountResponse>> {
        if (requestParameters.postDataAnalysisRankingReferralTotalCountRequest === null || requestParameters.postDataAnalysisRankingReferralTotalCountRequest === undefined) {
            throw new runtime.RequiredError('postDataAnalysisRankingReferralTotalCountRequest','Required parameter requestParameters.postDataAnalysisRankingReferralTotalCountRequest was null or undefined when calling postDataAnalysisRankingReferralTotalCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/data_analysis/ranking/referral/total_count`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostDataAnalysisRankingReferralTotalCountRequestToJSON(requestParameters.postDataAnalysisRankingReferralTotalCountRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostDataAnalysisRankingReferralTotalCountResponseFromJSON(jsonValue));
    }

    /**
     * 指定した期間の紹介数のランキングを取得
     */
    async postDataAnalysisRankingReferralTotalCount(requestParameters: PostDataAnalysisRankingReferralTotalCountOperationRequest, initOverrides?: RequestInit): Promise<PostDataAnalysisRankingReferralTotalCountResponse> {
        const response = await this.postDataAnalysisRankingReferralTotalCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
