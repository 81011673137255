/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartVisitsResponseVisits,
    PostChartVisitsResponseVisitsFromJSON,
    PostChartVisitsResponseVisitsFromJSONTyped,
    PostChartVisitsResponseVisitsToJSON,
} from './PostChartVisitsResponseVisits';

/**
 * 
 * @export
 * @interface PostChartVisitsResponse
 */
export interface PostChartVisitsResponse {
    /**
     * 訪問数チャートデータ
     * @type {Array<PostChartVisitsResponseVisits>}
     * @memberof PostChartVisitsResponse
     */
    visits: Array<PostChartVisitsResponseVisits>;
}

export function PostChartVisitsResponseFromJSON(json: any): PostChartVisitsResponse {
    return PostChartVisitsResponseFromJSONTyped(json, false);
}

export function PostChartVisitsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartVisitsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visits': ((json['visits'] as Array<any>).map(PostChartVisitsResponseVisitsFromJSON)),
    };
}

export function PostChartVisitsResponseToJSON(value?: PostChartVisitsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visits': ((value.visits as Array<any>).map(PostChartVisitsResponseVisitsToJSON)),
    };
}

