/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionForLabelSearchResults,
    MedicalInstitutionForLabelSearchResultsFromJSON,
    MedicalInstitutionForLabelSearchResultsFromJSONTyped,
    MedicalInstitutionForLabelSearchResultsToJSON,
} from './MedicalInstitutionForLabelSearchResults';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionSearchForLabelsResponse
 */
export interface PostMedicalInstitutionSearchForLabelsResponse {
    /**
     * 医療機関の一覧
     * @type {Array<MedicalInstitutionForLabelSearchResults>}
     * @memberof PostMedicalInstitutionSearchForLabelsResponse
     */
    medicalInstitutions: Array<MedicalInstitutionForLabelSearchResults>;
}

export function PostMedicalInstitutionSearchForLabelsResponseFromJSON(json: any): PostMedicalInstitutionSearchForLabelsResponse {
    return PostMedicalInstitutionSearchForLabelsResponseFromJSONTyped(json, false);
}

export function PostMedicalInstitutionSearchForLabelsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionSearchForLabelsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutions': ((json['medical_institutions'] as Array<any>).map(MedicalInstitutionForLabelSearchResultsFromJSON)),
    };
}

export function PostMedicalInstitutionSearchForLabelsResponseToJSON(value?: PostMedicalInstitutionSearchForLabelsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institutions': ((value.medicalInstitutions as Array<any>).map(MedicalInstitutionForLabelSearchResultsToJSON)),
    };
}

