/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * アラート条件タイプ
 * @export
 * @enum {string}
 */
export enum AlertConditionType {
    ReferralChangeCount = 'referral_change_count',
    ReferralChangeRate = 'referral_change_rate',
    ReferralCount = 'referral_count',
    ReferralAverage = 'referral_average',
    CommunicationVisit = 'communication_visit'
}

export function AlertConditionTypeFromJSON(json: any): AlertConditionType {
    return AlertConditionTypeFromJSONTyped(json, false);
}

export function AlertConditionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionType {
    return json as AlertConditionType;
}

export function AlertConditionTypeToJSON(value?: AlertConditionType | null): any {
    return value as any;
}

