import { useState, useCallback } from 'react';

import { NoApplicationAuthorizationError } from '../NoApplicationAuthorizationError';

export const useNoApplicationAuthorizationError = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setState] = useState();

  return useCallback((error: unknown) => {
    // reactのrenderサイクル中でエラーを発生させるためにsetStateの中でエラーをthrowする
    setState(() => {
      throw new NoApplicationAuthorizationError(error);
    });
  }, []) as (error: unknown) => never;
};
