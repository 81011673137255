/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * コミュニケーションラベル選択肢
 * @export
 * @interface CommunicationLabelSelection
 */
export interface CommunicationLabelSelection {
    /**
     * コミュニケーションラベル選択肢ID
     * @type {number}
     * @memberof CommunicationLabelSelection
     */
    id: number;
    /**
     * 表示名
     * @type {string}
     * @memberof CommunicationLabelSelection
     */
    label: string;
    /**
     * 表示順
     * @type {number}
     * @memberof CommunicationLabelSelection
     */
    order?: number;
}

export function CommunicationLabelSelectionFromJSON(json: any): CommunicationLabelSelection {
    return CommunicationLabelSelectionFromJSONTyped(json, false);
}

export function CommunicationLabelSelectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabelSelection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function CommunicationLabelSelectionToJSON(value?: CommunicationLabelSelection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'order': value.order,
    };
}

