/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionLabel,
    MedicalInstitutionLabelFromJSON,
    MedicalInstitutionLabelFromJSONTyped,
    MedicalInstitutionLabelToJSON,
} from './MedicalInstitutionLabel';

/**
 * 医療機関ラベルマスタ一覧のレスポンスレイアウト
 * @export
 * @interface GetMedicalInstitutionLabelsResponse
 */
export interface GetMedicalInstitutionLabelsResponse {
    /**
     * 
     * @type {Array<MedicalInstitutionLabel>}
     * @memberof GetMedicalInstitutionLabelsResponse
     */
    labels: Array<MedicalInstitutionLabel>;
}

export function GetMedicalInstitutionLabelsResponseFromJSON(json: any): GetMedicalInstitutionLabelsResponse {
    return GetMedicalInstitutionLabelsResponseFromJSONTyped(json, false);
}

export function GetMedicalInstitutionLabelsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMedicalInstitutionLabelsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labels': ((json['labels'] as Array<any>).map(MedicalInstitutionLabelFromJSON)),
    };
}

export function GetMedicalInstitutionLabelsResponseToJSON(value?: GetMedicalInstitutionLabelsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'labels': ((value.labels as Array<any>).map(MedicalInstitutionLabelToJSON)),
    };
}

