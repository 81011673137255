/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 値の型
 * @export
 * @enum {string}
 */
export enum OwnDepartmentReportItemDefinitionValueTypeEnum {
    String = 'string',
    Number = 'number',
    NumberArray = 'number_array'
}

export function OwnDepartmentReportItemDefinitionValueTypeEnumFromJSON(json: any): OwnDepartmentReportItemDefinitionValueTypeEnum {
    return OwnDepartmentReportItemDefinitionValueTypeEnumFromJSONTyped(json, false);
}

export function OwnDepartmentReportItemDefinitionValueTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportItemDefinitionValueTypeEnum {
    return json as OwnDepartmentReportItemDefinitionValueTypeEnum;
}

export function OwnDepartmentReportItemDefinitionValueTypeEnumToJSON(value?: OwnDepartmentReportItemDefinitionValueTypeEnum | null): any {
    return value as any;
}

