/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TagOptionGroup,
    TagOptionGroupFromJSON,
    TagOptionGroupFromJSONTyped,
    TagOptionGroupToJSON,
} from './TagOptionGroup';

/**
 * タググループ覧を取得 (各タググループは、属しているタグ一覧を含む)
 *   - items(tag_groups) の ソートは、tag_group_id の降順
 *     - tag_group > tags の ソートは、tag_id の降順
 *   - 属しているタグがない場合は、tags は空配列になる
 *   - id, name が null の場合は、グループに紐付いていないタグをまとめたもの
 * @export
 * @interface GetTagGroupsResponse
 */
export interface GetTagGroupsResponse {
    /**
     * タググループ一覧を取得
     * @type {Array<TagOptionGroup>}
     * @memberof GetTagGroupsResponse
     */
    tagGroups: Array<TagOptionGroup>;
}

export function GetTagGroupsResponseFromJSON(json: any): GetTagGroupsResponse {
    return GetTagGroupsResponseFromJSONTyped(json, false);
}

export function GetTagGroupsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTagGroupsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tagGroups': ((json['tag_groups'] as Array<any>).map(TagOptionGroupFromJSON)),
    };
}

export function GetTagGroupsResponseToJSON(value?: GetTagGroupsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tag_groups': ((value.tagGroups as Array<any>).map(TagOptionGroupToJSON)),
    };
}

