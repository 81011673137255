/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutMedicalInstitutionTagsRequest
 */
export interface PutMedicalInstitutionTagsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PutMedicalInstitutionTagsRequest
     */
    tagIds: Array<number>;
}

export function PutMedicalInstitutionTagsRequestFromJSON(json: any): PutMedicalInstitutionTagsRequest {
    return PutMedicalInstitutionTagsRequestFromJSONTyped(json, false);
}

export function PutMedicalInstitutionTagsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutMedicalInstitutionTagsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tagIds': json['tag_ids'],
    };
}

export function PutMedicalInstitutionTagsRequestToJSON(value?: PutMedicalInstitutionTagsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tag_ids': value.tagIds,
    };
}

