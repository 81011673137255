/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableMedicalInstitutionRunningStatusEnum,
    NullableMedicalInstitutionRunningStatusEnumFromJSON,
    NullableMedicalInstitutionRunningStatusEnumFromJSONTyped,
    NullableMedicalInstitutionRunningStatusEnumToJSON,
} from './NullableMedicalInstitutionRunningStatusEnum';

/**
 * 
 * @export
 * @interface PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution
 */
export interface PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution {
    /**
     * 医療機関ID
     * @type {number}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution
     */
    id: number;
    /**
     * 名前(医療機関IDの医療機関が見つからない場合は `null` となる)
     * @type {string}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution
     */
    name: string | null;
    /**
     * 
     * @type {NullableMedicalInstitutionRunningStatusEnum}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution
     */
    runningStatus: NullableMedicalInstitutionRunningStatusEnum | null;
    /**
     * 住所
     * @type {string}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution
     */
    address: string | null;
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSON(json: any): PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution {
    return PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSONTyped(json, false);
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'runningStatus': NullableMedicalInstitutionRunningStatusEnumFromJSON(json['running_status']),
        'address': json['address'],
    };
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionToJSON(value?: PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'running_status': NullableMedicalInstitutionRunningStatusEnumToJSON(value.runningStatus),
        'address': value.address,
    };
}

