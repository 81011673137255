/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartMedicalInstitutionsReferralsResponseStats,
    PostChartMedicalInstitutionsReferralsResponseStatsFromJSON,
    PostChartMedicalInstitutionsReferralsResponseStatsFromJSONTyped,
    PostChartMedicalInstitutionsReferralsResponseStatsToJSON,
} from './PostChartMedicalInstitutionsReferralsResponseStats';
import {
    PostChartMedicalInstitutionsReferralsResponseSummary,
    PostChartMedicalInstitutionsReferralsResponseSummaryFromJSON,
    PostChartMedicalInstitutionsReferralsResponseSummaryFromJSONTyped,
    PostChartMedicalInstitutionsReferralsResponseSummaryToJSON,
} from './PostChartMedicalInstitutionsReferralsResponseSummary';

/**
 * 
 * @export
 * @interface PostChartMedicalInstitutionsReferralsResponse
 */
export interface PostChartMedicalInstitutionsReferralsResponse {
    /**
     * 
     * @type {PostChartMedicalInstitutionsReferralsResponseSummary}
     * @memberof PostChartMedicalInstitutionsReferralsResponse
     */
    summary: PostChartMedicalInstitutionsReferralsResponseSummary;
    /**
     * 紹介数チャートデータ
     * @type {Array<PostChartMedicalInstitutionsReferralsResponseStats>}
     * @memberof PostChartMedicalInstitutionsReferralsResponse
     */
    stats: Array<PostChartMedicalInstitutionsReferralsResponseStats>;
}

export function PostChartMedicalInstitutionsReferralsResponseFromJSON(json: any): PostChartMedicalInstitutionsReferralsResponse {
    return PostChartMedicalInstitutionsReferralsResponseFromJSONTyped(json, false);
}

export function PostChartMedicalInstitutionsReferralsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartMedicalInstitutionsReferralsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'summary': PostChartMedicalInstitutionsReferralsResponseSummaryFromJSON(json['summary']),
        'stats': ((json['stats'] as Array<any>).map(PostChartMedicalInstitutionsReferralsResponseStatsFromJSON)),
    };
}

export function PostChartMedicalInstitutionsReferralsResponseToJSON(value?: PostChartMedicalInstitutionsReferralsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'summary': PostChartMedicalInstitutionsReferralsResponseSummaryToJSON(value.summary),
        'stats': ((value.stats as Array<any>).map(PostChartMedicalInstitutionsReferralsResponseStatsToJSON)),
    };
}

