/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 集計方法の指定
 * @export
 * @enum {string}
 */
export enum MedicalInstitutionReportItemEnabledAggregationMethodEnum {
    Sum = 'sum',
    Average = 'average'
}

export function MedicalInstitutionReportItemEnabledAggregationMethodEnumFromJSON(json: any): MedicalInstitutionReportItemEnabledAggregationMethodEnum {
    return MedicalInstitutionReportItemEnabledAggregationMethodEnumFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemEnabledAggregationMethodEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemEnabledAggregationMethodEnum {
    return json as MedicalInstitutionReportItemEnabledAggregationMethodEnum;
}

export function MedicalInstitutionReportItemEnabledAggregationMethodEnumToJSON(value?: MedicalInstitutionReportItemEnabledAggregationMethodEnum | null): any {
    return value as any;
}

