/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * 
 * @export
 * @interface AreaReportDefinition
 */
export interface AreaReportDefinition {
    /**
     * エリアレポートID
     * @type {number}
     * @memberof AreaReportDefinition
     */
    id: number;
    /**
     * レポート名
     * @type {string}
     * @memberof AreaReportDefinition
     */
    name: string;
    /**
     * 
     * @type {NullableStaff}
     * @memberof AreaReportDefinition
     */
    createdBy: NullableStaff | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof AreaReportDefinition
     */
    createdAt: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof AreaReportDefinition
     */
    updatedAt: string;
}

export function AreaReportDefinitionFromJSON(json: any): AreaReportDefinition {
    return AreaReportDefinitionFromJSONTyped(json, false);
}

export function AreaReportDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaReportDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'createdBy': NullableStaffFromJSON(json['created_by']),
        'createdAt': json['created_at'],
        'updatedAt': json['updated_at'],
    };
}

export function AreaReportDefinitionToJSON(value?: AreaReportDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'created_by': NullableStaffToJSON(value.createdBy),
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
    };
}

