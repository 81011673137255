import { useAccount } from '@/src/hooks/useAccount';
import { gaPageview } from '@/src/utils/gtag';

import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const GoogleAnalytics: React.FC = () => {
  const { account } = useAccount();
  const router = useRouter();

  const handleRouteChange = (url: string) => {
    if (account.id) gaPageview(account.id, account.tenant?.id, url);
  };

  useEffect(() => {
    handleRouteChange(router.asPath);
  }, [router.pathname]);

  return null;
};
