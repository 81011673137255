/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostDataAnalysisRankingReferralMonthlyResponseRankings,
    PostDataAnalysisRankingReferralMonthlyResponseRankingsFromJSON,
    PostDataAnalysisRankingReferralMonthlyResponseRankingsFromJSONTyped,
    PostDataAnalysisRankingReferralMonthlyResponseRankingsToJSON,
} from './PostDataAnalysisRankingReferralMonthlyResponseRankings';

/**
 * 
 * @export
 * @interface PostDataAnalysisRankingReferralMonthlyResponse
 */
export interface PostDataAnalysisRankingReferralMonthlyResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof PostDataAnalysisRankingReferralMonthlyResponse
     */
    totalPage: number;
    /**
     * ランキングデータ
     * @type {Array<PostDataAnalysisRankingReferralMonthlyResponseRankings>}
     * @memberof PostDataAnalysisRankingReferralMonthlyResponse
     */
    rankings: Array<PostDataAnalysisRankingReferralMonthlyResponseRankings>;
}

export function PostDataAnalysisRankingReferralMonthlyResponseFromJSON(json: any): PostDataAnalysisRankingReferralMonthlyResponse {
    return PostDataAnalysisRankingReferralMonthlyResponseFromJSONTyped(json, false);
}

export function PostDataAnalysisRankingReferralMonthlyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisRankingReferralMonthlyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'rankings': ((json['rankings'] as Array<any>).map(PostDataAnalysisRankingReferralMonthlyResponseRankingsFromJSON)),
    };
}

export function PostDataAnalysisRankingReferralMonthlyResponseToJSON(value?: PostDataAnalysisRankingReferralMonthlyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'rankings': ((value.rankings as Array<any>).map(PostDataAnalysisRankingReferralMonthlyResponseRankingsToJSON)),
    };
}

