/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutCommunicationLabelIdRequestSelections,
    PutCommunicationLabelIdRequestSelectionsFromJSON,
    PutCommunicationLabelIdRequestSelectionsFromJSONTyped,
    PutCommunicationLabelIdRequestSelectionsToJSON,
} from './PutCommunicationLabelIdRequestSelections';

/**
 * 
 * @export
 * @interface PutCommunicationLabelIdRequest
 */
export interface PutCommunicationLabelIdRequest {
    /**
     * 項目名
     * @type {string}
     * @memberof PutCommunicationLabelIdRequest
     */
    name?: string;
    /**
     * 説明
     * @type {string}
     * @memberof PutCommunicationLabelIdRequest
     */
    description?: string | null;
    /**
     * 選択肢リスト（コンポーネント種別が'select','checkbox'の場合のみ）
     * @type {Array<PutCommunicationLabelIdRequestSelections>}
     * @memberof PutCommunicationLabelIdRequest
     */
    selections?: Array<PutCommunicationLabelIdRequestSelections> | null;
    /**
     * 必須入力かどうか
     * @type {boolean}
     * @memberof PutCommunicationLabelIdRequest
     */
    isRequired?: boolean | null;
}

export function PutCommunicationLabelIdRequestFromJSON(json: any): PutCommunicationLabelIdRequest {
    return PutCommunicationLabelIdRequestFromJSONTyped(json, false);
}

export function PutCommunicationLabelIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutCommunicationLabelIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'selections': !exists(json, 'selections') ? undefined : (json['selections'] === null ? null : (json['selections'] as Array<any>).map(PutCommunicationLabelIdRequestSelectionsFromJSON)),
        'isRequired': !exists(json, 'is_required') ? undefined : json['is_required'],
    };
}

export function PutCommunicationLabelIdRequestToJSON(value?: PutCommunicationLabelIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'selections': value.selections === undefined ? undefined : (value.selections === null ? null : (value.selections as Array<any>).map(PutCommunicationLabelIdRequestSelectionsToJSON)),
        'is_required': value.isRequired,
    };
}

