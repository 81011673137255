/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommunicationLabelConditionTimeConditions
 */
export interface CommunicationLabelConditionTimeConditions {
    /**
     * 時刻型のラベル条件。指定した時分、もしくはそれ以降の場合にマッチする条件
     * @type {string}
     * @memberof CommunicationLabelConditionTimeConditions
     */
    startTime?: string | null;
    /**
     * 時刻型のラベル条件。指定した時分、もしくはそれ以前の場合にマッチする条件
     * @type {string}
     * @memberof CommunicationLabelConditionTimeConditions
     */
    endTime?: string | null;
}

export function CommunicationLabelConditionTimeConditionsFromJSON(json: any): CommunicationLabelConditionTimeConditions {
    return CommunicationLabelConditionTimeConditionsFromJSONTyped(json, false);
}

export function CommunicationLabelConditionTimeConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabelConditionTimeConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startTime': !exists(json, 'start_time') ? undefined : json['start_time'],
        'endTime': !exists(json, 'end_time') ? undefined : json['end_time'],
    };
}

export function CommunicationLabelConditionTimeConditionsToJSON(value?: CommunicationLabelConditionTimeConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_time': value.startTime,
        'end_time': value.endTime,
    };
}

