/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommunicationLabelConditionSelectionsConditions
 */
export interface CommunicationLabelConditionSelectionsConditions {
    /**
     * 複数選択型のラベル条件。指定したもののうちどれか一つでも含んでいる場合にマッチする条件。(ラベルタイプ checkbox type)
     * @type {Array<number>}
     * @memberof CommunicationLabelConditionSelectionsConditions
     */
    haveAny?: Array<number>;
    /**
     * 複数選択型のラベル条件。指定したものをすべてを含んでいる場合にマッチする条件。(ラベルタイプ checkbox type)
     * @type {Array<number>}
     * @memberof CommunicationLabelConditionSelectionsConditions
     */
    haveAll?: Array<number>;
    /**
     * 複数選択型のラベル条件。指定したものをすべてを含んでいない場合にマッチする条件。(ラベルタイプ checkbox type)
     * @type {Array<number>}
     * @memberof CommunicationLabelConditionSelectionsConditions
     */
    notHaveAll?: Array<number>;
}

export function CommunicationLabelConditionSelectionsConditionsFromJSON(json: any): CommunicationLabelConditionSelectionsConditions {
    return CommunicationLabelConditionSelectionsConditionsFromJSONTyped(json, false);
}

export function CommunicationLabelConditionSelectionsConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabelConditionSelectionsConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'haveAny': !exists(json, 'have_any') ? undefined : json['have_any'],
        'haveAll': !exists(json, 'have_all') ? undefined : json['have_all'],
        'notHaveAll': !exists(json, 'not_have_all') ? undefined : json['not_have_all'],
    };
}

export function CommunicationLabelConditionSelectionsConditionsToJSON(value?: CommunicationLabelConditionSelectionsConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'have_any': value.haveAny,
        'have_all': value.haveAll,
        'not_have_all': value.notHaveAll,
    };
}

