/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 計算項目の式
 * @export
 * @interface AreaReportItemFormula
 */
export interface AreaReportItemFormula {
    /**
     * UUID
     * @type {string}
     * @memberof AreaReportItemFormula
     */
    leftItemKey: string;
    /**
     * UUID
     * @type {string}
     * @memberof AreaReportItemFormula
     */
    rightItemKey: string;
}

export function AreaReportItemFormulaFromJSON(json: any): AreaReportItemFormula {
    return AreaReportItemFormulaFromJSONTyped(json, false);
}

export function AreaReportItemFormulaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaReportItemFormula {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leftItemKey': json['left_item_key'],
        'rightItemKey': json['right_item_key'],
    };
}

export function AreaReportItemFormulaToJSON(value?: AreaReportItemFormula | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'left_item_key': value.leftItemKey,
        'right_item_key': value.rightItemKey,
    };
}

