/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 並び替え条件
 * @export
 * @interface CommunicationReportDefinitionSortCondition
 */
export interface CommunicationReportDefinitionSortCondition {
    /**
     * UUID
     * @type {string}
     * @memberof CommunicationReportDefinitionSortCondition
     */
    itemKey: string;
    /**
     * 演算子
     * @type {string}
     * @memberof CommunicationReportDefinitionSortCondition
     */
    operator: CommunicationReportDefinitionSortConditionOperatorEnum;
}

/**
* @export
* @enum {string}
*/
export enum CommunicationReportDefinitionSortConditionOperatorEnum {
    Asc = 'asc',
    Desc = 'desc'
}

export function CommunicationReportDefinitionSortConditionFromJSON(json: any): CommunicationReportDefinitionSortCondition {
    return CommunicationReportDefinitionSortConditionFromJSONTyped(json, false);
}

export function CommunicationReportDefinitionSortConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportDefinitionSortCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemKey': json['item_key'],
        'operator': json['operator'],
    };
}

export function CommunicationReportDefinitionSortConditionToJSON(value?: CommunicationReportDefinitionSortCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_key': value.itemKey,
        'operator': value.operator,
    };
}

