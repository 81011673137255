/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutMedicalInstitutionMemoRequest
 */
export interface PutMedicalInstitutionMemoRequest {
    /**
     * メモの内容
     * @type {string}
     * @memberof PutMedicalInstitutionMemoRequest
     */
    content: string;
}

export function PutMedicalInstitutionMemoRequestFromJSON(json: any): PutMedicalInstitutionMemoRequest {
    return PutMedicalInstitutionMemoRequestFromJSONTyped(json, false);
}

export function PutMedicalInstitutionMemoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutMedicalInstitutionMemoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
    };
}

export function PutMedicalInstitutionMemoRequestToJSON(value?: PutMedicalInstitutionMemoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
    };
}

