/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnDepartmentReportItem,
    OwnDepartmentReportItemFromJSON,
    OwnDepartmentReportItemFromJSONTyped,
    OwnDepartmentReportItemToJSON,
} from './OwnDepartmentReportItem';

/**
 * レポート項目
 * @export
 * @interface OwnDepartmentReportDefinitionDisplayItem
 */
export interface OwnDepartmentReportDefinitionDisplayItem {
    /**
     * 項目ラベル
     * @type {string}
     * @memberof OwnDepartmentReportDefinitionDisplayItem
     */
    label: string;
    /**
     * 
     * @type {OwnDepartmentReportItem}
     * @memberof OwnDepartmentReportDefinitionDisplayItem
     */
    item: OwnDepartmentReportItem;
}

export function OwnDepartmentReportDefinitionDisplayItemFromJSON(json: any): OwnDepartmentReportDefinitionDisplayItem {
    return OwnDepartmentReportDefinitionDisplayItemFromJSONTyped(json, false);
}

export function OwnDepartmentReportDefinitionDisplayItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportDefinitionDisplayItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'item': OwnDepartmentReportItemFromJSON(json['item']),
    };
}

export function OwnDepartmentReportDefinitionDisplayItemToJSON(value?: OwnDepartmentReportDefinitionDisplayItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'item': OwnDepartmentReportItemToJSON(value.item),
    };
}

