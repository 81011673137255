/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutDoctorRequest
 */
export interface PutDoctorRequest {
    /**
     * 部署
     * @type {string}
     * @memberof PutDoctorRequest
     */
    division: string | null;
    /**
     * 役職
     * @type {string}
     * @memberof PutDoctorRequest
     */
    position: string | null;
    /**
     * 氏名
     * @type {string}
     * @memberof PutDoctorRequest
     */
    name: string;
    /**
     * 敬称
     * @type {string}
     * @memberof PutDoctorRequest
     */
    honorific: string | null;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PutDoctorRequest
     */
    email: string | null;
    /**
     * 電話番号
     * @type {string}
     * @memberof PutDoctorRequest
     */
    phoneNumber: string | null;
    /**
     * 経歴
     * @type {string}
     * @memberof PutDoctorRequest
     */
    profile: string | null;
    /**
     * その他
     * @type {string}
     * @memberof PutDoctorRequest
     */
    memo: string | null;
}

export function PutDoctorRequestFromJSON(json: any): PutDoctorRequest {
    return PutDoctorRequestFromJSONTyped(json, false);
}

export function PutDoctorRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutDoctorRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'division': json['division'],
        'position': json['position'],
        'name': json['name'],
        'honorific': json['honorific'],
        'email': json['email'],
        'phoneNumber': json['phone_number'],
        'profile': json['profile'],
        'memo': json['memo'],
    };
}

export function PutDoctorRequestToJSON(value?: PutDoctorRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'division': value.division,
        'position': value.position,
        'name': value.name,
        'honorific': value.honorific,
        'email': value.email,
        'phone_number': value.phoneNumber,
        'profile': value.profile,
        'memo': value.memo,
    };
}

