/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 医療機関ラベルの設定値
 * @export
 * @interface MedicalInstitutionLabelValue
 */
export interface MedicalInstitutionLabelValue {
    /**
     * 医療機関ラベルマスタID
     * @type {number}
     * @memberof MedicalInstitutionLabelValue
     */
    labelId: number;
    /**
     * テキスト値
     * @type {string}
     * @memberof MedicalInstitutionLabelValue
     */
    textValue: string | null;
    /**
     * 数値
     * @type {number}
     * @memberof MedicalInstitutionLabelValue
     */
    integerValue: number | null;
    /**
     * 医療機関ラベル選択肢ID
     * @type {number}
     * @memberof MedicalInstitutionLabelValue
     */
    selectionId: number | null;
    /**
     * 医療機関ラベル選択肢IDのリスト
     * @type {Array<number>}
     * @memberof MedicalInstitutionLabelValue
     */
    selectionIds: Array<number> | null;
    /**
     * 該当エリアID
     * @type {number}
     * @memberof MedicalInstitutionLabelValue
     */
    readonly areaId?: number | null;
}

export function MedicalInstitutionLabelValueFromJSON(json: any): MedicalInstitutionLabelValue {
    return MedicalInstitutionLabelValueFromJSONTyped(json, false);
}

export function MedicalInstitutionLabelValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionLabelValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labelId': json['label_id'],
        'textValue': json['text_value'],
        'integerValue': json['integer_value'],
        'selectionId': json['selection_id'],
        'selectionIds': json['selection_ids'],
        'areaId': !exists(json, 'area_id') ? undefined : json['area_id'],
    };
}

export function MedicalInstitutionLabelValueToJSON(value?: MedicalInstitutionLabelValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label_id': value.labelId,
        'text_value': value.textValue,
        'integer_value': value.integerValue,
        'selection_id': value.selectionId,
        'selection_ids': value.selectionIds,
    };
}

