/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
} from './CommunicationType';

/**
 * 
 * @export
 * @interface PostDataAnalysisEffectivenessVerificationCommunicationsRequest
 */
export interface PostDataAnalysisEffectivenessVerificationCommunicationsRequest {
    /**
     * ページ番号 (1始まり)
     * @type {number}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsRequest
     */
    page: number;
    /**
     * 訪問月
     * @type {string}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsRequest
     */
    month: string;
    /**
     * 増減数の並び順
     * @type {string}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsRequest
     */
    sort: PostDataAnalysisEffectivenessVerificationCommunicationsRequestSortEnum;
    /**
     * 集計期間(日)
     * @type {number}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsRequest
     */
    aggregationPeriod: number;
    /**
     * 医師面談（あり: true, なし: false, 指定なし: null) ※communication_type「visit」のときのみ受け付ける
     * @type {boolean}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsRequest
     */
    isDoctorAttended: boolean | null;
    /**
     * 都道府県
     * @type {string}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsRequest
     */
    prefecture: string | null;
    /**
     * 地域
     * @type {string}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsRequest
     */
    area: string | null;
    /**
     * 
     * @type {CommunicationType}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsRequest
     */
    communicationType: CommunicationType;
    /**
     * 診療科目ID
     * @type {Array<string>}
     * @memberof PostDataAnalysisEffectivenessVerificationCommunicationsRequest
     */
    departmentIds: Array<string> | null;
}

/**
* @export
* @enum {string}
*/
export enum PostDataAnalysisEffectivenessVerificationCommunicationsRequestSortEnum {
    Desc = 'desc',
    Asc = 'asc'
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsRequestFromJSON(json: any): PostDataAnalysisEffectivenessVerificationCommunicationsRequest {
    return PostDataAnalysisEffectivenessVerificationCommunicationsRequestFromJSONTyped(json, false);
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisEffectivenessVerificationCommunicationsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'month': json['month'],
        'sort': json['sort'],
        'aggregationPeriod': json['aggregation_period'],
        'isDoctorAttended': json['is_doctor_attended'],
        'prefecture': json['prefecture'],
        'area': json['area'],
        'communicationType': CommunicationTypeFromJSON(json['communication_type']),
        'departmentIds': json['department_ids'],
    };
}

export function PostDataAnalysisEffectivenessVerificationCommunicationsRequestToJSON(value?: PostDataAnalysisEffectivenessVerificationCommunicationsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'month': value.month,
        'sort': value.sort,
        'aggregation_period': value.aggregationPeriod,
        'is_doctor_attended': value.isDoctorAttended,
        'prefecture': value.prefecture,
        'area': value.area,
        'communication_type': CommunicationTypeToJSON(value.communicationType),
        'department_ids': value.departmentIds,
    };
}

