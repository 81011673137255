/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportItem,
    MedicalInstitutionReportItemFromJSON,
    MedicalInstitutionReportItemFromJSONTyped,
    MedicalInstitutionReportItemToJSON,
} from './MedicalInstitutionReportItem';

/**
 * 連携先レポート項目
 * @export
 * @interface MedicalInstitutionReportDefinitionDisplayItem
 */
export interface MedicalInstitutionReportDefinitionDisplayItem {
    /**
     * 項目ラベル
     * @type {string}
     * @memberof MedicalInstitutionReportDefinitionDisplayItem
     */
    label: string;
    /**
     * 
     * @type {MedicalInstitutionReportItem}
     * @memberof MedicalInstitutionReportDefinitionDisplayItem
     */
    item: MedicalInstitutionReportItem;
}

export function MedicalInstitutionReportDefinitionDisplayItemFromJSON(json: any): MedicalInstitutionReportDefinitionDisplayItem {
    return MedicalInstitutionReportDefinitionDisplayItemFromJSONTyped(json, false);
}

export function MedicalInstitutionReportDefinitionDisplayItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportDefinitionDisplayItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'item': MedicalInstitutionReportItemFromJSON(json['item']),
    };
}

export function MedicalInstitutionReportDefinitionDisplayItemToJSON(value?: MedicalInstitutionReportDefinitionDisplayItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'item': MedicalInstitutionReportItemToJSON(value.item),
    };
}

