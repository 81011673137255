/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartBothReferralsComparisonResponseItems,
    PostChartBothReferralsComparisonResponseItemsFromJSON,
    PostChartBothReferralsComparisonResponseItemsFromJSONTyped,
    PostChartBothReferralsComparisonResponseItemsToJSON,
} from './PostChartBothReferralsComparisonResponseItems';

/**
 * 
 * @export
 * @interface PostChartBothReferralsComparisonResponseBothReferrals
 */
export interface PostChartBothReferralsComparisonResponseBothReferrals {
    /**
     * 集計期間(週,月,年)
     * @type {string}
     * @memberof PostChartBothReferralsComparisonResponseBothReferrals
     */
    label: string;
    /**
     * 集計期間の開始日。当日を含む
     * @type {string}
     * @memberof PostChartBothReferralsComparisonResponseBothReferrals
     */
    startDate: string;
    /**
     * 集計期間の終了日。当日を含む
     * @type {string}
     * @memberof PostChartBothReferralsComparisonResponseBothReferrals
     */
    endDate: string;
    /**
     * 期間ごとの紹介数・逆紹介数
     * @type {Array<PostChartBothReferralsComparisonResponseItems>}
     * @memberof PostChartBothReferralsComparisonResponseBothReferrals
     */
    items: Array<PostChartBothReferralsComparisonResponseItems>;
}

export function PostChartBothReferralsComparisonResponseBothReferralsFromJSON(json: any): PostChartBothReferralsComparisonResponseBothReferrals {
    return PostChartBothReferralsComparisonResponseBothReferralsFromJSONTyped(json, false);
}

export function PostChartBothReferralsComparisonResponseBothReferralsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartBothReferralsComparisonResponseBothReferrals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'startDate': json['start_date'],
        'endDate': json['end_date'],
        'items': ((json['items'] as Array<any>).map(PostChartBothReferralsComparisonResponseItemsFromJSON)),
    };
}

export function PostChartBothReferralsComparisonResponseBothReferralsToJSON(value?: PostChartBothReferralsComparisonResponseBothReferrals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'start_date': value.startDate,
        'end_date': value.endDate,
        'items': ((value.items as Array<any>).map(PostChartBothReferralsComparisonResponseItemsToJSON)),
    };
}

