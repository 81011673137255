/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 項目に対する絞り込み条件
 * @export
 * @interface AreaReportDefinitionFilterConditionMatchingGroup
 */
export interface AreaReportDefinitionFilterConditionMatchingGroup {
    /**
     * trueにすると値が存在するものにのみマッチする。falseにすると値が存在しない（null）対象も含めてマッチする。
     * @type {boolean}
     * @memberof AreaReportDefinitionFilterConditionMatchingGroup
     */
    _exists?: boolean;
    /**
     * 指定した数値以上の場合にマッチする条件
     * @type {number}
     * @memberof AreaReportDefinitionFilterConditionMatchingGroup
     */
    moreEqual?: number | null;
    /**
     * 指定した数値以下の場合にマッチする条件
     * @type {number}
     * @memberof AreaReportDefinitionFilterConditionMatchingGroup
     */
    lessEqual?: number | null;
    /**
     * 数値が指定したいずれかと合致する場合にマッチする条件
     * @type {Array<number>}
     * @memberof AreaReportDefinitionFilterConditionMatchingGroup
     */
    _in?: Array<number>;
    /**
     * 数値が指定したいずれも含まない場合にマッチする条件
     * @type {Array<number>}
     * @memberof AreaReportDefinitionFilterConditionMatchingGroup
     */
    notIn?: Array<number>;
}

export function AreaReportDefinitionFilterConditionMatchingGroupFromJSON(json: any): AreaReportDefinitionFilterConditionMatchingGroup {
    return AreaReportDefinitionFilterConditionMatchingGroupFromJSONTyped(json, false);
}

export function AreaReportDefinitionFilterConditionMatchingGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaReportDefinitionFilterConditionMatchingGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_exists': !exists(json, 'exists') ? undefined : json['exists'],
        'moreEqual': !exists(json, 'more_equal') ? undefined : json['more_equal'],
        'lessEqual': !exists(json, 'less_equal') ? undefined : json['less_equal'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'not_in') ? undefined : json['not_in'],
    };
}

export function AreaReportDefinitionFilterConditionMatchingGroupToJSON(value?: AreaReportDefinitionFilterConditionMatchingGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exists': value._exists,
        'more_equal': value.moreEqual,
        'less_equal': value.lessEqual,
        'in': value._in,
        'not_in': value.notIn,
    };
}

