/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FacilityTypeGroup,
    FacilityTypeGroupFromJSON,
    FacilityTypeGroupFromJSONTyped,
    FacilityTypeGroupToJSON,
} from './FacilityTypeGroup';

/**
 * グルーピングした施設タイプー覧
 * @export
 * @interface GetFacilityTypeGroupsResponse
 */
export interface GetFacilityTypeGroupsResponse {
    /**
     * グルーピングした施設タイプー覧
     * @type {Array<FacilityTypeGroup>}
     * @memberof GetFacilityTypeGroupsResponse
     */
    facilityTypeGroups: Array<FacilityTypeGroup>;
}

export function GetFacilityTypeGroupsResponseFromJSON(json: any): GetFacilityTypeGroupsResponse {
    return GetFacilityTypeGroupsResponseFromJSONTyped(json, false);
}

export function GetFacilityTypeGroupsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFacilityTypeGroupsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facilityTypeGroups': ((json['facility_type_groups'] as Array<any>).map(FacilityTypeGroupFromJSON)),
    };
}

export function GetFacilityTypeGroupsResponseToJSON(value?: GetFacilityTypeGroupsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_type_groups': ((value.facilityTypeGroups as Array<any>).map(FacilityTypeGroupToJSON)),
    };
}

