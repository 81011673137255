/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsRequestPeriod,
    PostChartReferralsRequestPeriodFromJSON,
    PostChartReferralsRequestPeriodFromJSONTyped,
    PostChartReferralsRequestPeriodToJSON,
} from './PostChartReferralsRequestPeriod';

/**
 * 
 * @export
 * @interface PostChartMedicalInstitutionsReferralsDepartmentsRequest
 */
export interface PostChartMedicalInstitutionsReferralsDepartmentsRequest {
    /**
     * グループ病院のID (null の場合は全グループ病院が対象)
     * @type {Array<string>}
     * @memberof PostChartMedicalInstitutionsReferralsDepartmentsRequest
     */
    groupMedicalInstitutionIds: Array<string> | null;
    /**
     * 初再診フラグ
     * `all`, `first_visit`, `return_visit`
     * @type {string}
     * @memberof PostChartMedicalInstitutionsReferralsDepartmentsRequest
     */
    firstVisitFlag: PostChartMedicalInstitutionsReferralsDepartmentsRequestFirstVisitFlagEnum;
    /**
     * 
     * @type {PostChartReferralsRequestPeriod}
     * @memberof PostChartMedicalInstitutionsReferralsDepartmentsRequest
     */
    period: PostChartReferralsRequestPeriod;
}

/**
* @export
* @enum {string}
*/
export enum PostChartMedicalInstitutionsReferralsDepartmentsRequestFirstVisitFlagEnum {
    All = 'all',
    FirstVisit = 'first_visit',
    ReturnVisit = 'return_visit'
}

export function PostChartMedicalInstitutionsReferralsDepartmentsRequestFromJSON(json: any): PostChartMedicalInstitutionsReferralsDepartmentsRequest {
    return PostChartMedicalInstitutionsReferralsDepartmentsRequestFromJSONTyped(json, false);
}

export function PostChartMedicalInstitutionsReferralsDepartmentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartMedicalInstitutionsReferralsDepartmentsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupMedicalInstitutionIds': json['group_medical_institution_ids'],
        'firstVisitFlag': json['first_visit_flag'],
        'period': PostChartReferralsRequestPeriodFromJSON(json['period']),
    };
}

export function PostChartMedicalInstitutionsReferralsDepartmentsRequestToJSON(value?: PostChartMedicalInstitutionsReferralsDepartmentsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_medical_institution_ids': value.groupMedicalInstitutionIds,
        'first_visit_flag': value.firstVisitFlag,
        'period': PostChartReferralsRequestPeriodToJSON(value.period),
    };
}

