/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsRequestPeriod,
    PostChartReferralsRequestPeriodFromJSON,
    PostChartReferralsRequestPeriodFromJSONTyped,
    PostChartReferralsRequestPeriodToJSON,
} from './PostChartReferralsRequestPeriod';

/**
 * 
 * @export
 * @interface PostChartReferralsRequest
 */
export interface PostChartReferralsRequest {
    /**
     * 診療科目のID (null の場合は全診療科目の合算)
     * @type {Array<string>}
     * @memberof PostChartReferralsRequest
     */
    ownDepartmentIds: Array<string> | null;
    /**
     * 
     * @type {PostChartReferralsRequestPeriod}
     * @memberof PostChartReferralsRequest
     */
    period: PostChartReferralsRequestPeriod;
    /**
     * 紹介数の集計時間単位
     * `week`, `month`, `year`
     * @type {string}
     * @memberof PostChartReferralsRequest
     */
    unitType: PostChartReferralsRequestUnitTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostChartReferralsRequestUnitTypeEnum {
    Week = 'week',
    Month = 'month',
    Year = 'year'
}

export function PostChartReferralsRequestFromJSON(json: any): PostChartReferralsRequest {
    return PostChartReferralsRequestFromJSONTyped(json, false);
}

export function PostChartReferralsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReferralsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ownDepartmentIds': json['own_department_ids'],
        'period': PostChartReferralsRequestPeriodFromJSON(json['period']),
        'unitType': json['unit_type'],
    };
}

export function PostChartReferralsRequestToJSON(value?: PostChartReferralsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'own_department_ids': value.ownDepartmentIds,
        'period': PostChartReferralsRequestPeriodToJSON(value.period),
        'unit_type': value.unitType,
    };
}

