/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationReportItemAggregationCondition,
    CommunicationReportItemAggregationConditionFromJSON,
    CommunicationReportItemAggregationConditionFromJSONTyped,
    CommunicationReportItemAggregationConditionToJSON,
} from './CommunicationReportItemAggregationCondition';
import {
    CommunicationReportItemFormula,
    CommunicationReportItemFormulaFromJSON,
    CommunicationReportItemFormulaFromJSONTyped,
    CommunicationReportItemFormulaToJSON,
} from './CommunicationReportItemFormula';

/**
 * keyはレポート内で一意、他からの参照はこれで行う。<br />
 * 「過去に紹介がない医療機関を含む」など、項目として表示しない場合には何か固定値を指定するとよさそう<br />
 * spaでの生成時点で付与する必要があるので、idでの識別ではなくkeyを利用
 * @export
 * @interface CommunicationReportItem
 */
export interface CommunicationReportItem {
    /**
     * レポートの項目をレポート内で一意に識別するためのUUID
     * @type {string}
     * @memberof CommunicationReportItem
     */
    key: string;
    /**
     * レポート項目定義を一意に特定するキー
     * @type {string}
     * @memberof CommunicationReportItem
     */
    definitionKey: string;
    /**
     * 
     * @type {CommunicationReportItemAggregationCondition}
     * @memberof CommunicationReportItem
     */
    aggregationConditions?: CommunicationReportItemAggregationCondition;
    /**
     * 
     * @type {CommunicationReportItemFormula}
     * @memberof CommunicationReportItem
     */
    formula?: CommunicationReportItemFormula;
}

export function CommunicationReportItemFromJSON(json: any): CommunicationReportItem {
    return CommunicationReportItemFromJSONTyped(json, false);
}

export function CommunicationReportItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'definitionKey': json['definition_key'],
        'aggregationConditions': !exists(json, 'aggregation_conditions') ? undefined : CommunicationReportItemAggregationConditionFromJSON(json['aggregation_conditions']),
        'formula': !exists(json, 'formula') ? undefined : CommunicationReportItemFormulaFromJSON(json['formula']),
    };
}

export function CommunicationReportItemToJSON(value?: CommunicationReportItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'definition_key': value.definitionKey,
        'aggregation_conditions': CommunicationReportItemAggregationConditionToJSON(value.aggregationConditions),
        'formula': CommunicationReportItemFormulaToJSON(value.formula),
    };
}

