/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaReportDefinition,
    AreaReportDefinitionFromJSON,
    AreaReportDefinitionFromJSONTyped,
    AreaReportDefinitionToJSON,
} from './AreaReportDefinition';

/**
 * 
 * @export
 * @interface GetAreaReportDefinitionsResponse
 */
export interface GetAreaReportDefinitionsResponse {
    /**
     * エリアレポート定義
     * @type {Array<AreaReportDefinition>}
     * @memberof GetAreaReportDefinitionsResponse
     */
    definitions: Array<AreaReportDefinition>;
}

export function GetAreaReportDefinitionsResponseFromJSON(json: any): GetAreaReportDefinitionsResponse {
    return GetAreaReportDefinitionsResponseFromJSONTyped(json, false);
}

export function GetAreaReportDefinitionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAreaReportDefinitionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definitions': ((json['definitions'] as Array<any>).map(AreaReportDefinitionFromJSON)),
    };
}

export function GetAreaReportDefinitionsResponseToJSON(value?: GetAreaReportDefinitionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definitions': ((value.definitions as Array<any>).map(AreaReportDefinitionToJSON)),
    };
}

