/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * エリアレポートの一行
 * @export
 * @interface PostAreaReportResultsResponseResults
 */
export interface PostAreaReportResultsResponseResults {
    /**
     * エリアID
     * @type {number}
     * @memberof PostAreaReportResultsResponseResults
     */
    areaId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostAreaReportResultsResponseResults
     */
    values: Array<string>;
}

export function PostAreaReportResultsResponseResultsFromJSON(json: any): PostAreaReportResultsResponseResults {
    return PostAreaReportResultsResponseResultsFromJSONTyped(json, false);
}

export function PostAreaReportResultsResponseResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostAreaReportResultsResponseResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'areaId': json['area_id'],
        'values': json['values'],
    };
}

export function PostAreaReportResultsResponseResultsToJSON(value?: PostAreaReportResultsResponseResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'area_id': value.areaId,
        'values': value.values,
    };
}

