import { Button } from '@/src/components/foundations/Buttons/Button';
import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { Divider } from '@/src/components/foundations/Layouts/Divider';
import { Link } from '@/src/components/foundations/Utils/Link';
import { ExtractColor } from '@/src/types/styles';

import styles from './styles.module.scss';

import clsx from 'clsx';

type Color = ExtractColor<'primary' | 'basic' | 'danger' | 'secondary'>;

type Menu = {
  label: string;
  isTopDivider?: boolean;
  icon?: JSX.Element;
  className?: string;
} & (
  | {
      isLink?: false;
      type: Color;
      isDisabled?: boolean;
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    }
  | {
      isLink: true;
      href: string;
      isOpenAnotherTab?: boolean;
    }
);

type Props = {
  menus: Menu[];
};

export const PopoverMenu: React.FC<Props> = (props) => {
  return (
    <div className={styles.cardWrapper}>
      <Card>
        <ul className={styles.base}>
          {props.menus.map((menu, key) => (
            <li key={key} className={styles.listItem}>
              {menu.isTopDivider && (
                <div className={styles.dividerWrapper}>
                  <Divider />
                </div>
              )}
              {menu.isLink ? (
                <Link
                  href={menu.href}
                  isOpenAnotherTab={menu.isOpenAnotherTab}
                  className={clsx(styles.item, styles.link, menu.className)}
                >
                  {menu.icon && (
                    <span className={styles.icon}>{menu.icon}</span>
                  )}
                  {menu.label}
                </Link>
              ) : (
                <Button
                  type="skeleton"
                  color={menu.type ?? 'basic'}
                  onClick={menu.onClick}
                  isDisabled={menu.isDisabled}
                  icon={
                    menu.icon
                      ? { body: menu.icon, position: 'left' }
                      : undefined
                  }
                  className={clsx(styles.item, styles.button, menu.className)}
                >
                  {menu.label}
                </Button>
              )}
            </li>
          ))}
        </ul>
      </Card>
    </div>
  );
};
