/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Staff,
    StaffFromJSON,
    StaffFromJSONTyped,
    StaffToJSON,
} from './Staff';

/**
 * 
 * @export
 * @interface GetStaffsResponse
 */
export interface GetStaffsResponse {
    /**
     * スタッフ一覧
     * @type {Array<Staff>}
     * @memberof GetStaffsResponse
     */
    staffs: Array<Staff>;
}

export function GetStaffsResponseFromJSON(json: any): GetStaffsResponse {
    return GetStaffsResponseFromJSONTyped(json, false);
}

export function GetStaffsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStaffsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'staffs': ((json['staffs'] as Array<any>).map(StaffFromJSON)),
    };
}

export function GetStaffsResponseToJSON(value?: GetStaffsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'staffs': ((value.staffs as Array<any>).map(StaffToJSON)),
    };
}

