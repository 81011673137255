/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 比較期間
 * @export
 * @enum {string}
 */
export enum AlertConditionComparisonReference {
    Recent = 'recent',
    LastYear = 'last_year'
}

export function AlertConditionComparisonReferenceFromJSON(json: any): AlertConditionComparisonReference {
    return AlertConditionComparisonReferenceFromJSONTyped(json, false);
}

export function AlertConditionComparisonReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionComparisonReference {
    return json as AlertConditionComparisonReference;
}

export function AlertConditionComparisonReferenceToJSON(value?: AlertConditionComparisonReference | null): any {
    return value as any;
}

