/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Task,
    TaskFromJSON,
    TaskFromJSONTyped,
    TaskToJSON,
} from './Task';

/**
 * 
 * @export
 * @interface GetTasksResponse
 */
export interface GetTasksResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof GetTasksResponse
     */
    totalPage: number;
    /**
     * 総タスク数
     * @type {number}
     * @memberof GetTasksResponse
     */
    totalCount: number;
    /**
     * タスクの一覧
     * @type {Array<Task>}
     * @memberof GetTasksResponse
     */
    tasks: Array<Task>;
}

export function GetTasksResponseFromJSON(json: any): GetTasksResponse {
    return GetTasksResponseFromJSONTyped(json, false);
}

export function GetTasksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTasksResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'tasks': ((json['tasks'] as Array<any>).map(TaskFromJSON)),
    };
}

export function GetTasksResponseToJSON(value?: GetTasksResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'tasks': ((value.tasks as Array<any>).map(TaskToJSON)),
    };
}

