/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationReportDefinitionAggregationColumn,
    CommunicationReportDefinitionAggregationColumnFromJSON,
    CommunicationReportDefinitionAggregationColumnFromJSONTyped,
    CommunicationReportDefinitionAggregationColumnToJSON,
} from './CommunicationReportDefinitionAggregationColumn';
import {
    CommunicationReportDefinitionAggregationData,
    CommunicationReportDefinitionAggregationDataFromJSON,
    CommunicationReportDefinitionAggregationDataFromJSONTyped,
    CommunicationReportDefinitionAggregationDataToJSON,
} from './CommunicationReportDefinitionAggregationData';
import {
    CommunicationReportDefinitionAggregationRow,
    CommunicationReportDefinitionAggregationRowFromJSON,
    CommunicationReportDefinitionAggregationRowFromJSONTyped,
    CommunicationReportDefinitionAggregationRowToJSON,
} from './CommunicationReportDefinitionAggregationRow';
import {
    CommunicationReportDefinitionDisplayItem,
    CommunicationReportDefinitionDisplayItemFromJSON,
    CommunicationReportDefinitionDisplayItemFromJSONTyped,
    CommunicationReportDefinitionDisplayItemToJSON,
} from './CommunicationReportDefinitionDisplayItem';
import {
    CommunicationReportDefinitionFilterCondition,
    CommunicationReportDefinitionFilterConditionFromJSON,
    CommunicationReportDefinitionFilterConditionFromJSONTyped,
    CommunicationReportDefinitionFilterConditionToJSON,
} from './CommunicationReportDefinitionFilterCondition';
import {
    CommunicationReportDefinitionSortCondition,
    CommunicationReportDefinitionSortConditionFromJSON,
    CommunicationReportDefinitionSortConditionFromJSONTyped,
    CommunicationReportDefinitionSortConditionToJSON,
} from './CommunicationReportDefinitionSortCondition';
import {
    CommunicationReportItem,
    CommunicationReportItemFromJSON,
    CommunicationReportItemFromJSONTyped,
    CommunicationReportItemToJSON,
} from './CommunicationReportItem';
import {
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
} from './CommunicationType';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * 
 * @export
 * @interface CommunicationReportDefinitionDetail
 */
export interface CommunicationReportDefinitionDetail {
    /**
     * コミュニケーションレポートID
     * @type {number}
     * @memberof CommunicationReportDefinitionDetail
     */
    id: number;
    /**
     * レポート名
     * @type {string}
     * @memberof CommunicationReportDefinitionDetail
     */
    name: string;
    /**
     * 
     * @type {CommunicationType}
     * @memberof CommunicationReportDefinitionDetail
     */
    communicationType: CommunicationType;
    /**
     * 
     * @type {Array<CommunicationReportItem>}
     * @memberof CommunicationReportDefinitionDetail
     */
    items: Array<CommunicationReportItem>;
    /**
     * 
     * @type {Array<CommunicationReportDefinitionDisplayItem>}
     * @memberof CommunicationReportDefinitionDetail
     */
    displayItems: Array<CommunicationReportDefinitionDisplayItem>;
    /**
     * 
     * @type {Array<CommunicationReportDefinitionFilterCondition>}
     * @memberof CommunicationReportDefinitionDetail
     */
    filterConditions: Array<CommunicationReportDefinitionFilterCondition>;
    /**
     * 
     * @type {Array<CommunicationReportDefinitionSortCondition>}
     * @memberof CommunicationReportDefinitionDetail
     */
    sortConditions: Array<CommunicationReportDefinitionSortCondition>;
    /**
     * 
     * @type {CommunicationReportDefinitionAggregationRow}
     * @memberof CommunicationReportDefinitionDetail
     */
    aggregationRow: CommunicationReportDefinitionAggregationRow | null;
    /**
     * 
     * @type {CommunicationReportDefinitionAggregationColumn}
     * @memberof CommunicationReportDefinitionDetail
     */
    aggregationColumn: CommunicationReportDefinitionAggregationColumn | null;
    /**
     * 
     * @type {Array<CommunicationReportDefinitionAggregationData>}
     * @memberof CommunicationReportDefinitionDetail
     */
    aggregationData: Array<CommunicationReportDefinitionAggregationData>;
    /**
     * 
     * @type {NullableStaff}
     * @memberof CommunicationReportDefinitionDetail
     */
    createdBy: NullableStaff | null;
    /**
     * 作成日時
     * @type {string}
     * @memberof CommunicationReportDefinitionDetail
     */
    createdAt: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof CommunicationReportDefinitionDetail
     */
    updatedAt: string;
}

export function CommunicationReportDefinitionDetailFromJSON(json: any): CommunicationReportDefinitionDetail {
    return CommunicationReportDefinitionDetailFromJSONTyped(json, false);
}

export function CommunicationReportDefinitionDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportDefinitionDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'communicationType': CommunicationTypeFromJSON(json['communication_type']),
        'items': ((json['items'] as Array<any>).map(CommunicationReportItemFromJSON)),
        'displayItems': ((json['display_items'] as Array<any>).map(CommunicationReportDefinitionDisplayItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(CommunicationReportDefinitionFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(CommunicationReportDefinitionSortConditionFromJSON)),
        'aggregationRow': CommunicationReportDefinitionAggregationRowFromJSON(json['aggregation_row']),
        'aggregationColumn': CommunicationReportDefinitionAggregationColumnFromJSON(json['aggregation_column']),
        'aggregationData': ((json['aggregation_data'] as Array<any>).map(CommunicationReportDefinitionAggregationDataFromJSON)),
        'createdBy': NullableStaffFromJSON(json['created_by']),
        'createdAt': json['created_at'],
        'updatedAt': json['updated_at'],
    };
}

export function CommunicationReportDefinitionDetailToJSON(value?: CommunicationReportDefinitionDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'communication_type': CommunicationTypeToJSON(value.communicationType),
        'items': ((value.items as Array<any>).map(CommunicationReportItemToJSON)),
        'display_items': ((value.displayItems as Array<any>).map(CommunicationReportDefinitionDisplayItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(CommunicationReportDefinitionFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(CommunicationReportDefinitionSortConditionToJSON)),
        'aggregation_row': CommunicationReportDefinitionAggregationRowToJSON(value.aggregationRow),
        'aggregation_column': CommunicationReportDefinitionAggregationColumnToJSON(value.aggregationColumn),
        'aggregation_data': ((value.aggregationData as Array<any>).map(CommunicationReportDefinitionAggregationDataToJSON)),
        'created_by': NullableStaffToJSON(value.createdBy),
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
    };
}

