/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionFiltersDepartmentsItems,
    AlertConditionFiltersDepartmentsItemsFromJSON,
    AlertConditionFiltersDepartmentsItemsFromJSONTyped,
    AlertConditionFiltersDepartmentsItemsToJSON,
} from './AlertConditionFiltersDepartmentsItems';

/**
 * 
 * @export
 * @interface AlertConditionFiltersDepartments
 */
export interface AlertConditionFiltersDepartments {
    /**
     * 標榜診療科目一覧
     * @type {Array<AlertConditionFiltersDepartmentsItems>}
     * @memberof AlertConditionFiltersDepartments
     */
    items: Array<AlertConditionFiltersDepartmentsItems>;
    /**
     * 無効データの有無
     * @type {boolean}
     * @memberof AlertConditionFiltersDepartments
     */
    isInvalid: boolean;
}

export function AlertConditionFiltersDepartmentsFromJSON(json: any): AlertConditionFiltersDepartments {
    return AlertConditionFiltersDepartmentsFromJSONTyped(json, false);
}

export function AlertConditionFiltersDepartmentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionFiltersDepartments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(AlertConditionFiltersDepartmentsItemsFromJSON)),
        'isInvalid': json['is_invalid'],
    };
}

export function AlertConditionFiltersDepartmentsToJSON(value?: AlertConditionFiltersDepartments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(AlertConditionFiltersDepartmentsItemsToJSON)),
        'is_invalid': value.isInvalid,
    };
}

