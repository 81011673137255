/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportItemAggregationCondition,
    MedicalInstitutionReportItemAggregationConditionFromJSON,
    MedicalInstitutionReportItemAggregationConditionFromJSONTyped,
    MedicalInstitutionReportItemAggregationConditionToJSON,
} from './MedicalInstitutionReportItemAggregationCondition';
import {
    MedicalInstitutionReportItemEnabledAggregationMethodEnum,
    MedicalInstitutionReportItemEnabledAggregationMethodEnumFromJSON,
    MedicalInstitutionReportItemEnabledAggregationMethodEnumFromJSONTyped,
    MedicalInstitutionReportItemEnabledAggregationMethodEnumToJSON,
} from './MedicalInstitutionReportItemEnabledAggregationMethodEnum';
import {
    MedicalInstitutionReportItemFormula,
    MedicalInstitutionReportItemFormulaFromJSON,
    MedicalInstitutionReportItemFormulaFromJSONTyped,
    MedicalInstitutionReportItemFormulaToJSON,
} from './MedicalInstitutionReportItemFormula';

/**
 * 連携先レポート項目（集計用）
 * @export
 * @interface MedicalInstitutionReportItemForAggregation
 */
export interface MedicalInstitutionReportItemForAggregation {
    /**
     * 連携先レポート表示項目を一意に特定するキー
     * @type {string}
     * @memberof MedicalInstitutionReportItemForAggregation
     */
    itemKey: string;
    /**
     * 連携先レポート項目定義を一意に特定するキー名
     * @type {string}
     * @memberof MedicalInstitutionReportItemForAggregation
     */
    key: string;
    /**
     * 
     * @type {MedicalInstitutionReportItemEnabledAggregationMethodEnum}
     * @memberof MedicalInstitutionReportItemForAggregation
     */
    aggregationMethod?: MedicalInstitutionReportItemEnabledAggregationMethodEnum;
    /**
     * 
     * @type {MedicalInstitutionReportItemAggregationCondition}
     * @memberof MedicalInstitutionReportItemForAggregation
     */
    aggregationConditions?: MedicalInstitutionReportItemAggregationCondition;
    /**
     * 
     * @type {MedicalInstitutionReportItemFormula}
     * @memberof MedicalInstitutionReportItemForAggregation
     */
    formula?: MedicalInstitutionReportItemFormula;
}

export function MedicalInstitutionReportItemForAggregationFromJSON(json: any): MedicalInstitutionReportItemForAggregation {
    return MedicalInstitutionReportItemForAggregationFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemForAggregationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemForAggregation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemKey': json['item_key'],
        'key': json['key'],
        'aggregationMethod': !exists(json, 'aggregation_method') ? undefined : MedicalInstitutionReportItemEnabledAggregationMethodEnumFromJSON(json['aggregation_method']),
        'aggregationConditions': !exists(json, 'aggregation_conditions') ? undefined : MedicalInstitutionReportItemAggregationConditionFromJSON(json['aggregation_conditions']),
        'formula': !exists(json, 'formula') ? undefined : MedicalInstitutionReportItemFormulaFromJSON(json['formula']),
    };
}

export function MedicalInstitutionReportItemForAggregationToJSON(value?: MedicalInstitutionReportItemForAggregation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_key': value.itemKey,
        'key': value.key,
        'aggregation_method': MedicalInstitutionReportItemEnabledAggregationMethodEnumToJSON(value.aggregationMethod),
        'aggregation_conditions': MedicalInstitutionReportItemAggregationConditionToJSON(value.aggregationConditions),
        'formula': MedicalInstitutionReportItemFormulaToJSON(value.formula),
    };
}

