/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionLabelAreaAddress,
    MedicalInstitutionLabelAreaAddressFromJSON,
    MedicalInstitutionLabelAreaAddressFromJSONTyped,
    MedicalInstitutionLabelAreaAddressToJSON,
} from './MedicalInstitutionLabelAreaAddress';

/**
 * 
 * @export
 * @interface PutMedicalInstitutionLabelIdRequestAreas
 */
export interface PutMedicalInstitutionLabelIdRequestAreas {
    /**
     * 医療機関ラベル選択肢ID（更新の場合は設定する）
     * @type {number}
     * @memberof PutMedicalInstitutionLabelIdRequestAreas
     */
    id?: number | null;
    /**
     * エリア名
     * @type {string}
     * @memberof PutMedicalInstitutionLabelIdRequestAreas
     */
    name: string;
    /**
     * 住所リスト
     * @type {Array<MedicalInstitutionLabelAreaAddress>}
     * @memberof PutMedicalInstitutionLabelIdRequestAreas
     */
    addresses: Array<MedicalInstitutionLabelAreaAddress>;
}

export function PutMedicalInstitutionLabelIdRequestAreasFromJSON(json: any): PutMedicalInstitutionLabelIdRequestAreas {
    return PutMedicalInstitutionLabelIdRequestAreasFromJSONTyped(json, false);
}

export function PutMedicalInstitutionLabelIdRequestAreasFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutMedicalInstitutionLabelIdRequestAreas {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'addresses': ((json['addresses'] as Array<any>).map(MedicalInstitutionLabelAreaAddressFromJSON)),
    };
}

export function PutMedicalInstitutionLabelIdRequestAreasToJSON(value?: PutMedicalInstitutionLabelIdRequestAreas | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'addresses': ((value.addresses as Array<any>).map(MedicalInstitutionLabelAreaAddressToJSON)),
    };
}

