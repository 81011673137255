/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutActionListStatusRequest
 */
export interface PutActionListStatusRequest {
    /**
     * アクションリストのステータス
     * @type {string}
     * @memberof PutActionListStatusRequest
     */
    status: PutActionListStatusRequestStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum PutActionListStatusRequestStatusEnum {
    Incomplete = 'incomplete',
    Finished = 'finished'
}

export function PutActionListStatusRequestFromJSON(json: any): PutActionListStatusRequest {
    return PutActionListStatusRequestFromJSONTyped(json, false);
}

export function PutActionListStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutActionListStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function PutActionListStatusRequestToJSON(value?: PutActionListStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

