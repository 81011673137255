/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaReportDefinitionDisplayItem,
    AreaReportDefinitionDisplayItemFromJSON,
    AreaReportDefinitionDisplayItemFromJSONTyped,
    AreaReportDefinitionDisplayItemToJSON,
} from './AreaReportDefinitionDisplayItem';
import {
    AreaReportDefinitionFilterCondition,
    AreaReportDefinitionFilterConditionFromJSON,
    AreaReportDefinitionFilterConditionFromJSONTyped,
    AreaReportDefinitionFilterConditionToJSON,
} from './AreaReportDefinitionFilterCondition';
import {
    AreaReportDefinitionSortCondition,
    AreaReportDefinitionSortConditionFromJSON,
    AreaReportDefinitionSortConditionFromJSONTyped,
    AreaReportDefinitionSortConditionToJSON,
} from './AreaReportDefinitionSortCondition';
import {
    AreaReportItem,
    AreaReportItemFromJSON,
    AreaReportItemFromJSONTyped,
    AreaReportItemToJSON,
} from './AreaReportItem';

/**
 * 
 * @export
 * @interface PostAreaReportResultsRequest
 */
export interface PostAreaReportResultsRequest {
    /**
     * エリアタイプの医療機関ラベルID
     * @type {number}
     * @memberof PostAreaReportResultsRequest
     */
    medicalInstitutionLabelId: number;
    /**
     * レポート項目リスト
     * @type {Array<AreaReportItem>}
     * @memberof PostAreaReportResultsRequest
     */
    items: Array<AreaReportItem>;
    /**
     * レポート項目の表示設定リスト
     * @type {Array<AreaReportDefinitionDisplayItem>}
     * @memberof PostAreaReportResultsRequest
     */
    displayItems: Array<AreaReportDefinitionDisplayItem>;
    /**
     * 集計結果の絞り込み条件リスト
     * @type {Array<AreaReportDefinitionFilterCondition>}
     * @memberof PostAreaReportResultsRequest
     */
    filterConditions: Array<AreaReportDefinitionFilterCondition>;
    /**
     * 並び替え条件リスト
     * @type {Array<AreaReportDefinitionSortCondition>}
     * @memberof PostAreaReportResultsRequest
     */
    sortConditions: Array<AreaReportDefinitionSortCondition>;
    /**
     * ページ番号。1から始まる。
     * @type {number}
     * @memberof PostAreaReportResultsRequest
     */
    page: number;
    /**
     * 1ページあたりの表示件数。
     * @type {number}
     * @memberof PostAreaReportResultsRequest
     */
    perPage: number;
}

export function PostAreaReportResultsRequestFromJSON(json: any): PostAreaReportResultsRequest {
    return PostAreaReportResultsRequestFromJSONTyped(json, false);
}

export function PostAreaReportResultsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostAreaReportResultsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutionLabelId': json['medical_institution_label_id'],
        'items': ((json['items'] as Array<any>).map(AreaReportItemFromJSON)),
        'displayItems': ((json['display_items'] as Array<any>).map(AreaReportDefinitionDisplayItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(AreaReportDefinitionFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(AreaReportDefinitionSortConditionFromJSON)),
        'page': json['page'],
        'perPage': json['per_page'],
    };
}

export function PostAreaReportResultsRequestToJSON(value?: PostAreaReportResultsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution_label_id': value.medicalInstitutionLabelId,
        'items': ((value.items as Array<any>).map(AreaReportItemToJSON)),
        'display_items': ((value.displayItems as Array<any>).map(AreaReportDefinitionDisplayItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(AreaReportDefinitionFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(AreaReportDefinitionSortConditionToJSON)),
        'page': value.page,
        'per_page': value.perPage,
    };
}

