/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostChartBothReferralsComparisonRequest,
    PostChartBothReferralsComparisonRequestFromJSON,
    PostChartBothReferralsComparisonRequestToJSON,
    PostChartBothReferralsComparisonResponse,
    PostChartBothReferralsComparisonResponseFromJSON,
    PostChartBothReferralsComparisonResponseToJSON,
    PostChartMedicalInstitutionsReferralsDepartmentsRequest,
    PostChartMedicalInstitutionsReferralsDepartmentsRequestFromJSON,
    PostChartMedicalInstitutionsReferralsDepartmentsRequestToJSON,
    PostChartMedicalInstitutionsReferralsDepartmentsResponse,
    PostChartMedicalInstitutionsReferralsDepartmentsResponseFromJSON,
    PostChartMedicalInstitutionsReferralsDepartmentsResponseToJSON,
    PostChartMedicalInstitutionsReferralsRequest,
    PostChartMedicalInstitutionsReferralsRequestFromJSON,
    PostChartMedicalInstitutionsReferralsRequestToJSON,
    PostChartMedicalInstitutionsReferralsResponse,
    PostChartMedicalInstitutionsReferralsResponseFromJSON,
    PostChartMedicalInstitutionsReferralsResponseToJSON,
    PostChartReferralsComparisonAnnualRequest,
    PostChartReferralsComparisonAnnualRequestFromJSON,
    PostChartReferralsComparisonAnnualRequestToJSON,
    PostChartReferralsComparisonAnnualResponse,
    PostChartReferralsComparisonAnnualResponseFromJSON,
    PostChartReferralsComparisonAnnualResponseToJSON,
    PostChartReferralsComparisonDepartmentsResponse,
    PostChartReferralsComparisonDepartmentsResponseFromJSON,
    PostChartReferralsComparisonDepartmentsResponseToJSON,
    PostChartReferralsComparisonMonthlyRequest,
    PostChartReferralsComparisonMonthlyRequestFromJSON,
    PostChartReferralsComparisonMonthlyRequestToJSON,
    PostChartReferralsComparisonMonthlyResponse,
    PostChartReferralsComparisonMonthlyResponseFromJSON,
    PostChartReferralsComparisonMonthlyResponseToJSON,
    PostChartReferralsComparisonRequest,
    PostChartReferralsComparisonRequestFromJSON,
    PostChartReferralsComparisonRequestToJSON,
    PostChartReferralsComparisonResponse,
    PostChartReferralsComparisonResponseFromJSON,
    PostChartReferralsComparisonResponseToJSON,
    PostChartReferralsRequest,
    PostChartReferralsRequestFromJSON,
    PostChartReferralsRequestToJSON,
    PostChartReferralsResponse,
    PostChartReferralsResponseFromJSON,
    PostChartReferralsResponseToJSON,
    PostChartReverseReferralsComparisonDepartmentsResponse,
    PostChartReverseReferralsComparisonDepartmentsResponseFromJSON,
    PostChartReverseReferralsComparisonDepartmentsResponseToJSON,
    PostChartReverseReferralsComparisonMonthlyRequest,
    PostChartReverseReferralsComparisonMonthlyRequestFromJSON,
    PostChartReverseReferralsComparisonMonthlyRequestToJSON,
    PostChartReverseReferralsComparisonMonthlyResponse,
    PostChartReverseReferralsComparisonMonthlyResponseFromJSON,
    PostChartReverseReferralsComparisonMonthlyResponseToJSON,
    PostChartVisitsDoctorInterviewRatesRequest,
    PostChartVisitsDoctorInterviewRatesRequestFromJSON,
    PostChartVisitsDoctorInterviewRatesRequestToJSON,
    PostChartVisitsDoctorInterviewRatesResponse,
    PostChartVisitsDoctorInterviewRatesResponseFromJSON,
    PostChartVisitsDoctorInterviewRatesResponseToJSON,
    PostChartVisitsNextActionRatesRequest,
    PostChartVisitsNextActionRatesRequestFromJSON,
    PostChartVisitsNextActionRatesRequestToJSON,
    PostChartVisitsNextActionRatesResponse,
    PostChartVisitsNextActionRatesResponseFromJSON,
    PostChartVisitsNextActionRatesResponseToJSON,
    PostChartVisitsRequest,
    PostChartVisitsRequestFromJSON,
    PostChartVisitsRequestToJSON,
    PostChartVisitsResponse,
    PostChartVisitsResponseFromJSON,
    PostChartVisitsResponseToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface PostChartBothReferralsComparisonOperationRequest {
    postChartBothReferralsComparisonRequest: PostChartBothReferralsComparisonRequest;
}

export interface PostChartMedicalInstitutionsReferralsOperationRequest {
    id: number;
    postChartMedicalInstitutionsReferralsRequest: PostChartMedicalInstitutionsReferralsRequest;
}

export interface PostChartMedicalInstitutionsReferralsDepartmentsOperationRequest {
    id: number;
    postChartMedicalInstitutionsReferralsDepartmentsRequest: PostChartMedicalInstitutionsReferralsDepartmentsRequest;
}

export interface PostChartReferralsOperationRequest {
    postChartReferralsRequest: PostChartReferralsRequest;
}

export interface PostChartReferralsComparisonOperationRequest {
    postChartReferralsComparisonRequest: PostChartReferralsComparisonRequest;
}

export interface PostChartReferralsComparisonAnnualOperationRequest {
    postChartReferralsComparisonAnnualRequest: PostChartReferralsComparisonAnnualRequest;
}

export interface PostChartReferralsComparisonMonthlyOperationRequest {
    postChartReferralsComparisonMonthlyRequest: PostChartReferralsComparisonMonthlyRequest;
}

export interface PostChartReverseReferralsComparisonMonthlyOperationRequest {
    postChartReverseReferralsComparisonMonthlyRequest: PostChartReverseReferralsComparisonMonthlyRequest;
}

export interface PostChartVisitsOperationRequest {
    postChartVisitsRequest: PostChartVisitsRequest;
}

export interface PostChartVisitsDoctorInterviewRatesOperationRequest {
    postChartVisitsDoctorInterviewRatesRequest: PostChartVisitsDoctorInterviewRatesRequest;
}

export interface PostChartVisitsNextActionRatesOperationRequest {
    postChartVisitsNextActionRatesRequest: PostChartVisitsNextActionRatesRequest;
}

/**
 * ChartsApi - interface
 * 
 * @export
 * @interface ChartsApiInterface
 */
export interface ChartsApiInterface {
    /**
     * 
     * @summary 紹介数・逆紹介数の期間指定対比データの生成(データの取得)
     * @param {PostChartBothReferralsComparisonRequest} postChartBothReferralsComparisonRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartBothReferralsComparisonRaw(requestParameters: PostChartBothReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartBothReferralsComparisonResponse>>;

    /**
     * 紹介数・逆紹介数の期間指定対比データの生成(データの取得)
     */
    postChartBothReferralsComparison(requestParameters: PostChartBothReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<PostChartBothReferralsComparisonResponse>;

    /**
     * 
     * @summary 個別医療機関からの紹介数の取得
     * @param {number} id 医療機関ID
     * @param {PostChartMedicalInstitutionsReferralsRequest} postChartMedicalInstitutionsReferralsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartMedicalInstitutionsReferralsRaw(requestParameters: PostChartMedicalInstitutionsReferralsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartMedicalInstitutionsReferralsResponse>>;

    /**
     * 個別医療機関からの紹介数の取得
     */
    postChartMedicalInstitutionsReferrals(requestParameters: PostChartMedicalInstitutionsReferralsOperationRequest, initOverrides?: RequestInit): Promise<PostChartMedicalInstitutionsReferralsResponse>;

    /**
     * 
     * @summary 個別医療機関からの紹介または逆紹介に紐づく診療科目の取得
     * @param {number} id 医療機関ID
     * @param {PostChartMedicalInstitutionsReferralsDepartmentsRequest} postChartMedicalInstitutionsReferralsDepartmentsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartMedicalInstitutionsReferralsDepartmentsRaw(requestParameters: PostChartMedicalInstitutionsReferralsDepartmentsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartMedicalInstitutionsReferralsDepartmentsResponse>>;

    /**
     * 個別医療機関からの紹介または逆紹介に紐づく診療科目の取得
     */
    postChartMedicalInstitutionsReferralsDepartments(requestParameters: PostChartMedicalInstitutionsReferralsDepartmentsOperationRequest, initOverrides?: RequestInit): Promise<PostChartMedicalInstitutionsReferralsDepartmentsResponse>;

    /**
     * 
     * @summary 同一テナント内のデータ分析向けの紹介数の取得
     * @param {PostChartReferralsRequest} postChartReferralsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartReferralsRaw(requestParameters: PostChartReferralsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReferralsResponse>>;

    /**
     * 同一テナント内のデータ分析向けの紹介数の取得
     */
    postChartReferrals(requestParameters: PostChartReferralsOperationRequest, initOverrides?: RequestInit): Promise<PostChartReferralsResponse>;

    /**
     * 
     * @summary 紹介数の期間指定比較データの生成(データの取得)
     * @param {PostChartReferralsComparisonRequest} postChartReferralsComparisonRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartReferralsComparisonRaw(requestParameters: PostChartReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReferralsComparisonResponse>>;

    /**
     * 紹介数の期間指定比較データの生成(データの取得)
     */
    postChartReferralsComparison(requestParameters: PostChartReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<PostChartReferralsComparisonResponse>;

    /**
     * 
     * @summary 紹介数の年次比較データの生成(データの取得)
     * @param {PostChartReferralsComparisonAnnualRequest} postChartReferralsComparisonAnnualRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartReferralsComparisonAnnualRaw(requestParameters: PostChartReferralsComparisonAnnualOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReferralsComparisonAnnualResponse>>;

    /**
     * 紹介数の年次比較データの生成(データの取得)
     */
    postChartReferralsComparisonAnnual(requestParameters: PostChartReferralsComparisonAnnualOperationRequest, initOverrides?: RequestInit): Promise<PostChartReferralsComparisonAnnualResponse>;

    /**
     * 
     * @summary 紹介数の診療科別比較データの生成(データの取得)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartReferralsComparisonDepartmentsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReferralsComparisonDepartmentsResponse>>;

    /**
     * 紹介数の診療科別比較データの生成(データの取得)
     */
    postChartReferralsComparisonDepartments(initOverrides?: RequestInit): Promise<PostChartReferralsComparisonDepartmentsResponse>;

    /**
     * 
     * @summary 紹介数の月次比較データの生成(データの取得)
     * @param {PostChartReferralsComparisonMonthlyRequest} postChartReferralsComparisonMonthlyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartReferralsComparisonMonthlyRaw(requestParameters: PostChartReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReferralsComparisonMonthlyResponse>>;

    /**
     * 紹介数の月次比較データの生成(データの取得)
     */
    postChartReferralsComparisonMonthly(requestParameters: PostChartReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<PostChartReferralsComparisonMonthlyResponse>;

    /**
     * 
     * @summary 逆紹介数の診療科別比較データの生成(データの取得)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartReverseReferralsComparisonDepartmentsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReverseReferralsComparisonDepartmentsResponse>>;

    /**
     * 逆紹介数の診療科別比較データの生成(データの取得)
     */
    postChartReverseReferralsComparisonDepartments(initOverrides?: RequestInit): Promise<PostChartReverseReferralsComparisonDepartmentsResponse>;

    /**
     * 
     * @summary 逆紹介数の月次比較データの生成(データの取得)
     * @param {PostChartReverseReferralsComparisonMonthlyRequest} postChartReverseReferralsComparisonMonthlyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartReverseReferralsComparisonMonthlyRaw(requestParameters: PostChartReverseReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReverseReferralsComparisonMonthlyResponse>>;

    /**
     * 逆紹介数の月次比較データの生成(データの取得)
     */
    postChartReverseReferralsComparisonMonthly(requestParameters: PostChartReverseReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<PostChartReverseReferralsComparisonMonthlyResponse>;

    /**
     * 
     * @summary 同一テナント内のデータ分析向けの訪問数の取得
     * @param {PostChartVisitsRequest} postChartVisitsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartVisitsRaw(requestParameters: PostChartVisitsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartVisitsResponse>>;

    /**
     * 同一テナント内のデータ分析向けの訪問数の取得
     */
    postChartVisits(requestParameters: PostChartVisitsOperationRequest, initOverrides?: RequestInit): Promise<PostChartVisitsResponse>;

    /**
     * 
     * @summary 同一テナント内のデータ分析向けの医師面談設定率の取得
     * @param {PostChartVisitsDoctorInterviewRatesRequest} postChartVisitsDoctorInterviewRatesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartVisitsDoctorInterviewRatesRaw(requestParameters: PostChartVisitsDoctorInterviewRatesOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartVisitsDoctorInterviewRatesResponse>>;

    /**
     * 同一テナント内のデータ分析向けの医師面談設定率の取得
     */
    postChartVisitsDoctorInterviewRates(requestParameters: PostChartVisitsDoctorInterviewRatesOperationRequest, initOverrides?: RequestInit): Promise<PostChartVisitsDoctorInterviewRatesResponse>;

    /**
     * 
     * @summary 同一テナント内のデータ分析向けのネクストアクション設定率の取得
     * @param {PostChartVisitsNextActionRatesRequest} postChartVisitsNextActionRatesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChartsApiInterface
     */
    postChartVisitsNextActionRatesRaw(requestParameters: PostChartVisitsNextActionRatesOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartVisitsNextActionRatesResponse>>;

    /**
     * 同一テナント内のデータ分析向けのネクストアクション設定率の取得
     */
    postChartVisitsNextActionRates(requestParameters: PostChartVisitsNextActionRatesOperationRequest, initOverrides?: RequestInit): Promise<PostChartVisitsNextActionRatesResponse>;

}

/**
 * 
 */
export class ChartsApi extends runtime.BaseAPI implements ChartsApiInterface {

    /**
     * 紹介数・逆紹介数の期間指定対比データの生成(データの取得)
     */
    async postChartBothReferralsComparisonRaw(requestParameters: PostChartBothReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartBothReferralsComparisonResponse>> {
        if (requestParameters.postChartBothReferralsComparisonRequest === null || requestParameters.postChartBothReferralsComparisonRequest === undefined) {
            throw new runtime.RequiredError('postChartBothReferralsComparisonRequest','Required parameter requestParameters.postChartBothReferralsComparisonRequest was null or undefined when calling postChartBothReferralsComparison.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/both_referrals/comparison`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChartBothReferralsComparisonRequestToJSON(requestParameters.postChartBothReferralsComparisonRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartBothReferralsComparisonResponseFromJSON(jsonValue));
    }

    /**
     * 紹介数・逆紹介数の期間指定対比データの生成(データの取得)
     */
    async postChartBothReferralsComparison(requestParameters: PostChartBothReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<PostChartBothReferralsComparisonResponse> {
        const response = await this.postChartBothReferralsComparisonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 個別医療機関からの紹介数の取得
     */
    async postChartMedicalInstitutionsReferralsRaw(requestParameters: PostChartMedicalInstitutionsReferralsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartMedicalInstitutionsReferralsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postChartMedicalInstitutionsReferrals.');
        }

        if (requestParameters.postChartMedicalInstitutionsReferralsRequest === null || requestParameters.postChartMedicalInstitutionsReferralsRequest === undefined) {
            throw new runtime.RequiredError('postChartMedicalInstitutionsReferralsRequest','Required parameter requestParameters.postChartMedicalInstitutionsReferralsRequest was null or undefined when calling postChartMedicalInstitutionsReferrals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/medical_institutions/{id}/referrals`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChartMedicalInstitutionsReferralsRequestToJSON(requestParameters.postChartMedicalInstitutionsReferralsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartMedicalInstitutionsReferralsResponseFromJSON(jsonValue));
    }

    /**
     * 個別医療機関からの紹介数の取得
     */
    async postChartMedicalInstitutionsReferrals(requestParameters: PostChartMedicalInstitutionsReferralsOperationRequest, initOverrides?: RequestInit): Promise<PostChartMedicalInstitutionsReferralsResponse> {
        const response = await this.postChartMedicalInstitutionsReferralsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 個別医療機関からの紹介または逆紹介に紐づく診療科目の取得
     */
    async postChartMedicalInstitutionsReferralsDepartmentsRaw(requestParameters: PostChartMedicalInstitutionsReferralsDepartmentsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartMedicalInstitutionsReferralsDepartmentsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postChartMedicalInstitutionsReferralsDepartments.');
        }

        if (requestParameters.postChartMedicalInstitutionsReferralsDepartmentsRequest === null || requestParameters.postChartMedicalInstitutionsReferralsDepartmentsRequest === undefined) {
            throw new runtime.RequiredError('postChartMedicalInstitutionsReferralsDepartmentsRequest','Required parameter requestParameters.postChartMedicalInstitutionsReferralsDepartmentsRequest was null or undefined when calling postChartMedicalInstitutionsReferralsDepartments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/medical_institutions/{id}/referrals/departments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChartMedicalInstitutionsReferralsDepartmentsRequestToJSON(requestParameters.postChartMedicalInstitutionsReferralsDepartmentsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartMedicalInstitutionsReferralsDepartmentsResponseFromJSON(jsonValue));
    }

    /**
     * 個別医療機関からの紹介または逆紹介に紐づく診療科目の取得
     */
    async postChartMedicalInstitutionsReferralsDepartments(requestParameters: PostChartMedicalInstitutionsReferralsDepartmentsOperationRequest, initOverrides?: RequestInit): Promise<PostChartMedicalInstitutionsReferralsDepartmentsResponse> {
        const response = await this.postChartMedicalInstitutionsReferralsDepartmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 同一テナント内のデータ分析向けの紹介数の取得
     */
    async postChartReferralsRaw(requestParameters: PostChartReferralsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReferralsResponse>> {
        if (requestParameters.postChartReferralsRequest === null || requestParameters.postChartReferralsRequest === undefined) {
            throw new runtime.RequiredError('postChartReferralsRequest','Required parameter requestParameters.postChartReferralsRequest was null or undefined when calling postChartReferrals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/referrals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChartReferralsRequestToJSON(requestParameters.postChartReferralsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartReferralsResponseFromJSON(jsonValue));
    }

    /**
     * 同一テナント内のデータ分析向けの紹介数の取得
     */
    async postChartReferrals(requestParameters: PostChartReferralsOperationRequest, initOverrides?: RequestInit): Promise<PostChartReferralsResponse> {
        const response = await this.postChartReferralsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 紹介数の期間指定比較データの生成(データの取得)
     */
    async postChartReferralsComparisonRaw(requestParameters: PostChartReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReferralsComparisonResponse>> {
        if (requestParameters.postChartReferralsComparisonRequest === null || requestParameters.postChartReferralsComparisonRequest === undefined) {
            throw new runtime.RequiredError('postChartReferralsComparisonRequest','Required parameter requestParameters.postChartReferralsComparisonRequest was null or undefined when calling postChartReferralsComparison.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/referrals/comparison`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChartReferralsComparisonRequestToJSON(requestParameters.postChartReferralsComparisonRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartReferralsComparisonResponseFromJSON(jsonValue));
    }

    /**
     * 紹介数の期間指定比較データの生成(データの取得)
     */
    async postChartReferralsComparison(requestParameters: PostChartReferralsComparisonOperationRequest, initOverrides?: RequestInit): Promise<PostChartReferralsComparisonResponse> {
        const response = await this.postChartReferralsComparisonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 紹介数の年次比較データの生成(データの取得)
     */
    async postChartReferralsComparisonAnnualRaw(requestParameters: PostChartReferralsComparisonAnnualOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReferralsComparisonAnnualResponse>> {
        if (requestParameters.postChartReferralsComparisonAnnualRequest === null || requestParameters.postChartReferralsComparisonAnnualRequest === undefined) {
            throw new runtime.RequiredError('postChartReferralsComparisonAnnualRequest','Required parameter requestParameters.postChartReferralsComparisonAnnualRequest was null or undefined when calling postChartReferralsComparisonAnnual.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/referrals/comparison/annual`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChartReferralsComparisonAnnualRequestToJSON(requestParameters.postChartReferralsComparisonAnnualRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartReferralsComparisonAnnualResponseFromJSON(jsonValue));
    }

    /**
     * 紹介数の年次比較データの生成(データの取得)
     */
    async postChartReferralsComparisonAnnual(requestParameters: PostChartReferralsComparisonAnnualOperationRequest, initOverrides?: RequestInit): Promise<PostChartReferralsComparisonAnnualResponse> {
        const response = await this.postChartReferralsComparisonAnnualRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 紹介数の診療科別比較データの生成(データの取得)
     */
    async postChartReferralsComparisonDepartmentsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReferralsComparisonDepartmentsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/referrals/comparison/departments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartReferralsComparisonDepartmentsResponseFromJSON(jsonValue));
    }

    /**
     * 紹介数の診療科別比較データの生成(データの取得)
     */
    async postChartReferralsComparisonDepartments(initOverrides?: RequestInit): Promise<PostChartReferralsComparisonDepartmentsResponse> {
        const response = await this.postChartReferralsComparisonDepartmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 紹介数の月次比較データの生成(データの取得)
     */
    async postChartReferralsComparisonMonthlyRaw(requestParameters: PostChartReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReferralsComparisonMonthlyResponse>> {
        if (requestParameters.postChartReferralsComparisonMonthlyRequest === null || requestParameters.postChartReferralsComparisonMonthlyRequest === undefined) {
            throw new runtime.RequiredError('postChartReferralsComparisonMonthlyRequest','Required parameter requestParameters.postChartReferralsComparisonMonthlyRequest was null or undefined when calling postChartReferralsComparisonMonthly.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/referrals/comparison/monthly`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChartReferralsComparisonMonthlyRequestToJSON(requestParameters.postChartReferralsComparisonMonthlyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartReferralsComparisonMonthlyResponseFromJSON(jsonValue));
    }

    /**
     * 紹介数の月次比較データの生成(データの取得)
     */
    async postChartReferralsComparisonMonthly(requestParameters: PostChartReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<PostChartReferralsComparisonMonthlyResponse> {
        const response = await this.postChartReferralsComparisonMonthlyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 逆紹介数の診療科別比較データの生成(データの取得)
     */
    async postChartReverseReferralsComparisonDepartmentsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReverseReferralsComparisonDepartmentsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/reverse_referrals/comparison/departments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartReverseReferralsComparisonDepartmentsResponseFromJSON(jsonValue));
    }

    /**
     * 逆紹介数の診療科別比較データの生成(データの取得)
     */
    async postChartReverseReferralsComparisonDepartments(initOverrides?: RequestInit): Promise<PostChartReverseReferralsComparisonDepartmentsResponse> {
        const response = await this.postChartReverseReferralsComparisonDepartmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 逆紹介数の月次比較データの生成(データの取得)
     */
    async postChartReverseReferralsComparisonMonthlyRaw(requestParameters: PostChartReverseReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartReverseReferralsComparisonMonthlyResponse>> {
        if (requestParameters.postChartReverseReferralsComparisonMonthlyRequest === null || requestParameters.postChartReverseReferralsComparisonMonthlyRequest === undefined) {
            throw new runtime.RequiredError('postChartReverseReferralsComparisonMonthlyRequest','Required parameter requestParameters.postChartReverseReferralsComparisonMonthlyRequest was null or undefined when calling postChartReverseReferralsComparisonMonthly.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/reverse_referrals/comparison/monthly`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChartReverseReferralsComparisonMonthlyRequestToJSON(requestParameters.postChartReverseReferralsComparisonMonthlyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartReverseReferralsComparisonMonthlyResponseFromJSON(jsonValue));
    }

    /**
     * 逆紹介数の月次比較データの生成(データの取得)
     */
    async postChartReverseReferralsComparisonMonthly(requestParameters: PostChartReverseReferralsComparisonMonthlyOperationRequest, initOverrides?: RequestInit): Promise<PostChartReverseReferralsComparisonMonthlyResponse> {
        const response = await this.postChartReverseReferralsComparisonMonthlyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 同一テナント内のデータ分析向けの訪問数の取得
     */
    async postChartVisitsRaw(requestParameters: PostChartVisitsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartVisitsResponse>> {
        if (requestParameters.postChartVisitsRequest === null || requestParameters.postChartVisitsRequest === undefined) {
            throw new runtime.RequiredError('postChartVisitsRequest','Required parameter requestParameters.postChartVisitsRequest was null or undefined when calling postChartVisits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/visits`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChartVisitsRequestToJSON(requestParameters.postChartVisitsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartVisitsResponseFromJSON(jsonValue));
    }

    /**
     * 同一テナント内のデータ分析向けの訪問数の取得
     */
    async postChartVisits(requestParameters: PostChartVisitsOperationRequest, initOverrides?: RequestInit): Promise<PostChartVisitsResponse> {
        const response = await this.postChartVisitsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 同一テナント内のデータ分析向けの医師面談設定率の取得
     */
    async postChartVisitsDoctorInterviewRatesRaw(requestParameters: PostChartVisitsDoctorInterviewRatesOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartVisitsDoctorInterviewRatesResponse>> {
        if (requestParameters.postChartVisitsDoctorInterviewRatesRequest === null || requestParameters.postChartVisitsDoctorInterviewRatesRequest === undefined) {
            throw new runtime.RequiredError('postChartVisitsDoctorInterviewRatesRequest','Required parameter requestParameters.postChartVisitsDoctorInterviewRatesRequest was null or undefined when calling postChartVisitsDoctorInterviewRates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/visits/doctor_interview_rates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChartVisitsDoctorInterviewRatesRequestToJSON(requestParameters.postChartVisitsDoctorInterviewRatesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartVisitsDoctorInterviewRatesResponseFromJSON(jsonValue));
    }

    /**
     * 同一テナント内のデータ分析向けの医師面談設定率の取得
     */
    async postChartVisitsDoctorInterviewRates(requestParameters: PostChartVisitsDoctorInterviewRatesOperationRequest, initOverrides?: RequestInit): Promise<PostChartVisitsDoctorInterviewRatesResponse> {
        const response = await this.postChartVisitsDoctorInterviewRatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 同一テナント内のデータ分析向けのネクストアクション設定率の取得
     */
    async postChartVisitsNextActionRatesRaw(requestParameters: PostChartVisitsNextActionRatesOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostChartVisitsNextActionRatesResponse>> {
        if (requestParameters.postChartVisitsNextActionRatesRequest === null || requestParameters.postChartVisitsNextActionRatesRequest === undefined) {
            throw new runtime.RequiredError('postChartVisitsNextActionRatesRequest','Required parameter requestParameters.postChartVisitsNextActionRatesRequest was null or undefined when calling postChartVisitsNextActionRates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/charts/visits/next_action_rates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostChartVisitsNextActionRatesRequestToJSON(requestParameters.postChartVisitsNextActionRatesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostChartVisitsNextActionRatesResponseFromJSON(jsonValue));
    }

    /**
     * 同一テナント内のデータ分析向けのネクストアクション設定率の取得
     */
    async postChartVisitsNextActionRates(requestParameters: PostChartVisitsNextActionRatesOperationRequest, initOverrides?: RequestInit): Promise<PostChartVisitsNextActionRatesResponse> {
        const response = await this.postChartVisitsNextActionRatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
