/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * 
 * @export
 * @interface GetTagsResponse
 */
export interface GetTagsResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof GetTagsResponse
     */
    totalPage: number;
    /**
     * タグ数
     * @type {number}
     * @memberof GetTagsResponse
     */
    totalCount: number;
    /**
     * タグデータ
     * @type {Array<Tag>}
     * @memberof GetTagsResponse
     */
    tags: Array<Tag>;
}

export function GetTagsResponseFromJSON(json: any): GetTagsResponse {
    return GetTagsResponseFromJSONTyped(json, false);
}

export function GetTagsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTagsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'tags': ((json['tags'] as Array<any>).map(TagFromJSON)),
    };
}

export function GetTagsResponseToJSON(value?: GetTagsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'tags': ((value.tags as Array<any>).map(TagToJSON)),
    };
}

