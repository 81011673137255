/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Dashboard,
    DashboardFromJSON,
    DashboardToJSON,
    DashboardDetail,
    DashboardDetailFromJSON,
    DashboardDetailToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    GetDashboardsResponse,
    GetDashboardsResponseFromJSON,
    GetDashboardsResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostDashboardRequest,
    PostDashboardRequestFromJSON,
    PostDashboardRequestToJSON,
    PutDashboardRequest,
    PutDashboardRequestFromJSON,
    PutDashboardRequestToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteDashboardRequest {
    id: number;
}

export interface GetDashboardRequest {
    id: number;
}

export interface GetDashboardsRequest {
    page?: number;
    perPage?: number;
}

export interface PostDashboardOperationRequest {
    postDashboardRequest: PostDashboardRequest;
}

export interface PutDashboardOperationRequest {
    id: number;
    putDashboardRequest: PutDashboardRequest;
}

/**
 * DashboardsApi - interface
 * 
 * @export
 * @interface DashboardsApiInterface
 */
export interface DashboardsApiInterface {
    /**
     * 
     * @summary ダッシュボードの削除
     * @param {number} id ダッシュボードID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApiInterface
     */
    deleteDashboardRaw(requestParameters: DeleteDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * ダッシュボードの削除
     */
    deleteDashboard(requestParameters: DeleteDashboardRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary ダッシュボードの取得
     * @param {number} id ダッシュボードID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApiInterface
     */
    getDashboardRaw(requestParameters: GetDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DashboardDetail>>;

    /**
     * ダッシュボードの取得
     */
    getDashboard(requestParameters: GetDashboardRequest, initOverrides?: RequestInit): Promise<DashboardDetail>;

    /**
     * 登録されているダッシュボードが全て取得できる 
     * @summary ダッシュボード一覧の取得
     * @param {number} [page] ページ番号（１始まり）
     * @param {number} [perPage] １ページあたりの件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApiInterface
     */
    getDashboardsRaw(requestParameters: GetDashboardsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetDashboardsResponse>>;

    /**
     * 登録されているダッシュボードが全て取得できる 
     * ダッシュボード一覧の取得
     */
    getDashboards(requestParameters: GetDashboardsRequest, initOverrides?: RequestInit): Promise<GetDashboardsResponse>;

    /**
     * ダッシュボードを登録する 
     * @summary ダッシュボードの登録
     * @param {PostDashboardRequest} postDashboardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApiInterface
     */
    postDashboardRaw(requestParameters: PostDashboardOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Dashboard>>;

    /**
     * ダッシュボードを登録する 
     * ダッシュボードの登録
     */
    postDashboard(requestParameters: PostDashboardOperationRequest, initOverrides?: RequestInit): Promise<Dashboard>;

    /**
     * 
     * @summary ダッシュボードの更新
     * @param {number} id ダッシュボードID
     * @param {PutDashboardRequest} putDashboardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApiInterface
     */
    putDashboardRaw(requestParameters: PutDashboardOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DashboardDetail>>;

    /**
     * ダッシュボードの更新
     */
    putDashboard(requestParameters: PutDashboardOperationRequest, initOverrides?: RequestInit): Promise<DashboardDetail>;

}

/**
 * 
 */
export class DashboardsApi extends runtime.BaseAPI implements DashboardsApiInterface {

    /**
     * ダッシュボードの削除
     */
    async deleteDashboardRaw(requestParameters: DeleteDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ダッシュボードの削除
     */
    async deleteDashboard(requestParameters: DeleteDashboardRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteDashboardRaw(requestParameters, initOverrides);
    }

    /**
     * ダッシュボードの取得
     */
    async getDashboardRaw(requestParameters: GetDashboardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DashboardDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDetailFromJSON(jsonValue));
    }

    /**
     * ダッシュボードの取得
     */
    async getDashboard(requestParameters: GetDashboardRequest, initOverrides?: RequestInit): Promise<DashboardDetail> {
        const response = await this.getDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 登録されているダッシュボードが全て取得できる 
     * ダッシュボード一覧の取得
     */
    async getDashboardsRaw(requestParameters: GetDashboardsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetDashboardsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['per_page'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDashboardsResponseFromJSON(jsonValue));
    }

    /**
     * 登録されているダッシュボードが全て取得できる 
     * ダッシュボード一覧の取得
     */
    async getDashboards(requestParameters: GetDashboardsRequest = {}, initOverrides?: RequestInit): Promise<GetDashboardsResponse> {
        const response = await this.getDashboardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ダッシュボードを登録する 
     * ダッシュボードの登録
     */
    async postDashboardRaw(requestParameters: PostDashboardOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Dashboard>> {
        if (requestParameters.postDashboardRequest === null || requestParameters.postDashboardRequest === undefined) {
            throw new runtime.RequiredError('postDashboardRequest','Required parameter requestParameters.postDashboardRequest was null or undefined when calling postDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostDashboardRequestToJSON(requestParameters.postDashboardRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardFromJSON(jsonValue));
    }

    /**
     * ダッシュボードを登録する 
     * ダッシュボードの登録
     */
    async postDashboard(requestParameters: PostDashboardOperationRequest, initOverrides?: RequestInit): Promise<Dashboard> {
        const response = await this.postDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ダッシュボードの更新
     */
    async putDashboardRaw(requestParameters: PutDashboardOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DashboardDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putDashboard.');
        }

        if (requestParameters.putDashboardRequest === null || requestParameters.putDashboardRequest === undefined) {
            throw new runtime.RequiredError('putDashboardRequest','Required parameter requestParameters.putDashboardRequest was null or undefined when calling putDashboard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/dashboards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutDashboardRequestToJSON(requestParameters.putDashboardRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDetailFromJSON(jsonValue));
    }

    /**
     * ダッシュボードの更新
     */
    async putDashboard(requestParameters: PutDashboardOperationRequest, initOverrides?: RequestInit): Promise<DashboardDetail> {
        const response = await this.putDashboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
