/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostGroupsChartReferralsResponseReferrals,
    PostGroupsChartReferralsResponseReferralsFromJSON,
    PostGroupsChartReferralsResponseReferralsFromJSONTyped,
    PostGroupsChartReferralsResponseReferralsToJSON,
} from './PostGroupsChartReferralsResponseReferrals';

/**
 * 
 * @export
 * @interface PostGroupsChartReferralsResponse
 */
export interface PostGroupsChartReferralsResponse {
    /**
     * 紹介数チャートデータ
     * @type {Array<PostGroupsChartReferralsResponseReferrals>}
     * @memberof PostGroupsChartReferralsResponse
     */
    referrals: Array<PostGroupsChartReferralsResponseReferrals>;
}

export function PostGroupsChartReferralsResponseFromJSON(json: any): PostGroupsChartReferralsResponse {
    return PostGroupsChartReferralsResponseFromJSONTyped(json, false);
}

export function PostGroupsChartReferralsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostGroupsChartReferralsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrals': ((json['referrals'] as Array<any>).map(PostGroupsChartReferralsResponseReferralsFromJSON)),
    };
}

export function PostGroupsChartReferralsResponseToJSON(value?: PostGroupsChartReferralsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrals': ((value.referrals as Array<any>).map(PostGroupsChartReferralsResponseReferralsToJSON)),
    };
}

