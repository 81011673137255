/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * 
 * @export
 * @interface AlertConditionTriggersCommunicationVisitStaff
 */
export interface AlertConditionTriggersCommunicationVisitStaff {
    /**
     * 
     * @type {NullableStaff}
     * @memberof AlertConditionTriggersCommunicationVisitStaff
     */
    user: NullableStaff | null;
    /**
     * 無効データの有無
     * @type {boolean}
     * @memberof AlertConditionTriggersCommunicationVisitStaff
     */
    isInvalid: boolean;
}

export function AlertConditionTriggersCommunicationVisitStaffFromJSON(json: any): AlertConditionTriggersCommunicationVisitStaff {
    return AlertConditionTriggersCommunicationVisitStaffFromJSONTyped(json, false);
}

export function AlertConditionTriggersCommunicationVisitStaffFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionTriggersCommunicationVisitStaff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': NullableStaffFromJSON(json['user']),
        'isInvalid': json['is_invalid'],
    };
}

export function AlertConditionTriggersCommunicationVisitStaffToJSON(value?: AlertConditionTriggersCommunicationVisitStaff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': NullableStaffToJSON(value.user),
        'is_invalid': value.isInvalid,
    };
}

