/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnDepartmentReportItemAggregationCondition,
    OwnDepartmentReportItemAggregationConditionFromJSON,
    OwnDepartmentReportItemAggregationConditionFromJSONTyped,
    OwnDepartmentReportItemAggregationConditionToJSON,
} from './OwnDepartmentReportItemAggregationCondition';
import {
    OwnDepartmentReportItemEnabledAggregationMethodEnum,
    OwnDepartmentReportItemEnabledAggregationMethodEnumFromJSON,
    OwnDepartmentReportItemEnabledAggregationMethodEnumFromJSONTyped,
    OwnDepartmentReportItemEnabledAggregationMethodEnumToJSON,
} from './OwnDepartmentReportItemEnabledAggregationMethodEnum';
import {
    OwnDepartmentReportItemFormula,
    OwnDepartmentReportItemFormulaFromJSON,
    OwnDepartmentReportItemFormulaFromJSONTyped,
    OwnDepartmentReportItemFormulaToJSON,
} from './OwnDepartmentReportItemFormula';

/**
 * 診療科レポート項目
 * @export
 * @interface OwnDepartmentReportItem
 */
export interface OwnDepartmentReportItem {
    /**
     * レポート項目定義を一意に特定するキー名
     * @type {string}
     * @memberof OwnDepartmentReportItem
     */
    key: string;
    /**
     * コミュニケーション情報を診療科IDで紐づける場合に必須となるコミュニケーションラベルID
     * @type {number}
     * @memberof OwnDepartmentReportItem
     */
    communicationLabelId?: number;
    /**
     * 
     * @type {OwnDepartmentReportItemEnabledAggregationMethodEnum}
     * @memberof OwnDepartmentReportItem
     */
    aggregationMethod?: OwnDepartmentReportItemEnabledAggregationMethodEnum;
    /**
     * 
     * @type {OwnDepartmentReportItemAggregationCondition}
     * @memberof OwnDepartmentReportItem
     */
    aggregationConditions?: OwnDepartmentReportItemAggregationCondition;
    /**
     * 
     * @type {OwnDepartmentReportItemFormula}
     * @memberof OwnDepartmentReportItem
     */
    formula?: OwnDepartmentReportItemFormula;
}

export function OwnDepartmentReportItemFromJSON(json: any): OwnDepartmentReportItem {
    return OwnDepartmentReportItemFromJSONTyped(json, false);
}

export function OwnDepartmentReportItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'communicationLabelId': !exists(json, 'communication_label_id') ? undefined : json['communication_label_id'],
        'aggregationMethod': !exists(json, 'aggregation_method') ? undefined : OwnDepartmentReportItemEnabledAggregationMethodEnumFromJSON(json['aggregation_method']),
        'aggregationConditions': !exists(json, 'aggregation_conditions') ? undefined : OwnDepartmentReportItemAggregationConditionFromJSON(json['aggregation_conditions']),
        'formula': !exists(json, 'formula') ? undefined : OwnDepartmentReportItemFormulaFromJSON(json['formula']),
    };
}

export function OwnDepartmentReportItemToJSON(value?: OwnDepartmentReportItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'communication_label_id': value.communicationLabelId,
        'aggregation_method': OwnDepartmentReportItemEnabledAggregationMethodEnumToJSON(value.aggregationMethod),
        'aggregation_conditions': OwnDepartmentReportItemAggregationConditionToJSON(value.aggregationConditions),
        'formula': OwnDepartmentReportItemFormulaToJSON(value.formula),
    };
}

