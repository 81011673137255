/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSON,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSONTyped,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionToJSON,
} from './PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution';

/**
 * 
 * @export
 * @interface PostDataAnalysisRankingReferralAnnualResponseRankings
 */
export interface PostDataAnalysisRankingReferralAnnualResponseRankings {
    /**
     * 
     * @type {PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution}
     * @memberof PostDataAnalysisRankingReferralAnnualResponseRankings
     */
    medicalInstitution: PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution;
    /**
     * 対象年度の前年度の紹介数
     * @type {number}
     * @memberof PostDataAnalysisRankingReferralAnnualResponseRankings
     */
    referralCountBefore: number;
    /**
     * 対象年度の紹介数
     * @type {number}
     * @memberof PostDataAnalysisRankingReferralAnnualResponseRankings
     */
    referralCountAfter: number;
    /**
     * 増加数
     * @type {number}
     * @memberof PostDataAnalysisRankingReferralAnnualResponseRankings
     */
    increaseCount: number;
}

export function PostDataAnalysisRankingReferralAnnualResponseRankingsFromJSON(json: any): PostDataAnalysisRankingReferralAnnualResponseRankings {
    return PostDataAnalysisRankingReferralAnnualResponseRankingsFromJSONTyped(json, false);
}

export function PostDataAnalysisRankingReferralAnnualResponseRankingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisRankingReferralAnnualResponseRankings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitution': PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSON(json['medical_institution']),
        'referralCountBefore': json['referral_count_before'],
        'referralCountAfter': json['referral_count_after'],
        'increaseCount': json['increase_count'],
    };
}

export function PostDataAnalysisRankingReferralAnnualResponseRankingsToJSON(value?: PostDataAnalysisRankingReferralAnnualResponseRankings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution': PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionToJSON(value.medicalInstitution),
        'referral_count_before': value.referralCountBefore,
        'referral_count_after': value.referralCountAfter,
        'increase_count': value.increaseCount,
    };
}

