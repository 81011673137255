/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionRunningStatusEnum,
    MedicalInstitutionRunningStatusEnumFromJSON,
    MedicalInstitutionRunningStatusEnumFromJSONTyped,
    MedicalInstitutionRunningStatusEnumToJSON,
} from './MedicalInstitutionRunningStatusEnum';

/**
 * 医療機関
 * @export
 * @interface MedicalInstitutionForSalesMap
 */
export interface MedicalInstitutionForSalesMap {
    /**
     * 医療機関ID
     * @type {number}
     * @memberof MedicalInstitutionForSalesMap
     */
    id: number;
    /**
     * 名前
     * @type {string}
     * @memberof MedicalInstitutionForSalesMap
     */
    name: string;
    /**
     * 
     * @type {MedicalInstitutionRunningStatusEnum}
     * @memberof MedicalInstitutionForSalesMap
     */
    runningStatus: MedicalInstitutionRunningStatusEnum;
    /**
     * 住所
     * @type {string}
     * @memberof MedicalInstitutionForSalesMap
     */
    address: string;
    /**
     * 管理者
     * @type {string}
     * @memberof MedicalInstitutionForSalesMap
     */
    administratorName: string | null;
    /**
     * 電話番号
     * @type {string}
     * @memberof MedicalInstitutionForSalesMap
     */
    tel: string | null;
    /**
     * 郵便番号
     * @type {string}
     * @memberof MedicalInstitutionForSalesMap
     */
    postalCode: string | null;
    /**
     * 緯度
     * @type {number}
     * @memberof MedicalInstitutionForSalesMap
     */
    longitude: number;
    /**
     * 経度
     * @type {number}
     * @memberof MedicalInstitutionForSalesMap
     */
    latitude: number;
    /**
     * 診療科目一覧
     * @type {Array<string>}
     * @memberof MedicalInstitutionForSalesMap
     */
    departments: Array<string>;
    /**
     * 紹介数
     * @type {number}
     * @memberof MedicalInstitutionForSalesMap
     */
    referralCount: number;
    /**
     * 紹介数(前年 or 前々年)
     * @type {number}
     * @memberof MedicalInstitutionForSalesMap
     */
    comparisonReferralCount: number;
    /**
     * 紹介数の増減率
     * @type {number}
     * @memberof MedicalInstitutionForSalesMap
     */
    rateOfReferralChange: number;
    /**
     * タグ一覧
     * @type {Array<string>}
     * @memberof MedicalInstitutionForSalesMap
     */
    tags: Array<string>;
}

export function MedicalInstitutionForSalesMapFromJSON(json: any): MedicalInstitutionForSalesMap {
    return MedicalInstitutionForSalesMapFromJSONTyped(json, false);
}

export function MedicalInstitutionForSalesMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionForSalesMap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'runningStatus': MedicalInstitutionRunningStatusEnumFromJSON(json['running_status']),
        'address': json['address'],
        'administratorName': json['administrator_name'],
        'tel': json['tel'],
        'postalCode': json['postal_code'],
        'longitude': json['longitude'],
        'latitude': json['latitude'],
        'departments': json['departments'],
        'referralCount': json['referral_count'],
        'comparisonReferralCount': json['comparison_referral_count'],
        'rateOfReferralChange': json['rate_of_referral_change'],
        'tags': json['tags'],
    };
}

export function MedicalInstitutionForSalesMapToJSON(value?: MedicalInstitutionForSalesMap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'running_status': MedicalInstitutionRunningStatusEnumToJSON(value.runningStatus),
        'address': value.address,
        'administrator_name': value.administratorName,
        'tel': value.tel,
        'postal_code': value.postalCode,
        'longitude': value.longitude,
        'latitude': value.latitude,
        'departments': value.departments,
        'referral_count': value.referralCount,
        'comparison_referral_count': value.comparisonReferralCount,
        'rate_of_referral_change': value.rateOfReferralChange,
        'tags': value.tags,
    };
}

