/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnDepartmentReportItemDefinitionEnabledAggregationsEnum,
    OwnDepartmentReportItemDefinitionEnabledAggregationsEnumFromJSON,
    OwnDepartmentReportItemDefinitionEnabledAggregationsEnumFromJSONTyped,
    OwnDepartmentReportItemDefinitionEnabledAggregationsEnumToJSON,
} from './OwnDepartmentReportItemDefinitionEnabledAggregationsEnum';
import {
    OwnDepartmentReportItemDefinitionEnabledFiltersEnum,
    OwnDepartmentReportItemDefinitionEnabledFiltersEnumFromJSON,
    OwnDepartmentReportItemDefinitionEnabledFiltersEnumFromJSONTyped,
    OwnDepartmentReportItemDefinitionEnabledFiltersEnumToJSON,
} from './OwnDepartmentReportItemDefinitionEnabledFiltersEnum';
import {
    OwnDepartmentReportItemDefinitionValueTypeEnum,
    OwnDepartmentReportItemDefinitionValueTypeEnumFromJSON,
    OwnDepartmentReportItemDefinitionValueTypeEnumFromJSONTyped,
    OwnDepartmentReportItemDefinitionValueTypeEnumToJSON,
} from './OwnDepartmentReportItemDefinitionValueTypeEnum';
import {
    OwnDepartmentReportItemKey,
    OwnDepartmentReportItemKeyFromJSON,
    OwnDepartmentReportItemKeyFromJSONTyped,
    OwnDepartmentReportItemKeyToJSON,
} from './OwnDepartmentReportItemKey';

/**
 * 診療科レポート項目（プリセット項目）定義
 * @export
 * @interface OwnDepartmentReportItemDefinitionPreset
 */
export interface OwnDepartmentReportItemDefinitionPreset {
    /**
     * クライアントでタグ付きユニオンとして扱うためのタグ
     * @type {string}
     * @memberof OwnDepartmentReportItemDefinitionPreset
     */
    type: OwnDepartmentReportItemDefinitionPresetTypeEnum;
    /**
     * 
     * @type {OwnDepartmentReportItemKey}
     * @memberof OwnDepartmentReportItemDefinitionPreset
     */
    key: OwnDepartmentReportItemKey;
    /**
     * 項目ラベル
     * @type {string}
     * @memberof OwnDepartmentReportItemDefinitionPreset
     */
    label: string;
    /**
     * 
     * @type {OwnDepartmentReportItemDefinitionValueTypeEnum}
     * @memberof OwnDepartmentReportItemDefinitionPreset
     */
    valueType: OwnDepartmentReportItemDefinitionValueTypeEnum;
    /**
     * 集計方法の変更可否
     * @type {boolean}
     * @memberof OwnDepartmentReportItemDefinitionPreset
     */
    calculable: boolean;
    /**
     * この項目で利用可能なフィルタ条件
     * @type {Array<OwnDepartmentReportItemDefinitionEnabledFiltersEnum>}
     * @memberof OwnDepartmentReportItemDefinitionPreset
     */
    enabledFilters: Array<OwnDepartmentReportItemDefinitionEnabledFiltersEnum>;
    /**
     * この項目で利用可能な集計指定
     * @type {Array<OwnDepartmentReportItemDefinitionEnabledAggregationsEnum>}
     * @memberof OwnDepartmentReportItemDefinitionPreset
     */
    enabledAggregations?: Array<OwnDepartmentReportItemDefinitionEnabledAggregationsEnum> | null;
}

/**
* @export
* @enum {string}
*/
export enum OwnDepartmentReportItemDefinitionPresetTypeEnum {
    Preset = 'preset'
}

export function OwnDepartmentReportItemDefinitionPresetFromJSON(json: any): OwnDepartmentReportItemDefinitionPreset {
    return OwnDepartmentReportItemDefinitionPresetFromJSONTyped(json, false);
}

export function OwnDepartmentReportItemDefinitionPresetFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportItemDefinitionPreset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'key': OwnDepartmentReportItemKeyFromJSON(json['key']),
        'label': json['label'],
        'valueType': OwnDepartmentReportItemDefinitionValueTypeEnumFromJSON(json['value_type']),
        'calculable': json['calculable'],
        'enabledFilters': ((json['enabled_filters'] as Array<any>).map(OwnDepartmentReportItemDefinitionEnabledFiltersEnumFromJSON)),
        'enabledAggregations': !exists(json, 'enabled_aggregations') ? undefined : (json['enabled_aggregations'] === null ? null : (json['enabled_aggregations'] as Array<any>).map(OwnDepartmentReportItemDefinitionEnabledAggregationsEnumFromJSON)),
    };
}

export function OwnDepartmentReportItemDefinitionPresetToJSON(value?: OwnDepartmentReportItemDefinitionPreset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'key': OwnDepartmentReportItemKeyToJSON(value.key),
        'label': value.label,
        'value_type': OwnDepartmentReportItemDefinitionValueTypeEnumToJSON(value.valueType),
        'calculable': value.calculable,
        'enabled_filters': ((value.enabledFilters as Array<any>).map(OwnDepartmentReportItemDefinitionEnabledFiltersEnumToJSON)),
        'enabled_aggregations': value.enabledAggregations === undefined ? undefined : (value.enabledAggregations === null ? null : (value.enabledAggregations as Array<any>).map(OwnDepartmentReportItemDefinitionEnabledAggregationsEnumToJSON)),
    };
}

