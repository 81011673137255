import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';

import styles from './styles.module.scss';

interface Props {
  message?: string;
}

export const ScrimPresenter: React.FC<Props> = (props) => (
  <div className={styles.base}>
    <div className={styles.container}>
      <LoadingCircle />
      {props.message != null && (
        <span className={styles.text}>{props.message}</span>
      )}
    </div>
  </div>
);
