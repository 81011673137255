/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostChartVisitsRequestPeriod
 */
export interface PostChartVisitsRequestPeriod {
    /**
     * 訪問した日付の始まり
     * @type {string}
     * @memberof PostChartVisitsRequestPeriod
     */
    startDate: string;
    /**
     * 訪問した日付の終わり
     * @type {string}
     * @memberof PostChartVisitsRequestPeriod
     */
    endDate: string;
}

export function PostChartVisitsRequestPeriodFromJSON(json: any): PostChartVisitsRequestPeriod {
    return PostChartVisitsRequestPeriodFromJSONTyped(json, false);
}

export function PostChartVisitsRequestPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartVisitsRequestPeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': json['start_date'],
        'endDate': json['end_date'],
    };
}

export function PostChartVisitsRequestPeriodToJSON(value?: PostChartVisitsRequestPeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': value.startDate,
        'end_date': value.endDate,
    };
}

