/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionForSalesMap,
    MedicalInstitutionForSalesMapFromJSON,
    MedicalInstitutionForSalesMapFromJSONTyped,
    MedicalInstitutionForSalesMapToJSON,
} from './MedicalInstitutionForSalesMap';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionSearchForSalesMapResponse
 */
export interface PostMedicalInstitutionSearchForSalesMapResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof PostMedicalInstitutionSearchForSalesMapResponse
     */
    totalPage: number;
    /**
     * 一覧の総要素数
     * @type {number}
     * @memberof PostMedicalInstitutionSearchForSalesMapResponse
     */
    totalCount: number;
    /**
     * 医療機関の一覧
     * @type {Array<MedicalInstitutionForSalesMap>}
     * @memberof PostMedicalInstitutionSearchForSalesMapResponse
     */
    medicalInstitutions: Array<MedicalInstitutionForSalesMap>;
}

export function PostMedicalInstitutionSearchForSalesMapResponseFromJSON(json: any): PostMedicalInstitutionSearchForSalesMapResponse {
    return PostMedicalInstitutionSearchForSalesMapResponseFromJSONTyped(json, false);
}

export function PostMedicalInstitutionSearchForSalesMapResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionSearchForSalesMapResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'medicalInstitutions': ((json['medical_institutions'] as Array<any>).map(MedicalInstitutionForSalesMapFromJSON)),
    };
}

export function PostMedicalInstitutionSearchForSalesMapResponseToJSON(value?: PostMedicalInstitutionSearchForSalesMapResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'medical_institutions': ((value.medicalInstitutions as Array<any>).map(MedicalInstitutionForSalesMapToJSON)),
    };
}

