/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionComparisonReference,
    AlertConditionComparisonReferenceFromJSON,
    AlertConditionComparisonReferenceFromJSONTyped,
    AlertConditionComparisonReferenceToJSON,
} from './AlertConditionComparisonReference';
import {
    AlertConditionTriggersReferralChangeCountOwnDepartments,
    AlertConditionTriggersReferralChangeCountOwnDepartmentsFromJSON,
    AlertConditionTriggersReferralChangeCountOwnDepartmentsFromJSONTyped,
    AlertConditionTriggersReferralChangeCountOwnDepartmentsToJSON,
} from './AlertConditionTriggersReferralChangeCountOwnDepartments';
import {
    AlertConditionUnitType,
    AlertConditionUnitTypeFromJSON,
    AlertConditionUnitTypeFromJSONTyped,
    AlertConditionUnitTypeToJSON,
} from './AlertConditionUnitType';

/**
 * 
 * @export
 * @interface AlertConditionTriggersReferralChangeCount
 */
export interface AlertConditionTriggersReferralChangeCount {
    /**
     * 紹介数の増減
     * @type {number}
     * @memberof AlertConditionTriggersReferralChangeCount
     */
    count: number;
    /**
     * 
     * @type {AlertConditionUnitType}
     * @memberof AlertConditionTriggersReferralChangeCount
     */
    unitType: AlertConditionUnitType;
    /**
     * 
     * @type {AlertConditionComparisonReference}
     * @memberof AlertConditionTriggersReferralChangeCount
     */
    comparisonReference: AlertConditionComparisonReference;
    /**
     * 
     * @type {AlertConditionTriggersReferralChangeCountOwnDepartments}
     * @memberof AlertConditionTriggersReferralChangeCount
     */
    ownDepartments: AlertConditionTriggersReferralChangeCountOwnDepartments | null;
}

export function AlertConditionTriggersReferralChangeCountFromJSON(json: any): AlertConditionTriggersReferralChangeCount {
    return AlertConditionTriggersReferralChangeCountFromJSONTyped(json, false);
}

export function AlertConditionTriggersReferralChangeCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionTriggersReferralChangeCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'unitType': AlertConditionUnitTypeFromJSON(json['unit_type']),
        'comparisonReference': AlertConditionComparisonReferenceFromJSON(json['comparison_reference']),
        'ownDepartments': AlertConditionTriggersReferralChangeCountOwnDepartmentsFromJSON(json['own_departments']),
    };
}

export function AlertConditionTriggersReferralChangeCountToJSON(value?: AlertConditionTriggersReferralChangeCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'unit_type': AlertConditionUnitTypeToJSON(value.unitType),
        'comparison_reference': AlertConditionComparisonReferenceToJSON(value.comparisonReference),
        'own_departments': AlertConditionTriggersReferralChangeCountOwnDepartmentsToJSON(value.ownDepartments),
    };
}

