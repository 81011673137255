/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnDepartmentReportItem,
    OwnDepartmentReportItemFromJSON,
    OwnDepartmentReportItemFromJSONTyped,
    OwnDepartmentReportItemToJSON,
} from './OwnDepartmentReportItem';

/**
 * レポート項目ソート条件
 * @export
 * @interface OwnDepartmentReportSortCondition
 */
export interface OwnDepartmentReportSortCondition {
    /**
     * ソート条件演算子
     * @type {string}
     * @memberof OwnDepartmentReportSortCondition
     */
    operator: OwnDepartmentReportSortConditionOperatorEnum;
    /**
     * 
     * @type {OwnDepartmentReportItem}
     * @memberof OwnDepartmentReportSortCondition
     */
    item: OwnDepartmentReportItem;
}

/**
* @export
* @enum {string}
*/
export enum OwnDepartmentReportSortConditionOperatorEnum {
    Asc = 'asc',
    Desc = 'desc'
}

export function OwnDepartmentReportSortConditionFromJSON(json: any): OwnDepartmentReportSortCondition {
    return OwnDepartmentReportSortConditionFromJSONTyped(json, false);
}

export function OwnDepartmentReportSortConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportSortCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operator': json['operator'],
        'item': OwnDepartmentReportItemFromJSON(json['item']),
    };
}

export function OwnDepartmentReportSortConditionToJSON(value?: OwnDepartmentReportSortCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operator': value.operator,
        'item': OwnDepartmentReportItemToJSON(value.item),
    };
}

