/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportAggregationResultsResults
 */
export interface ReportAggregationResultsResults {
    /**
     * 
     * @type {string}
     * @memberof ReportAggregationResultsResults
     */
    rowValue: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ReportAggregationResultsResults
     */
    dataValues: { [key: string]: Array<string>; };
}

export function ReportAggregationResultsResultsFromJSON(json: any): ReportAggregationResultsResults {
    return ReportAggregationResultsResultsFromJSONTyped(json, false);
}

export function ReportAggregationResultsResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportAggregationResultsResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowValue': json['row_value'],
        'dataValues': json['data_values'],
    };
}

export function ReportAggregationResultsResultsToJSON(value?: ReportAggregationResultsResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'row_value': value.rowValue,
        'data_values': value.dataValues,
    };
}

