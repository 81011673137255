/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostDashboardRequestContents,
    PostDashboardRequestContentsFromJSON,
    PostDashboardRequestContentsFromJSONTyped,
    PostDashboardRequestContentsToJSON,
} from './PostDashboardRequestContents';

/**
 * 
 * @export
 * @interface PutDashboardRequest
 */
export interface PutDashboardRequest {
    /**
     * ダッシュボード名
     * @type {string}
     * @memberof PutDashboardRequest
     */
    name: string;
    /**
     * 診療科で絞り込み可能なダッシュボードならtrue
     * @type {boolean}
     * @memberof PutDashboardRequest
     */
    filterDepartment: boolean;
    /**
     * ダッシュボードで絞り込み可能なエリアの医療機関ラベルID
     * @type {number}
     * @memberof PutDashboardRequest
     */
    medicalInstitutionLabelId: number | null;
    /**
     * 
     * @type {Array<PostDashboardRequestContents>}
     * @memberof PutDashboardRequest
     */
    contents: Array<PostDashboardRequestContents>;
}

export function PutDashboardRequestFromJSON(json: any): PutDashboardRequest {
    return PutDashboardRequestFromJSONTyped(json, false);
}

export function PutDashboardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutDashboardRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'filterDepartment': json['filter_department'],
        'medicalInstitutionLabelId': json['medical_institution_label_id'],
        'contents': ((json['contents'] as Array<any>).map(PostDashboardRequestContentsFromJSON)),
    };
}

export function PutDashboardRequestToJSON(value?: PutDashboardRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'filter_department': value.filterDepartment,
        'medical_institution_label_id': value.medicalInstitutionLabelId,
        'contents': ((value.contents as Array<any>).map(PostDashboardRequestContentsToJSON)),
    };
}

