/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CommunicationImport,
    CommunicationImportFromJSON,
    CommunicationImportToJSON,
    GetImportsCommunicationsResponse,
    GetImportsCommunicationsResponseFromJSON,
    GetImportsCommunicationsResponseToJSON,
    GetImportsMedicalInstitutionLabelValuesResponse,
    GetImportsMedicalInstitutionLabelValuesResponseFromJSON,
    GetImportsMedicalInstitutionLabelValuesResponseToJSON,
    MedicalInstitutionLabelValueImport,
    MedicalInstitutionLabelValueImportFromJSON,
    MedicalInstitutionLabelValueImportToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteImportsCommunicationIdRequest {
    id: number;
}

export interface PostImportsCommunicationsOperationRequest {
    file: Blob;
}

export interface PostImportsMedicalInstitutionLabelValuesOperationRequest {
    file: Blob;
}

export interface PutImportsCommunicationsOperationRequest {
    file: Blob;
}

/**
 * ImportsApi - interface
 * 
 * @export
 * @interface ImportsApiInterface
 */
export interface ImportsApiInterface {
    /**
     * 
     * @summary コミュニケーションインポート結果一括削除API
     * @param {number} id コミュニケーションインポート履歴ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportsApiInterface
     */
    deleteImportsCommunicationIdRaw(requestParameters: DeleteImportsCommunicationIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * コミュニケーションインポート結果一括削除API
     */
    deleteImportsCommunicationId(requestParameters: DeleteImportsCommunicationIdRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary コミュニケーションインポート処理履歴一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportsApiInterface
     */
    getImportsCommunicationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetImportsCommunicationsResponse>>;

    /**
     * コミュニケーションインポート処理履歴一覧
     */
    getImportsCommunications(initOverrides?: RequestInit): Promise<GetImportsCommunicationsResponse>;

    /**
     * 
     * @summary インポート処理履歴一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportsApiInterface
     */
    getImportsMedicalInstitutionLabelValuesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetImportsMedicalInstitutionLabelValuesResponse>>;

    /**
     * インポート処理履歴一覧
     */
    getImportsMedicalInstitutionLabelValues(initOverrides?: RequestInit): Promise<GetImportsMedicalInstitutionLabelValuesResponse>;

    /**
     * 
     * @summary コミュニケーション新規追加インポート処理を登録
     * @param {Blob} file アップロードするファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportsApiInterface
     */
    postImportsCommunicationsOperationRaw(requestParameters: PostImportsCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationImport>>;

    /**
     * コミュニケーション新規追加インポート処理を登録
     */
    postImportsCommunicationsOperation(requestParameters: PostImportsCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<CommunicationImport>;

    /**
     * 
     * @summary インポート処理を登録
     * @param {Blob} file アップロードするファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportsApiInterface
     */
    postImportsMedicalInstitutionLabelValuesOperationRaw(requestParameters: PostImportsMedicalInstitutionLabelValuesOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionLabelValueImport>>;

    /**
     * インポート処理を登録
     */
    postImportsMedicalInstitutionLabelValuesOperation(requestParameters: PostImportsMedicalInstitutionLabelValuesOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionLabelValueImport>;

    /**
     * 
     * @summary コミュニケーション更新インポート処理を登録
     * @param {Blob} file アップロードするファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportsApiInterface
     */
    putImportsCommunicationsOperationRaw(requestParameters: PutImportsCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationImport>>;

    /**
     * コミュニケーション更新インポート処理を登録
     */
    putImportsCommunicationsOperation(requestParameters: PutImportsCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<CommunicationImport>;

}

/**
 * 
 */
export class ImportsApi extends runtime.BaseAPI implements ImportsApiInterface {

    /**
     * コミュニケーションインポート結果一括削除API
     */
    async deleteImportsCommunicationIdRaw(requestParameters: DeleteImportsCommunicationIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteImportsCommunicationId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/imports/communications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * コミュニケーションインポート結果一括削除API
     */
    async deleteImportsCommunicationId(requestParameters: DeleteImportsCommunicationIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteImportsCommunicationIdRaw(requestParameters, initOverrides);
    }

    /**
     * コミュニケーションインポート処理履歴一覧
     */
    async getImportsCommunicationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetImportsCommunicationsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/imports/communications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetImportsCommunicationsResponseFromJSON(jsonValue));
    }

    /**
     * コミュニケーションインポート処理履歴一覧
     */
    async getImportsCommunications(initOverrides?: RequestInit): Promise<GetImportsCommunicationsResponse> {
        const response = await this.getImportsCommunicationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * インポート処理履歴一覧
     */
    async getImportsMedicalInstitutionLabelValuesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetImportsMedicalInstitutionLabelValuesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/imports/medical_institution_label_values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetImportsMedicalInstitutionLabelValuesResponseFromJSON(jsonValue));
    }

    /**
     * インポート処理履歴一覧
     */
    async getImportsMedicalInstitutionLabelValues(initOverrides?: RequestInit): Promise<GetImportsMedicalInstitutionLabelValuesResponse> {
        const response = await this.getImportsMedicalInstitutionLabelValuesRaw(initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーション新規追加インポート処理を登録
     */
    async postImportsCommunicationsOperationRaw(requestParameters: PostImportsCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationImport>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling postImportsCommunicationsOperation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/imports/communications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationImportFromJSON(jsonValue));
    }

    /**
     * コミュニケーション新規追加インポート処理を登録
     */
    async postImportsCommunicationsOperation(requestParameters: PostImportsCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<CommunicationImport> {
        const response = await this.postImportsCommunicationsOperationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * インポート処理を登録
     */
    async postImportsMedicalInstitutionLabelValuesOperationRaw(requestParameters: PostImportsMedicalInstitutionLabelValuesOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalInstitutionLabelValueImport>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling postImportsMedicalInstitutionLabelValuesOperation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/imports/medical_institution_label_values`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalInstitutionLabelValueImportFromJSON(jsonValue));
    }

    /**
     * インポート処理を登録
     */
    async postImportsMedicalInstitutionLabelValuesOperation(requestParameters: PostImportsMedicalInstitutionLabelValuesOperationRequest, initOverrides?: RequestInit): Promise<MedicalInstitutionLabelValueImport> {
        const response = await this.postImportsMedicalInstitutionLabelValuesOperationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュニケーション更新インポート処理を登録
     */
    async putImportsCommunicationsOperationRaw(requestParameters: PutImportsCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CommunicationImport>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling putImportsCommunicationsOperation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/imports/communications`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationImportFromJSON(jsonValue));
    }

    /**
     * コミュニケーション更新インポート処理を登録
     */
    async putImportsCommunicationsOperation(requestParameters: PutImportsCommunicationsOperationRequest, initOverrides?: RequestInit): Promise<CommunicationImport> {
        const response = await this.putImportsCommunicationsOperationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
