import { useState } from 'react';

export const useFlag = (): [
  flag: boolean,
  on: VoidFunction,
  off: VoidFunction,
] => {
  const [flag, setFlag] = useState(false);
  const on = () => {
    setFlag(true);
  };
  const off = () => {
    setFlag(false);
  };

  return [flag, on, off];
};
