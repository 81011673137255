/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NullableStaff,
    NullableStaffFromJSON,
    NullableStaffFromJSONTyped,
    NullableStaffToJSON,
} from './NullableStaff';

/**
 * 
 * @export
 * @interface MedicalInstitutionLabelValueImport
 */
export interface MedicalInstitutionLabelValueImport {
    /**
     * インポート処理ID
     * @type {number}
     * @memberof MedicalInstitutionLabelValueImport
     */
    id: number;
    /**
     * インポート処理を依頼したファイル名
     * @type {string}
     * @memberof MedicalInstitutionLabelValueImport
     */
    filename: string;
    /**
     * 処理ステータス
     * @type {string}
     * @memberof MedicalInstitutionLabelValueImport
     */
    status: MedicalInstitutionLabelValueImportStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalInstitutionLabelValueImport
     */
    errorMessages: Array<string> | null;
    /**
     * インポート処理に成功した行数
     * @type {number}
     * @memberof MedicalInstitutionLabelValueImport
     */
    importedRowCount: number;
    /**
     * インポート処理に失敗した行数
     * @type {number}
     * @memberof MedicalInstitutionLabelValueImport
     */
    failedRowCount: number;
    /**
     * インポート処理されなかった行数
     * @type {number}
     * @memberof MedicalInstitutionLabelValueImport
     */
    unprocessedRowCount: number;
    /**
     * 打ち切り発生行番号
     * @type {number}
     * @memberof MedicalInstitutionLabelValueImport
     */
    abortionRowNumber: number | null;
    /**
     * インポート処理依頼日時
     * @type {string}
     * @memberof MedicalInstitutionLabelValueImport
     */
    createdAt: string;
    /**
     * 
     * @type {NullableStaff}
     * @memberof MedicalInstitutionLabelValueImport
     */
    createdBy: NullableStaff | null;
}

/**
* @export
* @enum {string}
*/
export enum MedicalInstitutionLabelValueImportStatusEnum {
    Processing = 'processing',
    Completed = 'completed',
    Failed = 'failed'
}

export function MedicalInstitutionLabelValueImportFromJSON(json: any): MedicalInstitutionLabelValueImport {
    return MedicalInstitutionLabelValueImportFromJSONTyped(json, false);
}

export function MedicalInstitutionLabelValueImportFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionLabelValueImport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filename': json['filename'],
        'status': json['status'],
        'errorMessages': json['error_messages'],
        'importedRowCount': json['imported_row_count'],
        'failedRowCount': json['failed_row_count'],
        'unprocessedRowCount': json['unprocessed_row_count'],
        'abortionRowNumber': json['abortion_row_number'],
        'createdAt': json['created_at'],
        'createdBy': NullableStaffFromJSON(json['created_by']),
    };
}

export function MedicalInstitutionLabelValueImportToJSON(value?: MedicalInstitutionLabelValueImport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'filename': value.filename,
        'status': value.status,
        'error_messages': value.errorMessages,
        'imported_row_count': value.importedRowCount,
        'failed_row_count': value.failedRowCount,
        'unprocessed_row_count': value.unprocessedRowCount,
        'abortion_row_number': value.abortionRowNumber,
        'created_at': value.createdAt,
        'created_by': NullableStaffToJSON(value.createdBy),
    };
}

