/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Diagnosis,
    DiagnosisFromJSON,
    DiagnosisFromJSONTyped,
    DiagnosisToJSON,
} from './Diagnosis';

/**
 * 
 * @export
 * @interface Mdc
 */
export interface Mdc {
    /**
     * MDCコード
     * @type {string}
     * @memberof Mdc
     */
    mdcCode: string;
    /**
     * MDC名称
     * @type {string}
     * @memberof Mdc
     */
    name: string;
    /**
     * 
     * @type {Array<Diagnosis>}
     * @memberof Mdc
     */
    diagnoses: Array<Diagnosis>;
}

export function MdcFromJSON(json: any): Mdc {
    return MdcFromJSONTyped(json, false);
}

export function MdcFromJSONTyped(json: any, ignoreDiscriminator: boolean): Mdc {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mdcCode': json['mdc_code'],
        'name': json['name'],
        'diagnoses': ((json['diagnoses'] as Array<any>).map(DiagnosisFromJSON)),
    };
}

export function MdcToJSON(value?: Mdc | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mdc_code': value.mdcCode,
        'name': value.name,
        'diagnoses': ((value.diagnoses as Array<any>).map(DiagnosisToJSON)),
    };
}

