/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsComparisonDepartmentsResponseReferrals,
    PostChartReferralsComparisonDepartmentsResponseReferralsFromJSON,
    PostChartReferralsComparisonDepartmentsResponseReferralsFromJSONTyped,
    PostChartReferralsComparisonDepartmentsResponseReferralsToJSON,
} from './PostChartReferralsComparisonDepartmentsResponseReferrals';

/**
 * 
 * @export
 * @interface PostChartReferralsComparisonDepartmentsResponse
 */
export interface PostChartReferralsComparisonDepartmentsResponse {
    /**
     * 紹介数診療科別比較データ
     * @type {Array<PostChartReferralsComparisonDepartmentsResponseReferrals>}
     * @memberof PostChartReferralsComparisonDepartmentsResponse
     */
    referrals: Array<PostChartReferralsComparisonDepartmentsResponseReferrals>;
}

export function PostChartReferralsComparisonDepartmentsResponseFromJSON(json: any): PostChartReferralsComparisonDepartmentsResponse {
    return PostChartReferralsComparisonDepartmentsResponseFromJSONTyped(json, false);
}

export function PostChartReferralsComparisonDepartmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReferralsComparisonDepartmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrals': ((json['referrals'] as Array<any>).map(PostChartReferralsComparisonDepartmentsResponseReferralsFromJSON)),
    };
}

export function PostChartReferralsComparisonDepartmentsResponseToJSON(value?: PostChartReferralsComparisonDepartmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrals': ((value.referrals as Array<any>).map(PostChartReferralsComparisonDepartmentsResponseReferralsToJSON)),
    };
}

