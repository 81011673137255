/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdsSearchCondition,
    IdsSearchConditionFromJSON,
    IdsSearchConditionFromJSONTyped,
    IdsSearchConditionToJSON,
} from './IdsSearchCondition';
import {
    LabelSearchCondition,
    LabelSearchConditionFromJSON,
    LabelSearchConditionFromJSONTyped,
    LabelSearchConditionToJSON,
} from './LabelSearchCondition';
import {
    PostMedicalInstitutionSearchForSalesMapRequestBounds,
    PostMedicalInstitutionSearchForSalesMapRequestBoundsFromJSON,
    PostMedicalInstitutionSearchForSalesMapRequestBoundsFromJSONTyped,
    PostMedicalInstitutionSearchForSalesMapRequestBoundsToJSON,
} from './PostMedicalInstitutionSearchForSalesMapRequestBounds';
import {
    PostMedicalInstitutionSearchForSalesMapRequestReferral,
    PostMedicalInstitutionSearchForSalesMapRequestReferralFromJSON,
    PostMedicalInstitutionSearchForSalesMapRequestReferralFromJSONTyped,
    PostMedicalInstitutionSearchForSalesMapRequestReferralToJSON,
} from './PostMedicalInstitutionSearchForSalesMapRequestReferral';
import {
    PostMedicalInstitutionSearchForSalesMapRequestVisits,
    PostMedicalInstitutionSearchForSalesMapRequestVisitsFromJSON,
    PostMedicalInstitutionSearchForSalesMapRequestVisitsFromJSONTyped,
    PostMedicalInstitutionSearchForSalesMapRequestVisitsToJSON,
} from './PostMedicalInstitutionSearchForSalesMapRequestVisits';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionSearchForSalesMapRequest
 */
export interface PostMedicalInstitutionSearchForSalesMapRequest {
    /**
     * ページ番号（１始まり）
     * @type {number}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequest
     */
    page: number | null;
    /**
     * 
     * @type {PostMedicalInstitutionSearchForSalesMapRequestReferral}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequest
     */
    referral: PostMedicalInstitutionSearchForSalesMapRequestReferral;
    /**
     * 
     * @type {PostMedicalInstitutionSearchForSalesMapRequestBounds}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequest
     */
    bounds: PostMedicalInstitutionSearchForSalesMapRequestBounds;
    /**
     * 医療機関タイプ(施設タイプ)ID
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequest
     */
    facilityTypeIds: Array<string> | null;
    /**
     * 標榜診療科目ID
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequest
     */
    departmentIds: Array<string> | null;
    /**
     * 
     * @type {PostMedicalInstitutionSearchForSalesMapRequestVisits}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequest
     */
    visits: PostMedicalInstitutionSearchForSalesMapRequestVisits | null;
    /**
     * タグID
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequest
     */
    tagIds: Array<string> | null;
    /**
     * 
     * @type {IdsSearchCondition}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequest
     */
    tagIdsSearchCondition: IdsSearchCondition | null;
    /**
     * ラベル検索条件
     * @type {Array<LabelSearchCondition>}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequest
     */
    labels: Array<LabelSearchCondition> | null;
}

export function PostMedicalInstitutionSearchForSalesMapRequestFromJSON(json: any): PostMedicalInstitutionSearchForSalesMapRequest {
    return PostMedicalInstitutionSearchForSalesMapRequestFromJSONTyped(json, false);
}

export function PostMedicalInstitutionSearchForSalesMapRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionSearchForSalesMapRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'referral': PostMedicalInstitutionSearchForSalesMapRequestReferralFromJSON(json['referral']),
        'bounds': PostMedicalInstitutionSearchForSalesMapRequestBoundsFromJSON(json['bounds']),
        'facilityTypeIds': json['facility_type_ids'],
        'departmentIds': json['department_ids'],
        'visits': PostMedicalInstitutionSearchForSalesMapRequestVisitsFromJSON(json['visits']),
        'tagIds': json['tag_ids'],
        'tagIdsSearchCondition': IdsSearchConditionFromJSON(json['tag_ids_search_condition']),
        'labels': (json['labels'] === null ? null : (json['labels'] as Array<any>).map(LabelSearchConditionFromJSON)),
    };
}

export function PostMedicalInstitutionSearchForSalesMapRequestToJSON(value?: PostMedicalInstitutionSearchForSalesMapRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'referral': PostMedicalInstitutionSearchForSalesMapRequestReferralToJSON(value.referral),
        'bounds': PostMedicalInstitutionSearchForSalesMapRequestBoundsToJSON(value.bounds),
        'facility_type_ids': value.facilityTypeIds,
        'department_ids': value.departmentIds,
        'visits': PostMedicalInstitutionSearchForSalesMapRequestVisitsToJSON(value.visits),
        'tag_ids': value.tagIds,
        'tag_ids_search_condition': IdsSearchConditionToJSON(value.tagIdsSearchCondition),
        'labels': (value.labels === null ? null : (value.labels as Array<any>).map(LabelSearchConditionToJSON)),
    };
}

