/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationReportDefinitionAggregationColumn,
    CommunicationReportDefinitionAggregationColumnFromJSON,
    CommunicationReportDefinitionAggregationColumnFromJSONTyped,
    CommunicationReportDefinitionAggregationColumnToJSON,
} from './CommunicationReportDefinitionAggregationColumn';
import {
    CommunicationReportDefinitionAggregationData,
    CommunicationReportDefinitionAggregationDataFromJSON,
    CommunicationReportDefinitionAggregationDataFromJSONTyped,
    CommunicationReportDefinitionAggregationDataToJSON,
} from './CommunicationReportDefinitionAggregationData';
import {
    CommunicationReportDefinitionAggregationRow,
    CommunicationReportDefinitionAggregationRowFromJSON,
    CommunicationReportDefinitionAggregationRowFromJSONTyped,
    CommunicationReportDefinitionAggregationRowToJSON,
} from './CommunicationReportDefinitionAggregationRow';
import {
    CommunicationReportDefinitionDisplayItem,
    CommunicationReportDefinitionDisplayItemFromJSON,
    CommunicationReportDefinitionDisplayItemFromJSONTyped,
    CommunicationReportDefinitionDisplayItemToJSON,
} from './CommunicationReportDefinitionDisplayItem';
import {
    CommunicationReportDefinitionFilterCondition,
    CommunicationReportDefinitionFilterConditionFromJSON,
    CommunicationReportDefinitionFilterConditionFromJSONTyped,
    CommunicationReportDefinitionFilterConditionToJSON,
} from './CommunicationReportDefinitionFilterCondition';
import {
    CommunicationReportDefinitionSortCondition,
    CommunicationReportDefinitionSortConditionFromJSON,
    CommunicationReportDefinitionSortConditionFromJSONTyped,
    CommunicationReportDefinitionSortConditionToJSON,
} from './CommunicationReportDefinitionSortCondition';
import {
    CommunicationReportItem,
    CommunicationReportItemFromJSON,
    CommunicationReportItemFromJSONTyped,
    CommunicationReportItemToJSON,
} from './CommunicationReportItem';
import {
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
} from './CommunicationType';

/**
 * 
 * @export
 * @interface PutCommunicationReportDefinitionRequest
 */
export interface PutCommunicationReportDefinitionRequest {
    /**
     * レポート名
     * @type {string}
     * @memberof PutCommunicationReportDefinitionRequest
     */
    name: string;
    /**
     * 
     * @type {Array<CommunicationReportItem>}
     * @memberof PutCommunicationReportDefinitionRequest
     */
    items: Array<CommunicationReportItem>;
    /**
     * 
     * @type {CommunicationType}
     * @memberof PutCommunicationReportDefinitionRequest
     */
    communicationType: CommunicationType;
    /**
     * 
     * @type {Array<CommunicationReportDefinitionDisplayItem>}
     * @memberof PutCommunicationReportDefinitionRequest
     */
    displayItems: Array<CommunicationReportDefinitionDisplayItem>;
    /**
     * 
     * @type {Array<CommunicationReportDefinitionFilterCondition>}
     * @memberof PutCommunicationReportDefinitionRequest
     */
    filterConditions: Array<CommunicationReportDefinitionFilterCondition>;
    /**
     * 
     * @type {Array<CommunicationReportDefinitionSortCondition>}
     * @memberof PutCommunicationReportDefinitionRequest
     */
    sortConditions: Array<CommunicationReportDefinitionSortCondition>;
    /**
     * 
     * @type {CommunicationReportDefinitionAggregationRow}
     * @memberof PutCommunicationReportDefinitionRequest
     */
    aggregationRow?: CommunicationReportDefinitionAggregationRow | null;
    /**
     * 
     * @type {CommunicationReportDefinitionAggregationColumn}
     * @memberof PutCommunicationReportDefinitionRequest
     */
    aggregationColumn?: CommunicationReportDefinitionAggregationColumn | null;
    /**
     * 集計設定：値
     * @type {Array<CommunicationReportDefinitionAggregationData>}
     * @memberof PutCommunicationReportDefinitionRequest
     */
    aggregationData?: Array<CommunicationReportDefinitionAggregationData>;
}

export function PutCommunicationReportDefinitionRequestFromJSON(json: any): PutCommunicationReportDefinitionRequest {
    return PutCommunicationReportDefinitionRequestFromJSONTyped(json, false);
}

export function PutCommunicationReportDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutCommunicationReportDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'items': ((json['items'] as Array<any>).map(CommunicationReportItemFromJSON)),
        'communicationType': CommunicationTypeFromJSON(json['communication_type']),
        'displayItems': ((json['display_items'] as Array<any>).map(CommunicationReportDefinitionDisplayItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(CommunicationReportDefinitionFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(CommunicationReportDefinitionSortConditionFromJSON)),
        'aggregationRow': !exists(json, 'aggregation_row') ? undefined : CommunicationReportDefinitionAggregationRowFromJSON(json['aggregation_row']),
        'aggregationColumn': !exists(json, 'aggregation_column') ? undefined : CommunicationReportDefinitionAggregationColumnFromJSON(json['aggregation_column']),
        'aggregationData': !exists(json, 'aggregation_data') ? undefined : ((json['aggregation_data'] as Array<any>).map(CommunicationReportDefinitionAggregationDataFromJSON)),
    };
}

export function PutCommunicationReportDefinitionRequestToJSON(value?: PutCommunicationReportDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'items': ((value.items as Array<any>).map(CommunicationReportItemToJSON)),
        'communication_type': CommunicationTypeToJSON(value.communicationType),
        'display_items': ((value.displayItems as Array<any>).map(CommunicationReportDefinitionDisplayItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(CommunicationReportDefinitionFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(CommunicationReportDefinitionSortConditionToJSON)),
        'aggregation_row': CommunicationReportDefinitionAggregationRowToJSON(value.aggregationRow),
        'aggregation_column': CommunicationReportDefinitionAggregationColumnToJSON(value.aggregationColumn),
        'aggregation_data': value.aggregationData === undefined ? undefined : ((value.aggregationData as Array<any>).map(CommunicationReportDefinitionAggregationDataToJSON)),
    };
}

