/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionRunningStatusEnum,
    MedicalInstitutionRunningStatusEnumFromJSON,
    MedicalInstitutionRunningStatusEnumFromJSONTyped,
    MedicalInstitutionRunningStatusEnumToJSON,
} from './MedicalInstitutionRunningStatusEnum';
import {
    MedicalInstitutionSummaryNps,
    MedicalInstitutionSummaryNpsFromJSON,
    MedicalInstitutionSummaryNpsFromJSONTyped,
    MedicalInstitutionSummaryNpsToJSON,
} from './MedicalInstitutionSummaryNps';

/**
 * 
 * @export
 * @interface MedicalInstitutionSummary
 */
export interface MedicalInstitutionSummary {
    /**
     * 
     * @type {MedicalInstitutionSummaryNps}
     * @memberof MedicalInstitutionSummary
     */
    nps: MedicalInstitutionSummaryNps | null;
    /**
     * 医療機関名
     * @type {string}
     * @memberof MedicalInstitutionSummary
     */
    name: string;
    /**
     * 
     * @type {MedicalInstitutionRunningStatusEnum}
     * @memberof MedicalInstitutionSummary
     */
    runningStatus: MedicalInstitutionRunningStatusEnum;
    /**
     * 医療機関種類
     * @type {Array<string>}
     * @memberof MedicalInstitutionSummary
     */
    typeNames: Array<string>;
    /**
     * 診療科目
     * @type {Array<string>}
     * @memberof MedicalInstitutionSummary
     */
    departments: Array<string>;
    /**
     * 住所
     * @type {string}
     * @memberof MedicalInstitutionSummary
     */
    address: string | null;
    /**
     * 管理者名
     * @type {string}
     * @memberof MedicalInstitutionSummary
     */
    administratorName: string | null;
    /**
     * 紐付いているタグ名一覧
     * @type {Array<string>}
     * @memberof MedicalInstitutionSummary
     */
    tags: Array<string>;
}

export function MedicalInstitutionSummaryFromJSON(json: any): MedicalInstitutionSummary {
    return MedicalInstitutionSummaryFromJSONTyped(json, false);
}

export function MedicalInstitutionSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nps': MedicalInstitutionSummaryNpsFromJSON(json['nps']),
        'name': json['name'],
        'runningStatus': MedicalInstitutionRunningStatusEnumFromJSON(json['running_status']),
        'typeNames': json['type_names'],
        'departments': json['departments'],
        'address': json['address'],
        'administratorName': json['administrator_name'],
        'tags': json['tags'],
    };
}

export function MedicalInstitutionSummaryToJSON(value?: MedicalInstitutionSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nps': MedicalInstitutionSummaryNpsToJSON(value.nps),
        'name': value.name,
        'running_status': MedicalInstitutionRunningStatusEnumToJSON(value.runningStatus),
        'type_names': value.typeNames,
        'departments': value.departments,
        'address': value.address,
        'administrator_name': value.administratorName,
        'tags': value.tags,
    };
}

