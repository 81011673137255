/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaReportItemDefinitionEnabledAggregationsEnum,
    AreaReportItemDefinitionEnabledAggregationsEnumFromJSON,
    AreaReportItemDefinitionEnabledAggregationsEnumFromJSONTyped,
    AreaReportItemDefinitionEnabledAggregationsEnumToJSON,
} from './AreaReportItemDefinitionEnabledAggregationsEnum';
import {
    AreaReportItemDefinitionEnabledFiltersEnum,
    AreaReportItemDefinitionEnabledFiltersEnumFromJSON,
    AreaReportItemDefinitionEnabledFiltersEnumFromJSONTyped,
    AreaReportItemDefinitionEnabledFiltersEnumToJSON,
} from './AreaReportItemDefinitionEnabledFiltersEnum';
import {
    AreaReportItemDefinitionKey,
    AreaReportItemDefinitionKeyFromJSON,
    AreaReportItemDefinitionKeyFromJSONTyped,
    AreaReportItemDefinitionKeyToJSON,
} from './AreaReportItemDefinitionKey';
import {
    AreaReportItemDefinitionValueTypeEnum,
    AreaReportItemDefinitionValueTypeEnumFromJSON,
    AreaReportItemDefinitionValueTypeEnumFromJSONTyped,
    AreaReportItemDefinitionValueTypeEnumToJSON,
} from './AreaReportItemDefinitionValueTypeEnum';

/**
 * エリアレポート項目（プリセット項目）定義
 * @export
 * @interface AreaReportItemDefinitionPreset
 */
export interface AreaReportItemDefinitionPreset {
    /**
     * 
     * @type {string}
     * @memberof AreaReportItemDefinitionPreset
     */
    type: AreaReportItemDefinitionPresetTypeEnum;
    /**
     * 
     * @type {AreaReportItemDefinitionKey}
     * @memberof AreaReportItemDefinitionPreset
     */
    key: AreaReportItemDefinitionKey;
    /**
     * 項目ラベル
     * @type {string}
     * @memberof AreaReportItemDefinitionPreset
     */
    label: string;
    /**
     * 
     * @type {AreaReportItemDefinitionValueTypeEnum}
     * @memberof AreaReportItemDefinitionPreset
     */
    valueType: AreaReportItemDefinitionValueTypeEnum;
    /**
     * この項目で利用可能なフィルタ条件
     * @type {Array<AreaReportItemDefinitionEnabledFiltersEnum>}
     * @memberof AreaReportItemDefinitionPreset
     */
    enabledFilters: Array<AreaReportItemDefinitionEnabledFiltersEnum>;
    /**
     * この項目で利用可能な集計指定
     * @type {Array<AreaReportItemDefinitionEnabledAggregationsEnum>}
     * @memberof AreaReportItemDefinitionPreset
     */
    enabledAggregations?: Array<AreaReportItemDefinitionEnabledAggregationsEnum> | null;
}

/**
* @export
* @enum {string}
*/
export enum AreaReportItemDefinitionPresetTypeEnum {
    Preset = 'preset'
}

export function AreaReportItemDefinitionPresetFromJSON(json: any): AreaReportItemDefinitionPreset {
    return AreaReportItemDefinitionPresetFromJSONTyped(json, false);
}

export function AreaReportItemDefinitionPresetFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaReportItemDefinitionPreset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'key': AreaReportItemDefinitionKeyFromJSON(json['key']),
        'label': json['label'],
        'valueType': AreaReportItemDefinitionValueTypeEnumFromJSON(json['value_type']),
        'enabledFilters': ((json['enabled_filters'] as Array<any>).map(AreaReportItemDefinitionEnabledFiltersEnumFromJSON)),
        'enabledAggregations': !exists(json, 'enabled_aggregations') ? undefined : (json['enabled_aggregations'] === null ? null : (json['enabled_aggregations'] as Array<any>).map(AreaReportItemDefinitionEnabledAggregationsEnumFromJSON)),
    };
}

export function AreaReportItemDefinitionPresetToJSON(value?: AreaReportItemDefinitionPreset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'key': AreaReportItemDefinitionKeyToJSON(value.key),
        'label': value.label,
        'value_type': AreaReportItemDefinitionValueTypeEnumToJSON(value.valueType),
        'enabled_filters': ((value.enabledFilters as Array<any>).map(AreaReportItemDefinitionEnabledFiltersEnumToJSON)),
        'enabled_aggregations': value.enabledAggregations === undefined ? undefined : (value.enabledAggregations === null ? null : (value.enabledAggregations as Array<any>).map(AreaReportItemDefinitionEnabledAggregationsEnumToJSON)),
    };
}

