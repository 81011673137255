import { useAccount } from '@/src/hooks/useAccount';
import { useResponsive } from '@/src/hooks/useResponsive';
import { gaEventClick } from '@/src/utils/gtag';
import { ALL_MENUS } from '@/src/utils/menus';

import { SideMenuPresenter } from './presenter';
import { SpSideMenuPresenter } from './spPresenter';
import { PresenterProps } from './types';

import { useRouter } from 'next/router';

type Props = Pick<PresenterProps, 'onClickCloseSideMenu' | 'isShown'>;

export const SideMenu: React.FC<Props> = (props) => {
  const router = useRouter();
  const accountManage = useAccount();
  const responsive = useResponsive();

  const onClickHandler = (url: string) => {
    gaEventClick(`sidebar${url}`);
  };

  const presenterProps: PresenterProps = {
    ...props,
    currentPathname: router.pathname,
    onClickSideMenu: onClickHandler,
    menus: ALL_MENUS.filter((m) =>
      accountManage.account.features.includes(m.value),
    ),
  };

  return responsive.isSp ? (
    <SpSideMenuPresenter {...presenterProps} />
  ) : (
    <SideMenuPresenter {...presenterProps} />
  );
};
