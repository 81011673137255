import { useToastState, useHideToast } from '@/src/hooks/useToast';

import { ToastPresenter } from './presenter';

export const Toast: React.FC = () => {
  const toastState = useToastState();
  const hideToast = useHideToast();

  if (!toastState.isShown) {
    return null;
  }

  return (
    <ToastPresenter
      severity={toastState.severity}
      message={toastState.message}
      onClickHideButton={hideToast}
    />
  );
};
