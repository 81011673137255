import { isAbsoluteUrl } from '@/src/utils/isAbsoluteUrl';

import NextLink from 'next/link';
import { useRouter } from 'next/router';

type Props = Omit<React.ComponentProps<typeof NextLink>, 'onClick'> & {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  isOpenAnotherTab?: boolean;
  className?: string;
  children: React.ReactNode;
};

export const Link: React.FC<Props> = ({
  onClick,
  isOpenAnotherTab,
  className,
  children,
  href,
  ...nextLinkProps
}) => {
  const router = useRouter();
  const openAnotherTabProps: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > = isOpenAnotherTab
    ? {
        target: '_blank',
        ref: 'noreferrer',
      }
    : {};

  return (
    <NextLink passHref={true} href={href} {...nextLinkProps} legacyBehavior>
      <a
        // 別タブでリンクを開く場合、速度のためにaタグによる遷移ではなくwindow.openを使用する。
        // 理由は不明だが、window.openにより別タブで開いた場合の認証を飛ばせるため速度が改善される
        onClick={
          isOpenAnotherTab && !isAbsoluteUrl(href.toString())
            ? (e) => {
                e.preventDefault();
                window.open(`${router.basePath}${href.toString()}`, '_blank');
                onClick?.(e);
              }
            : onClick
        }
        {...openAnotherTabProps}
        className={className}
      >
        {children}
      </a>
    </NextLink>
  );
};
