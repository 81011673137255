import { useTopPageURL } from '@/src/hooks/useTopPageURL';
import { msalInstance } from '@/src/middlewares/Auth/config';

import { ErrorPresenter } from './presenter';

type Props = Pick<React.ComponentProps<typeof ErrorPresenter>, 'fetchError'>;

export const Error: React.FC<Props> = (props) => {
  const topPageURL = useTopPageURL();

  const onClickButton = async () => {
    const account = msalInstance.getActiveAccount();
    if (props.fetchError.statusCode === 401 && account !== null) {
      await msalInstance.logoutRedirect();
    }
    window.location.href = topPageURL;
  };

  return <ErrorPresenter {...props} onClickButton={onClickButton} />;
};
