/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSON,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSONTyped,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionToJSON,
} from './PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution';
import {
    PostDataAnalysisRankingBothReferralsMonthlyResponseDifferenceCounts,
    PostDataAnalysisRankingBothReferralsMonthlyResponseDifferenceCountsFromJSON,
    PostDataAnalysisRankingBothReferralsMonthlyResponseDifferenceCountsFromJSONTyped,
    PostDataAnalysisRankingBothReferralsMonthlyResponseDifferenceCountsToJSON,
} from './PostDataAnalysisRankingBothReferralsMonthlyResponseDifferenceCounts';
import {
    PostDataAnalysisRankingBothReferralsMonthlyResponseReferralCounts,
    PostDataAnalysisRankingBothReferralsMonthlyResponseReferralCountsFromJSON,
    PostDataAnalysisRankingBothReferralsMonthlyResponseReferralCountsFromJSONTyped,
    PostDataAnalysisRankingBothReferralsMonthlyResponseReferralCountsToJSON,
} from './PostDataAnalysisRankingBothReferralsMonthlyResponseReferralCounts';
import {
    PostDataAnalysisRankingBothReferralsMonthlyResponseReverseReferralCounts,
    PostDataAnalysisRankingBothReferralsMonthlyResponseReverseReferralCountsFromJSON,
    PostDataAnalysisRankingBothReferralsMonthlyResponseReverseReferralCountsFromJSONTyped,
    PostDataAnalysisRankingBothReferralsMonthlyResponseReverseReferralCountsToJSON,
} from './PostDataAnalysisRankingBothReferralsMonthlyResponseReverseReferralCounts';

/**
 * 
 * @export
 * @interface PostDataAnalysisRankingBothReferralsMonthlyResponseRankings
 */
export interface PostDataAnalysisRankingBothReferralsMonthlyResponseRankings {
    /**
     * 
     * @type {PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyResponseRankings
     */
    medicalInstitution: PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution;
    /**
     * 月ごとの紹介数
     * @type {Array<PostDataAnalysisRankingBothReferralsMonthlyResponseReferralCounts>}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyResponseRankings
     */
    referralCounts: Array<PostDataAnalysisRankingBothReferralsMonthlyResponseReferralCounts>;
    /**
     * 月ごとの逆紹介数
     * @type {Array<PostDataAnalysisRankingBothReferralsMonthlyResponseReverseReferralCounts>}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyResponseRankings
     */
    reverseReferralCounts: Array<PostDataAnalysisRankingBothReferralsMonthlyResponseReverseReferralCounts>;
    /**
     * 月ごとの紹介数と逆紹介数の差分
     * @type {Array<PostDataAnalysisRankingBothReferralsMonthlyResponseDifferenceCounts>}
     * @memberof PostDataAnalysisRankingBothReferralsMonthlyResponseRankings
     */
    differenceCounts: Array<PostDataAnalysisRankingBothReferralsMonthlyResponseDifferenceCounts>;
}

export function PostDataAnalysisRankingBothReferralsMonthlyResponseRankingsFromJSON(json: any): PostDataAnalysisRankingBothReferralsMonthlyResponseRankings {
    return PostDataAnalysisRankingBothReferralsMonthlyResponseRankingsFromJSONTyped(json, false);
}

export function PostDataAnalysisRankingBothReferralsMonthlyResponseRankingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisRankingBothReferralsMonthlyResponseRankings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitution': PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSON(json['medical_institution']),
        'referralCounts': ((json['referral_counts'] as Array<any>).map(PostDataAnalysisRankingBothReferralsMonthlyResponseReferralCountsFromJSON)),
        'reverseReferralCounts': ((json['reverse_referral_counts'] as Array<any>).map(PostDataAnalysisRankingBothReferralsMonthlyResponseReverseReferralCountsFromJSON)),
        'differenceCounts': ((json['difference_counts'] as Array<any>).map(PostDataAnalysisRankingBothReferralsMonthlyResponseDifferenceCountsFromJSON)),
    };
}

export function PostDataAnalysisRankingBothReferralsMonthlyResponseRankingsToJSON(value?: PostDataAnalysisRankingBothReferralsMonthlyResponseRankings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution': PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionToJSON(value.medicalInstitution),
        'referral_counts': ((value.referralCounts as Array<any>).map(PostDataAnalysisRankingBothReferralsMonthlyResponseReferralCountsToJSON)),
        'reverse_referral_counts': ((value.reverseReferralCounts as Array<any>).map(PostDataAnalysisRankingBothReferralsMonthlyResponseReverseReferralCountsToJSON)),
        'difference_counts': ((value.differenceCounts as Array<any>).map(PostDataAnalysisRankingBothReferralsMonthlyResponseDifferenceCountsToJSON)),
    };
}

