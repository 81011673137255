/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AreaReportDefinitionDetail,
    AreaReportDefinitionDetailFromJSON,
    AreaReportDefinitionDetailToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    GetAreaReportDefinitionsResponse,
    GetAreaReportDefinitionsResponseFromJSON,
    GetAreaReportDefinitionsResponseToJSON,
    GetAreaReportItemDefinitionsResponse,
    GetAreaReportItemDefinitionsResponseFromJSON,
    GetAreaReportItemDefinitionsResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostAreaReportDefinitionRequest,
    PostAreaReportDefinitionRequestFromJSON,
    PostAreaReportDefinitionRequestToJSON,
    PostAreaReportResultsRequest,
    PostAreaReportResultsRequestFromJSON,
    PostAreaReportResultsRequestToJSON,
    PostAreaReportResultsResponse,
    PostAreaReportResultsResponseFromJSON,
    PostAreaReportResultsResponseToJSON,
    PutAreaReportDefinitionRequest,
    PutAreaReportDefinitionRequestFromJSON,
    PutAreaReportDefinitionRequestToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteAreaReportDefinitionRequest {
    id: number;
}

export interface GetAreaReportDefinitionRequest {
    id: number;
}

export interface PostAreaReportDefinitionOperationRequest {
    postAreaReportDefinitionRequest: PostAreaReportDefinitionRequest;
}

export interface PostAreaReportResultsOperationRequest {
    postAreaReportResultsRequest: PostAreaReportResultsRequest;
}

export interface PutAreaReportDefinitionOperationRequest {
    id: number;
    putAreaReportDefinitionRequest: PutAreaReportDefinitionRequest;
}

/**
 * AreaReportsApi - interface
 * 
 * @export
 * @interface AreaReportsApiInterface
 */
export interface AreaReportsApiInterface {
    /**
     * 
     * @summary エリアレポート定義の削除
     * @param {number} id エリアレポート定義ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaReportsApiInterface
     */
    deleteAreaReportDefinitionRaw(requestParameters: DeleteAreaReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * エリアレポート定義の削除
     */
    deleteAreaReportDefinition(requestParameters: DeleteAreaReportDefinitionRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary エリアレポート定義の取得
     * @param {number} id エリアレポート定義ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaReportsApiInterface
     */
    getAreaReportDefinitionRaw(requestParameters: GetAreaReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AreaReportDefinitionDetail>>;

    /**
     * エリアレポート定義の取得
     */
    getAreaReportDefinition(requestParameters: GetAreaReportDefinitionRequest, initOverrides?: RequestInit): Promise<AreaReportDefinitionDetail>;

    /**
     * 
     * @summary エリアレポート定義一覧の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaReportsApiInterface
     */
    getAreaReportDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAreaReportDefinitionsResponse>>;

    /**
     * エリアレポート定義一覧の取得
     */
    getAreaReportDefinitions(initOverrides?: RequestInit): Promise<GetAreaReportDefinitionsResponse>;

    /**
     * 
     * @summary エリアレポート項目マスタ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaReportsApiInterface
     */
    getAreaReportItemDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAreaReportItemDefinitionsResponse>>;

    /**
     * エリアレポート項目マスタ一覧
     */
    getAreaReportItemDefinitions(initOverrides?: RequestInit): Promise<GetAreaReportItemDefinitionsResponse>;

    /**
     * 
     * @summary エリアレポート定義の登録
     * @param {PostAreaReportDefinitionRequest} postAreaReportDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaReportsApiInterface
     */
    postAreaReportDefinitionRaw(requestParameters: PostAreaReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AreaReportDefinitionDetail>>;

    /**
     * エリアレポート定義の登録
     */
    postAreaReportDefinition(requestParameters: PostAreaReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<AreaReportDefinitionDetail>;

    /**
     * エリアレポート生成
     * @param {PostAreaReportResultsRequest} postAreaReportResultsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaReportsApiInterface
     */
    postAreaReportResultsRaw(requestParameters: PostAreaReportResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostAreaReportResultsResponse>>;

    /**
     * エリアレポート生成
     */
    postAreaReportResults(requestParameters: PostAreaReportResultsOperationRequest, initOverrides?: RequestInit): Promise<PostAreaReportResultsResponse>;

    /**
     * 
     * @summary エリアレポート定義の更新
     * @param {number} id エリアレポート定義ID
     * @param {PutAreaReportDefinitionRequest} putAreaReportDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaReportsApiInterface
     */
    putAreaReportDefinitionRaw(requestParameters: PutAreaReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AreaReportDefinitionDetail>>;

    /**
     * エリアレポート定義の更新
     */
    putAreaReportDefinition(requestParameters: PutAreaReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<AreaReportDefinitionDetail>;

}

/**
 * 
 */
export class AreaReportsApi extends runtime.BaseAPI implements AreaReportsApiInterface {

    /**
     * エリアレポート定義の削除
     */
    async deleteAreaReportDefinitionRaw(requestParameters: DeleteAreaReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAreaReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/area_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * エリアレポート定義の削除
     */
    async deleteAreaReportDefinition(requestParameters: DeleteAreaReportDefinitionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteAreaReportDefinitionRaw(requestParameters, initOverrides);
    }

    /**
     * エリアレポート定義の取得
     */
    async getAreaReportDefinitionRaw(requestParameters: GetAreaReportDefinitionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AreaReportDefinitionDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAreaReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/area_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AreaReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * エリアレポート定義の取得
     */
    async getAreaReportDefinition(requestParameters: GetAreaReportDefinitionRequest, initOverrides?: RequestInit): Promise<AreaReportDefinitionDetail> {
        const response = await this.getAreaReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * エリアレポート定義一覧の取得
     */
    async getAreaReportDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAreaReportDefinitionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/area_reports/definitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAreaReportDefinitionsResponseFromJSON(jsonValue));
    }

    /**
     * エリアレポート定義一覧の取得
     */
    async getAreaReportDefinitions(initOverrides?: RequestInit): Promise<GetAreaReportDefinitionsResponse> {
        const response = await this.getAreaReportDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * エリアレポート項目マスタ一覧
     */
    async getAreaReportItemDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetAreaReportItemDefinitionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/area_reports/item_definitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAreaReportItemDefinitionsResponseFromJSON(jsonValue));
    }

    /**
     * エリアレポート項目マスタ一覧
     */
    async getAreaReportItemDefinitions(initOverrides?: RequestInit): Promise<GetAreaReportItemDefinitionsResponse> {
        const response = await this.getAreaReportItemDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * エリアレポート定義の登録
     */
    async postAreaReportDefinitionRaw(requestParameters: PostAreaReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AreaReportDefinitionDetail>> {
        if (requestParameters.postAreaReportDefinitionRequest === null || requestParameters.postAreaReportDefinitionRequest === undefined) {
            throw new runtime.RequiredError('postAreaReportDefinitionRequest','Required parameter requestParameters.postAreaReportDefinitionRequest was null or undefined when calling postAreaReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/area_reports/definitions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAreaReportDefinitionRequestToJSON(requestParameters.postAreaReportDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AreaReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * エリアレポート定義の登録
     */
    async postAreaReportDefinition(requestParameters: PostAreaReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<AreaReportDefinitionDetail> {
        const response = await this.postAreaReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * エリアレポート生成
     */
    async postAreaReportResultsRaw(requestParameters: PostAreaReportResultsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostAreaReportResultsResponse>> {
        if (requestParameters.postAreaReportResultsRequest === null || requestParameters.postAreaReportResultsRequest === undefined) {
            throw new runtime.RequiredError('postAreaReportResultsRequest','Required parameter requestParameters.postAreaReportResultsRequest was null or undefined when calling postAreaReportResults.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/area_reports/results`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAreaReportResultsRequestToJSON(requestParameters.postAreaReportResultsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostAreaReportResultsResponseFromJSON(jsonValue));
    }

    /**
     * エリアレポート生成
     */
    async postAreaReportResults(requestParameters: PostAreaReportResultsOperationRequest, initOverrides?: RequestInit): Promise<PostAreaReportResultsResponse> {
        const response = await this.postAreaReportResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * エリアレポート定義の更新
     */
    async putAreaReportDefinitionRaw(requestParameters: PutAreaReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AreaReportDefinitionDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putAreaReportDefinition.');
        }

        if (requestParameters.putAreaReportDefinitionRequest === null || requestParameters.putAreaReportDefinitionRequest === undefined) {
            throw new runtime.RequiredError('putAreaReportDefinitionRequest','Required parameter requestParameters.putAreaReportDefinitionRequest was null or undefined when calling putAreaReportDefinition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/area_reports/definitions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutAreaReportDefinitionRequestToJSON(requestParameters.putAreaReportDefinitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AreaReportDefinitionDetailFromJSON(jsonValue));
    }

    /**
     * エリアレポート定義の更新
     */
    async putAreaReportDefinition(requestParameters: PutAreaReportDefinitionOperationRequest, initOverrides?: RequestInit): Promise<AreaReportDefinitionDetail> {
        const response = await this.putAreaReportDefinitionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
