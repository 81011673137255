/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionMoreLess,
    AlertConditionMoreLessFromJSON,
    AlertConditionMoreLessFromJSONTyped,
    AlertConditionMoreLessToJSON,
} from './AlertConditionMoreLess';
import {
    AlertConditionUnitType,
    AlertConditionUnitTypeFromJSON,
    AlertConditionUnitTypeFromJSONTyped,
    AlertConditionUnitTypeToJSON,
} from './AlertConditionUnitType';

/**
 * 
 * @export
 * @interface PostNewAlertConditionFiltersReferralAverage
 */
export interface PostNewAlertConditionFiltersReferralAverage {
    /**
     * 紹介の月平均件数
     * @type {number}
     * @memberof PostNewAlertConditionFiltersReferralAverage
     */
    count: number;
    /**
     * 
     * @type {AlertConditionMoreLess}
     * @memberof PostNewAlertConditionFiltersReferralAverage
     */
    moreLess: AlertConditionMoreLess;
    /**
     * 
     * @type {AlertConditionUnitType}
     * @memberof PostNewAlertConditionFiltersReferralAverage
     */
    unitType: AlertConditionUnitType;
    /**
     * 診療科目ID
     * @type {Array<string>}
     * @memberof PostNewAlertConditionFiltersReferralAverage
     */
    ownDepartmentIds: Array<string> | null;
}

export function PostNewAlertConditionFiltersReferralAverageFromJSON(json: any): PostNewAlertConditionFiltersReferralAverage {
    return PostNewAlertConditionFiltersReferralAverageFromJSONTyped(json, false);
}

export function PostNewAlertConditionFiltersReferralAverageFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostNewAlertConditionFiltersReferralAverage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'moreLess': AlertConditionMoreLessFromJSON(json['more_less']),
        'unitType': AlertConditionUnitTypeFromJSON(json['unit_type']),
        'ownDepartmentIds': json['own_department_ids'],
    };
}

export function PostNewAlertConditionFiltersReferralAverageToJSON(value?: PostNewAlertConditionFiltersReferralAverage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'more_less': AlertConditionMoreLessToJSON(value.moreLess),
        'unit_type': AlertConditionUnitTypeToJSON(value.unitType),
        'own_department_ids': value.ownDepartmentIds,
    };
}

