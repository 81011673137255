/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnDepartmentReportItemForFormula,
    OwnDepartmentReportItemForFormulaFromJSON,
    OwnDepartmentReportItemForFormulaFromJSONTyped,
    OwnDepartmentReportItemForFormulaToJSON,
} from './OwnDepartmentReportItemForFormula';

/**
 * レポート項目計算式に利用する要素
 * @export
 * @interface OwnDepartmentReportItemFormula
 */
export interface OwnDepartmentReportItemFormula {
    /**
     * 
     * @type {OwnDepartmentReportItemForFormula}
     * @memberof OwnDepartmentReportItemFormula
     */
    leftItem: OwnDepartmentReportItemForFormula;
    /**
     * 
     * @type {OwnDepartmentReportItemForFormula}
     * @memberof OwnDepartmentReportItemFormula
     */
    rightItem: OwnDepartmentReportItemForFormula;
}

export function OwnDepartmentReportItemFormulaFromJSON(json: any): OwnDepartmentReportItemFormula {
    return OwnDepartmentReportItemFormulaFromJSONTyped(json, false);
}

export function OwnDepartmentReportItemFormulaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportItemFormula {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leftItem': OwnDepartmentReportItemForFormulaFromJSON(json['left_item']),
        'rightItem': OwnDepartmentReportItemForFormulaFromJSON(json['right_item']),
    };
}

export function OwnDepartmentReportItemFormulaToJSON(value?: OwnDepartmentReportItemFormula | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'left_item': OwnDepartmentReportItemForFormulaToJSON(value.leftItem),
        'right_item': OwnDepartmentReportItemForFormulaToJSON(value.rightItem),
    };
}

