/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AreaReportItemDefinitionEnabledFiltersEnum {
    Exists = 'exists',
    MoreEqual = 'more_equal',
    LessEqual = 'less_equal',
    In = 'in',
    NotIn = 'not_in'
}

export function AreaReportItemDefinitionEnabledFiltersEnumFromJSON(json: any): AreaReportItemDefinitionEnabledFiltersEnum {
    return AreaReportItemDefinitionEnabledFiltersEnumFromJSONTyped(json, false);
}

export function AreaReportItemDefinitionEnabledFiltersEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaReportItemDefinitionEnabledFiltersEnum {
    return json as AreaReportItemDefinitionEnabledFiltersEnum;
}

export function AreaReportItemDefinitionEnabledFiltersEnumToJSON(value?: AreaReportItemDefinitionEnabledFiltersEnum | null): any {
    return value as any;
}

