/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportFilterCondition,
    MedicalInstitutionReportFilterConditionFromJSON,
    MedicalInstitutionReportFilterConditionFromJSONTyped,
    MedicalInstitutionReportFilterConditionToJSON,
} from './MedicalInstitutionReportFilterCondition';
import {
    MedicalInstitutionReportItem,
    MedicalInstitutionReportItemFromJSON,
    MedicalInstitutionReportItemFromJSONTyped,
    MedicalInstitutionReportItemToJSON,
} from './MedicalInstitutionReportItem';
import {
    MedicalInstitutionReportSortCondition,
    MedicalInstitutionReportSortConditionFromJSON,
    MedicalInstitutionReportSortConditionFromJSONTyped,
    MedicalInstitutionReportSortConditionToJSON,
} from './MedicalInstitutionReportSortCondition';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionReportResultsRequest
 */
export interface PostMedicalInstitutionReportResultsRequest {
    /**
     * 表示項目一覧
     * @type {Array<MedicalInstitutionReportItem>}
     * @memberof PostMedicalInstitutionReportResultsRequest
     */
    displayItems: Array<MedicalInstitutionReportItem>;
    /**
     * 連携先絞り込み条件一覧
     * @type {Array<MedicalInstitutionReportFilterCondition>}
     * @memberof PostMedicalInstitutionReportResultsRequest
     */
    filterConditions: Array<MedicalInstitutionReportFilterCondition>;
    /**
     * 並び替え条件一覧
     * @type {Array<MedicalInstitutionReportSortCondition>}
     * @memberof PostMedicalInstitutionReportResultsRequest
     */
    sortConditions: Array<MedicalInstitutionReportSortCondition>;
    /**
     * 表示するページ番号
     * @type {number}
     * @memberof PostMedicalInstitutionReportResultsRequest
     */
    page: number;
    /**
     * ページあたりの件数
     * @type {number}
     * @memberof PostMedicalInstitutionReportResultsRequest
     */
    perPage: number;
}

export function PostMedicalInstitutionReportResultsRequestFromJSON(json: any): PostMedicalInstitutionReportResultsRequest {
    return PostMedicalInstitutionReportResultsRequestFromJSONTyped(json, false);
}

export function PostMedicalInstitutionReportResultsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionReportResultsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayItems': ((json['display_items'] as Array<any>).map(MedicalInstitutionReportItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(MedicalInstitutionReportFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(MedicalInstitutionReportSortConditionFromJSON)),
        'page': json['page'],
        'perPage': json['per_page'],
    };
}

export function PostMedicalInstitutionReportResultsRequestToJSON(value?: PostMedicalInstitutionReportResultsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_items': ((value.displayItems as Array<any>).map(MedicalInstitutionReportItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(MedicalInstitutionReportFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(MedicalInstitutionReportSortConditionToJSON)),
        'page': value.page,
        'per_page': value.perPage,
    };
}

