/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetUsersMeNotificationsResponse,
    GetUsersMeNotificationsResponseFromJSON,
    GetUsersMeNotificationsResponseToJSON,
    PutUsersMeNotificationsRequest,
    PutUsersMeNotificationsRequestFromJSON,
    PutUsersMeNotificationsRequestToJSON,
    PutUsersMeNotificationsResponse,
    PutUsersMeNotificationsResponseFromJSON,
    PutUsersMeNotificationsResponseToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface PutUsersMeNotificationsOperationRequest {
    putUsersMeNotificationsRequest: PutUsersMeNotificationsRequest;
}

/**
 * NotificationsApi - interface
 * 
 * @export
 * @interface NotificationsApiInterface
 */
export interface NotificationsApiInterface {
    /**
     * 
     * @summary 通知設定ステータスの一覧を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    getUsersMeNotificationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetUsersMeNotificationsResponse>>;

    /**
     * 通知設定ステータスの一覧を取得
     */
    getUsersMeNotifications(initOverrides?: RequestInit): Promise<GetUsersMeNotificationsResponse>;

    /**
     * 
     * @summary 通知設定ステータスを更新
     * @param {PutUsersMeNotificationsRequest} putUsersMeNotificationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    putUsersMeNotificationsRaw(requestParameters: PutUsersMeNotificationsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PutUsersMeNotificationsResponse>>;

    /**
     * 通知設定ステータスを更新
     */
    putUsersMeNotifications(requestParameters: PutUsersMeNotificationsOperationRequest, initOverrides?: RequestInit): Promise<PutUsersMeNotificationsResponse>;

}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI implements NotificationsApiInterface {

    /**
     * 通知設定ステータスの一覧を取得
     */
    async getUsersMeNotificationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetUsersMeNotificationsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/me/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersMeNotificationsResponseFromJSON(jsonValue));
    }

    /**
     * 通知設定ステータスの一覧を取得
     */
    async getUsersMeNotifications(initOverrides?: RequestInit): Promise<GetUsersMeNotificationsResponse> {
        const response = await this.getUsersMeNotificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 通知設定ステータスを更新
     */
    async putUsersMeNotificationsRaw(requestParameters: PutUsersMeNotificationsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PutUsersMeNotificationsResponse>> {
        if (requestParameters.putUsersMeNotificationsRequest === null || requestParameters.putUsersMeNotificationsRequest === undefined) {
            throw new runtime.RequiredError('putUsersMeNotificationsRequest','Required parameter requestParameters.putUsersMeNotificationsRequest was null or undefined when calling putUsersMeNotifications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/users/me/notifications`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutUsersMeNotificationsRequestToJSON(requestParameters.putUsersMeNotificationsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutUsersMeNotificationsResponseFromJSON(jsonValue));
    }

    /**
     * 通知設定ステータスを更新
     */
    async putUsersMeNotifications(requestParameters: PutUsersMeNotificationsOperationRequest, initOverrides?: RequestInit): Promise<PutUsersMeNotificationsResponse> {
        const response = await this.putUsersMeNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
