/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Communication,
    CommunicationFromJSON,
    CommunicationFromJSONTyped,
    CommunicationToJSON,
} from './Communication';

/**
 * 
 * @export
 * @interface PostCommunicationsSearchResultsResponse
 */
export interface PostCommunicationsSearchResultsResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof PostCommunicationsSearchResultsResponse
     */
    totalPage: number;
    /**
     * 一覧の総要素数
     * @type {number}
     * @memberof PostCommunicationsSearchResultsResponse
     */
    totalCount: number;
    /**
     * コミュニケーションの一覧
     * @type {Array<Communication>}
     * @memberof PostCommunicationsSearchResultsResponse
     */
    communications: Array<Communication>;
}

export function PostCommunicationsSearchResultsResponseFromJSON(json: any): PostCommunicationsSearchResultsResponse {
    return PostCommunicationsSearchResultsResponseFromJSONTyped(json, false);
}

export function PostCommunicationsSearchResultsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCommunicationsSearchResultsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'communications': ((json['communications'] as Array<any>).map(CommunicationFromJSON)),
    };
}

export function PostCommunicationsSearchResultsResponseToJSON(value?: PostCommunicationsSearchResultsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'communications': ((value.communications as Array<any>).map(CommunicationToJSON)),
    };
}

