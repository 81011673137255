import { Card } from '@/src/components/foundations/DataDisplays/Card';

import styles from './styles.module.scss';

import clsx from 'clsx';
import { useEffect, useRef } from 'react';

type Props = {
  trigger: React.ReactNode;
  placement: 'left' | 'right';
  isShown: boolean;
  hide: VoidFunction;
  children: React.ReactNode;
};

export const Popover: React.FC<Props> = (props) => {
  const cardWrapperRef = useRef<HTMLDivElement>(null);

  // isShown変更のタイミングでイベントリスナーを設定しなおす。
  // 表示状態ではない場合はイベントリスナーは不要なので設定していない。
  useEffect(() => {
    if (!props.isShown) {
      return;
    }

    const onClickOutsideContainer = (event: MouseEvent) => {
      if (!cardWrapperRef.current?.contains(event.target as Node)) {
        props.hide();
      }
    };

    // ActionListからPopOverを使うときに、PopOverが開いた瞬間に閉じてしまう事象への暫定対応。
    // クリックしてisShownが変更されbodyにイベントリスナーが登録された直後に、
    // イベントバブリングによりbodyに設定されたイベントリスナーが発火してしまっているのではないかと思われる。
    // これを回避するためにbodyへのイベントリスナーの登録をsetTimeoutによりタイミングをずらしている。
    window.setTimeout(
      () => document.body.addEventListener('click', onClickOutsideContainer),
      0,
    );

    return () => {
      document.body.removeEventListener('click', onClickOutsideContainer);
    };
  }, [props.isShown]);

  return (
    <div className={styles.container}>
      {props.trigger}
      {props.isShown && (
        <div
          className={clsx(styles.cardWrapper, styles[props.placement])}
          ref={cardWrapperRef}
        >
          <Card>{props.children}</Card>
        </div>
      )}
    </div>
  );
};
