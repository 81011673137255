/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommunicationLabelConditionDateConditions
 */
export interface CommunicationLabelConditionDateConditions {
    /**
     * 日付型のラベル条件。指定した月、もしくはそれ以降の場合にマッチする条件
     * @type {string}
     * @memberof CommunicationLabelConditionDateConditions
     */
    startDate?: string | null;
    /**
     * 日付型のラベル条件。指定した月、もしくはそれ以前の場合にマッチする条件
     * @type {string}
     * @memberof CommunicationLabelConditionDateConditions
     */
    endDate?: string | null;
}

export function CommunicationLabelConditionDateConditionsFromJSON(json: any): CommunicationLabelConditionDateConditions {
    return CommunicationLabelConditionDateConditionsFromJSONTyped(json, false);
}

export function CommunicationLabelConditionDateConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabelConditionDateConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': !exists(json, 'start_date') ? undefined : json['start_date'],
        'endDate': !exists(json, 'end_date') ? undefined : json['end_date'],
    };
}

export function CommunicationLabelConditionDateConditionsToJSON(value?: CommunicationLabelConditionDateConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_date': value.startDate,
        'end_date': value.endDate,
    };
}

