/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * メールID
     * @type {number}
     * @memberof Email
     */
    id: number;
    /**
     * 宛先数
     * @type {number}
     * @memberof Email
     */
    destinationCount: number;
    /**
     * 不備のある宛先数
     * @type {number}
     * @memberof Email
     */
    invalidDestinationCount: number;
    /**
     * 送信元の名前
     * @type {string}
     * @memberof Email
     */
    senderName: string | null;
    /**
     * 送信元のメールアドレス
     * @type {string}
     * @memberof Email
     */
    senderEmail: string | null;
    /**
     * タイトル
     * @type {string}
     * @memberof Email
     */
    title: string;
    /**
     * メール本文
     * @type {string}
     * @memberof Email
     */
    mainContent: string | null;
    /**
     * 添付ファイル名 (添付ファイルがない場合は null)
     * @type {string}
     * @memberof Email
     */
    attachmentName: string | null;
    /**
     * 添付ファイルのID (添付ファイルがない場合は null)
     * @type {number}
     * @memberof Email
     */
    attachmentId: number | null;
}

export function EmailFromJSON(json: any): Email {
    return EmailFromJSONTyped(json, false);
}

export function EmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): Email {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'destinationCount': json['destination_count'],
        'invalidDestinationCount': json['invalid_destination_count'],
        'senderName': json['sender_name'],
        'senderEmail': json['sender_email'],
        'title': json['title'],
        'mainContent': json['main_content'],
        'attachmentName': json['attachment_name'],
        'attachmentId': json['attachment_id'],
    };
}

export function EmailToJSON(value?: Email | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'destination_count': value.destinationCount,
        'invalid_destination_count': value.invalidDestinationCount,
        'sender_name': value.senderName,
        'sender_email': value.senderEmail,
        'title': value.title,
        'main_content': value.mainContent,
        'attachment_name': value.attachmentName,
        'attachment_id': value.attachmentId,
    };
}

