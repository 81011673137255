/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReverseReferralsComparisonMonthlyResponseReverseReferrals,
    PostChartReverseReferralsComparisonMonthlyResponseReverseReferralsFromJSON,
    PostChartReverseReferralsComparisonMonthlyResponseReverseReferralsFromJSONTyped,
    PostChartReverseReferralsComparisonMonthlyResponseReverseReferralsToJSON,
} from './PostChartReverseReferralsComparisonMonthlyResponseReverseReferrals';

/**
 * 
 * @export
 * @interface PostChartReverseReferralsComparisonMonthlyResponse
 */
export interface PostChartReverseReferralsComparisonMonthlyResponse {
    /**
     * 逆紹介数月次比較データ
     * @type {Array<PostChartReverseReferralsComparisonMonthlyResponseReverseReferrals>}
     * @memberof PostChartReverseReferralsComparisonMonthlyResponse
     */
    reverseReferrals: Array<PostChartReverseReferralsComparisonMonthlyResponseReverseReferrals>;
}

export function PostChartReverseReferralsComparisonMonthlyResponseFromJSON(json: any): PostChartReverseReferralsComparisonMonthlyResponse {
    return PostChartReverseReferralsComparisonMonthlyResponseFromJSONTyped(json, false);
}

export function PostChartReverseReferralsComparisonMonthlyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReverseReferralsComparisonMonthlyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reverseReferrals': ((json['reverse_referrals'] as Array<any>).map(PostChartReverseReferralsComparisonMonthlyResponseReverseReferralsFromJSON)),
    };
}

export function PostChartReverseReferralsComparisonMonthlyResponseToJSON(value?: PostChartReverseReferralsComparisonMonthlyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reverse_referrals': ((value.reverseReferrals as Array<any>).map(PostChartReverseReferralsComparisonMonthlyResponseReverseReferralsToJSON)),
    };
}

