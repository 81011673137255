/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDoctorsEmailDestinationsResponseEmailDestinations,
    GetDoctorsEmailDestinationsResponseEmailDestinationsFromJSON,
    GetDoctorsEmailDestinationsResponseEmailDestinationsFromJSONTyped,
    GetDoctorsEmailDestinationsResponseEmailDestinationsToJSON,
} from './GetDoctorsEmailDestinationsResponseEmailDestinations';

/**
 * 
 * @export
 * @interface GetDoctorsEmailDestinationsResponse
 */
export interface GetDoctorsEmailDestinationsResponse {
    /**
     * 医師の連絡先情報一覧
     * @type {Array<GetDoctorsEmailDestinationsResponseEmailDestinations>}
     * @memberof GetDoctorsEmailDestinationsResponse
     */
    emailDestinations: Array<GetDoctorsEmailDestinationsResponseEmailDestinations>;
}

export function GetDoctorsEmailDestinationsResponseFromJSON(json: any): GetDoctorsEmailDestinationsResponse {
    return GetDoctorsEmailDestinationsResponseFromJSONTyped(json, false);
}

export function GetDoctorsEmailDestinationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDoctorsEmailDestinationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailDestinations': ((json['email_destinations'] as Array<any>).map(GetDoctorsEmailDestinationsResponseEmailDestinationsFromJSON)),
    };
}

export function GetDoctorsEmailDestinationsResponseToJSON(value?: GetDoctorsEmailDestinationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_destinations': ((value.emailDestinations as Array<any>).map(GetDoctorsEmailDestinationsResponseEmailDestinationsToJSON)),
    };
}

