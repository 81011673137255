/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartBothReferralsComparisonRequestPeriod,
    PostChartBothReferralsComparisonRequestPeriodFromJSON,
    PostChartBothReferralsComparisonRequestPeriodFromJSONTyped,
    PostChartBothReferralsComparisonRequestPeriodToJSON,
} from './PostChartBothReferralsComparisonRequestPeriod';

/**
 * 
 * @export
 * @interface PostChartBothReferralsComparisonRequest
 */
export interface PostChartBothReferralsComparisonRequest {
    /**
     * 診療科目のID
     * @type {Array<string>}
     * @memberof PostChartBothReferralsComparisonRequest
     */
    ownDepartmentIds: Array<string>;
    /**
     * 
     * @type {PostChartBothReferralsComparisonRequestPeriod}
     * @memberof PostChartBothReferralsComparisonRequest
     */
    period: PostChartBothReferralsComparisonRequestPeriod;
    /**
     * 紹介数・逆紹介数の集計時間単位
     * `month`, `year`
     * @type {string}
     * @memberof PostChartBothReferralsComparisonRequest
     */
    unitType: PostChartBothReferralsComparisonRequestUnitTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostChartBothReferralsComparisonRequestUnitTypeEnum {
    Month = 'month',
    Year = 'year'
}

export function PostChartBothReferralsComparisonRequestFromJSON(json: any): PostChartBothReferralsComparisonRequest {
    return PostChartBothReferralsComparisonRequestFromJSONTyped(json, false);
}

export function PostChartBothReferralsComparisonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartBothReferralsComparisonRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ownDepartmentIds': json['own_department_ids'],
        'period': PostChartBothReferralsComparisonRequestPeriodFromJSON(json['period']),
        'unitType': json['unit_type'],
    };
}

export function PostChartBothReferralsComparisonRequestToJSON(value?: PostChartBothReferralsComparisonRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'own_department_ids': value.ownDepartmentIds,
        'period': PostChartBothReferralsComparisonRequestPeriodToJSON(value.period),
        'unit_type': value.unitType,
    };
}

