/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionForActionList,
    MedicalInstitutionForActionListFromJSON,
    MedicalInstitutionForActionListFromJSONTyped,
    MedicalInstitutionForActionListToJSON,
} from './MedicalInstitutionForActionList';

/**
 * 
 * @export
 * @interface PutActionListItemsStatusResponse
 */
export interface PutActionListItemsStatusResponse {
    /**
     * 医療機関の一覧
     * @type {Array<MedicalInstitutionForActionList>}
     * @memberof PutActionListItemsStatusResponse
     */
    medicalInstitutions: Array<MedicalInstitutionForActionList>;
}

export function PutActionListItemsStatusResponseFromJSON(json: any): PutActionListItemsStatusResponse {
    return PutActionListItemsStatusResponseFromJSONTyped(json, false);
}

export function PutActionListItemsStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutActionListItemsStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutions': ((json['medical_institutions'] as Array<any>).map(MedicalInstitutionForActionListFromJSON)),
    };
}

export function PutActionListItemsStatusResponseToJSON(value?: PutActionListItemsStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institutions': ((value.medicalInstitutions as Array<any>).map(MedicalInstitutionForActionListToJSON)),
    };
}

