import { useEffect } from 'react';

export const useResolveDvhUnit = () => useEffect(resolveDvhUnit, []);

// Safari version 15.4 ~ 16 の間では dvh の挙動に不具合があるため、dvh を vh に扱うようにする
const resolveDvhUnit = () => {
  const userAgent = navigator.userAgent;

  // Safariの例
  // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6.1 Safari/605.1.15"
  const isSafari =
    userAgent.includes('Safari') && !userAgent.includes('Chrome');
  if (!isSafari) {
    return;
  }

  // major or (major.minor) バージョンまでを取得。 例: 15.6.1 -> 15.6, 15.6 -> 15.6, 15 -> 15
  const matchGroup = userAgent.match(/Version\/(\d+(?:\.\d+)?)/);
  if (matchGroup && matchGroup[1]) {
    const versionNumber = Number(matchGroup[1]);
    const isIssueVersion = versionNumber >= 15.4 && versionNumber < 16.1;

    if (!isIssueVersion) {
      return;
    }

    // globals で定義している --dvh-unit css変数を 1vh に変更する (初期値は 1dvh)
    document.documentElement.style.setProperty('--dvh-unit', '1vh');
  }
};
