/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Doctor,
    DoctorFromJSON,
    DoctorToJSON,
    GetDoctorsEmailDestinationsResponse,
    GetDoctorsEmailDestinationsResponseFromJSON,
    GetDoctorsEmailDestinationsResponseToJSON,
    GetDoctorsResponse,
    GetDoctorsResponseFromJSON,
    GetDoctorsResponseToJSON,
    NotFound,
    NotFoundFromJSON,
    NotFoundToJSON,
    PostDoctorRequest,
    PostDoctorRequestFromJSON,
    PostDoctorRequestToJSON,
    PutDoctorRequest,
    PutDoctorRequestFromJSON,
    PutDoctorRequestToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteDoctorRequest {
    medicalInstitutionId: number;
    id: number;
}

export interface GetDoctorsRequest {
    id: number;
}

export interface PostDoctorOperationRequest {
    id: number;
    postDoctorRequest: PostDoctorRequest;
}

export interface PutDoctorOperationRequest {
    medicalInstitutionId: number;
    id: number;
    putDoctorRequest: PutDoctorRequest;
}

/**
 * DoctorsApi - interface
 * 
 * @export
 * @interface DoctorsApiInterface
 */
export interface DoctorsApiInterface {
    /**
     * 所属医師を削除する 
     * @summary 所属医師の削除
     * @param {number} medicalInstitutionId 医療機関ID
     * @param {number} id 所属医師ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsApiInterface
     */
    deleteDoctorRaw(requestParameters: DeleteDoctorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * 所属医師を削除する 
     * 所属医師の削除
     */
    deleteDoctor(requestParameters: DeleteDoctorRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 所属医師の一覧が所属医師作成日降順で取得できる。 
     * @summary 所属医師一覧の取得
     * @param {number} id 医療機関ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsApiInterface
     */
    getDoctorsRaw(requestParameters: GetDoctorsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetDoctorsResponse>>;

    /**
     * 所属医師の一覧が所属医師作成日降順で取得できる。 
     * 所属医師一覧の取得
     */
    getDoctors(requestParameters: GetDoctorsRequest, initOverrides?: RequestInit): Promise<GetDoctorsResponse>;

    /**
     * 宛先アップロードに必要な情報や医師に関連する情報が、医師が所属する医療機関IDの昇順で取得できる。 
     * @summary メール機能で用いる宛先リストを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsApiInterface
     */
    getDoctorsEmailDestinationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetDoctorsEmailDestinationsResponse>>;

    /**
     * 宛先アップロードに必要な情報や医師に関連する情報が、医師が所属する医療機関IDの昇順で取得できる。 
     * メール機能で用いる宛先リストを取得
     */
    getDoctorsEmailDestinations(initOverrides?: RequestInit): Promise<GetDoctorsEmailDestinationsResponse>;

    /**
     * 
     * @summary 所属医師の作成
     * @param {number} id 医療機関ID
     * @param {PostDoctorRequest} postDoctorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsApiInterface
     */
    postDoctorRaw(requestParameters: PostDoctorOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Doctor>>;

    /**
     * 所属医師の作成
     */
    postDoctor(requestParameters: PostDoctorOperationRequest, initOverrides?: RequestInit): Promise<Doctor>;

    /**
     * 
     * @summary 所属医師の更新
     * @param {number} medicalInstitutionId 医療機関ID
     * @param {number} id 所属医師ID
     * @param {PutDoctorRequest} putDoctorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsApiInterface
     */
    putDoctorRaw(requestParameters: PutDoctorOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Doctor>>;

    /**
     * 所属医師の更新
     */
    putDoctor(requestParameters: PutDoctorOperationRequest, initOverrides?: RequestInit): Promise<Doctor>;

}

/**
 * 
 */
export class DoctorsApi extends runtime.BaseAPI implements DoctorsApiInterface {

    /**
     * 所属医師を削除する 
     * 所属医師の削除
     */
    async deleteDoctorRaw(requestParameters: DeleteDoctorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.medicalInstitutionId === null || requestParameters.medicalInstitutionId === undefined) {
            throw new runtime.RequiredError('medicalInstitutionId','Required parameter requestParameters.medicalInstitutionId was null or undefined when calling deleteDoctor.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDoctor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{medical_institution_id}/doctors/{id}`.replace(`{${"medical_institution_id"}}`, encodeURIComponent(String(requestParameters.medicalInstitutionId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 所属医師を削除する 
     * 所属医師の削除
     */
    async deleteDoctor(requestParameters: DeleteDoctorRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteDoctorRaw(requestParameters, initOverrides);
    }

    /**
     * 所属医師の一覧が所属医師作成日降順で取得できる。 
     * 所属医師一覧の取得
     */
    async getDoctorsRaw(requestParameters: GetDoctorsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetDoctorsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDoctors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/doctors`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDoctorsResponseFromJSON(jsonValue));
    }

    /**
     * 所属医師の一覧が所属医師作成日降順で取得できる。 
     * 所属医師一覧の取得
     */
    async getDoctors(requestParameters: GetDoctorsRequest, initOverrides?: RequestInit): Promise<GetDoctorsResponse> {
        const response = await this.getDoctorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 宛先アップロードに必要な情報や医師に関連する情報が、医師が所属する医療機関IDの昇順で取得できる。 
     * メール機能で用いる宛先リストを取得
     */
    async getDoctorsEmailDestinationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetDoctorsEmailDestinationsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/doctors/email_destinations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDoctorsEmailDestinationsResponseFromJSON(jsonValue));
    }

    /**
     * 宛先アップロードに必要な情報や医師に関連する情報が、医師が所属する医療機関IDの昇順で取得できる。 
     * メール機能で用いる宛先リストを取得
     */
    async getDoctorsEmailDestinations(initOverrides?: RequestInit): Promise<GetDoctorsEmailDestinationsResponse> {
        const response = await this.getDoctorsEmailDestinationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 所属医師の作成
     */
    async postDoctorRaw(requestParameters: PostDoctorOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Doctor>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postDoctor.');
        }

        if (requestParameters.postDoctorRequest === null || requestParameters.postDoctorRequest === undefined) {
            throw new runtime.RequiredError('postDoctorRequest','Required parameter requestParameters.postDoctorRequest was null or undefined when calling postDoctor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{id}/doctors`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostDoctorRequestToJSON(requestParameters.postDoctorRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoctorFromJSON(jsonValue));
    }

    /**
     * 所属医師の作成
     */
    async postDoctor(requestParameters: PostDoctorOperationRequest, initOverrides?: RequestInit): Promise<Doctor> {
        const response = await this.postDoctorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 所属医師の更新
     */
    async putDoctorRaw(requestParameters: PutDoctorOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Doctor>> {
        if (requestParameters.medicalInstitutionId === null || requestParameters.medicalInstitutionId === undefined) {
            throw new runtime.RequiredError('medicalInstitutionId','Required parameter requestParameters.medicalInstitutionId was null or undefined when calling putDoctor.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putDoctor.');
        }

        if (requestParameters.putDoctorRequest === null || requestParameters.putDoctorRequest === undefined) {
            throw new runtime.RequiredError('putDoctorRequest','Required parameter requestParameters.putDoctorRequest was null or undefined when calling putDoctor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/medical_institutions/{medical_institution_id}/doctors/{id}`.replace(`{${"medical_institution_id"}}`, encodeURIComponent(String(requestParameters.medicalInstitutionId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutDoctorRequestToJSON(requestParameters.putDoctorRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoctorFromJSON(jsonValue));
    }

    /**
     * 所属医師の更新
     */
    async putDoctor(requestParameters: PutDoctorOperationRequest, initOverrides?: RequestInit): Promise<Doctor> {
        const response = await this.putDoctorRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
