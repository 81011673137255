import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { UnprocessableEntity } from '@/src/error/fetchError/unprocessableEntity';

type SetStatusOfResponse<S> = Response & { status: S };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DistributeSetStatusOfResponse<S extends number> = S extends any
  ? SetStatusOfResponse<S>
  : never;

type UnprocessableEntityResponse<
  SSOR extends SetStatusOfResponse<number>,
  SC = string,
> = SSOR extends SetStatusOfResponse<infer S>
  ? S extends 422
    ? UnprocessableEntity<SC>
    : undefined
  : never;

const isErrorInStatuses = <S extends number>(
  error: Response,
  statuses: S[],
): error is DistributeSetStatusOfResponse<S> => {
  // @ts-expect-error
  return statuses.includes(error.status);
};

const isUnprocessableEntityError = (
  error: Response,
): error is SetStatusOfResponse<422> => error.status === 422;

const isFieldError = <SC extends string>(
  response: unknown,
): response is UnprocessableEntityResponse<
  DistributeSetStatusOfResponse<422>,
  SC
> => {
  // @ts-expect-error
  return Array.isArray(response.errors);
};

type FetchErrorHandle = <S extends number, SC extends string>(
  error: unknown,
  statuses: S[],
  errorHandle: (
    error: DistributeSetStatusOfResponse<S>,
    response: UnprocessableEntityResponse<
      DistributeSetStatusOfResponse<422>,
      SC
    >,
  ) => void,
) => void;
export const useFetchErrorHandle = (): FetchErrorHandle => {
  const throwFetchError = useFetchError();

  const fetchErrorHandle: FetchErrorHandle = async (
    error,
    statuses,
    errorHandle,
  ) => {
    if (!(error instanceof Response)) {
      return throwFetchError(500);
    }

    if (isErrorInStatuses(error, statuses)) {
      const response = (await error.json()) as unknown;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (isUnprocessableEntityError(error) && isFieldError<any>(response)) {
        return errorHandle(error, response);
      }

      // FIXME: UnprocessableEntityErrors以外のエラーの型になるようにする
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
      return errorHandle(error, response as any);
    }

    // @ts-expect-error
    throwFetchError(error.status);
  };

  return fetchErrorHandle;
};
