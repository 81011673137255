/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActionList
 */
export interface ActionList {
    /**
     * アクションリストID
     * @type {number}
     * @memberof ActionList
     */
    id: number;
    /**
     * アクションリストの名前
     * @type {string}
     * @memberof ActionList
     */
    name: string;
    /**
     * アクションリストのステータス (未完了・完了済み)
     * @type {string}
     * @memberof ActionList
     */
    status: ActionListStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum ActionListStatusEnum {
    Incomplete = 'incomplete',
    Finished = 'finished'
}

export function ActionListFromJSON(json: any): ActionList {
    return ActionListFromJSONTyped(json, false);
}

export function ActionListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
    };
}

export function ActionListToJSON(value?: ActionList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': value.status,
    };
}

