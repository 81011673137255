/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutTaskRequest
 */
export interface PutTaskRequest {
    /**
     * 期日
     * @type {string}
     * @memberof PutTaskRequest
     */
    deadline: string | null;
    /**
     * タイトル
     * @type {string}
     * @memberof PutTaskRequest
     */
    title: string;
    /**
     * 医療機関ID
     * @type {number}
     * @memberof PutTaskRequest
     */
    medicalInstitutionId: number | null;
    /**
     * 完了済みか
     * @type {string}
     * @memberof PutTaskRequest
     */
    status: PutTaskRequestStatusEnum;
    /**
     * スタッフID
     * @type {number}
     * @memberof PutTaskRequest
     */
    staffId: number | null;
}

/**
* @export
* @enum {string}
*/
export enum PutTaskRequestStatusEnum {
    Incomplete = 'incomplete',
    Finished = 'finished'
}

export function PutTaskRequestFromJSON(json: any): PutTaskRequest {
    return PutTaskRequestFromJSONTyped(json, false);
}

export function PutTaskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutTaskRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deadline': json['deadline'],
        'title': json['title'],
        'medicalInstitutionId': json['medical_institution_id'],
        'status': json['status'],
        'staffId': json['staff_id'],
    };
}

export function PutTaskRequestToJSON(value?: PutTaskRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deadline': value.deadline,
        'title': value.title,
        'medical_institution_id': value.medicalInstitutionId,
        'status': value.status,
        'staff_id': value.staffId,
    };
}

