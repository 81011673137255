/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsRequestPeriod,
    PostChartReferralsRequestPeriodFromJSON,
    PostChartReferralsRequestPeriodFromJSONTyped,
    PostChartReferralsRequestPeriodToJSON,
} from './PostChartReferralsRequestPeriod';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionReverseReferralsRequest
 */
export interface PostMedicalInstitutionReverseReferralsRequest {
    /**
     * ページ番号（１始まり）
     * @type {number}
     * @memberof PostMedicalInstitutionReverseReferralsRequest
     */
    page: number | null;
    /**
     * １ページあたりの件数
     * @type {number}
     * @memberof PostMedicalInstitutionReverseReferralsRequest
     */
    perPage: number | null;
    /**
     * グループ病院のID (null の場合は全グループ病院の合算)
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionReverseReferralsRequest
     */
    groupMedicalInstitutionIds: Array<string> | null;
    /**
     * 診療科目のID (null の場合は全診療科目)
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionReverseReferralsRequest
     */
    ownDepartmentIds: Array<string> | null;
    /**
     * 
     * @type {PostChartReferralsRequestPeriod}
     * @memberof PostMedicalInstitutionReverseReferralsRequest
     */
    period: PostChartReferralsRequestPeriod;
}

export function PostMedicalInstitutionReverseReferralsRequestFromJSON(json: any): PostMedicalInstitutionReverseReferralsRequest {
    return PostMedicalInstitutionReverseReferralsRequestFromJSONTyped(json, false);
}

export function PostMedicalInstitutionReverseReferralsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionReverseReferralsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'perPage': json['per_page'],
        'groupMedicalInstitutionIds': json['group_medical_institution_ids'],
        'ownDepartmentIds': json['own_department_ids'],
        'period': PostChartReferralsRequestPeriodFromJSON(json['period']),
    };
}

export function PostMedicalInstitutionReverseReferralsRequestToJSON(value?: PostMedicalInstitutionReverseReferralsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'per_page': value.perPage,
        'group_medical_institution_ids': value.groupMedicalInstitutionIds,
        'own_department_ids': value.ownDepartmentIds,
        'period': PostChartReferralsRequestPeriodToJSON(value.period),
    };
}

