/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 経度の範囲
 * @export
 * @interface PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitude
 */
export interface PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitude {
    /**
     * 経度(下限)
     * @type {number}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitude
     */
    min: number;
    /**
     * 経度(上限)
     * @type {number}
     * @memberof PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitude
     */
    max: number;
}

export function PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitudeFromJSON(json: any): PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitude {
    return PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitudeFromJSONTyped(json, false);
}

export function PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitudeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitude {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'min': json['min'],
        'max': json['max'],
    };
}

export function PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitudeToJSON(value?: PostMedicalInstitutionSearchForSalesMapRequestBoundsLatitude | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'min': value.min,
        'max': value.max,
    };
}

