/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostCommunicationReportResultsResponseResults,
    PostCommunicationReportResultsResponseResultsFromJSON,
    PostCommunicationReportResultsResponseResultsFromJSONTyped,
    PostCommunicationReportResultsResponseResultsToJSON,
} from './PostCommunicationReportResultsResponseResults';

/**
 * コミュニケーションレポート生成成功
 * @export
 * @interface PostCommunicationReportResultsResponse
 */
export interface PostCommunicationReportResultsResponse {
    /**
     * 表示中のページ番号
     * @type {number}
     * @memberof PostCommunicationReportResultsResponse
     */
    page: number;
    /**
     * 総ページ数
     * @type {number}
     * @memberof PostCommunicationReportResultsResponse
     */
    totalPage: number;
    /**
     * 総件数
     * @type {number}
     * @memberof PostCommunicationReportResultsResponse
     */
    totalCount: number;
    /**
     * エリアレポートの結果
     * @type {Array<PostCommunicationReportResultsResponseResults>}
     * @memberof PostCommunicationReportResultsResponse
     */
    results: Array<PostCommunicationReportResultsResponseResults>;
}

export function PostCommunicationReportResultsResponseFromJSON(json: any): PostCommunicationReportResultsResponse {
    return PostCommunicationReportResultsResponseFromJSONTyped(json, false);
}

export function PostCommunicationReportResultsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCommunicationReportResultsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'totalPage': json['total_page'],
        'totalCount': json['total_count'],
        'results': ((json['results'] as Array<any>).map(PostCommunicationReportResultsResponseResultsFromJSON)),
    };
}

export function PostCommunicationReportResultsResponseToJSON(value?: PostCommunicationReportResultsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'total_page': value.totalPage,
        'total_count': value.totalCount,
        'results': ((value.results as Array<any>).map(PostCommunicationReportResultsResponseResultsToJSON)),
    };
}

