/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AreaReportItemAggregationCondition,
    AreaReportItemAggregationConditionFromJSON,
    AreaReportItemAggregationConditionFromJSONTyped,
    AreaReportItemAggregationConditionToJSON,
} from './AreaReportItemAggregationCondition';
import {
    AreaReportItemDefinitionKey,
    AreaReportItemDefinitionKeyFromJSON,
    AreaReportItemDefinitionKeyFromJSONTyped,
    AreaReportItemDefinitionKeyToJSON,
} from './AreaReportItemDefinitionKey';
import {
    AreaReportItemFormula,
    AreaReportItemFormulaFromJSON,
    AreaReportItemFormulaFromJSONTyped,
    AreaReportItemFormulaToJSON,
} from './AreaReportItemFormula';

/**
 * keyはレポート内で一意、他からの参照はこれで行う。<br />
 * 「過去に紹介がない医療機関を含む」など、項目として表示しない場合には何か固定値を指定するとよさそう<br />
 * spaでの生成時点で付与する必要があるので、idでの識別ではなくkeyを利用
 * @export
 * @interface AreaReportItem
 */
export interface AreaReportItem {
    /**
     * レポートの項目をレポート内で一意に識別するためのUUID
     * @type {string}
     * @memberof AreaReportItem
     */
    key: string;
    /**
     * 
     * @type {AreaReportItemDefinitionKey}
     * @memberof AreaReportItem
     */
    definitionKey: AreaReportItemDefinitionKey;
    /**
     * 
     * @type {AreaReportItemAggregationCondition}
     * @memberof AreaReportItem
     */
    aggregationConditions?: AreaReportItemAggregationCondition | null;
    /**
     * 
     * @type {AreaReportItemFormula}
     * @memberof AreaReportItem
     */
    formula?: AreaReportItemFormula | null;
}

export function AreaReportItemFromJSON(json: any): AreaReportItem {
    return AreaReportItemFromJSONTyped(json, false);
}

export function AreaReportItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AreaReportItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'definitionKey': AreaReportItemDefinitionKeyFromJSON(json['definition_key']),
        'aggregationConditions': !exists(json, 'aggregation_conditions') ? undefined : AreaReportItemAggregationConditionFromJSON(json['aggregation_conditions']),
        'formula': !exists(json, 'formula') ? undefined : AreaReportItemFormulaFromJSON(json['formula']),
    };
}

export function AreaReportItemToJSON(value?: AreaReportItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'definition_key': AreaReportItemDefinitionKeyToJSON(value.definitionKey),
        'aggregation_conditions': AreaReportItemAggregationConditionToJSON(value.aggregationConditions),
        'formula': AreaReportItemFormulaToJSON(value.formula),
    };
}

