/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationReportItemDefinitionCommunicationLabel,
    CommunicationReportItemDefinitionCommunicationLabelFromJSON,
    CommunicationReportItemDefinitionCommunicationLabelFromJSONTyped,
    CommunicationReportItemDefinitionCommunicationLabelToJSON,
} from './CommunicationReportItemDefinitionCommunicationLabel';
import {
    CommunicationReportItemDefinitionMedicalInstitutionLabel,
    CommunicationReportItemDefinitionMedicalInstitutionLabelFromJSON,
    CommunicationReportItemDefinitionMedicalInstitutionLabelFromJSONTyped,
    CommunicationReportItemDefinitionMedicalInstitutionLabelToJSON,
} from './CommunicationReportItemDefinitionMedicalInstitutionLabel';
import {
    CommunicationReportItemDefinitionPreset,
    CommunicationReportItemDefinitionPresetFromJSON,
    CommunicationReportItemDefinitionPresetFromJSONTyped,
    CommunicationReportItemDefinitionPresetToJSON,
} from './CommunicationReportItemDefinitionPreset';

/**
 * 
 * @export
 * @interface GetCommunicationReportItemDefinitionsResponse
 */
export interface GetCommunicationReportItemDefinitionsResponse {
    /**
     * コミュニケーションレポート項目定義、プリセット項目
     * @type {Array<CommunicationReportItemDefinitionPreset>}
     * @memberof GetCommunicationReportItemDefinitionsResponse
     */
    presets: Array<CommunicationReportItemDefinitionPreset>;
    /**
     * コミュニケーションレポート項目定義、コミュニケーションラベル項目
     * @type {Array<CommunicationReportItemDefinitionCommunicationLabel>}
     * @memberof GetCommunicationReportItemDefinitionsResponse
     */
    communicationLabels: Array<CommunicationReportItemDefinitionCommunicationLabel>;
    /**
     * コミュニケーションレポート項目定義、医療機関ラベル項目
     * @type {Array<CommunicationReportItemDefinitionMedicalInstitutionLabel>}
     * @memberof GetCommunicationReportItemDefinitionsResponse
     */
    medicalInstitutionLabels?: Array<CommunicationReportItemDefinitionMedicalInstitutionLabel>;
}

export function GetCommunicationReportItemDefinitionsResponseFromJSON(json: any): GetCommunicationReportItemDefinitionsResponse {
    return GetCommunicationReportItemDefinitionsResponseFromJSONTyped(json, false);
}

export function GetCommunicationReportItemDefinitionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCommunicationReportItemDefinitionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presets': ((json['presets'] as Array<any>).map(CommunicationReportItemDefinitionPresetFromJSON)),
        'communicationLabels': ((json['communication_labels'] as Array<any>).map(CommunicationReportItemDefinitionCommunicationLabelFromJSON)),
        'medicalInstitutionLabels': !exists(json, 'medical_institution_labels') ? undefined : ((json['medical_institution_labels'] as Array<any>).map(CommunicationReportItemDefinitionMedicalInstitutionLabelFromJSON)),
    };
}

export function GetCommunicationReportItemDefinitionsResponseToJSON(value?: GetCommunicationReportItemDefinitionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'presets': ((value.presets as Array<any>).map(CommunicationReportItemDefinitionPresetToJSON)),
        'communication_labels': ((value.communicationLabels as Array<any>).map(CommunicationReportItemDefinitionCommunicationLabelToJSON)),
        'medical_institution_labels': value.medicalInstitutionLabels === undefined ? undefined : ((value.medicalInstitutionLabels as Array<any>).map(CommunicationReportItemDefinitionMedicalInstitutionLabelToJSON)),
    };
}

