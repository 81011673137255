/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationLabelValue,
    CommunicationLabelValueFromJSON,
    CommunicationLabelValueFromJSONTyped,
    CommunicationLabelValueToJSON,
} from './CommunicationLabelValue';
import {
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
} from './CommunicationType';

/**
 * コミュニケーション
 * @export
 * @interface PutCommunicationRequest
 */
export interface PutCommunicationRequest {
    /**
     * 医療機関ID
     * @type {number}
     * @memberof PutCommunicationRequest
     */
    medicalInstitutionId?: number;
    /**
     * 
     * @type {CommunicationType}
     * @memberof PutCommunicationRequest
     */
    communicationType: CommunicationType;
    /**
     * コミュニケーション日付
     * @type {string}
     * @memberof PutCommunicationRequest
     */
    actedDate: string;
    /**
     * カテゴリー (communication_type が 'visit' のみ有効)
     * @type {string}
     * @memberof PutCommunicationRequest
     */
    category?: PutCommunicationRequestCategoryEnum;
    /**
     * 目的 (communication_type が 'visit' のみ有効)
     * @type {string}
     * @memberof PutCommunicationRequest
     */
    purpose?: string | null;
    /**
     * ストーリー (communication_type が 'visit' のみ有効)
     * @type {string}
     * @memberof PutCommunicationRequest
     */
    story?: string | null;
    /**
     * 送付物 (communication_type が 'postal_item' のみ有効)
     * @type {string}
     * @memberof PutCommunicationRequest
     */
    attachments?: string | null;
    /**
     * イベント名 (communication_type が 'event_attendance' のみ有効)
     * @type {string}
     * @memberof PutCommunicationRequest
     */
    eventName?: string | null;
    /**
     * 面談内容メモ
     * @type {string}
     * @memberof PutCommunicationRequest
     */
    memo: string | null;
    /**
     * その他メモ (communication_type が 'visit' のみ有効)
     * @type {string}
     * @memberof PutCommunicationRequest
     */
    otherMemo?: string | null;
    /**
     * 担当者ID
     * @type {number}
     * @memberof PutCommunicationRequest
     */
    userId: number | null;
    /**
     * 当院訪問者 (communication_type が 'visit' 'new_visit' 'phone' 'email' のみ有効)
     * @type {string}
     * @memberof PutCommunicationRequest
     */
    personInChargeHospital?: string | null;
    /**
     * 先方対応者 (communication_type が 'visit' 'new_visit' 'phone' 'email' 'postal_item' 'event_attendance' のみ有効)
     * @type {string}
     * @memberof PutCommunicationRequest
     */
    attendee?: string | null;
    /**
     * 先方医師との面談実施 (communication_type が 'visit' のみ有効)
     * @type {boolean}
     * @memberof PutCommunicationRequest
     */
    isDoctorAttended?: boolean;
    /**
     * コミュニケーションラベル値
     * @type {Array<CommunicationLabelValue>}
     * @memberof PutCommunicationRequest
     */
    labelValues: Array<CommunicationLabelValue>;
}

/**
* @export
* @enum {string}
*/
export enum PutCommunicationRequestCategoryEnum {
    Sales = 'sales',
    Other = 'other'
}

export function PutCommunicationRequestFromJSON(json: any): PutCommunicationRequest {
    return PutCommunicationRequestFromJSONTyped(json, false);
}

export function PutCommunicationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutCommunicationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutionId': !exists(json, 'medical_institution_id') ? undefined : json['medical_institution_id'],
        'communicationType': CommunicationTypeFromJSON(json['communication_type']),
        'actedDate': json['acted_date'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'purpose': !exists(json, 'purpose') ? undefined : json['purpose'],
        'story': !exists(json, 'story') ? undefined : json['story'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'eventName': !exists(json, 'event_name') ? undefined : json['event_name'],
        'memo': json['memo'],
        'otherMemo': !exists(json, 'other_memo') ? undefined : json['other_memo'],
        'userId': json['user_id'],
        'personInChargeHospital': !exists(json, 'person_in_charge_hospital') ? undefined : json['person_in_charge_hospital'],
        'attendee': !exists(json, 'attendee') ? undefined : json['attendee'],
        'isDoctorAttended': !exists(json, 'is_doctor_attended') ? undefined : json['is_doctor_attended'],
        'labelValues': ((json['label_values'] as Array<any>).map(CommunicationLabelValueFromJSON)),
    };
}

export function PutCommunicationRequestToJSON(value?: PutCommunicationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution_id': value.medicalInstitutionId,
        'communication_type': CommunicationTypeToJSON(value.communicationType),
        'acted_date': value.actedDate,
        'category': value.category,
        'purpose': value.purpose,
        'story': value.story,
        'attachments': value.attachments,
        'event_name': value.eventName,
        'memo': value.memo,
        'other_memo': value.otherMemo,
        'user_id': value.userId,
        'person_in_charge_hospital': value.personInChargeHospital,
        'attendee': value.attendee,
        'is_doctor_attended': value.isDoctorAttended,
        'label_values': ((value.labelValues as Array<any>).map(CommunicationLabelValueToJSON)),
    };
}

