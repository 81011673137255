/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostNewAlertConditionFiltersCommunicationVisit,
    PostNewAlertConditionFiltersCommunicationVisitFromJSON,
    PostNewAlertConditionFiltersCommunicationVisitFromJSONTyped,
    PostNewAlertConditionFiltersCommunicationVisitToJSON,
} from './PostNewAlertConditionFiltersCommunicationVisit';
import {
    PostNewAlertConditionFiltersReferralAverage,
    PostNewAlertConditionFiltersReferralAverageFromJSON,
    PostNewAlertConditionFiltersReferralAverageFromJSONTyped,
    PostNewAlertConditionFiltersReferralAverageToJSON,
} from './PostNewAlertConditionFiltersReferralAverage';
import {
    PostNewAlertConditionFiltersReferralCount,
    PostNewAlertConditionFiltersReferralCountFromJSON,
    PostNewAlertConditionFiltersReferralCountFromJSONTyped,
    PostNewAlertConditionFiltersReferralCountToJSON,
} from './PostNewAlertConditionFiltersReferralCount';

/**
 * 
 * @export
 * @interface PostNewAlertConditionFilters
 */
export interface PostNewAlertConditionFilters {
    /**
     * 医療機関の絞り込み要素の都道府県
     * @type {string}
     * @memberof PostNewAlertConditionFilters
     */
    prefecture?: string;
    /**
     * 医療機関の絞り込み要素の地域
     * @type {string}
     * @memberof PostNewAlertConditionFilters
     */
    area?: string;
    /**
     * 医療機関タイプ(施設タイプ)ID
     * @type {Array<string>}
     * @memberof PostNewAlertConditionFilters
     */
    facilityTypeIds?: Array<string>;
    /**
     * 診療科目ID
     * @type {Array<string>}
     * @memberof PostNewAlertConditionFilters
     */
    departmentIds?: Array<string>;
    /**
     * タグID
     * @type {Array<string>}
     * @memberof PostNewAlertConditionFilters
     */
    tagIds?: Array<string>;
    /**
     * 
     * @type {PostNewAlertConditionFiltersReferralCount}
     * @memberof PostNewAlertConditionFilters
     */
    referralCount?: PostNewAlertConditionFiltersReferralCount;
    /**
     * 
     * @type {PostNewAlertConditionFiltersReferralAverage}
     * @memberof PostNewAlertConditionFilters
     */
    referralAverage?: PostNewAlertConditionFiltersReferralAverage;
    /**
     * 
     * @type {PostNewAlertConditionFiltersCommunicationVisit}
     * @memberof PostNewAlertConditionFilters
     */
    communicationVisit?: PostNewAlertConditionFiltersCommunicationVisit;
}

export function PostNewAlertConditionFiltersFromJSON(json: any): PostNewAlertConditionFilters {
    return PostNewAlertConditionFiltersFromJSONTyped(json, false);
}

export function PostNewAlertConditionFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostNewAlertConditionFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prefecture': !exists(json, 'prefecture') ? undefined : json['prefecture'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'facilityTypeIds': !exists(json, 'facility_type_ids') ? undefined : json['facility_type_ids'],
        'departmentIds': !exists(json, 'department_ids') ? undefined : json['department_ids'],
        'tagIds': !exists(json, 'tag_ids') ? undefined : json['tag_ids'],
        'referralCount': !exists(json, 'referral_count') ? undefined : PostNewAlertConditionFiltersReferralCountFromJSON(json['referral_count']),
        'referralAverage': !exists(json, 'referral_average') ? undefined : PostNewAlertConditionFiltersReferralAverageFromJSON(json['referral_average']),
        'communicationVisit': !exists(json, 'communication_visit') ? undefined : PostNewAlertConditionFiltersCommunicationVisitFromJSON(json['communication_visit']),
    };
}

export function PostNewAlertConditionFiltersToJSON(value?: PostNewAlertConditionFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prefecture': value.prefecture,
        'area': value.area,
        'facility_type_ids': value.facilityTypeIds,
        'department_ids': value.departmentIds,
        'tag_ids': value.tagIds,
        'referral_count': PostNewAlertConditionFiltersReferralCountToJSON(value.referralCount),
        'referral_average': PostNewAlertConditionFiltersReferralAverageToJSON(value.referralAverage),
        'communication_visit': PostNewAlertConditionFiltersCommunicationVisitToJSON(value.communicationVisit),
    };
}

