/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostGroupsChartReferralsComparisonAnnualResponseReferrals,
    PostGroupsChartReferralsComparisonAnnualResponseReferralsFromJSON,
    PostGroupsChartReferralsComparisonAnnualResponseReferralsFromJSONTyped,
    PostGroupsChartReferralsComparisonAnnualResponseReferralsToJSON,
} from './PostGroupsChartReferralsComparisonAnnualResponseReferrals';

/**
 * 
 * @export
 * @interface PostGroupsChartReferralsComparisonAnnualResponse
 */
export interface PostGroupsChartReferralsComparisonAnnualResponse {
    /**
     * 紹介数年次比較データ
     * @type {Array<PostGroupsChartReferralsComparisonAnnualResponseReferrals>}
     * @memberof PostGroupsChartReferralsComparisonAnnualResponse
     */
    referrals: Array<PostGroupsChartReferralsComparisonAnnualResponseReferrals>;
}

export function PostGroupsChartReferralsComparisonAnnualResponseFromJSON(json: any): PostGroupsChartReferralsComparisonAnnualResponse {
    return PostGroupsChartReferralsComparisonAnnualResponseFromJSONTyped(json, false);
}

export function PostGroupsChartReferralsComparisonAnnualResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostGroupsChartReferralsComparisonAnnualResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrals': ((json['referrals'] as Array<any>).map(PostGroupsChartReferralsComparisonAnnualResponseReferralsFromJSON)),
    };
}

export function PostGroupsChartReferralsComparisonAnnualResponseToJSON(value?: PostGroupsChartReferralsComparisonAnnualResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrals': ((value.referrals as Array<any>).map(PostGroupsChartReferralsComparisonAnnualResponseReferralsToJSON)),
    };
}

