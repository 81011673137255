/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MedicalInstitutionSummaryNps
 */
export interface MedicalInstitutionSummaryNps {
    /**
     * NPSのスコア
     * @type {number}
     * @memberof MedicalInstitutionSummaryNps
     */
    score: number;
    /**
     * NPSの更新時間
     * @type {string}
     * @memberof MedicalInstitutionSummaryNps
     */
    latestCreatedDate: string;
}

export function MedicalInstitutionSummaryNpsFromJSON(json: any): MedicalInstitutionSummaryNps {
    return MedicalInstitutionSummaryNpsFromJSONTyped(json, false);
}

export function MedicalInstitutionSummaryNpsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionSummaryNps {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
        'latestCreatedDate': json['latest_created_date'],
    };
}

export function MedicalInstitutionSummaryNpsToJSON(value?: MedicalInstitutionSummaryNps | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
        'latest_created_date': value.latestCreatedDate,
    };
}

