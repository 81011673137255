/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Segment,
    SegmentFromJSON,
    SegmentFromJSONTyped,
    SegmentToJSON,
} from './Segment';

/**
 * 連携先セグメント分け設定
 * @export
 * @interface NullableSegmentationSetting
 */
export interface NullableSegmentationSetting {
    /**
     * 
     * @type {string}
     * @memberof NullableSegmentationSetting
     */
    id: string;
    /**
     * "その他"セグメントのラベル
     * @type {string}
     * @memberof NullableSegmentationSetting
     */
    restSegmentLabel: string;
    /**
     * 除外対象の診療科ID
     * @type {Array<string>}
     * @memberof NullableSegmentationSetting
     */
    excludedOwnDepartmentIds: Array<string>;
    /**
     * 
     * @type {Array<Segment>}
     * @memberof NullableSegmentationSetting
     */
    segments: Array<Segment>;
}

export function NullableSegmentationSettingFromJSON(json: any): NullableSegmentationSetting {
    return NullableSegmentationSettingFromJSONTyped(json, false);
}

export function NullableSegmentationSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): NullableSegmentationSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'restSegmentLabel': json['rest_segment_label'],
        'excludedOwnDepartmentIds': json['excluded_own_department_ids'],
        'segments': ((json['segments'] as Array<any>).map(SegmentFromJSON)),
    };
}

export function NullableSegmentationSettingToJSON(value?: NullableSegmentationSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rest_segment_label': value.restSegmentLabel,
        'excluded_own_department_ids': value.excludedOwnDepartmentIds,
        'segments': ((value.segments as Array<any>).map(SegmentToJSON)),
    };
}

