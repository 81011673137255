/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 連携先セグメント分け設定
 * @export
 * @interface Segment
 */
export interface Segment {
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    id: string;
    /**
     * セグメントのラベル
     * @type {string}
     * @memberof Segment
     */
    label: string;
    /**
     * セグメント分けの優先度、数字が小さい方が優先される
     * @type {number}
     * @memberof Segment
     */
    priority: number;
    /**
     * 紹介数のしきい値
     * @type {number}
     * @memberof Segment
     */
    referralCountMoreEqual: number | null;
    /**
     * 紹介入院率のしきい値
     * @type {number}
     * @memberof Segment
     */
    referralAdmissionRateMoreEqual: number | null;
}

export function SegmentFromJSON(json: any): Segment {
    return SegmentFromJSONTyped(json, false);
}

export function SegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Segment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'label': json['label'],
        'priority': json['priority'],
        'referralCountMoreEqual': json['referral_count_more_equal'],
        'referralAdmissionRateMoreEqual': json['referral_admission_rate_more_equal'],
    };
}

export function SegmentToJSON(value?: Segment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'priority': value.priority,
        'referral_count_more_equal': value.referralCountMoreEqual,
        'referral_admission_rate_more_equal': value.referralAdmissionRateMoreEqual,
    };
}

