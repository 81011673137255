/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * 
 * @export
 * @interface GetMedicalInstitutionTagsResponse
 */
export interface GetMedicalInstitutionTagsResponse {
    /**
     * タグデータ
     * @type {Array<Tag>}
     * @memberof GetMedicalInstitutionTagsResponse
     */
    tags: Array<Tag>;
}

export function GetMedicalInstitutionTagsResponseFromJSON(json: any): GetMedicalInstitutionTagsResponse {
    return GetMedicalInstitutionTagsResponseFromJSONTyped(json, false);
}

export function GetMedicalInstitutionTagsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMedicalInstitutionTagsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': ((json['tags'] as Array<any>).map(TagFromJSON)),
    };
}

export function GetMedicalInstitutionTagsResponseToJSON(value?: GetMedicalInstitutionTagsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': ((value.tags as Array<any>).map(TagToJSON)),
    };
}

