import { useResponsive } from '@/src/hooks/useResponsive';

const buildAppURL = (): string => {
  const base = process.env.APP_BASE_HOST;
  const { protocol } = window.location;
  return `${protocol}//${base}`;
};

export const useTopPageURL = (): string => {
  const responsive = useResponsive();

  const host = buildAppURL();
  const initialPage = responsive.isSp
    ? 'medical_institutions_search'
    : 'data_analysis';

  return `${host}/app/${initialPage}`;
};
