/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionMoreLess,
    AlertConditionMoreLessFromJSON,
    AlertConditionMoreLessFromJSONTyped,
    AlertConditionMoreLessToJSON,
} from './AlertConditionMoreLess';
import {
    AlertConditionUnitType,
    AlertConditionUnitTypeFromJSON,
    AlertConditionUnitTypeFromJSONTyped,
    AlertConditionUnitTypeToJSON,
} from './AlertConditionUnitType';

/**
 * 
 * @export
 * @interface PostNewAlertConditionFiltersCommunicationVisit
 */
export interface PostNewAlertConditionFiltersCommunicationVisit {
    /**
     * 訪問件数
     * @type {number}
     * @memberof PostNewAlertConditionFiltersCommunicationVisit
     */
    count: number;
    /**
     * 
     * @type {AlertConditionMoreLess}
     * @memberof PostNewAlertConditionFiltersCommunicationVisit
     */
    moreLess: AlertConditionMoreLess;
    /**
     * 
     * @type {AlertConditionUnitType}
     * @memberof PostNewAlertConditionFiltersCommunicationVisit
     */
    unitType: AlertConditionUnitType;
    /**
     * 医師面談の有無 (あり(true)/なし(false)/すべて(null))
     * @type {boolean}
     * @memberof PostNewAlertConditionFiltersCommunicationVisit
     */
    isDoctorAttended: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PostNewAlertConditionFiltersCommunicationVisit
     */
    userId: number | null;
}

export function PostNewAlertConditionFiltersCommunicationVisitFromJSON(json: any): PostNewAlertConditionFiltersCommunicationVisit {
    return PostNewAlertConditionFiltersCommunicationVisitFromJSONTyped(json, false);
}

export function PostNewAlertConditionFiltersCommunicationVisitFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostNewAlertConditionFiltersCommunicationVisit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'moreLess': AlertConditionMoreLessFromJSON(json['more_less']),
        'unitType': AlertConditionUnitTypeFromJSON(json['unit_type']),
        'isDoctorAttended': json['is_doctor_attended'],
        'userId': json['user_id'],
    };
}

export function PostNewAlertConditionFiltersCommunicationVisitToJSON(value?: PostNewAlertConditionFiltersCommunicationVisit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'more_less': AlertConditionMoreLessToJSON(value.moreLess),
        'unit_type': AlertConditionUnitTypeToJSON(value.unitType),
        'is_doctor_attended': value.isDoctorAttended,
        'user_id': value.userId,
    };
}

