import { useRouter } from 'next/router';

const titleList: {
  title: string;
  pathname: string;
  query?: { [key: string]: string };
}[] = [
  {
    title: 'ログイン',
    pathname: '/login',
  },
  {
    title: 'パスワードをお忘れですか？',
    pathname: '/user/password',
  },
  {
    title: 'パスワード再設定',
    pathname: '/user/password/change',
  },
  {
    title: 'データ分析',
    pathname: '/data_analysis',
  },
  {
    title: '診療圏分析マップ',
    pathname: '/segment_map',
  },
  {
    title: '営業分析マップ',
    pathname: '/sale_map',
  },
  {
    title: 'タスク',
    pathname: '/tasks',
  },
  {
    title: '連携先管理',
    pathname: '/medical_institutions_search',
  },
  {
    title: 'タスク',
    pathname: '/crms/[id]/tasks',
  },
  {
    title: '紹介',
    pathname: '/crms/[id]/referrals',
  },
  {
    title: 'コミュニケーション',
    pathname: '/crms/[id]/communications',
  },
  {
    title: '連携先カルテ',
    pathname: '/crms/[id]',
    query: { view: 'karte' },
  },
  {
    title: '個別医療機関',
    pathname: '/crms/[id]',
  },
  {
    title: 'アクションリスト',
    pathname: '/action_lists',
  },
  {
    title: 'HTMLメール配信',
    pathname: '/emails',
  },
  {
    title: 'コミュニケーション',
    pathname: '/communications',
  },
  {
    title: 'コミュニケーション追加',
    pathname: '/communications/new',
  },
  {
    title: 'HTMLメールレポート',
    pathname: '/emails/[id]/reports',
  },
  {
    title: 'HTMLメール配信',
    pathname: '/emails/new',
  },
  {
    title: 'HTMLメール配信',
    pathname: '/emails/[id]/edit',
  },
  {
    title: 'アラート',
    pathname: '/alerts',
  },
  {
    title: 'アラート',
    pathname: '/alerts/[id]',
  },
  {
    title: 'アラート',
    pathname: '/alert_conditions',
  },
  {
    title: 'ラベル管理',
    pathname: '/labels',
  },
  {
    title: 'ラベル管理',
    pathname: '/labels/medical_institutions/import',
  },
  {
    title: 'ラベル管理',
    pathname: '/labels/communications/import',
  },
  {
    title: 'タグ管理',
    pathname: '/tags',
  },
  {
    title: 'データアップロード',
    pathname: '/data_uploads',
  },
  {
    title: 'アカウント管理',
    pathname: '/accounts',
  },
  {
    title: 'サインアップ',
    pathname: '/accounts/confirmation',
  },
  {
    title: '通知設定',
    pathname: '/profile/notification',
  },
  {
    title: 'レポート',
    pathname: '/medical_institutions_reports',
  },
  {
    title: '連携先レポート',
    pathname: '/medical_institutions_reports/new',
  },
  {
    title: '連携先レポート',
    pathname: '/medical_institutions_reports/[id]',
  },
  {
    title: 'レポート',
    pathname: '/own_department_reports',
  },
  {
    title: '診療科レポート',
    pathname: '/own_department_reports/new',
  },
  {
    title: '診療科レポート',
    pathname: '/own_department_reports/[id]',
  },
  {
    title: 'レポート',
    pathname: '/area_reports',
  },
  {
    title: 'エリアレポート',
    pathname: '/area_reports/new',
  },
  {
    title: 'エリアレポート',
    pathname: '/area_reports/[id]',
  },
  {
    title: 'レポート',
    pathname: '/communication_reports',
  },
  {
    title: 'コミュニケーションレポート',
    pathname: '/communication_reports/new',
  },
  {
    title: 'コミュニケーションレポート',
    pathname: '/communication_reports/[id]',
  },
  {
    title: 'ダッシュボード',
    pathname: '/dashboards',
  },
  {
    title: 'ダッシュボード',
    pathname: '/dashboards/new',
  },
  {
    title: 'ダッシュボード',
    pathname: '/dashboards/[id]',
  },
  {
    title: 'ダッシュボード',
    pathname: '/dashboards/[id]/edit',
  },
];

export const useTitle = (): string => {
  const router = useRouter();

  return (
    titleList.find((t) =>
      t.query
        ? t.pathname === router.pathname &&
          Object.keys(t.query).some(
            (key) => router.query[key] === t.query?.[key],
          )
        : t.pathname === router.pathname,
    )?.title ?? ''
  );
};
