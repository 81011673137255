/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsResponseReferrals,
    PostChartReferralsResponseReferralsFromJSON,
    PostChartReferralsResponseReferralsFromJSONTyped,
    PostChartReferralsResponseReferralsToJSON,
} from './PostChartReferralsResponseReferrals';

/**
 * 
 * @export
 * @interface PostChartReferralsResponse
 */
export interface PostChartReferralsResponse {
    /**
     * 紹介数チャートデータ
     * @type {Array<PostChartReferralsResponseReferrals>}
     * @memberof PostChartReferralsResponse
     */
    referrals: Array<PostChartReferralsResponseReferrals>;
}

export function PostChartReferralsResponseFromJSON(json: any): PostChartReferralsResponse {
    return PostChartReferralsResponseFromJSONTyped(json, false);
}

export function PostChartReferralsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartReferralsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrals': ((json['referrals'] as Array<any>).map(PostChartReferralsResponseReferralsFromJSON)),
    };
}

export function PostChartReferralsResponseToJSON(value?: PostChartReferralsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrals': ((value.referrals as Array<any>).map(PostChartReferralsResponseReferralsToJSON)),
    };
}

