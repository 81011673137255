/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OwnDepartmentReportFilterConditionMatcher,
    OwnDepartmentReportFilterConditionMatcherFromJSON,
    OwnDepartmentReportFilterConditionMatcherFromJSONTyped,
    OwnDepartmentReportFilterConditionMatcherToJSON,
} from './OwnDepartmentReportFilterConditionMatcher';
import {
    OwnDepartmentReportItem,
    OwnDepartmentReportItemFromJSON,
    OwnDepartmentReportItemFromJSONTyped,
    OwnDepartmentReportItemToJSON,
} from './OwnDepartmentReportItem';

/**
 * レポート絞り込み条件
 * @export
 * @interface OwnDepartmentReportFilterCondition
 */
export interface OwnDepartmentReportFilterCondition {
    /**
     * 
     * @type {OwnDepartmentReportItem}
     * @memberof OwnDepartmentReportFilterCondition
     */
    item: OwnDepartmentReportItem;
    /**
     * 項目に対する絞り込みマッチ条件のグループ。複数指定した場合どれかひとつのグループにマッチすれば抽出される
     * @type {Array<OwnDepartmentReportFilterConditionMatcher>}
     * @memberof OwnDepartmentReportFilterCondition
     */
    matchingGroups: Array<OwnDepartmentReportFilterConditionMatcher>;
}

export function OwnDepartmentReportFilterConditionFromJSON(json: any): OwnDepartmentReportFilterCondition {
    return OwnDepartmentReportFilterConditionFromJSONTyped(json, false);
}

export function OwnDepartmentReportFilterConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportFilterCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'item': OwnDepartmentReportItemFromJSON(json['item']),
        'matchingGroups': ((json['matching_groups'] as Array<any>).map(OwnDepartmentReportFilterConditionMatcherFromJSON)),
    };
}

export function OwnDepartmentReportFilterConditionToJSON(value?: OwnDepartmentReportFilterCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': OwnDepartmentReportItemToJSON(value.item),
        'matching_groups': ((value.matchingGroups as Array<any>).map(OwnDepartmentReportFilterConditionMatcherToJSON)),
    };
}

