/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Department,
    DepartmentFromJSON,
    DepartmentFromJSONTyped,
    DepartmentToJSON,
} from './Department';

/**
 * 
 * @export
 * @interface GetDepartmentsResponse
 */
export interface GetDepartmentsResponse {
    /**
     * 
     * @type {Array<Department>}
     * @memberof GetDepartmentsResponse
     */
    departments: Array<Department>;
}

export function GetDepartmentsResponseFromJSON(json: any): GetDepartmentsResponse {
    return GetDepartmentsResponseFromJSONTyped(json, false);
}

export function GetDepartmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDepartmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'departments': ((json['departments'] as Array<any>).map(DepartmentFromJSON)),
    };
}

export function GetDepartmentsResponseToJSON(value?: GetDepartmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'departments': ((value.departments as Array<any>).map(DepartmentToJSON)),
    };
}

