/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostGroupsChartReferralsComparisonMonthlyResponseReferrals,
    PostGroupsChartReferralsComparisonMonthlyResponseReferralsFromJSON,
    PostGroupsChartReferralsComparisonMonthlyResponseReferralsFromJSONTyped,
    PostGroupsChartReferralsComparisonMonthlyResponseReferralsToJSON,
} from './PostGroupsChartReferralsComparisonMonthlyResponseReferrals';

/**
 * 
 * @export
 * @interface PostGroupsChartReferralsComparisonMonthlyResponse
 */
export interface PostGroupsChartReferralsComparisonMonthlyResponse {
    /**
     * 紹介数月次比較データ
     * @type {Array<PostGroupsChartReferralsComparisonMonthlyResponseReferrals>}
     * @memberof PostGroupsChartReferralsComparisonMonthlyResponse
     */
    referrals: Array<PostGroupsChartReferralsComparisonMonthlyResponseReferrals>;
}

export function PostGroupsChartReferralsComparisonMonthlyResponseFromJSON(json: any): PostGroupsChartReferralsComparisonMonthlyResponse {
    return PostGroupsChartReferralsComparisonMonthlyResponseFromJSONTyped(json, false);
}

export function PostGroupsChartReferralsComparisonMonthlyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostGroupsChartReferralsComparisonMonthlyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrals': ((json['referrals'] as Array<any>).map(PostGroupsChartReferralsComparisonMonthlyResponseReferralsFromJSON)),
    };
}

export function PostGroupsChartReferralsComparisonMonthlyResponseToJSON(value?: PostGroupsChartReferralsComparisonMonthlyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrals': ((value.referrals as Array<any>).map(PostGroupsChartReferralsComparisonMonthlyResponseReferralsToJSON)),
    };
}

