/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionForSearchResults,
    MedicalInstitutionForSearchResultsFromJSON,
    MedicalInstitutionForSearchResultsFromJSONTyped,
    MedicalInstitutionForSearchResultsToJSON,
} from './MedicalInstitutionForSearchResults';

/**
 * 
 * @export
 * @interface GetDoctorsEmailDestinationsResponseEmailDestinations
 */
export interface GetDoctorsEmailDestinationsResponseEmailDestinations {
    /**
     * メールアドレス
     * @type {string}
     * @memberof GetDoctorsEmailDestinationsResponseEmailDestinations
     */
    email: string;
    /**
     * 名前
     * @type {string}
     * @memberof GetDoctorsEmailDestinationsResponseEmailDestinations
     */
    name: string;
    /**
     * 名前
     * @type {string}
     * @memberof GetDoctorsEmailDestinationsResponseEmailDestinations
     */
    honorific: string | null;
    /**
     * 役職
     * @type {string}
     * @memberof GetDoctorsEmailDestinationsResponseEmailDestinations
     */
    position: string | null;
    /**
     * 
     * @type {MedicalInstitutionForSearchResults}
     * @memberof GetDoctorsEmailDestinationsResponseEmailDestinations
     */
    medicalInstitution: MedicalInstitutionForSearchResults;
}

export function GetDoctorsEmailDestinationsResponseEmailDestinationsFromJSON(json: any): GetDoctorsEmailDestinationsResponseEmailDestinations {
    return GetDoctorsEmailDestinationsResponseEmailDestinationsFromJSONTyped(json, false);
}

export function GetDoctorsEmailDestinationsResponseEmailDestinationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDoctorsEmailDestinationsResponseEmailDestinations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'name': json['name'],
        'honorific': json['honorific'],
        'position': json['position'],
        'medicalInstitution': MedicalInstitutionForSearchResultsFromJSON(json['medical_institution']),
    };
}

export function GetDoctorsEmailDestinationsResponseEmailDestinationsToJSON(value?: GetDoctorsEmailDestinationsResponseEmailDestinations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'honorific': value.honorific,
        'position': value.position,
        'medical_institution': MedicalInstitutionForSearchResultsToJSON(value.medicalInstitution),
    };
}

