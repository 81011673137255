/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertCondition,
    AlertConditionFromJSON,
    AlertConditionFromJSONTyped,
    AlertConditionToJSON,
} from './AlertCondition';

/**
 * 
 * @export
 * @interface GetAlertConditionsResponse
 */
export interface GetAlertConditionsResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof GetAlertConditionsResponse
     */
    totalPage: number;
    /**
     * アラート条件の一覧
     * @type {Array<AlertCondition>}
     * @memberof GetAlertConditionsResponse
     */
    alertConditions: Array<AlertCondition>;
}

export function GetAlertConditionsResponseFromJSON(json: any): GetAlertConditionsResponse {
    return GetAlertConditionsResponseFromJSONTyped(json, false);
}

export function GetAlertConditionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAlertConditionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'alertConditions': ((json['alert_conditions'] as Array<any>).map(AlertConditionFromJSON)),
    };
}

export function GetAlertConditionsResponseToJSON(value?: GetAlertConditionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'alert_conditions': ((value.alertConditions as Array<any>).map(AlertConditionToJSON)),
    };
}

