/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionFiltersTagGroupsItems,
    AlertConditionFiltersTagGroupsItemsFromJSON,
    AlertConditionFiltersTagGroupsItemsFromJSONTyped,
    AlertConditionFiltersTagGroupsItemsToJSON,
} from './AlertConditionFiltersTagGroupsItems';

/**
 * 
 * @export
 * @interface AlertConditionFiltersTagGroups
 */
export interface AlertConditionFiltersTagGroups {
    /**
     * タググループ一覧
     * @type {Array<AlertConditionFiltersTagGroupsItems>}
     * @memberof AlertConditionFiltersTagGroups
     */
    items: Array<AlertConditionFiltersTagGroupsItems>;
    /**
     * 無効データの有無
     * @type {boolean}
     * @memberof AlertConditionFiltersTagGroups
     */
    isInvalid: boolean;
}

export function AlertConditionFiltersTagGroupsFromJSON(json: any): AlertConditionFiltersTagGroups {
    return AlertConditionFiltersTagGroupsFromJSONTyped(json, false);
}

export function AlertConditionFiltersTagGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionFiltersTagGroups {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(AlertConditionFiltersTagGroupsItemsFromJSON)),
        'isInvalid': json['is_invalid'],
    };
}

export function AlertConditionFiltersTagGroupsToJSON(value?: AlertConditionFiltersTagGroups | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(AlertConditionFiltersTagGroupsItemsToJSON)),
        'is_invalid': value.isInvalid,
    };
}

