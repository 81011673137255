/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSON,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSONTyped,
    PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionToJSON,
} from './PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution';

/**
 * 
 * @export
 * @interface PostDataAnalysisRankingReferralTotalCountResponseRankings
 */
export interface PostDataAnalysisRankingReferralTotalCountResponseRankings {
    /**
     * 
     * @type {PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution}
     * @memberof PostDataAnalysisRankingReferralTotalCountResponseRankings
     */
    medicalInstitution: PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitution;
    /**
     * 対象期間の紹介数
     * @type {number}
     * @memberof PostDataAnalysisRankingReferralTotalCountResponseRankings
     */
    referralCount: number;
}

export function PostDataAnalysisRankingReferralTotalCountResponseRankingsFromJSON(json: any): PostDataAnalysisRankingReferralTotalCountResponseRankings {
    return PostDataAnalysisRankingReferralTotalCountResponseRankingsFromJSONTyped(json, false);
}

export function PostDataAnalysisRankingReferralTotalCountResponseRankingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostDataAnalysisRankingReferralTotalCountResponseRankings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitution': PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionFromJSON(json['medical_institution']),
        'referralCount': json['referral_count'],
    };
}

export function PostDataAnalysisRankingReferralTotalCountResponseRankingsToJSON(value?: PostDataAnalysisRankingReferralTotalCountResponseRankings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution': PostDataAnalysisEffectivenessVerificationCommunicationsResponseMedicalInstitutionToJSON(value.medicalInstitution),
        'referral_count': value.referralCount,
    };
}

