/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostEmailRequestStringified,
    PostEmailRequestStringifiedFromJSON,
    PostEmailRequestStringifiedFromJSONTyped,
    PostEmailRequestStringifiedToJSON,
} from './PostEmailRequestStringified';

/**
 * 
 * @export
 * @interface PutEmailIdRequest
 */
export interface PutEmailIdRequest {
    /**
     * 宛先ファイル(CSV) ※ 宛先ファイルが未設定の場合は本key をリクエストに含めないこと
     * @type {Blob}
     * @memberof PutEmailIdRequest
     */
    recipientsFile?: Blob;
    /**
     * 
     * @type {PostEmailRequestStringified}
     * @memberof PutEmailIdRequest
     */
    stringified: PostEmailRequestStringified;
}

export function PutEmailIdRequestFromJSON(json: any): PutEmailIdRequest {
    return PutEmailIdRequestFromJSONTyped(json, false);
}

export function PutEmailIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutEmailIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipientsFile': !exists(json, 'recipients_file') ? undefined : json['recipients_file'],
        'stringified': PostEmailRequestStringifiedFromJSON(json['stringified']),
    };
}

export function PutEmailIdRequestToJSON(value?: PutEmailIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipients_file': value.recipientsFile,
        'stringified': PostEmailRequestStringifiedToJSON(value.stringified),
    };
}

