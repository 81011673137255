/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailReport,
    EmailReportFromJSON,
    EmailReportFromJSONTyped,
    EmailReportToJSON,
} from './EmailReport';

/**
 * 
 * @export
 * @interface GetEmailReportsResponse
 */
export interface GetEmailReportsResponse {
    /**
     * 一覧の総ページ数
     * @type {number}
     * @memberof GetEmailReportsResponse
     */
    totalPage: number;
    /**
     * メールレポートの一覧
     * @type {Array<EmailReport>}
     * @memberof GetEmailReportsResponse
     */
    reports: Array<EmailReport>;
}

export function GetEmailReportsResponseFromJSON(json: any): GetEmailReportsResponse {
    return GetEmailReportsResponseFromJSONTyped(json, false);
}

export function GetEmailReportsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEmailReportsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPage': json['total_page'],
        'reports': ((json['reports'] as Array<any>).map(EmailReportFromJSON)),
    };
}

export function GetEmailReportsResponseToJSON(value?: GetEmailReportsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_page': value.totalPage,
        'reports': ((value.reports as Array<any>).map(EmailReportToJSON)),
    };
}

