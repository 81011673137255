type Props = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  src: string;
};

export const Img: React.FC<Props> = ({ src, ...props }) => (
  <img {...props} src={`/app${src}`} />
);
