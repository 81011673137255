/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionList,
    ActionListFromJSON,
    ActionListFromJSONTyped,
    ActionListToJSON,
} from './ActionList';

/**
 * 
 * @export
 * @interface GetMedicalInstitutionActionListsResponse
 */
export interface GetMedicalInstitutionActionListsResponse {
    /**
     * アクションリストの一覧
     * @type {Array<ActionList>}
     * @memberof GetMedicalInstitutionActionListsResponse
     */
    actionLists: Array<ActionList>;
}

export function GetMedicalInstitutionActionListsResponseFromJSON(json: any): GetMedicalInstitutionActionListsResponse {
    return GetMedicalInstitutionActionListsResponseFromJSONTyped(json, false);
}

export function GetMedicalInstitutionActionListsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMedicalInstitutionActionListsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionLists': ((json['action_lists'] as Array<any>).map(ActionListFromJSON)),
    };
}

export function GetMedicalInstitutionActionListsResponseToJSON(value?: GetMedicalInstitutionActionListsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action_lists': ((value.actionLists as Array<any>).map(ActionListToJSON)),
    };
}

