/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * レポート項目集計条件で利用する医療機関ラベル (集計条件で利用される医療機関ラベルに対して適用するフィルタ条件を含む)
 * @export
 * @interface OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel
 */
export interface OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel {
    /**
     * 医療機関 id
     * @type {number}
     * @memberof OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel
     */
    medicalInstitutionLabelId: number;
    /**
     * 指定した文字列が含まれる場合にマッチする条件
     * @type {string}
     * @memberof OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel
     */
    contain?: string | null;
    /**
     * trueにすると値が存在しないものもマッチする
     * @type {boolean}
     * @memberof OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel
     */
    orNotExists?: boolean | null;
    /**
     * 指定した数値以上の場合にマッチする条件
     * @type {number}
     * @memberof OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel
     */
    moreEqual?: number | null;
    /**
     * 指定した数値以下の場合にマッチする条件
     * @type {number}
     * @memberof OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel
     */
    lessEqual?: number | null;
    /**
     * 数値が指定したいずれかと合致する場合にマッチする条件 (ラベルタイプ checkbox 以外)
     * @type {Array<number>}
     * @memberof OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel
     */
    _in?: Array<number>;
    /**
     * 数値が指定したいずれも含まない場合にマッチする条件 (ラベルタイプ checkbox type 以外)
     * @type {Array<number>}
     * @memberof OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel
     */
    notIn?: Array<number>;
    /**
     * 指定したもののうちどれか一つでも含んでいる場合にマッチする条件。(ラベルタイプ checkbox type)
     * @type {Array<number>}
     * @memberof OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel
     */
    haveAny?: Array<number>;
    /**
     * 指定したものをすべてを含んでいる場合にマッチする条件。(ラベルタイプ checkbox type)
     * @type {Array<number>}
     * @memberof OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel
     */
    haveAll?: Array<number>;
    /**
     * 指定したものをすべてを含んでいない場合にマッチする条件。(ラベルタイプ checkbox type)
     * @type {Array<number>}
     * @memberof OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel
     */
    notHaveAll?: Array<number>;
}

export function OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabelFromJSON(json: any): OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel {
    return OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabelFromJSONTyped(json, false);
}

export function OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutionLabelId': json['medical_institution_label_id'],
        'contain': !exists(json, 'contain') ? undefined : json['contain'],
        'orNotExists': !exists(json, 'or_not_exists') ? undefined : json['or_not_exists'],
        'moreEqual': !exists(json, 'more_equal') ? undefined : json['more_equal'],
        'lessEqual': !exists(json, 'less_equal') ? undefined : json['less_equal'],
        '_in': !exists(json, 'in') ? undefined : json['in'],
        'notIn': !exists(json, 'not_in') ? undefined : json['not_in'],
        'haveAny': !exists(json, 'have_any') ? undefined : json['have_any'],
        'haveAll': !exists(json, 'have_all') ? undefined : json['have_all'],
        'notHaveAll': !exists(json, 'not_have_all') ? undefined : json['not_have_all'],
    };
}

export function OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabelToJSON(value?: OwnDepartmentReportItemAggregationConditionMedicalInstitutionLabel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution_label_id': value.medicalInstitutionLabelId,
        'contain': value.contain,
        'or_not_exists': value.orNotExists,
        'more_equal': value.moreEqual,
        'less_equal': value.lessEqual,
        'in': value._in,
        'not_in': value.notIn,
        'have_any': value.haveAny,
        'have_all': value.haveAll,
        'not_have_all': value.notHaveAll,
    };
}

