/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertConditionMoreLess,
    AlertConditionMoreLessFromJSON,
    AlertConditionMoreLessFromJSONTyped,
    AlertConditionMoreLessToJSON,
} from './AlertConditionMoreLess';
import {
    AlertConditionTriggersReferralChangeCountOwnDepartments,
    AlertConditionTriggersReferralChangeCountOwnDepartmentsFromJSON,
    AlertConditionTriggersReferralChangeCountOwnDepartmentsFromJSONTyped,
    AlertConditionTriggersReferralChangeCountOwnDepartmentsToJSON,
} from './AlertConditionTriggersReferralChangeCountOwnDepartments';
import {
    AlertConditionUnitType,
    AlertConditionUnitTypeFromJSON,
    AlertConditionUnitTypeFromJSONTyped,
    AlertConditionUnitTypeToJSON,
} from './AlertConditionUnitType';

/**
 * 
 * @export
 * @interface AlertConditionFiltersReferralCount
 */
export interface AlertConditionFiltersReferralCount {
    /**
     * 紹介の件数
     * @type {number}
     * @memberof AlertConditionFiltersReferralCount
     */
    count: number;
    /**
     * 
     * @type {AlertConditionMoreLess}
     * @memberof AlertConditionFiltersReferralCount
     */
    moreLess: AlertConditionMoreLess;
    /**
     * 
     * @type {AlertConditionUnitType}
     * @memberof AlertConditionFiltersReferralCount
     */
    unitType: AlertConditionUnitType;
    /**
     * 
     * @type {AlertConditionTriggersReferralChangeCountOwnDepartments}
     * @memberof AlertConditionFiltersReferralCount
     */
    ownDepartments: AlertConditionTriggersReferralChangeCountOwnDepartments | null;
}

export function AlertConditionFiltersReferralCountFromJSON(json: any): AlertConditionFiltersReferralCount {
    return AlertConditionFiltersReferralCountFromJSONTyped(json, false);
}

export function AlertConditionFiltersReferralCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertConditionFiltersReferralCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'moreLess': AlertConditionMoreLessFromJSON(json['more_less']),
        'unitType': AlertConditionUnitTypeFromJSON(json['unit_type']),
        'ownDepartments': AlertConditionTriggersReferralChangeCountOwnDepartmentsFromJSON(json['own_departments']),
    };
}

export function AlertConditionFiltersReferralCountToJSON(value?: AlertConditionFiltersReferralCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'more_less': AlertConditionMoreLessToJSON(value.moreLess),
        'unit_type': AlertConditionUnitTypeToJSON(value.unitType),
        'own_departments': AlertConditionTriggersReferralChangeCountOwnDepartmentsToJSON(value.ownDepartments),
    };
}

