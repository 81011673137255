/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * コンポーネント種別
 * @export
 * @enum {string}
 */
export enum CommunicationLabelComponentType {
    Text = 'text',
    Textarea = 'textarea',
    Integer = 'integer',
    Select = 'select',
    Radio = 'radio',
    Checkbox = 'checkbox',
    Date = 'date',
    Time = 'time',
    OwnDepartment = 'own_department'
}

export function CommunicationLabelComponentTypeFromJSON(json: any): CommunicationLabelComponentType {
    return CommunicationLabelComponentTypeFromJSONTyped(json, false);
}

export function CommunicationLabelComponentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationLabelComponentType {
    return json as CommunicationLabelComponentType;
}

export function CommunicationLabelComponentTypeToJSON(value?: CommunicationLabelComponentType | null): any {
    return value as any;
}

