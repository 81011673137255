/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MedicalInstitutionReportDefinitionDisplayItem,
    MedicalInstitutionReportDefinitionDisplayItemFromJSON,
    MedicalInstitutionReportDefinitionDisplayItemFromJSONTyped,
    MedicalInstitutionReportDefinitionDisplayItemToJSON,
} from './MedicalInstitutionReportDefinitionDisplayItem';
import {
    MedicalInstitutionReportFilterCondition,
    MedicalInstitutionReportFilterConditionFromJSON,
    MedicalInstitutionReportFilterConditionFromJSONTyped,
    MedicalInstitutionReportFilterConditionToJSON,
} from './MedicalInstitutionReportFilterCondition';
import {
    MedicalInstitutionReportSortCondition,
    MedicalInstitutionReportSortConditionFromJSON,
    MedicalInstitutionReportSortConditionFromJSONTyped,
    MedicalInstitutionReportSortConditionToJSON,
} from './MedicalInstitutionReportSortCondition';

/**
 * 
 * @export
 * @interface PutMedicalInstitutionReportDefinitionRequest
 */
export interface PutMedicalInstitutionReportDefinitionRequest {
    /**
     * レポート名
     * @type {string}
     * @memberof PutMedicalInstitutionReportDefinitionRequest
     */
    name: string;
    /**
     * 表示項目一覧
     * @type {Array<MedicalInstitutionReportDefinitionDisplayItem>}
     * @memberof PutMedicalInstitutionReportDefinitionRequest
     */
    displayItems: Array<MedicalInstitutionReportDefinitionDisplayItem>;
    /**
     * 連携先絞り込み条件一覧
     * @type {Array<MedicalInstitutionReportFilterCondition>}
     * @memberof PutMedicalInstitutionReportDefinitionRequest
     */
    filterConditions: Array<MedicalInstitutionReportFilterCondition>;
    /**
     * 並び替え条件一覧
     * @type {Array<MedicalInstitutionReportSortCondition>}
     * @memberof PutMedicalInstitutionReportDefinitionRequest
     */
    sortConditions: Array<MedicalInstitutionReportSortCondition>;
}

export function PutMedicalInstitutionReportDefinitionRequestFromJSON(json: any): PutMedicalInstitutionReportDefinitionRequest {
    return PutMedicalInstitutionReportDefinitionRequestFromJSONTyped(json, false);
}

export function PutMedicalInstitutionReportDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutMedicalInstitutionReportDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'displayItems': ((json['display_items'] as Array<any>).map(MedicalInstitutionReportDefinitionDisplayItemFromJSON)),
        'filterConditions': ((json['filter_conditions'] as Array<any>).map(MedicalInstitutionReportFilterConditionFromJSON)),
        'sortConditions': ((json['sort_conditions'] as Array<any>).map(MedicalInstitutionReportSortConditionFromJSON)),
    };
}

export function PutMedicalInstitutionReportDefinitionRequestToJSON(value?: PutMedicalInstitutionReportDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'display_items': ((value.displayItems as Array<any>).map(MedicalInstitutionReportDefinitionDisplayItemToJSON)),
        'filter_conditions': ((value.filterConditions as Array<any>).map(MedicalInstitutionReportFilterConditionToJSON)),
        'sort_conditions': ((value.sortConditions as Array<any>).map(MedicalInstitutionReportSortConditionToJSON)),
    };
}

