/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationReportDefinitionAggregationDateGroupingEnum,
    CommunicationReportDefinitionAggregationDateGroupingEnumFromJSON,
    CommunicationReportDefinitionAggregationDateGroupingEnumFromJSONTyped,
    CommunicationReportDefinitionAggregationDateGroupingEnumToJSON,
} from './CommunicationReportDefinitionAggregationDateGroupingEnum';

/**
 * 集計設定：行
 * @export
 * @interface CommunicationReportDefinitionAggregationRow
 */
export interface CommunicationReportDefinitionAggregationRow {
    /**
     * 集計対象の項目のキー
     * @type {string}
     * @memberof CommunicationReportDefinitionAggregationRow
     */
    itemKey: string;
    /**
     * 
     * @type {CommunicationReportDefinitionAggregationDateGroupingEnum}
     * @memberof CommunicationReportDefinitionAggregationRow
     */
    dateGrouping?: CommunicationReportDefinitionAggregationDateGroupingEnum;
}

export function CommunicationReportDefinitionAggregationRowFromJSON(json: any): CommunicationReportDefinitionAggregationRow {
    return CommunicationReportDefinitionAggregationRowFromJSONTyped(json, false);
}

export function CommunicationReportDefinitionAggregationRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationReportDefinitionAggregationRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemKey': json['item_key'],
        'dateGrouping': !exists(json, 'date_grouping') ? undefined : CommunicationReportDefinitionAggregationDateGroupingEnumFromJSON(json['date_grouping']),
    };
}

export function CommunicationReportDefinitionAggregationRowToJSON(value?: CommunicationReportDefinitionAggregationRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_key': value.itemKey,
        'date_grouping': CommunicationReportDefinitionAggregationDateGroupingEnumToJSON(value.dateGrouping),
    };
}

