/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartVisitsRequestPeriod,
    PostChartVisitsRequestPeriodFromJSON,
    PostChartVisitsRequestPeriodFromJSONTyped,
    PostChartVisitsRequestPeriodToJSON,
} from './PostChartVisitsRequestPeriod';

/**
 * 
 * @export
 * @interface PostChartVisitsNextActionRatesRequest
 */
export interface PostChartVisitsNextActionRatesRequest {
    /**
     * 担当者のID (null の場合は全担当者の合算)
     * @type {Array<string>}
     * @memberof PostChartVisitsNextActionRatesRequest
     */
    staffIds: Array<string> | null;
    /**
     * 
     * @type {PostChartVisitsRequestPeriod}
     * @memberof PostChartVisitsNextActionRatesRequest
     */
    period: PostChartVisitsRequestPeriod;
    /**
     * ネクストアクション設定率の集計時間単位
     * `month`, `year`
     * @type {string}
     * @memberof PostChartVisitsNextActionRatesRequest
     */
    unitType: PostChartVisitsNextActionRatesRequestUnitTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostChartVisitsNextActionRatesRequestUnitTypeEnum {
    Month = 'month',
    Year = 'year'
}

export function PostChartVisitsNextActionRatesRequestFromJSON(json: any): PostChartVisitsNextActionRatesRequest {
    return PostChartVisitsNextActionRatesRequestFromJSONTyped(json, false);
}

export function PostChartVisitsNextActionRatesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChartVisitsNextActionRatesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'staffIds': json['staff_ids'],
        'period': PostChartVisitsRequestPeriodFromJSON(json['period']),
        'unitType': json['unit_type'],
    };
}

export function PostChartVisitsNextActionRatesRequestToJSON(value?: PostChartVisitsNextActionRatesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'staff_ids': value.staffIds,
        'period': PostChartVisitsRequestPeriodToJSON(value.period),
        'unit_type': value.unitType,
    };
}

