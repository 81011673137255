/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostActionListItemsBulkInsertRequest
 */
export interface PostActionListItemsBulkInsertRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PostActionListItemsBulkInsertRequest
     */
    medicalInstitutionIds: Array<number>;
}

export function PostActionListItemsBulkInsertRequestFromJSON(json: any): PostActionListItemsBulkInsertRequest {
    return PostActionListItemsBulkInsertRequestFromJSONTyped(json, false);
}

export function PostActionListItemsBulkInsertRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostActionListItemsBulkInsertRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medicalInstitutionIds': json['medical_institution_ids'],
    };
}

export function PostActionListItemsBulkInsertRequestToJSON(value?: PostActionListItemsBulkInsertRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_institution_ids': value.medicalInstitutionIds,
    };
}

