/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 連携先の状態を表す列挙型。DB上はarchiveも存在するが、これは実質ジャンクデータで、api側で必ずフィルタする。spa側では扱わないので含めていない。
 * @export
 * @enum {string}
 */
export enum MedicalInstitutionRunningStatusEnum {
    Open = 'open',
    Closed = 'closed',
    Suspended = 'suspended',
    Unknown = 'unknown'
}

export function MedicalInstitutionRunningStatusEnumFromJSON(json: any): MedicalInstitutionRunningStatusEnum {
    return MedicalInstitutionRunningStatusEnumFromJSONTyped(json, false);
}

export function MedicalInstitutionRunningStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionRunningStatusEnum {
    return json as MedicalInstitutionRunningStatusEnum;
}

export function MedicalInstitutionRunningStatusEnumToJSON(value?: MedicalInstitutionRunningStatusEnum | null): any {
    return value as any;
}

