/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PostChartReferralsRequestPeriod,
    PostChartReferralsRequestPeriodFromJSON,
    PostChartReferralsRequestPeriodFromJSONTyped,
    PostChartReferralsRequestPeriodToJSON,
} from './PostChartReferralsRequestPeriod';

/**
 * 
 * @export
 * @interface PostMedicalInstitutionReferralsRequest
 */
export interface PostMedicalInstitutionReferralsRequest {
    /**
     * ページ番号（１始まり）
     * @type {number}
     * @memberof PostMedicalInstitutionReferralsRequest
     */
    page: number | null;
    /**
     * １ページあたりの件数
     * @type {number}
     * @memberof PostMedicalInstitutionReferralsRequest
     */
    perPage: number | null;
    /**
     * グループ病院のID (null の場合は全グループ病院の合算)
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionReferralsRequest
     */
    groupMedicalInstitutionIds: Array<string> | null;
    /**
     * 初再診フラグ
     * `all`, `first_visit`, `return_visit`
     * @type {string}
     * @memberof PostMedicalInstitutionReferralsRequest
     */
    firstVisitFlag: PostMedicalInstitutionReferralsRequestFirstVisitFlagEnum;
    /**
     * 診療科目のID (null の場合は全診療科目)
     * @type {Array<string>}
     * @memberof PostMedicalInstitutionReferralsRequest
     */
    ownDepartmentIds: Array<string> | null;
    /**
     * 
     * @type {PostChartReferralsRequestPeriod}
     * @memberof PostMedicalInstitutionReferralsRequest
     */
    period: PostChartReferralsRequestPeriod;
    /**
     * 紹介・入院種別
     * `referral`, `admission`, `operation`
     * @type {string}
     * @memberof PostMedicalInstitutionReferralsRequest
     */
    referralAdmissionType: PostMedicalInstitutionReferralsRequestReferralAdmissionTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PostMedicalInstitutionReferralsRequestFirstVisitFlagEnum {
    All = 'all',
    FirstVisit = 'first_visit',
    ReturnVisit = 'return_visit'
}/**
* @export
* @enum {string}
*/
export enum PostMedicalInstitutionReferralsRequestReferralAdmissionTypeEnum {
    Referral = 'referral',
    Admission = 'admission',
    Operation = 'operation'
}

export function PostMedicalInstitutionReferralsRequestFromJSON(json: any): PostMedicalInstitutionReferralsRequest {
    return PostMedicalInstitutionReferralsRequestFromJSONTyped(json, false);
}

export function PostMedicalInstitutionReferralsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostMedicalInstitutionReferralsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'perPage': json['per_page'],
        'groupMedicalInstitutionIds': json['group_medical_institution_ids'],
        'firstVisitFlag': json['first_visit_flag'],
        'ownDepartmentIds': json['own_department_ids'],
        'period': PostChartReferralsRequestPeriodFromJSON(json['period']),
        'referralAdmissionType': json['referral_admission_type'],
    };
}

export function PostMedicalInstitutionReferralsRequestToJSON(value?: PostMedicalInstitutionReferralsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'per_page': value.perPage,
        'group_medical_institution_ids': value.groupMedicalInstitutionIds,
        'first_visit_flag': value.firstVisitFlag,
        'own_department_ids': value.ownDepartmentIds,
        'period': PostChartReferralsRequestPeriodToJSON(value.period),
        'referral_admission_type': value.referralAdmissionType,
    };
}

